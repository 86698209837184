import httpAuth from '../../services/httpAuth'
import httpNotAuth from '../../services/httpNotAuth'

import getErrorMessage from '../../helpers/getErrorMessage.helper'
import { getLiveCasinoSuccess, getLiveCasinoError, allGames, topGames, liveGames, tableGames, pokerGames, blackjackGames, baccaratGames, rouletteGames, showsGames, virtualGames, getLiveCasinoIDSuccess, getLiveCasinoIDError} from './liveCasino.slice'
import axios from 'axios'
import { BASE_DEV_URL } from '../../config/api.config'
import { IndianCasino } from '../../constants/Casino/IndainCasino'
import { getCasinoPointError, getCasinoPointSuccess } from './cashPoint.slice'

export const requestLiveCasino = (id) => async (dispatch, getState) => {
    const checkAuth = getState().auth
    
    if(checkAuth) {
        try {
            const response = await httpAuth.get(`/casino/v2/games/${id}`)
           if (response.data.success) {
               if(id === '11'){
                dispatch(allGames(response.data.result))
               } else if (id === '12'){
                dispatch(topGames(response.data.result))
               } else if (id === '14'){
                dispatch(liveGames(response.data.result))
               } else if (id === '13'){
                dispatch(tableGames(response.data.result))
               } else if (id === '15'){
                dispatch(pokerGames(response.data.result))
               } else if (id === '16'){
                dispatch(blackjackGames(response.data.result))
               } else if (id === '17'){
                dispatch(baccaratGames(response.data.result))
               } else if (id === '18'){
                dispatch(rouletteGames(response.data.result))
               } else if (id === '19'){
                dispatch(showsGames(response.data.result))
               } else if (id === '20'){
                dispatch(virtualGames(response.data.result))
               } 
            }
            return response
        } catch (e) {
            dispatch(getLiveCasinoError())
            const errorMsg = getErrorMessage(e)
            throw errorMsg
        }
    } else {
        try {
            const response = await httpNotAuth.get(`/casino/v2/games/${id}`)
           if (response.data.success) {
               if(id === '11'){
                dispatch(allGames(response.data.result))
               } else if (id === '12'){
                dispatch(topGames(response.data.result))
               } else if (id === '14'){
                dispatch(liveGames(response.data.result))
               } else if (id === '13'){
                dispatch(tableGames(response.data.result))
               } else if (id === '15'){
                dispatch(pokerGames(response.data.result))
               } else if (id === '16'){
                dispatch(blackjackGames(response.data.result))
               } else if (id === '17'){
                dispatch(baccaratGames(response.data.result))
               } else if (id === '18'){
                dispatch(rouletteGames(response.data.result))
               } else if (id === '19'){
                dispatch(showsGames(response.data.result))
               } else if (id === '20'){
                dispatch(virtualGames(response.data.result))
               } 
            }
            return response
        } catch (e) {
            dispatch(getLiveCasinoError())
            const errorMsg = getErrorMessage(e)
            throw errorMsg
        }
    }
}

export const requestLiveCasinoLobby = () => async (dispatch, getState) => {
    const checkAuth = getState().auth
    const data = {sport_id: "-100"}
    if(checkAuth) {
        try {
            const response = await httpAuth.post(`/match/homeMatches`, data)
           if (response.data.status) {
            dispatch(getLiveCasinoSuccess(response.data.data))
        }
        return response
        } catch (e) {
            dispatch(getLiveCasinoError())
        }
    } else {
        dispatch(getLiveCasinoSuccess(IndianCasino))
        try {
            const response = await httpNotAuth.post(`/match/homeMatchesOpen`, data)
           if (response.data.status) {
            dispatch(getLiveCasinoSuccess(response.data.data))
        }
        return response
        } catch (e) {
            dispatch(getLiveCasinoError())
        }
    }
}

export const requestLiveCasinoID = (id) => async (dispatch) => {
    try {
        let data = { game_id: id}
        const response = await httpAuth.post(`lotus/launchUrl`, data)
       if (response.data.status) {
        dispatch(getLiveCasinoIDSuccess(response.data.launchUrl))
    }
    return response
    } catch (e) {
        dispatch(getLiveCasinoIDError())
        const errorMsg = getErrorMessage(e)
        throw errorMsg
    }
}

export const requestQTechCasinoID = (data) => async (dispatch) => {
    try {
        const response = await httpAuth.post(`qtech/launchUrl`, data)
       if (response.data.status) {
        dispatch(getLiveCasinoIDSuccess(response.data.data.url))
    }
    return response
    } catch (e) {
        dispatch(getLiveCasinoIDError())
        const errorMsg = getErrorMessage(e)
        throw errorMsg
    }
}

export const requestCasinoPoint = () => async (dispatch) => {
    try {
      const response = await httpAuth.get(`/getCasinoConversionRate`)
      if (response.data.status) {
        dispatch(getCasinoPointSuccess(response.data.data.casino_conversion_rate))
      }
      return response
    } catch (e) {
      dispatch(getCasinoPointError())
      // const errorMsg = getErrorMessage(e)
      // throw errorMsg
    }
  }

