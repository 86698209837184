import React, { useState, useEffect, useRef } from 'react'
import './LiveSection.scss'
import { useDispatch } from 'react-redux'
import { toastr } from 'react-redux-toastr'
import { connectLiveSection } from './LiveSection.connect'
import {
  IonLabel,
  IonButton,
  useIonViewWillEnter,
} from '@ionic/react'
import { useHistory } from 'react-router'
import isEmptyObjectHelper from '../../helpers/isEmptyObject.helper'
import { USER_DATA } from '../../config/app.config'

const tabs = [
  {
    name: 'MATCH STATS',
  },
  {
    name: 'WATCH LIVE',
    icon: <span className="LiveSection__tv-icon"></span>,
  },
]

const LiveSection = ({
  eventTypeId,
  eventId,
  sportEventStats,
  sportVideo,
  sportVisualisation,
  historyVideo,
  actions,
  exchangeGames,
  auth,
  elementsParams,
  balance,
}) => {
  const history = useHistory()
  const [activeTab, setActiveTab] = useState(0)
  const [activeIframe, setActiveIframe] = useState(true)
  const refVideo = useRef(null)

  // window width
  const width =
    elementsParams && elementsParams?.clientWidth
      ? elementsParams?.clientWidth
      : window.innerWidth
  // end window width

  const virtualsGamesId = [
    '98792',
    '56966',
    '92036',
    '98793',
    '56766',
    '56769',
    '56969',
    '67563',
    '87565',
    '92036',
    '98795',
    '67566',
    '98794',
  ]

  let timer = exchangeGames && exchangeGames[0].timer

  // set timer coords
  let iframeRef = useRef()
  let iframeWidth = iframeRef?.current?.offsetWidth
  let wrapperRef = useRef()
  let wrapperWitdh = wrapperRef?.current?.offsetWidth
  let coords = (wrapperWitdh - iframeWidth) / 2 + 40

  // const [renderWidget, setRenderWidget] = useState(false);

  // useEffect(() => {
  //   setRenderWidget(false);
  //   setTimeout(() => {
  //     setRenderWidget(true);
  //   }, 1000);
  // }, [sportVisualisation, eventTypeId, eventId, history.location.pathname]);

  // useEffect(() => {
  //   let matchId = sportVisualisation.url.match(/\/([\w.]+)\?token/)?.[1]
  //   window.SIR('addWidget', '.sr-widget-1', 'match.lmtPlus', {
  //     showOdds: true,
  //     layout: 'double',
  //     tabsPosition: 'top',
  //     matchId,
  //     collapseTo: 'disable',
  //   })
  // }, [auth])

  useIonViewWillEnter(() => {
    // dispatch(getSportVideoSuccess(null));
    // dispatch(getSportVisualisationSuccess(null));
    // actions
    //   .requestSportVisualisation(eventTypeId, eventId)
    //   .catch((e) => console.log("error = ", e));
    const matchId = sportVisualisation?.url?.match(/\/([\w.]+)\?token/)?.[1]
    // window.SIR('addWidget', '.sr-widget-1', 'match.lmtPlus', {
    //   showOdds: true,
    //   layout: 'double',
    //   tabsPosition: 'top',
    //   matchId,
    //   collapseTo: 'dashboard',
    // })
    window && window.SIR && window.SIR('addWidget', '.sr-widget-1', 'match.lmtPlus', {
      branding: {
        tabs: { option: 'icon', iconPosition: 'start', variant: 'fullWidth' },
      },
      layout: 'double',
      collapseTo: 'momentum',
      goalBannerCustomBgColor: '#027B5C',
      pitchLogo:
        './assets/images/' +
        window.location.hostname.replace('www.', '').replace('app.', '') +
        '/logo.png',
      goalBannerImage:
        './assets/images/' +
        window.location.hostname.replace('www.', '').replace('app.', '') +
        '/logo.png',
      logo: [
        './assets/images/' +
          window.location.hostname.replace('www.', '').replace('app.', '') +
          '/logo.png',
      ],
      matchId,
    })
  }, [auth, eventTypeId, eventId, history.location.pathname])

  // useEffect(() => {
  //   launchFrame();
  // }, [auth, eventTypeId, eventId, history.location.pathname]);

  // var iframe = document?.querySelector('[title="Live video"]')
  // console.log('iframeHeight', iframe);

  // end set timer coords

  const dispatch = useDispatch()

  useEffect(() => {
    if (sportVideo && !sportVideo.tv_url && sportVideo.match_scoreboard_url)
      setActiveTab(0)
    if (sportVideo && sportVideo.tv_url && !sportVideo.match_scoreboard_url)
      setActiveTab(1)
  }, [sportEventStats])

  useEffect(() => {
    // if (activeTab === 0)
    //   actions
    //     .requestSportVisualisation(eventTypeId, eventId)
    //     .catch((e) => console.log("error = ", e));
    // if (activeTab === 1) {
    //   actions
    //     .requestSportVideo(eventTypeId, eventId)
    //     .catch((e) => console.log("error = ", e));
    // }
  }, [activeTab, eventId, history.location.pathname])

  const renderTabs = () => {
    if (sportVideo && sportVideo.tv_url && sportVideo.match_scoreboard_url)
      return (
        <div className="ion-toolbar__wrapper toolbar__tabs">
          <div className="toolbar__tabs__bar" slot="bottom">
            {tabs.map(
              (tab, index) =>
                tab && (
                  <div
                    className={`toolbar__tabs__bar__tab ${
                      activeTab == index ? 'active' : ''
                    }`}
                    onClick={() => {
                      let user = localStorage.getItem(USER_DATA)
                      let userData = JSON.parse(user)
                      if (index === 1 && userData?.is_demo) {
                        toastr.error(
                          '',
                          'Please login from your read ID.'
                        )
                        return
                      } else if (index === 1 && Number(balance?.balance) <= 0) {
                        if (Number(balance?.liability) === 0){
                          toastr.error(
                            '',
                            'For watch streaming, plz add amount in your wallet Id.'
                          )
                          return
                        } 
                        
                      }
                      setActiveTab(index)
                    }}
                    key={index}
                  >
                    <IonLabel>{tab.name}</IonLabel>
                    {tab.icon}
                  </div>
                )
            )}
          </div>
        </div>
      )
    return null
  }

  //Not in use temporary
  const renderScoreboardBtn = () => {
    if (sportEventStats && sportEventStats.scorecard)
      return (
        <IonButton
          class="LiveSection__btn"
          mode="md"
          onClick={() => {
            history.push({
              pathname: `/scorecard/${eventTypeId}/${eventId}`,
            })
          }}
        >
          Scorecard
        </IonButton>
      )
    return null
  }

  useEffect(() => {
    if (
      sportVisualisation &&
      isEmptyObjectHelper(sportVisualisation) &&
      (eventTypeId == 1 ||
        eventTypeId == 2 ||
        (eventTypeId == 4 &&
          (sportEventStats?.isBRScore ||
            sportEventStats?.stats?.eventId == 3004)))
    ) {
      const matchId = sportVisualisation.url.match(/\/([\w.]+)\?token/)?.[1]
      // window.SIR('addWidget', '.sr-widget-1', 'match.lmtPlus', {
      //   showOdds: true,
      //   layout: 'double',
      //   tabsPosition: 'top',
      //   matchId,
      //   collapseTo: 'dashboard',
      // })
      window.SIR('addWidget', '.sr-widget-1', 'match.lmtPlus', {
        branding: {
          tabs: { option: 'icon', iconPosition: 'start', variant: 'fullWidth' },
        },
        layout: 'double',
        collapseTo: 'momentum',
        goalBannerCustomBgColor: '#027B5C',
        pitchLogo:
          './assets/images/' +
          window.location.hostname.replace('www.', '').replace('app.', '') +
          '/logo.png',
        goalBannerImage:
          './assets/images/' +
          window.location.hostname.replace('www.', '').replace('app.', '') +
          '/logo.png',
        logo: [
          './assets/images/' +
            window.location.hostname.replace('www.', '').replace('app.', '') +
            '/logo.png',
        ],
        matchId,
      })
    }
  }, [sportVisualisation, eventTypeId, eventId, history.location.pathname])

  const heightVideo = (iframeWidth / 16) * 9

  const renderMatchStats = () => {
    // sportVideo &&
    //   sportVideo.tv_url &&
    //   sportVideo.match_scoreboard_url
    if (sportVideo?.match_scoreboard_url) {
      // if (
      //   !sportEventStats?.isBRScore &&
      //   sportEventStats?.stats?.eventId != 3004 &&
      //   eventTypeId == 4
      // ) {
      //   return <MatchStats stats={sportEventStats?.stats} />;
      // }
      if (sportVideo?.match_scoreboard_url) {
        // if (
        //   eventTypeId == 1 ||
        //   eventTypeId == 2 ||
        //   ((sportEventStats?.isBRScore ||
        //       sportEventStats?.stats?.eventId == 3004) &&
        //     eventTypeId == 4)
        // ) {
        //   return (
        //     <div className="widgets">
        //       <div>
        //         <div className="sr-widget sr-widget-1"></div>
        //       </div>
        //     </div>
        //   );
        // } else {
        return (
          <iframe
            className={`LiveSection__content__video ${
              eventTypeId == 1 && 'football'
            } ${eventTypeId == 2 && 'tennis'} ${
              eventTypeId == 4 && 'cricket'
            } sportVisualisation`}
            allow="autoplay"
            allowFullScreen={true}
            frameBorder="0"
            marginHeight="0"
            marginWidth="0"
            scrolling="no"
            title="Live video"
            src={sportVideo.match_scoreboard_url}
            ref={iframeRef}
          ></iframe>
        )
      }
      // }
    }
    return null
  }

  // const renderMatchStatsnotAuth = () => {
  //   if (
  //     !sportEventStats?.isBRScore &&
  //     sportEventStats?.stats?.eventId != 3004 &&
  //     eventTypeId == 4
  //   ) {
  //     return (<MatchStats stats={sportEventStats.stats} />);
  //   } else if ((sportVisualisation && sportVisualisation.url) &&
  //     eventTypeId == 1 ||
  //     eventTypeId == 2 ||
  //     ((sportEventStats?.isBRScore ||
  //         sportEventStats?.stats?.eventId == 3004) &&
  //       eventTypeId == 4)
  //   ) {
  //     const matchId = sportVisualisation?.url?.match(/\/([\w.]+)\?token/)?.[1];
  //     //   window.SIR('addWidget', '.sr-widget-1', 'match.lmtPlus', {
  //     //   showOdds: true,
  //     //   layout: 'double',
  //     //   tabsPosition: 'top',
  //     //   matchId,
  //     //   collapseTo: 'dashboard',
  //     // })
  //     window.SIR("addWidget", ".sr-widget-1", "match.lmtPlus",
  //       {
  //         branding:
  //           {
  //             tabs:
  //               { option: "icon", iconPosition: "start", variant: "fullWidth" }
  //           },
  //         layout: "double",
  //         collapseTo: "momentum",
  //         goalBannerCustomBgColor: "#027B5C",
  //         pitchLogo: "./assets/images/" + window.location.hostname.replace("www.", "").replace("app.", "") + "/logo.png",
  //         goalBannerImage: "./assets/images/" + window.location.hostname.replace("www.", "").replace("app.", "") + "/logo.png",
  //         logo: ["./assets/images/" + window.location.hostname.replace("www.", "").replace("app.", "") + "/logo.png"],
  //         matchId
  //       });
  //     return (renderWidget &&
  //       <div className="widgets">
  //         <div>
  //           <div className="sr-widget sr-widget-1"></div>
  //         </div>
  //       </div>
  //     );
  //   } else {
  //     return (renderWidget && <div className="not-auth">
  //       <img loading={"lazy"} src={loginVideo} />
  //       <h1>To see more</h1>
  //       <h2 onClick={() => {
  //         dispatch(setLoginNew(true));
  //       }}>Login</h2>
  //     </div>);
  //   }
  // };

  useEffect(() => {
    if (
      history.location.pathname === `/event-page/` &&
      history.location.pathname.indexOf(`/event-page/`) == -1
    ) {
      setActiveIframe(false)
    }
    if (
      history.location.pathname === `/exchange-game-page/` &&
      history.location.pathname.indexOf(`/exchange-game-page/`) == -1
    ) {
      setActiveIframe(false)
    }
    return () => {
      setActiveIframe(false)
    }
  }, [historyVideo])

  const handleLocalhost = (src) => {
    if (src.includes('/localhost:3000/')) {
      return src.replace(/localhost:3000/, 'open.gemexch.bet')
    } else if (src.includes('gemexch-desktop.d1nv65amemtgb6.amplifyapp.com')) {
      return src.replace(
        /gemexch-desktop.d1nv65amemtgb6.amplifyapp.com/,
        'open.gemexch.bet'
      )
    } else {
      return src
    }
  }

  return (
    <section
      className={`LiveSection ${!auth ? '' : ''} ${
        virtualsGamesId.includes(eventId) ? 'virtual' : ''
      } ${eventId === '98794' ? 'dragon' : ''}`}
    >
      {
        auth ? (
          <>
            <div
              className={`LiveSection__content ${
                history.location.pathname.includes(`/event-page/`)
                  ? 'eventPageFixLiveSection'
                  : ''
              } ${
                sportEventStats?.showVisualisation &&
                !sportEventStats?.isBRScore &&
                sportEventStats?.stats?.eventId != 3004 &&
                eventTypeId == 4
                  ? 'LiveSection__content-withStats'
                  : ''
              }`}
              ref={wrapperRef}
            >
              {activeTab === 0 && activeIframe && renderMatchStats()}
              {activeTab === 1 &&
                activeIframe &&
                sportVideo &&
                sportVideo.tv_url && (
                  <>
                    {/* {sportEventStats?.showVisualisation &&
                      !sportEventStats?.isBRScore &&
                      sportEventStats?.stats?.eventId != 3004 &&
                      eventTypeId == 4 &&
                      (width > 1024) &&
                      <MatchStats stats={sportEventStats.stats} statsInVideoTab={true} />} */}
                    <iframe
                      ref={refVideo}
                      className={`LiveSection__content__video ${
                        sportEventStats?.showVisualisation &&
                        !sportEventStats?.isBRScore &&
                        sportEventStats?.stats?.eventId != 3004 &&
                        eventTypeId == 4 &&
                        width > 1024
                          ? 'LiveSection__content__video-withStats'
                          : ''
                      }`}
                      allow="autoplay"
                      allowFullScreen={true}
                      frameBorder="0"
                      marginHeight="0"
                      marginWidth="0"
                      scrolling="no"
                      title="Live video"
                      src={sportVideo.tv_url}
                    ></iframe>
                  </>
                )}
              {/* {exchangeGames &&
                exchangeGames[0] &&
                exchangeGames[0].timer !== 0 &&
                !virtualsGamesId.includes(eventId) &&
                history.location.pathname.indexOf(`/exchange-game-page/`) !==
                  -1 && <RoundTimer timer={timer} coords={coords} />} */}
            </div>
            {renderScoreboardBtn()}
            {renderTabs()}
          </>
        ) : (
          <>
            {/* {(sportEventStats?.showVisualisation && !sportEventStats?.isBRScore && sportEventStats?.stats?.eventId != 3004 && eventTypeId == 4  && <MatchStats stats={sportEventStats.stats}/>)
          ||
          (
            <div className='not-auth'>
              <img src={loginVideo} />
              <h1>To see more</h1>
              <h2 onClick={() => {dispatch(setLoginNew(true))}}>Login</h2>
            </div>
          )} */}
            {
              // renderMatchStatsnotAuth()
            }

            {/* {(sportEventStats?.showVisualisation && !sportEventStats?.isBRScore && sportEventStats?.stats?.eventId != 3004 && eventTypeId == 4  && <MatchStats stats={sportEventStats.stats}/>)}
          { eventTypeId == 1 ||
          eventTypeId == 2 ||
          ((sportEventStats?.isBRScore ||
            sportEventStats?.stats?.eventId == 3004) &&
            eventTypeId == 4) && <div className="widgets">
            <div>
              <div className="sr-widget sr-widget-1"></div>
            </div>
          </div>}
          ||
          // {sportEventStats?.showVisualisation ? renderMatchStats()
          {(
            <div className='not-auth'>
              <img src={loginVideo} />
              <h1>To see more</h1>
              <h2 onClick={() => {dispatch(setLoginNew(true))}}>Login</h2>
            </div>
          )} */}
          </>
        )
        // <>
        //   {
        //     (sportEventStats?.showVisualisation && !sportEventStats?.isBRScore && sportEventStats?.stats?.eventId != 3004 && eventTypeId == 4)
        //     ?
        //       <MatchStats stats={sportEventStats.stats} statsInVideoTab={true} />
        //     :
        //       <div className='not-auth'>
        //         <img src={loginVideo} />
        //         <h1>To see more</h1>
        //         <h2 onClick={() => {dispatch(setLoginNew(true))}}>Login</h2>
        //       </div>
        //   }
        // </>
      }
    </section>
  )
}

export default connectLiveSection()(LiveSection)
