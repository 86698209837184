import http from "../../services/httpAuth";
import getErrorMessage from "../../helpers/getErrorMessage.helper";
import {getOrderListError, getOrderListSuccess} from "./order-list.slice";

export const
  requestOrderListSuccess = (memberCode, orderStatus, marketId, selectedDateFrom, selectedDateTo) => async (dispatch) => {
      const currentTime = (new Date()).getTime();
      const lastEightDayTime = currentTime - (14 * 24 * 60 * 60 * 1000);

    // try {
    //     const response = await http.get(`/account/${memberCode}/order-list?orderStatus=${orderStatus}${marketId ? `&marketId=${marketId}` :`&to=${selectedDateTo || lastEightDayTime}&from=${selectedDateFrom || currentTime}`}`);
    //     if (response.data.success) {
    //         dispatch(getOrderListSuccess(response.data.result));
    //     }
    //     return response;
    // } catch (e) {
    //     dispatch(getOrderListError());
    //     const errorMsg = getErrorMessage(e);
    //     throw errorMsg;
    // }
};
