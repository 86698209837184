  import { createSlice } from '@reduxjs/toolkit'
import { IS_B2C } from '../../config/api.config'

export const B2CSlice = createSlice({
  name: 'B2C',
  initialState: IS_B2C,
  reducers: {
    setB2C: (state, action) => action.payload,
  },
})

export const { setB2C } = B2CSlice.actions

export default B2CSlice.reducer
