import './index.scss'
import React, { useEffect } from 'react'
import UpdatePopUpCloseIcon from './IconUpdatePopUpCloseIcon.svg'
import { useSelector } from 'react-redux'
// import { Capacitor } from '@capacitor/core'
// import * as serviceWorkerRegistration from '../../serviceWorkerRegistration.js'
import { createPortal } from 'react-dom'
// import { CONFIG } from '../../config/api.config'

const modalRoot = document.querySelector('#modal-root')

const UpdatePopUp = ({ isOpen }) => {
  // const androidVersion = useSelector((state) => state.androidVersion)
  // const clearCacheData = () => {
  //   if ('serviceWorker' in navigator) {
  //     serviceWorkerRegistration.unregister()
  //     caches
  //       .keys()
  //       .then((cacheNames) => {
  //         cacheNames.forEach((cacheName) => {
  //           caches.delete(cacheName)
  //         })
  //       })
  //       .then(() => {
  //         serviceWorkerRegistration.register()
  //       })
  //   }
  //   setTimeout(() => {
  //     window.location.reload()
  //   }, 300)
  // }
  useEffect(() => {
    // console.log(window?.AppVersion?.version, 'window.AppVersion.version')
    // console.log(androidVersion, 'androidVersion')

    // if (
    //   Capacitor.getPlatform() === 'android' &&
    //   window.cordova &&
    //   window.AppVersion?.version &&
    //   androidVersion &&
    //   window.AppVersion?.version !== androidVersion
    // ) {
    //   // AppUpdate.checkAppUpdate(APP_VERSION_XML + "?version=" + randomBytes(4)).then(update => {
    //   //   console.log(update, 'update')
    //   //   // alert("Update Status:  "+update.msg);
    //   // }).catch(error => {
    //   //   console.log(error, 'error')
    //   //   alert("Error: " + error.msg);
    //   // });
    // }
  }, [])
  // const ignoreAndroid = () => {
  //   isOpen = false
  // }

  return createPortal(
    <>
      {/* {isOpen && Capacitor.getPlatform() !== 'android' ? (
        <div className="update-popup">
          <div className="update-popup-content">
            <div className="update-popup-header">
              <button
                className="update-popup-header__close"
                onClick={() => {
                  if (Capacitor.getPlatform() !== 'android') clearCacheData()
                  else ignoreAndroid()
                }}
              >
                <img
                  src={UpdatePopUpCloseIcon}
                  alt="close"
                  className="update-popup__close-icon"
                />
              </button>
            </div>
            <div className="update-popup-container">
              <div className="update-popup-container__text">
                <h2>New version available!</h2>
                <p>
                  Please update to the new version to experience latest
                  features.
                </p>
              </div>
              {Capacitor.getPlatform() !== 'android' && (
                <button
                  className="update-popup__submit-button"
                  onClick={() => {
                    clearCacheData()
                  }}
                >
                  <span>UPDATE</span>
                </button>
              )}
              {Capacitor.getPlatform() === 'android' && (
                <button
                  className="update-popup__submit-button"
                  onClick={() => {
                    window.open(CONFIG.downloadAppLink, '_blank')
                  }}
                >
                  <span>Download App</span>
                </button>
              )}
            </div>
          </div>
        </div>
      ) : null} */}
    </>,
    modalRoot
  )
}

export default UpdatePopUp
