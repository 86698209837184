import { createSlice } from '@reduxjs/toolkit'

export const fortuneWheelSlice = createSlice({
  name: 'fortuneWheel',
  initialState: null,
  reducers: {
    getFortuneWheelSuccess: (state, action) => action.payload,
    getFortuneWheelError: (state) => null
  },
})

export const { getFortuneWheelSuccess, getFortuneWheelError } = fortuneWheelSlice.actions

export default fortuneWheelSlice.reducer
