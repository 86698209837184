import httpAuth from '../../services/httpAuth'
import getErrorMessage from '../../helpers/getErrorMessage.helper'
import { getQTechGamesSuccess, getQTechGamesError } from './qTechGames.slice'


export const requestQtechGames = (provider) => async (dispatch) => {
  try {
    const response = await httpAuth.get(`/qtech/gameList?providers=${provider}`)
    if (response.data.status) {
      dispatch(getQTechGamesSuccess(response.data.data?.items))
    }
    return response
  } catch (e) {
    dispatch(getQTechGamesError())
    // const errorMsg = getErrorMessage(e)
    // throw errorMsg
  }
}
