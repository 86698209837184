import './VerifyPopUp.scss'
import { IonButton } from '@ionic/react'
import React, { useRef, useState, useEffect } from 'react'
import { createPortal } from 'react-dom'
// import editImg from '../../../assets/images/profile/edit.png'
import closeImg from '../../../assets/images/profile/closePopUp.svg'
import { connectProfile } from '../Profile.connect'

const modalRoot = document.querySelector('#modal-root')

const VerifyPopUp = ({ setVerifyPopUp, countryCode, mobile, actions }) => {
  const inputRefs = useRef([])
  const numInputs = 4

  const [otp, setOtp] = useState(['', '', '', ''])
  const [otpError, setOtpError] = useState(false)


  const handleInputChange = (event, currentIndex) => {
    setOtpError(false)
    const inputValue = event.target.value

    if (/^\d+$/.test(inputValue) && currentIndex < numInputs) {
      const newOtp = [...otp]
      newOtp[currentIndex] = inputValue
      setOtp(newOtp)

      if (inputValue.length === 1 && currentIndex < numInputs - 1) {
        inputRefs.current[currentIndex + 1].focus()
      }
    }
  }

  useEffect(() => {
    actions
      .requestMobileVerification(countryCode, mobile)
      .then(() => {})
      .catch((e) => {
        console.log('E', e)
      })
    if (inputRefs.current[0]) {
      inputRefs.current[0].focus()
    }
  }, [actions, countryCode, mobile])
  const verifyOTP = async () => {
    setOtpError(false)
    actions
      .requestOTPVerification(countryCode, mobile, otp.join(''))
      .then(() => {
        actions.requestProfile()
        setVerifyPopUp(false)
      })
      .catch((e) => {
        setOtpError(true)
        console.log('E', e)
      })
    // toastr.success('', t('pages.profilePage.otpSuccessMsg'))
  }

  const resendOTP = async () => {
    setOtpError(false)
    await actions.requestMobileVerification('+91', mobile).then((res) => {
      console.log('res', res)
    })
    // toastr.success('', t('pages.profilePage.otpResendMsg'))
  }

  console.log('OTP', otp)

  return createPortal(
    <div
      className="VerifyPopUp"
      onClick={(e) => {
        if (e.currentTarget === e.target) {
          setVerifyPopUp(false)
        }
      }}
    >
      <div className="VerifyPopUp_content">
        <div className="VerifyPopUp_content_header">
          <img src={closeImg} onClick={() => setVerifyPopUp(false)} alt="X" />
        </div>

        <>
          <div className="VerifyPopUp_content_subTitle">
            We have sent OTP to {countryCode} {mobile}
          </div>

          <div className="VerifyPopUp_content_otpBox">
            {Array.from({ length: numInputs }, (_, index) => (
              <input
                key={index}
                ref={(el) => (inputRefs.current[index] = el)}
                // value={otp[index]}
                onFocus={(e) => (e.target.value = '')}
                onChange={(e) => handleInputChange(e, index)}
                maxLength={1}
                type="text"
                autoComplete="one-time-code"
                className="VerifyPopUp_content_otpBox-item"
              />
            ))}
          </div>
        </>
        <div className="VerifyPopUp_content_otpBtns">
          <div
            className="VerifyPopUp_content_otpBtns_resendBtn"
            onClick={() => resendOTP()}
          >
            Resend
          </div>
          {otpError && <div className="VerifyPopUp_content_otpBtns_resendBtn otpError">
            Invalid OTP provided
          </div>}
        </div>

        <IonButton
          onClick={verifyOTP}
          mode="md"
          class="VerifyPopUp_content_btn"
        >
          Verify
        </IonButton>
      </div>
    </div>,
    modalRoot
  )
}

export default connectProfile()(VerifyPopUp)
