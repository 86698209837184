export const joidTigari = (selectedRaces) => {
    let resultBack  = 0;
      let resultLay  = 0;
      let totalOddsBack = 0;
      let totalOddsLay = 0;

      let firstBack = (Number(selectedRaces[0]?.ex?.availableToBack[0]?.price) -1) || 0;
      let firstLay = (Number(selectedRaces[0]?.ex?.availableToLay[0]?.price) -1) || 0;
      let secondBack = (Number(selectedRaces[1]?.ex?.availableToBack[0]?.price) -1) || 0;
      let secondLay = (Number(selectedRaces[1]?.ex?.availableToLay[0]?.price) -1) || 0;

      totalOddsBack = firstBack + secondBack + 2;
      totalOddsLay = firstLay + secondLay + 2;

      let firstBackAdd = firstBack + secondBack + 2;
      let firstLayAdd = firstLay + secondLay + 2;

      let firstBackSum = (firstBack * secondBack) - 2;
      let firstLaySum = (firstLay * secondLay ) - 2;

      let firstBackResult = (firstBackSum / firstBackAdd);
      let firstLayResult = (firstLaySum / firstLayAdd);

      if (selectedRaces.length === 3) {
        let thirdBack = (Number(selectedRaces[2]?.ex?.availableToBack[0]?.price) -1) || 0;
        let thirdLay = (Number(selectedRaces[2]?.ex?.availableToLay[0]?.price) -1) || 0;

        totalOddsBack = totalOddsLay + thirdBack + 1;
        totalOddsLay = totalOddsLay + thirdLay + 1;

        let secondBackAdd = firstBackResult + thirdBack + 2;
        let secondLayAdd = firstLayResult + thirdLay + 2;

        let secondBackSum = (firstBackResult * thirdBack) - 2;
        let secondLaySum = (firstLayResult * thirdLay ) - 2;

        firstBackResult = (secondBackSum / secondBackAdd);
        firstLayResult = (secondLaySum / secondLayAdd); 
      }
      if (selectedRaces.length === 4) {
        let fourthBack = (Number(selectedRaces[3]?.ex?.availableToBack[0]?.price) -1) || 0;
        let fourthLay = (Number(selectedRaces[3]?.ex?.availableToLay[0]?.price) -1) || 0;

        totalOddsBack = totalOddsBack + fourthBack + 1;
        totalOddsLay = totalOddsLay + fourthLay + 1;

        let fourthBackAdd = firstBackResult + fourthBack + 2;
        let fourthLayAdd = firstLayResult + fourthLay + 2;

        let fourthBackSum = (firstBackResult * fourthBack) - 2;
        let fourthLaySum = (firstLayResult * fourthLay ) - 2;

        firstBackResult = (fourthBackSum / fourthBackAdd);
        firstLayResult = (fourthLaySum / fourthLayAdd); 
      }

      resultBack = firstBackResult + 1;
      resultLay = firstLayResult + 1;

      let finalResult = 
        {
          "sport_id":"",
          "match_id":selectedRaces[0]?.match_id,
          "match_name":selectedRaces[0]?.match_name,
          "match_date":selectedRaces[0]?.match_date,
          "market_id":selectedRaces[0]?.market_id,
          "name":selectedRaces[0]?.name,
          "market_name":selectedRaces[0]?.market_name,
          "market_type":selectedRaces[0]?.market_type,
          "status":"OPEN",
          "inplay":false,
          "runners":[
             {
                "selectionId":74080478,
                "name":"1. Lotusbird",
                "selection_name":`1 + 2 ${selectedRaces.length > 2 ? '+ 3' : ''} ${selectedRaces.length > 3 ? '+ 4': ''}`,
                "win_loss":0,
                "status":"ACTIVE",
                "ex":{
                   "availableToBack":[
                      {
                         "price":resultBack.toFixed(2)
                      }
                   ],
                   "availableToLay":[
                      {
                         "price":resultLay.toFixed(2)
                      }
                   ],
                   "tradedVolume":[
                      
                   ]
                }
             }
          ],
          "is_active":1,
          "enable_fancy":0,
          "is_lock":false,
          "bet_count":0,
          "match_tv_url":null,
          "has_tv_url":false,
          "selection": selectedRaces,
          "totalOddsBack": totalOddsBack,
          "totalOddsLay": totalOddsLay,
          "isHrBet": true,
       }
    return finalResult
}