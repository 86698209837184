import { IonButton, IonIcon } from '@ionic/react'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import './SportButton.scss'
import { connectSportButton } from './SportButton.connect'
import ReactGA from 'react-ga4'

const SportButton = ({
  itemButton: {
    name,
    backgroundColour,
    sport_id,
  },
  actions,
  elementsParams,
  searchValue,
  isNavigationName,
}) => {
  const history = useHistory()

  const width =
    elementsParams && elementsParams?.clientWidth
      ? elementsParams?.clientWidth
      : window.innerWidth

  const getMenu = (data) => {
    actions.requestMenu(data).catch((e) => console.log('menu error = ', e))
  }

  const gotoEvent = () => {
    actions.getMenuSuccess([])
    {
      if (sport_id === '-100') {
        actions.setSlotCasinoPopUp(true)
        actions.requestCasinoPoint()
        history.push('/exchange-games')
        return
      }
      if (sport_id === 'QT' || sport_id === '-100') {
        actions.setSlotCasinoPopUp(true)
        actions.requestCasinoPoint()
      }

      sport_id !== '1444001' &&
        name !== isNavigationName &&
        actions.setIsNavigationOpen(true)
      actions.setRenderIonContent(false)
      // actions.setIsAnimatedMenu(true)
      setTimeout(() => {
        actions.setIsNavigationOpen(false)
      }, 400)
      setTimeout(() => {
        actions.setIsAnimatedMenu(false)
      }, 400)
      getMenu({ sport_id: sport_id })
      actions.setIsNavigationID(sport_id)
      actions.setIsNavigationName(name === 'Soccer' ? 'Football' : name)
      actions.setIsMoreDetailed(sport_id === 'QT' ? 'EZU' : '')
      actions.setIsDetailedGroup('')
    }

    if (width < 1024) {
      let page = `/menu-event/${sport_id}`
      if (sport_id === 'QT') {
        page = `/menu-event/${sport_id}/EZU`
      }
      history.push({
        pathname: page,
        state: { id: sport_id },
      })
    } else {
      history.push(
        sport_id == 4339 || sport_id == 7
          ? '/racing-sports-page'
          : sport_id === '1444001'
          ? '/exchange-games'
          : '/sports-page'
      )
    }

    {
      !searchValue && actions.setIsAnimatedText(false)
    }

    // if(auth) {
    //   actions.setIsNavigationOpen(true)
    //   setTimeout(() => {
    //     actions.setIsNavigationOpen(false)
    //   }, 400)
    //   setTimeout(() => {
    //     actions.setIsAnimatedMenu(false)
    //   }, 400)
    //   actions.setIsNavigationID(redirect.split('/')[2])
    //   actions.setIsNavigationName(name)
    //   history.push(redirect.split('/',2).join('/'))
    // }
    // else {
    //   actions.setLoginNew(true)
    // }
  }
  return (
    <>
      <IonButton className="sport-button" mode="md" id={sport_id}>
        <div
          className="sport-button__content"
          onClick={() => {
            ReactGA.event({
              label: 'Sports-Button',
              category: 'User-Click',
              action: name + '_Sports_Button',
            })
            gotoEvent()
          }}
          style={{ backgroundColor: `${backgroundColour}` }}
        >
          <img
            className="sport-button__background-img"
            src={`../../assets/images/promotions/sportsButton/${sport_id}-bg.png`}
            alt=""
          />
          <div className={`sport-button__content-text`}>
            <img
              src={`../../assets/images/promotions/sportsButton/${sport_id}.svg`}
              alt=""
            />
            <span>{name}</span>
          </div>
        </div>
      </IonButton>
    </>
  )
}

export default connectSportButton()(SportButton)
