import "./AccountStatementTable.scss";
import React, {useEffect, useState} from "react";
import {timeToDate, tsToDate} from "../../helpers/date.helper";
import { connectAccountStatementTable } from "./connectAccountStatementTable";
import { getCurrencyFormatWithZeroAfterDot } from "../../helpers/getCurrencyFormat.helper";

const AccountStatementTable = ({accountStatement, timeSettings, setSelectPNLItem}) => {
    const [accountStatementMap, setAccountStatementMap] = useState(new Map());

    const buildAccountStatementMap = () => {
        if (!accountStatement)
            {return;}

        accountStatementMap.clear();
        accountStatement.data.forEach(d => {
            const ketMap = timeToDate(d.date);
            const mapValue = accountStatementMap.get(ketMap.time);

            if (mapValue) {
                mapValue.data.push(d);
            } else {
                accountStatementMap.set(ketMap.time, {
                    dateString: ketMap.dateString,
                    data: [d],
                });
            }
        },
    );
        setAccountStatementMap(new Map([...accountStatementMap].sort((a, b) => b[0] - a[0])));
    };

    useEffect(() => {
        if (accountStatement && accountStatement.data) {
            buildAccountStatementMap(accountStatement);
        } else {
          setAccountStatementMap(new Map());
        }

    }, [accountStatement]);

    const handleClickName = (item) => {
        setSelectPNLItem(item);
    };

    const getTotalProfit = () => {
      if (!accountStatement?.data || !accountStatement?.data?.length || accountStatement?.data?.length <= 0) {
        return 0;
      } else {
  
        let sum = 0;
        accountStatement.data.forEach(item => {
          sum += (item?.credit_debit || 0);
        });
        return sum;
      }
    };

    return (
    <section className="AccountStatementTable">
      <div className="AccountStatementTable__table">
        {/* <h6 className="AccountStatementTable__table-title">Total P&L:&nbsp;
          <span
            className={getTotalProfit() >= 0 ? "green" : "red"}>{getCurrencyFormatWithZeroAfterDot(getTotalProfit())}</span>
        </h6> */}
        <ul className="AccountStatementTable__table-header">
          <li><span>Date</span></li>
          <li>Description</li>
          <li className="info-right">P&L</li>
          <li className="info-right">Credit <br /> Limit</li>
          <li className="info-right">Balance</li>
        </ul>
        <div className="AccountStatementTable__table-body">
          { accountStatementMap && accountStatementMap?.size ?
            [...accountStatementMap].map((itemWithDate) =>
            <div key={itemWithDate}>
              <div className="AccountStatementTable__table-body-time">
                <span>{tsToDate(itemWithDate[0], timeSettings?.timeZoneName)
                  .split(" ")[0]
                  .split("/")
                  .map((dateItem) => dateItem.length === 1 ? "0" + dateItem : dateItem)
                  .join("/")}
                </span>
              </div>
              {itemWithDate[1]?.data.map((item, index) => (
                  <ul className="AccountStatementTable__table-body-row" key={item?.date + index}>
                    <li className="info-right">
                      <span className="text-color">{tsToDate(item?.date, timeSettings?.timeZoneName).split(" ")[1]}</span>
                    </li>

                    <li className="AccountStatementTable__table-body-row-description">
                      
                      {item?.description &&
                        <div onClick={() => item.match_id && handleClickName(item)} className="AccountStatementTable__table-body-row-description-name">
                          <span>{item?.description} </span>
                        </div>
                      }
                    </li>
                    <li className={`info-right ${item?.credit_debit >= 0 ? "green" : "red"}`}>{getCurrencyFormatWithZeroAfterDot(item?.credit_debit)}</li>
                    <li className="info-right">-</li>
                    <li className={`info-right ${item?.balance >= 0 ? "green" : "red"}`}>{getCurrencyFormatWithZeroAfterDot(item.balance)}</li>
                  </ul>
                ),
              )}
            </div>,
          )
            :
            <div className="AccountStatementTable__table-body">
              <div className="AccountStatementTable__table-body-empty"> No data to display. Try changing filters and search again</div>
            </div>
          }
        </div>
      </div>
    </section>
    );
};

export default connectAccountStatementTable()(AccountStatementTable);
