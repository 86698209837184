import './BettingProfitAndLossItem.scss'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { connectBettingProfitAndLoseItem } from './BettingProfitAndLossItem.connect'
import { getCurrencyFormat } from '../../helpers/getCurrencyFormat.helper'

const BettingProfitAndLossItem = ({
  data,
  user,
  actions,
  setSelectedItemDetails,
  elementsParams,
}) => {
  //get window with
  const width =
    elementsParams && elementsParams?.clientWidth
      ? elementsParams?.clientWidth
      : window.innerWidth
  //end get window with

  const history = useHistory()
  const [title, setTitle] = useState('')
  const [startTime, setStartTime] = useState(new Date().toDateString())
  const [settledTime, setSettledTime] = useState(new Date().toDateString())
  const [comm, setComm] = useState(0)
  const [netWin, setNetWin] = useState(0)

  const fetchPNLData = (providerId) => {
    if (providerId.sport_id === '-100' || providerId.sport_id === 'QT') {
      const data = {
        bets_type: 'settled',
        roundId: providerId.event_id.split('.').pop(),
      }

      actions
        .requestCasinoBetHistorySuccess(data)
        .then((data) =>
          goToDetailsPage(data.data?.data?.data, providerId.sport_id)
        )
        .catch((e) => console.log('requestBetHistoryError = ', e))
      return
    }

    const data = {
      match_id: providerId.match_id,
    }
    actions
      .requestBetHistorySuccess(data)
      .then((data) =>
        goToDetailsPage(data.data?.data[0]?.data, providerId.sport_id)
      )
      .catch((e) => console.log('requestBetHistoryError = ', e))
  }

  const selectPNLItem = () => {
    fetchPNLData(data)
  }

  const goToDetailsPage = (data, sportId) => {
    if (width < 1024) {
      history.push({
        pathname:
          sportId === '-100' || sportId === 'QT'
            ? 'betting_profit_and_loss_casino_details'
            : '/betting_profit_and_loss_details',
        state: { itemData: data },
      })
    } else {
      setSelectedItemDetails(data)
    }
  }

  const getValueColor = (value) => {
    return value >= 0 ? 'pnl-item-value--green' : 'pnl-item-value--red'
  }

  const parseData = (data) => {
    const eventName = data.event_name
    const marketName = data.match_name
    const settledTime = data.result_date
    const marketTime = data.match_date
    const memberComm = data?.memberComm || 0
    // const memberWin = data.memberWin;
    const memberWin = data.net_pl
    setTitle(`${eventName} - ${marketName}`)
    setStartTime(
      new Intl.DateTimeFormat('en-GB', {
        dateStyle: 'short',
        timeStyle: 'medium',
      }).format(new Date(marketTime))
    )
    setSettledTime(
      new Intl.DateTimeFormat('en-GB', {
        dateStyle: 'short',
        timeStyle: 'medium',
      }).format(new Date(settledTime))
    )
    setComm(memberComm)
    setNetWin(memberWin)
  }

  useEffect(() => {
    parseData(data)
  }, [data])

  if (width < 1024) {
    return (
      <div className="betting-pnl-item">
        <div className="betting-pnl-item__left-side">
          <span
            className="betting-pnl-item__title"
            onClick={() => selectPNLItem()}
          >
            {title}
          </span>
          <span className="start-time">
            <span className="start-time__title">Start Time:</span>
            <span className="start-time__value">{startTime}</span>
          </span>
          <span className="settled-time">
            <span className="settled-time__title">Settled Time:</span>
            <span className="settled-time__value">{settledTime}</span>
          </span>
        </div>
        <div className="betting-pnl-item__right-side">
          <span className="comm">
            <span className="comm__title">Comm:</span>
            <span className={`comm__value ${getValueColor(-1 * comm)}`}>
              {-1 * getCurrencyFormat(comm)}
            </span>
          </span>
          <span className="net-win">
            <span className="net-win__title">Net Win:</span>
            <span className={`net-win__value ${getValueColor(netWin)}`}>
              {getCurrencyFormat(netWin)}
            </span>
          </span>
        </div>
      </div>
    )
  }

  return (
    <ul className="betting-pnl-item">
      <li>
        <span
          className="betting-pnl-item__cell__value betting-pnl-item__title"
          onClick={() => selectPNLItem()}
        >
          {title}
        </span>
      </li>
      <li>
        <span className="betting-pnl-item__cell__value start-time__value">
          {startTime}
        </span>
      </li>
      <li>
        <span className="betting-pnl-item__cell__value settled-time__value">
          {settledTime}
        </span>
      </li>
      <li>
        <span
          className={`betting-pnl-item__cell__value comm__value ${getValueColor(
            -1 * comm
          )}`}
        >
          {(-1 * comm).toFixed(2)}
        </span>
      </li>
      <li>
        <span
          className={`betting-pnl-item__cell__value net-win__value ${getValueColor(
            netWin
          )}`}
        >
          {getCurrencyFormat(netWin)}
        </span>
      </li>
    </ul>
  )
}

export default connectBettingProfitAndLoseItem()(BettingProfitAndLossItem)
