import httpAuth from '../../services/httpAuth'
import httpNotAuth from '../../services/httpNotAuth'
import getErrorMessage from '../../helpers/getErrorMessage.helper'
import { getInplayPopularError, getInplayPopularSuccess } from './inplayPopular.slice'


export const requestInplayPopular = (id) => async (dispatch, getState) => {
    const checkAuth = getState().auth
    let data = {
        series_id: id
    }
    if(checkAuth) {
        try {
            const response = await httpAuth.post(`match/homeMatches`, {...data, "combine": true})
            // const response = await httpNotAuth.post(`match/homeMatchesOpen`, data)
            if (response.data.status) {
                dispatch(getInplayPopularSuccess(response.data.data))
            }
            return response
        } catch (e) {
            dispatch(getInplayPopularError())
            // const errorMsg = getErrorMessage(e) 
            // throw errorMsg                       
        }
    } else {
        try {
            const response = await httpNotAuth.post(`match/homeMatchesOpen`, data)
            //const response = await axios.get(`https://89383249-2f56-4a28-b7cf-2bc8402ce7ed.mock.pstmn.io/api/exchange/odds/inPlayAndPopularEvents`)
            if (response.data.status) {
                dispatch(getInplayPopularSuccess(response.data.data))
            }
            return response
        } catch (e) {
            dispatch(getInplayPopularError())
            // const errorMsg = getErrorMessage(e)
            // throw errorMsg
        }
    }
    
}

