import httpAuth from '../../services/httpAuth'
import httpNotAuth from '../../services/httpNotAuth'

import getErrorMessage from '../../helpers/getErrorMessage.helper'
import { getMenuTopError, getMenuTopSuccess } from './menuTop.slice'


export const requestMenuTop = (data) => async (dispatch, getState) => {
  const checkAuth = getState().auth
  if(checkAuth) {
      try {
        const response = await httpAuth.post(`/sport/getSports`)
  
        if (response?.data?.status) {
          let res = response?.data?.data
          dispatch(getMenuTopSuccess(res))
          // const menu = await response.data.result.filter((item, index, self) =>
          //   index === self.findIndex((t) => t.id === item.id)
          // )
        }
        return response
      } catch (e) {
        dispatch(getMenuTopError())
        //  const errorMsg = getErrorMessage(e)
        //  throw errorMsg
      }
    
  } else {
      try {
        const response = await httpAuth.post(`/sport/sports`)
  
        if (response?.data?.status) {
          let res = response?.data?.data
          dispatch(getMenuTopSuccess(res))
          // const menu = await response.data.result.filter((item, index, self) =>
          //   index === self.findIndex((t) => t.id === item.id)
          // )
        }
        return response
      } catch (e) {
        dispatch(getMenuTopError())
        //  const errorMsg = getErrorMessage(e)
        //  throw errorMsg
      }
  }
}
