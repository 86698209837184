import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {requestAccountReport} from "../../store/accountReport/account-report.thunks";
import { requestBetHistorySuccess, requestCasinoBetHistorySuccess } from '../../store/betHistory/bet-history.thunks';

export const connectBettingProfitAndLosePage = (wrappedProps) => (component) => {
    function mapStateToProps(state) {

        return {
            user: state.user,
            accountReport: state.accountReport,
            lastActivityDate: state.balance.lastActivityDate,
            timeSettings: state.timeSettings,
            orderList: state.orderList,
            elementsParams: state.elementsParams
        }
    }

    function mapDispatchToProps(dispatch) {
        const actions = {
            requestAccountReport,
            requestBetHistorySuccess,
            requestCasinoBetHistorySuccess
        }

        return { actions: bindActionCreators(actions, dispatch) }
    }

    return connect(mapStateToProps, mapDispatchToProps)(component)
}
