import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IonList, IonListHeader } from "@ionic/react";
import { useHistory } from 'react-router';
import EventTypeBanners from '../EventTypeBanners/EventTypeBanners';
import { headerType } from '../../helpers/InPlayPopular.helper';
import './ExchangeGamesDesktopList.scss'
import { setLoginNew } from '../../store/LoginNew/loginNew.slice';


const ExchangeGamesDesktopList = ({ itemHeaderData, itemsData, type, actions  }) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const auth = useSelector((state) => state.auth)

    const getBanner = () => {
        if (headerType['INPLAY'] == type) {
             return <EventTypeBanners eventType={itemHeaderData.id} />
        } else return null
    }

    const renderHeader = () => {
        return <IonListHeader className="inplay-item-list-exchange__header ">
            <div className="inplay-item-list-exchange__header-content">
                <div className="inplay-item-list-exchange__header-logo">
                    <i className={`inplay-content__logo-icon inplay-content__logo-icon--exchange-games inplay-color-icon`} />
                   {itemHeaderData.name}
                    {/* <span>Indian Card Games</span> */}
                </div>
            </div>
        </IonListHeader>
    }

    const checkStateWidth = useSelector((state) => state.elementsParams.clientWidth)
    const width = checkStateWidth ? checkStateWidth : window.innerWidth

    return (
        <IonList className="inplay-item-list_exchangeGames">
            {getBanner()}
            {itemHeaderData ? renderHeader() : null}
            <div className='exchangeGames-contentDesktop'>
                {itemsData && itemsData.map((item, index) => {
                    return (
                      <div className='exchangeGames-itemDesktop' key={index}
                        style={{backgroundColor: `${item?.backgroundColour}`}}
                        onClick={()=> {
                            if(auth) {
                                actions.requestLiveCasinoID(item?.match_id)
                                history.push(`/live_casino_slot/${item?.match_id}`)
                                // history.push(`/exchange-game-page/${item?.eventTypeId}/${item?.event?.id}`)
                            } else {
                                dispatch(setLoginNew(true))
                            }
                        }}
                      >
                          {width > 1024 &&
                              <div className="exchangeGames-img-bodyDesktop"
                                   style={item?.desktopImage || item?.match_id || itemHeaderData?.banner
                                     ? { backgroundImage: `url(./assets/images/casino/${item?.match_id}.jpg)`}
                                     : { background: "#405644", backgroundPosition: 'top', backgroundSize: '160% 100%'}
                              }>
                              </div>
                          }
                          {width <= 1024 &&
                                <div className="exchangeGames-img-bodyDesktop"
                                     style={item?.match_id || itemHeaderData?.banner
                                       ? { backgroundImage: `url(./assets/images/casino/${item?.match_id}.jpg)`}
                                       : { background: "#405644", backgroundPosition: 'top', backgroundSize: '160% 100%'}
                                     }>
                                    <div className='exchangeGames-gamename-bodyDesktop'>{item?.market?.event?.altName?.indexOf("(") > 0 ? item?.market?.event?.altName?.substring(0, item?.market?.event?.altName?.indexOf("(")) : item?.market?.event?.altName}</div>
                                    {/*<div className='exchangeGames-gamename-bodyDesktopName'>{item?.image || itemHeaderData?.banner ? null : item?.market?.event?.altName}</div>*/}
                                </div>
                           }
                      </div>
                    )
                })}

            </div>
        </IonList>

    )
}

export default ExchangeGamesDesktopList
