import './RaceInplayitem.scss'
import React from 'react'
import { IonItem } from '@ionic/react'
import OddButton from '../OddButton/oddbutton'
import BetPlacing from '../BetPlacing/BetPlacing'
import { connectRaceInplayitem } from './RaceInplayitem.connect'
import { useParams } from 'react-router-dom'

const InPlayItem = ({
  className = '',
  type,
  raceOdds,
  itemData,
  betPlacing,
  exposure,
  exposureRace,
  selectedRaces = [],
  setSelectedRaces,
}) => {
  const { eventId } = useParams()
  const item = itemData
  const renderBetPlacing = () => {
    const status = raceOdds?.status
    if (
      betPlacing.market === raceOdds.market_id &&
      betPlacing.runner === item.selectionId &&
      status.indexOf('OPEN') !== -1 &&
      item.status.indexOf('ACTIVE') !== -1
    )
      return <BetPlacing />
    return null
  }

  // betPlacing.market === data.market_id &&
  //   betPlacing.runner === runner.id &&
  //   status &&
  //   !status.localeCompare("OPEN") &&
  //   (!statusLabel || betPlacing.btype != "casino") &&
  //   width < 1024

  const addMarketData = (market) => {
    // Check if market_id already exists in state
    const marketExists = selectedRaces.some(
      (item) => item.selectionId === market.selectionId
    )

    if (
      !marketExists &&
      selectedRaces.length < 4 &&
      raceOdds?.status !== 'SUSPENDED' &&
      market?.status === 'ACTIVE'
    ) {
      // If it doesn't exist, add the new data to the array
      setSelectedRaces && setSelectedRaces((prevMarkets) => [
        ...prevMarkets,
        {
          ...market,
          match_id: raceOdds.match_id,
          match_name: raceOdds.match_name,
          match_date: raceOdds.match_date,
          market_id: raceOdds.market_id,
          name: raceOdds.name,
          market_name: raceOdds.market_name,
          market_type: raceOdds.market_type,
        },
      ])
    } else {
      setSelectedRaces && setSelectedRaces((prevMarkets) =>
        prevMarkets.filter((item) => item.selectionId !== market.selectionId)
      )
      // console.log("Market with this market_id already exists.", market);
    }
  }

  const getOdds = () => {
    if (!item) return

    const status = raceOdds?.status
    const back = item.ex.availableToBack
    const lay = item.ex.availableToLay
    const runner = {
      id: item.selectionId,
      name: item.name,
    }

    if (status) {
      if (status.indexOf('OPEN') !== -1) {
        if (item.status.indexOf('ACTIVE') !== -1) {
          return (
            <div className="inplay-item__back">
              <div className="inplay-item__back-inner odds">
                <div className={`inplay-item__back-inner`}>
                  <OddButton
                    market={raceOdds}
                    type={type}
                    isBack={true}
                    oddData={{ ...(back && back[0]), runner }}
                    showEmpty
                    centered
                  />
                  <OddButton
                    market={raceOdds}
                    type={type}
                    isBack={false}
                    oddData={{ ...(lay && lay[0]), runner }}
                    showEmpty
                    centered
                  />
                </div>
              </div>
            </div>
          )
        }

        if (item.status.indexOf('REMOVED') !== -1) {
          return (
            <div className="inplay-item__back">
              <div className="inplay-item__back-inner">
                <div className={`inplay-item__back-inner`}>
                  <div className="inplay-item__back-inner__status grey">
                    <span>Non runner {item.adjustmentFactor}%</span>
                  </div>
                </div>
              </div>
            </div>
          )
        }
      } else if (status.indexOf('CLOSED') !== -1) {
        if (item.status.indexOf('WINNER') !== -1) {
          return (
            <div className="inplay-item__back">
              <div className="inplay-item__back-inner">
                <div className={`inplay-item__back-inner`}>
                  <div className="inplay-item__back-inner__status">
                    <span>Winner</span>
                  </div>
                </div>
              </div>
            </div>
          )
        }

        if (item.status.indexOf('REMOVED') !== -1) {
          return (
            <div className="inplay-item__back">
              <div className="inplay-item__back-inner">
                <div className={`inplay-item__back-inner`}>
                  <div className="inplay-item__back-inner__status grey">
                    <span>Non runner {item.adjustmentFactor}%</span>
                  </div>
                </div>
              </div>
            </div>
          )
        }

        if (item.status.indexOf('LOSER') !== -1) {
          return (
            <div className="inplay-item__back">
              <div className="inplay-item__back-inner">
                <div className={`inplay-item__back-inner`}>
                  <div className="inplay-item__back-inner__status"></div>
                </div>
              </div>
            </div>
          )
        }
      } else if (status.indexOf('SUSPENDED') !== -1) {
        if (item.status.indexOf('REMOVED') !== -1) {
          return (
            <div className="inplay-item__back">
              <div className="inplay-item__back-inner">
                <div className={`inplay-item__back-inner`}>
                  <div className="inplay-item__back-inner__status grey">
                    <span>Non runner {item.adjustmentFactor}%</span>
                  </div>
                </div>
              </div>
            </div>
          )
        } else {
          return (
            <div className="inplay-item__back">
              <div className="inplay-item__back-inner odds">
                <div className={`inplay-item__back-inner`}>
                  <div className="inplay-item__status">SUSPENDED</div>
                </div>
              </div>
            </div>
          )
        }
      }
    }
  }

  const getScore = () => {
    if (!item) return
    else if (item.metadata) {
      // const status = raceOdds?.status;
      const stallDraw = item.metadata?.STALL_DRAW
      return (
        <div className="inplay-item__score">
          <div className="inplay-item__score">
            <div className="score-content racing">
              <span className="score-content--1">
                {item.metadata?.CLOTH_NUMBER}
              </span>
              <span className="score-content--2">
                {' '}
                {stallDraw && `(${item.metadata?.STALL_DRAW})`}
              </span>
            </div>
          </div>
        </div>
      )
    } else return false
  }

  const renderExposure = (runnerId) => {
    let number = 0,
      calced
    const isFancy = raceOdds.mtype == 'INNINGS_RUNS' && raceOdds.btype == 'LINE'
    const pnlId = raceOdds.market_id
    if (exposure && exposure && exposure[pnlId]) {
      let findNumber = exposure[pnlId]?.filter(
        (item) => item.selection_id == runnerId
      )
      number = findNumber[0]?.user_pl
    }
    if (betPlacing.market === raceOdds.id) {
      if (betPlacing.runner === item.id) {
        // const profit = isFancy ? (betPlacing.stake * betPlacing.priceActual / 100).toFixed(2) : (betPlacing.stake * betPlacing.priceActual - betPlacing.stake).toFixed(2)
        const profit = isFancy
          ? -betPlacing.stake
          : (
              betPlacing.stake * betPlacing.priceActual -
              betPlacing.stake
            ).toFixed(2)
        calced = (isFancy ? 0 : number) + Number(profit)
      } else {
        calced = number - Number(betPlacing.stake)
      }
    }
    const preCalced = isFancy && number == 0 ? 'Max exposure: ' : ''
    if (number || calced)
      return (
        <div className="SportEvent__market__title__exposure">
          {number ? (
            <div className={`${number > 0 ? 'positive' : 'negative'}`}>
              {number.toFixed(2)}
            </div>
          ) : null}
          {preCalced}
          {(calced || (number && calced == 0)) && (
            <div className={`${calced > 0 ? 'positive' : 'negative'}`}>
              » {calced.toFixed(2)}
            </div>
          )}
        </div>
      )
    return null
  }

  const getName = () => {
    if (!item) return

    const leftLabel = [
      { name: item.name },
      { name: item.metadata?.JOCKEY_NAME },
    ]

    if (!leftLabel) {
      return <div className="inplay-item__players"></div>
    }
    return (
      <div className="inplay-item__players">
        <span key={0} className="inplay-item__player">
          <span>{item.name}</span>
        </span>
        <span key={1} className="inplay-item__player">
          <span className="jockey">{item.metadata?.JOCKEY_NAME}</span>
        </span>
        {renderExposure(item?.selectionId)}
      </div>
    )
  }

  return (
    <>
      <IonItem className={`RaceInplayItem inplay-item ${className}`}>
        {raceOdds.sport_id == 7 && (
          <div
            onClick={() => addMarketData(itemData)}
            className={
              !selectedRaces.some(
                (itemSelect) => itemSelect.selectionId === itemData.selectionId
              )
                ? 'topFilters-item-check'
                : 'topFilters-item-check checked'
            }
          ></div>
        )}
        {getScore()}
        {getName()}
        {getOdds()}
      </IonItem>
      {renderBetPlacing()}
    </>
  )
}

export default connectRaceInplayitem()(InPlayItem)
