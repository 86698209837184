import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { requestCasinoStart, requestCasinoEnd } from '../../store/casinoLive/casinoLive.thunks';
import { requestQtechFilter } from '../../store/qTech/qTech.thunks';
import { requestQtechGames } from '../../store/qTechGames/qTechGames.thunks';
import { requestCasinoPoint, requestQTechCasinoID } from '../../store/liveCasino/liveCasino.thunks';
import { setSlotCasinoPopUp } from '../../store/slotCasinoPopUp/slotCasinoPopUp.slice';

export const connectCasinoProvider = (wrappedProps) => (component) => {
  function mapStateToProps(state) {
    return {
      casinoLive: state.casinoLive,
      elementsParams: state.elementsParams,
      qtechFilter: state.qTech,
      qTechGames: state.qTechGames,
      auth: state.auth,
      slotCasinoPopUp: state.slotCasinoPopUp,
    }
  }

  function mapDispatchToProps(dispatch) {
    const actions = {
      requestQtechFilter,
      requestQtechGames,
      requestQTechCasinoID,
      setSlotCasinoPopUp,
      requestCasinoPoint,
    }

    return { actions: bindActionCreators(actions, dispatch) }
  }

  return connect(mapStateToProps, mapDispatchToProps)(component)
}
