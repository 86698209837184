import React, { useEffect, useRef, useState } from 'react'
import {
  clearIntervalAsync,
  setIntervalAsync,
} from 'set-interval-async/dynamic'
import { timeToDate, tsToDate } from '../../helpers/date.helper'
import { connectRacingPage } from './RacingPage.connect'
import SportMarketStandard from '../../components/SportMarketStandard/SportMarketStandard'
import './RacingPage.scss'
import UpcomingRacesAccordion from '../../components/UpcomingRacesAccordion/UpcomingRacesAccordion'
import httpAuth from '../../services/httpAuth'
import { GET_MARKET_DATA_INTERVAL } from '../../config/app.config'
import httpNotAuth from '../../services/httpNotAuth'
import { joidTigari } from '../../helpers/joditigari.helper'
// import getErrorMessage from '../../helpers/getErrorMessage.helper'
// import { requestRacingPageData } from '../../store/racingPage/racingPage.thunks'
// import { useSelector } from 'react-redux'
// import {useHistory} from "react-router";

const RacingPage = ({
  timeSettings,
  navigationHeaderMenu,
  betPlacing,
  menu,
  auth,
  actions,
  racingPage,
}) => {
  //states
  const [raceOdds, setRaceOdds] = useState(null)
  const [isAccordionOpen, setIsAccordionOpen] = useState(1)
  const [allRunnerSuspend, setAllRunnerSuspend] = useState(false)
  const [eventID, setEventId] = useState(null)
  const [oddId, setOddId] = useState(null)
  const [selectedRaces, setSelectedRaces] = useState([])
  const [combinedMarket, setCombinedMarket] = useState(null)
  const headerRef = useRef(null)

  //end states

  useEffect(()=> {
    if (selectedRaces.length <= 1) {
      setCombinedMarket(null);
    } else {
      let horseData = joidTigari(selectedRaces)
      setCombinedMarket(horseData);
    }
  }, [selectedRaces])

  useEffect(() => {
    if (!auth) {
      if (menu && menu?.length > 0) {
        setEventId(menu[0]?.match_id)
        setOddId(menu[0]?.market_id)
        raceDataRequestNoAuth(menu[0]?.match_id, menu[0]?.market_id)
      }
    } else {
      if (menu && menu?.length > 0) {
        setEventId(menu[0]?.match_id)
        setOddId(menu[0]?.market_id)
        raceDataRequest(menu[0]?.match_id, menu[0]?.market_id)
        actions.requestSportStart(menu[0]?.market_id, true)
      }
    }
  }, [menu, auth])

  useEffect(() => {
    if (!auth) {
      const mainTimer = setIntervalAsync(async () => {
        if (menu && menu?.length > 0) {
          setEventId(menu[0]?.match_id)
          setOddId(menu[0]?.market_id)
          raceDataRequestNoAuth(menu[0]?.match_id, menu[0]?.market_id)
        }
      }, 15000)
      return () => clearIntervalAsync(mainTimer)
    } else {
      const mainTimer = setIntervalAsync(async () => {
        if (menu && menu?.length > 0) {
          setEventId(menu[0]?.match_id)
          setOddId(menu[0]?.market_id)
          raceDataRequest(menu[0]?.match_id, menu[0]?.market_id)
        }
      }, GET_MARKET_DATA_INTERVAL)

      return () => clearIntervalAsync(mainTimer)
    }
  }, [menu, auth])

  useEffect(() => {
    if (auth) {
      const mainTimer = setIntervalAsync(async () => {
        if (menu && menu?.length > 0) {
          actions.requestSportStart(menu[0]?.market_id, true)
        }
      }, 30000)
      return () => clearIntervalAsync(mainTimer)
    }
  }, [menu, auth])

  // get all suspend
  useEffect(() => {
    if (
      raceOdds?.runners?.filter((runner) => runner.status !== 'SUSPENDED')
        .length >= 1
    ) {
      setAllRunnerSuspend(false)
    } else {
      setAllRunnerSuspend(true)
    }
  }, [raceOdds])
  // end get all suspend

  // request api
  const raceDataRequestNoAuth = async (event, market) => {
    const data = {
      market_id: market,
      match_id: event,
    }
    try {
      const response = await httpNotAuth.post(`match/matchDetailsOpen`, data)
      if (response.data.status) {
        setRaceOdds(response?.data?.data[0])
      }
    } catch (error) {
      console.log(error)
    }
  }

  const raceDataRequest = async (event, market) => {
    const data = {
      market_id: market,
      match_id: event,
      combine: true,
    }
    try {
      const response = await httpAuth.post(`match/matchDetails`, data)
      if (response.data.status) {
        setRaceOdds(response?.data?.data[0])
      }
    } catch (error) {
      console.log(error)
    }
  }

  // useEffect(() => {
  //   console.log('my response try useffect')
  //   actions.requestRacingPageData(navigationHeaderMenu.navigationID).then((response) => console.log('??response', response))
  // }, [navigationHeaderMenu.navigationID])
  // end request api

  // save page id if page refreshed
  useEffect(() => {
    if (navigationHeaderMenu.navigationID) {
      sessionStorage.setItem('navigationID', navigationHeaderMenu.navigationID)
    }
    if (navigationHeaderMenu.navigationName) {
      sessionStorage.setItem(
        'navigationName',
        navigationHeaderMenu.navigationName
      )
    }
    headerRef.current.scrollIntoView({
      block: 'start',
      behavior: 'smooth',
    })
  }, [navigationHeaderMenu.navigationID])

  // useEffect(() => {
  // }, [navigationHeaderId, navigationName])
  // end save page id if page refreshed

  // console.log('raceOdds ??!!', raceOdds)
  // console.log('Date.parse(raceOdds?.start) ??!!', Date.parse(1645701420000))

  return (
    <div className="race-page" style={{ position: 'relative' }}>
      <span
        style={{ position: 'absolute', top: '-135px' }}
        ref={headerRef}
      ></span>

      <div className="race-page__header">
        <h2 className="race-page__header-title">
          {navigationHeaderMenu.navigationName ||
            sessionStorage.getItem('navigationName')}
        </h2>
        <span className="race-page__header-subtitle">
          Betting from 1min before start{' '}
        </span>
      </div>
      {menu && menu.length > 0 ? (
        <div className="race-page__content">
          <div
            className={`race-page__content-rase-odds-table-wrapper ${
              allRunnerSuspend === true ? 'all-suspended' : ''
            }`}
          >
            <div className="race-page__content-header">
              <div
                className={`${
                  raceOdds?.inplay === false ? 'not-in-play' : 'in-play'
                } race-page__content-header-next-race`}
              >
                <span>Next Race:&nbsp;</span>
                {/*<span>{(tsToDate(Date.parse(raceOdds?.start), timeSettings?.timeZoneName).split(" ")[1]).split(":", 2).join(":") }&nbsp;</span>*/}
                <span>
                  {tsToDate(
                    Date.parse(raceOdds?.market_start_time),
                    timeSettings?.timeZoneName
                  )
                    .split(' ')[1]
                    .split(':', 2)
                    .join(':')}
                  &nbsp;
                </span>
                <span>{raceOdds?.name.replace('${HH:mm}', '')}</span>
              </div>
              <span className="race-page__header-subtitle">Opens in</span>
            </div>
            {combinedMarket &&
            <div>
              <div className="race-page__content-rase-odds-table-header">
                <p>Combined Market</p>
                <div>
                  <span>Back</span>
                  <span>Lay</span>
                </div>
              </div>
              <div
                className={`${
                  combinedMarket?.isBettable === false ? 'unbettable' : ''
                } race-page__content-rase-odds-table`}
              >
                {allRunnerSuspend === true && (
                  <div className="race-page__content-all-suspend-cover">
                    <span>SUSPENDED</span>
                  </div>
                )}
                {combinedMarket && (
                  <SportMarketStandard
                    data={combinedMarket}
                    key={combinedMarket?.market_id}
                    betPlacing={betPlacing}
                    showHeader={false}
                    showJockeyName={true}
                    isRacing={true}
                  />
                )}
              </div>
            </div>
}
            <div>
              <div className="race-page__content-rase-odds-table-header">
                <p>Race Odds</p>
                <div>
                  <span>Back</span>
                  <span>Lay</span>
                </div>
              </div>
              <div
                className={`${
                  raceOdds?.isBettable === false ? 'unbettable' : ''
                } race-page__content-rase-odds-table`}
              >
                {allRunnerSuspend === true && (
                  <div className="race-page__content-all-suspend-cover">
                    <span>SUSPENDED</span>
                  </div>
                )}
                {raceOdds && (
                  <SportMarketStandard
                    data={raceOdds}
                    key={raceOdds?.market_id}
                    betPlacing={betPlacing}
                    showHeader={false}
                    showJockeyName={true}
                    isRacing={true}
                    selectedRaces={selectedRaces}
                    setSelectedRaces={setSelectedRaces}
                  />
                )}
              </div>
            </div>
          </div>
          <div
            className={`race-page__content-upcoming-races-table-wrapper ${
              allRunnerSuspend === true ? 'all-suspended' : ''
            }`}
          >
            <div className="race-page__content-header">
              <span className="race-page__content-header-next-race">
                Upcoming Races
              </span>
            </div>
            <div className="race-page__content-upcoming-races-table">
              <div>
                {menu &&
                  menu.map((item, index) => {
                    return (
                      <UpcomingRacesAccordion
                        key={item?.id + index}
                        item={item}
                        index={index}
                        isAccordionOpen={isAccordionOpen}
                        setIsAccordionOpen={setIsAccordionOpen}
                        showHeader={false}
                        showJockeyName={true}
                      />
                    )
                    // const slicedItem = {...item, runners: item?.runners?.slice(0, 3)}
                    // let flag = slicedItem?.country_code?.toLowerCase()

                    // const setEvent = (item) => {
                    //   setIsAccordionOpen(item?.market_id)
                    //   setEventId(item?.match_id)
                    //   let eventId = item?.match_id;
                    //   let oddId = item?.market_id;
                    //   actions.requestRaceOdds(eventId, oddId)
                    // }

                    // return (
                    //   <div key={slicedItem?.market_id + index} className="race-page__content-upcoming-races-table-row">
                    //     <div className="race-page__content-upcoming-races-table-row-header f32"
                    //       onClick={() => isAccordionOpen === slicedItem?.market_id ? setIsAccordionOpen(null) : setEvent(slicedItem)}
                    //     >
                    //       <div className={`flag ${flag}`}></div>
                    //       <div className="race-page__content-upcoming-races-table-row-header-text">
                    //         <span>{slicedItem?.name.replace("${HH:mm}","")}</span>
                    //         <span>{(tsToDate(Date.parse(slicedItem?.market_start_time), timeSettings?.timeZoneName).split(" ")[1]).split(":", 2).join(":") }</span>
                    //       </div>
                    //       <div className="race-page__content-upcoming-races-table-row-header-arrowContainer">
                    //         <span className={`race-page__content-upcoming-races-table-row-header-arrow ${isAccordionOpen === slicedItem?.market_id ? "active" : ""}`}></span>
                    //       </div>
                    //     </div>
                    //       <div className={`race-page__content-upcoming-races-table-row-inner ${isAccordionOpen === slicedItem?.market_id ? "open" : ""}`}>
                    //         <div className="race-page__content-rase-odds-table-header">
                    //           <p>Top 3 of {raceOdds && raceOdds[`${eventID} ${isAccordionOpen}`]?.runners?.length} runners</p>
                    //           <div>
                    //             <span>Back</span>
                    //             <span>Lay</span>
                    //           </div>
                    //         </div>
                    //         <div className="race-page__content-upcoming-races-table-row-items-wrapper">
                    //           {allRunnerSuspend === true && <div className="race-page__content-all-suspend-cover"><span>SUSPENDED</span></div>}
                    //           {<SportMarketStandard data={raceOdds ? raceOdds[`${eventID} ${isAccordionOpen}`] : []} key={slicedItem?.market_id} betPlacing={betPlacing} showHeader={false} showJockeyName={true} />}
                    //         </div>
                    //       </div>
                    //   </div>
                    // )
                  })}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="sportsPage-subHeader">
            <span>There are currently no upcoming events.</span>
          </div>
        </>
      )}
    </div>
  )
}

export default connectRacingPage()(RacingPage)
