import httpAuth from '../../services/httpAuth'
import getErrorMessage from '../../helpers/getErrorMessage.helper'
import { getCasinoLiveError, getCasinoLiveSuccess } from './casinoLive.slice'
import { OPERATORID, USERID } from '../../config/app.config'

export const requestCasinoStart =
  (id = '1444000', type = '') =>
  async (dispatch, getState) => {
    try {
      const checkAuth = getState().auth
      if ((id === '67722-1' || id === '67722-2') && !checkAuth) {
        dispatch(
          getCasinoLiveSuccess({
            url: 'https://games.fawk.app/' + makeId(10) + '/6666',
            frame_key: '',
            marketId: '7.7',
            status: 'OPEN',
          })
        )
      } else if (type === 'LIVE_CASINO' || type === 'LOBBY'){
        console.log("Live Casino", type, id)
        const response = await httpAuth.get(`/qtech/gameList?providers=EZU`)
        if (response.data.status) {
          dispatch(console.log({ url: response.data }))
        }
        return response
      } else if (id === '98789') {
        const response = await httpAuth.post(`/lotus/lobbyUrl`)
        if (response.data.status) {
          dispatch(getCasinoLiveSuccess({ url: response.data?.launchUrl }))
        }
        return response
      } else {
        const userId = localStorage.getItem(USERID)
        const operatorId = localStorage.getItem(OPERATORID)
        let url = `https://games.fawk.app/${userId}/${operatorId}/${id}`

        let data = {
          url: url,
        }
        dispatch(getCasinoLiveSuccess(data))
        return data
      }
    } catch (e) {
      dispatch(getCasinoLiveError())
      // const errorMsg = getErrorMessage(e)
      // throw errorMsg
    }
  }

function makeId(length) {
  let result = ''
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length
  let counter = 0
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
    counter += 1
  }
  return result
}

export const requestCasinoEnd = () => async (dispatch) => {
  try {
    const response = await httpAuth.post(`/casino/live/session/close`)
    if (response.data.success) {
      dispatch(getCasinoLiveSuccess(response.data.result))
    }
    return response
  } catch (e) {
    dispatch(getCasinoLiveError())
    // const errorMsg = getErrorMessage(e)
    // throw errorMsg
  }
}
