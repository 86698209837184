import http from '../../services/httpAuth'
import getErrorMessage from '../../helpers/getErrorMessage.helper'
import { getTransferStatementError, getTransferStatementSuccess } from './transferStatement.slice'

export const requestTransferStatementSuccess = (memberCode) => async (dispatch) => {
    const currentTime = (new Date()).getTime();
    const lastEightDayTime = currentTime - (14 * 24 * 60 * 60 * 1000);
    const data = {
        limit: 150,
        page: 1,
        user_id: memberCode,
        from_date: new Date(lastEightDayTime),
        to_date: new Date()
    }
    try {
        const response = await http.post(`/wallet/getwalletsummary`, data)
        if (response.data.status) {
            dispatch(getTransferStatementSuccess(response.data.data))
        }
        return response
    } catch (e) {
        dispatch(getTransferStatementError())
        const errorMsg = getErrorMessage(e)
        throw errorMsg
    }
}
