import './editStakesPopUp.scss'
import { IonInput } from '@ionic/react'
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { connectEditStakesPopUp } from './EditStakesPopUp.connect'
import { useRef } from 'react'

const EditStakesPopUp = ({ user, actions, open, balanceInformationHeight, isDetailedBalanceOpen, ...props }) => {
  const isPopUpOpenDispatch = useSelector(
    (state) => state.editStakesPopUp.isOpen
  )



  //intial three fileds for classic stake
  const [stake, setStake] = useState(
    user.settings.presetStakeSettings.settings
  )

  const saveMobileStakesSettings = () => {
    actions.requestSaveStakesSettings(stake)
  }

  const handleInputStake = (e, index) => {
    e.preventDefault()
    const val = Number(e.target.value)
    Number.isInteger(val) &&
      setStake(() => {
        const newStake = [...stake]
        newStake[index] = val
        return newStake
      })
  }

  useEffect(() => {
    actions.requestStack().then((res) => {
      if (res.data?.status) setStake(res.data?.match_stack)
    })
    // setStake(user.settings.presetStakeSettings.settings)
  }, [open])

  return (
    <>
      {open && (
        // <div className='editStakesPopUp_wrapperrr'>
        <div className="editStakesPopUp">
          <div
            className="editStakesPopUp_wrapper"
            style={balanceInformationHeight > 30 
              ? { top: `${balanceInformationHeight + 118}px`} 
              : {}}
            onClick={(e) => {
              if (e.currentTarget === e.target) {
                actions.setIsOpen(!isPopUpOpenDispatch)
              }
            }}
          >
            <div className="editStakesPopUp_place">
              <div
                className="editStakesPopUp__fields"
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <div className="editStakesPopUp__fields-container">
                  <IonInput
                    className="editStakesPopUp__input"
                    placeholder="0"
                    name="firstOneclick"
                    type="text"
                    autofocus={true}
                    value={stake ? stake[0] : 0}
                    onIonChange={(e) => handleInputStake(e, 0)}
                  />
                  <IonInput
                    className="editStakesPopUp__input"
                    placeholder="0"
                    name="secondOneclick"
                    type="text"
                    value={stake ? stake[1] : 0}
                    onIonChange={(e) => handleInputStake(e, 1)}
                  />
                  <IonInput
                    className="editStakesPopUp__input"
                    placeholder="0"
                    name="thirdOneclick"
                    type="text"
                    value={stake ? stake[2] : 0}
                    onIonChange={(e) => handleInputStake(e, 2)}
                  />
                   <IonInput
                    className="editStakesPopUp__input"
                    placeholder="0"
                    name="fourthOneclick"
                    type="text"
                    value={stake && stake[3]}
                    onIonChange={(e) => handleInputStake(e, 3)}
                  />
                </div>
                <div className="editStakesPopUp__fields-container">
                  <IonInput
                    className="editStakesPopUp__input"
                    placeholder="0"
                    name="fifthOneclick"
                    type="text"
                    value={stake && stake[4]}
                    onIonChange={(e) => handleInputStake(e, 4)}
                  />
                  <IonInput
                    className="editStakesPopUp__input"
                    placeholder="0"
                    name="sixthOneclick"
                    type="text"
                    value={stake && stake[5]}
                    onIonChange={(e) => handleInputStake(e, 5)}
                  />
                  <IonInput
                    className="editStakesPopUp__input"
                    placeholder="0"
                    name="sixthOneclick"
                    type="text"
                    value={stake && stake[6]}
                    onIonChange={(e) => handleInputStake(e, 6)}
                  />
                  <IonInput
                    className="editStakesPopUp__input"
                    placeholder="0"
                    name="sixthOneclick"
                    type="text"
                    value={stake && stake[7]}
                    onIonChange={(e) => handleInputStake(e, 7)}
                  />
                </div>
                <div className="editStakesPopUp__fields-container">
                  <IonInput
                    className="editStakesPopUp__input"
                    placeholder="0"
                    name="fifthOneclick"
                    type="text"
                    value={stake && stake[8]}
                    onIonChange={(e) => handleInputStake(e, 8)}
                  />
                  <IonInput
                    className="editStakesPopUp__input"
                    placeholder="0"
                    name="sixthOneclick"
                    type="text"
                    value={stake && stake[9]}
                    onIonChange={(e) => handleInputStake(e, 9)}
                  />
                </div>
              </div>
              {
                <div className="editStakesPopUp__fields-bottomContainer">
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() => actions.setIsOpen(!isPopUpOpenDispatch)}
                  >
                    Cancel
                  </div>
                  <button
                    type="button"
                    onClick={() => {
                      saveMobileStakesSettings()
                      actions.setIsOpen(!isPopUpOpenDispatch)
                    }}
                    className="pushable"
                  >
                    <span className="shadow"></span>
                    <span className="edge refreshBtn"></span>
                    <span className="front refreshBtn">Save</span>
                  </button>
                  {/* <button
                    onClick={() => {
                      saveMobileStakesSettings()
                      actions.setIsOpen(!isPopUpOpenDispatch)
                    }}
                    class="editStakesPopUp__btn"
                    // mode="md"
                  >
                    Save
                  </button> */}
                </div>
              }
            </div>
          </div>
        </div>
        // {/* </div> */}
      )}
    </>
  )
}

export default connectEditStakesPopUp()(EditStakesPopUp)
