import { createSlice } from '@reduxjs/toolkit'

export const profilePageSlice = createSlice({
    name: 'profile',
    initialState: {},
    reducers: {
        getProfileSuccess: (state, action) => action.payload,
        getProfileError: (state) => state
    },
})

export const {
    getProfileSuccess,
    getProfileError
} = profilePageSlice.actions

export default profilePageSlice.reducer
