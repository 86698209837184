import './Profile.scss'
import {
  IonButton,
  IonContent,
  IonHeader,
  IonInput,
  IonPage,
} from '@ionic/react'
import React, { useEffect, useState } from 'react'
import Toolbar from '../../components/Toolbar/Toolbar'
import { connectProfile } from './Profile.connect'
import editImg from '../../assets/images/profile/profileEdit.svg'
import verifiedMark from '../../assets/images/profile/verifiedMark.svg'
import { toastr } from 'react-redux-toastr'
import DatePicker from 'react-date-picker'
import VerifyPopUp from './VerifyPopUp'
import PhoneInput from 'react-phone-number-input'
import { countryCodesArray } from '../../components/Verification/countryCodes'

const Profile = ({ user, profile, actions, elementsParams, ...props }) => {
  const [formValue, setFormValue] = useState({
    name: profile.full_name?.replace(/\d/g, '') || '',
    mob: profile.mobile?.split('_')?.[1] || '',
    email: profile.email || '',
    gender: profile.gender || '',
    birth: profile.date_of_birth || '',
  })

  const [formErrors, setFormErrors] = useState({
    name: false,
    mob: false,
    email: false,
    gender: false,
    birth: false,
  })
  const [emailLoading, setEmailLoading] = useState(false)
  const [formActive, setFormActive] = useState('')
  const [date, setDate] = useState('')
  const [phone, setPhone] = useState('')
  const [userModified, setUserModified] = useState({
    phone: '',
    isLoginMame: false,
    canChange: true,
  })
  const [verifyPopUp, setVerifyPopUp] = useState(false)
  const [countryCodeInput, setCountryCodeInput] = useState('IN')
  const [countryCodeDial, setCountryCodeDial] = useState('+91')

  const startMailVerify = async (mail) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    if (emailRegex.test(mail)) {
      setEmailLoading(true)
      console.log('regex', emailRegex.test(mail))

      await actions
        .requestMailVerification(mail)
        .then((res) => {
          res?.status?.statusDesc
            ? toastr.success('', res?.status?.statusDesc)
            : toastr.success('', 'Email sent')
        })
        .catch((e) => {
          console.log('EEE', e)
          toastr.error('', 'Something went wrong')
        })
      setEmailLoading(false)
    } else {
      setFormErrors({ ...formErrors, email: true })
    }
  }
  console.log('@@@@##@#@#', phone, userModified.phone, formValue.mob);

  const checkUser = async () => {
    const loginName = JSON.parse(
      localStorage.getItem('user_data') || '{}'
    )?.loginName
    const isPhoneInLoginName = !![9, 10].includes(
      loginName?.split('_')[1]?.length
    )

    const getPart = (index) =>
      loginName && isPhoneInLoginName
        ? loginName?.split('_')[index]
        : user?.mobile_verified
        ? user?.mobile?.split('_')[index]
        : ''

    const country = getPart(0) || '91'
    const mobile = getPart(1)

    const countryInfo = countryCodesArray.find(
      (item) => item.dial_code === `+${country}`
    )

    setCountryCodeDial(countryInfo?.dial_code)
    setCountryCodeInput(countryInfo?.code)
    setUserModified({
      phone: mobile,
      isLoginMame: loginName && isPhoneInLoginName,
      canChange: !(isPhoneInLoginName || user?.mobile_verified),
    })
    setPhone(mobile)
  }

  useEffect(() => {
    checkUser()
  }, [])

  const onFormChange = (e) => {
    const { name, value } = e.target
    if (name === 'name' || name === 'gender') {

      if (/^[a-zA-Z\s]*$/.test(value)) {

        setFormValue({ ...formValue, [name]: value })
      }
    } else {
      setFormValue({ ...formValue, [name]: value })
    }
  }

  const activateInput = (inputName) => {
    setFormActive(inputName)
  }

  const openVerifyPopUp = () => {
    if (formValue?.mob?.length === 10 || phone?.length === 10) {
      setVerifyPopUp(true)
    } else {
      setFormErrors({ ...formErrors, mob: true })
    }
  }

  const updateProfile = async () => {
    if (formValue.name) {
      const res = await actions
        .updateProfile(
          formValue.name,
          formValue.gender,
          date
            ? `${getDate(new Date(date))}-${getMonth(
                new Date(date)
              )}-${new Date(date).getFullYear()}`
            : formValue.birth
        )
        .then((res) => {
          toastr.success('', 'Profile updated successfully')
          setFormActive('')
          actions.requestProfile()
        })
    } else {
      setFormErrors({ ...formErrors, name: true })
    }
  }

  useEffect(() => {
    setFormValue({
      name: profile.full_name || '',
      mob: profile.mobile?.includes('_')
        ? profile.mobile?.split('_')?.[1]
        : profile?.mobile?.length
        ? profile?.mobile
        : '',
      email: profile.email || '',
      gender: profile.gender || '',
      birth: profile.date_of_birth || '',
    })
  }, [profile])

  const getMonth = (date) => {
    return `${date.toLocaleDateString('en-GB', {
      month: '2-digit',
    })}`
  }

  const getDate = (date) => {
    return `${date.toLocaleDateString('en-GB', {
      day: '2-digit',
    })}`
  }

  return (
    <IonPage>
      <IonHeader>
        <Toolbar />
      </IonHeader>
      <IonContent className="profile" fullscreen>
        <h2 className="profile-title">Complete Your Profile</h2>
        <div className="profile_container">
          <div className="profile_container_image"></div>
          <div className="profile_container_formItem">
            <div className="profile_container_formItem-title required">
              Full Name
            </div>
            <div className="profileInputWrapper">
              <IonInput
                placeholder="Full Name"
                name="name"
                type="text"
                className={`profile_container_formItem-input ${
                  formErrors.name ? 'errored' : ''
                }`}
                value={formValue.name?.replace(/\d/g, "")}
                onIonChange={onFormChange}
                disabled={formActive !== 'name'}
              ></IonInput>
              {formErrors.name && (
                <span className="profile_container_formItem-errorMsg ">
                  Please enter a valid name
                </span>
              )}

              <img
                alt="edit"
                src={editImg}
                onClick={() => activateInput('name')}
                className={`profile_container_formItem-input-editImg ${
                  formActive === 'name' ? 'editable' : ''
                }`}
              />
            </div>
          </div>

          <div className="profile_container_formItem">
            <div className="profile_container_formItem-title required">
              Mobile Number
              {profile?.mobile_verified && (
                <div className="profile_container_formItem-title-verified">
                  <img src={verifiedMark} alt="verified" /> Verified
                </div>
              )}
            </div>
            <div className="profile_container_formItem-mobInputBox">
              <div className="profileInputWrapper">
                <IonInput
                  placeholder="Mobile Number"
                  name="mob"
                  type="tel"
                  className={`profile_container_formItem-input mob ${
                    formErrors.mob ? 'errored' : ''
                  }`}
                  value={phone || userModified?.phone || formValue.mob}
                  onIonChange={onFormChange}
                  disabled={formActive !== 'mob'}
                >
                  <>
                    <PhoneInput
                      readOnly={!userModified.canChange}
                      className={`countryCodeInput ${
                        !userModified.canChange && 'arrowDisabled'
                      }`}
                      value={countryCodeDial}
                      international
                      defaultCountry={countryCodeInput}
                      onChange={setCountryCodeDial}
                    />
                    <div
                      className={`placeholder ${phone.length ? 'active' : ''}`}
                    >
                      {countryCodeDial}
                    </div>
                  </>
                  {!profile.mobile_verified && (
                    <div
                      className={`profile_container_formItem-verifyBtn ${
                        profile.mobile_verified ? 'verified' : ''
                      }`}
                      onClick={() => {
                        if (formActive !== 'mob' && userModified.canChange) {
                          activateInput('mob')
                        } else {
                          openVerifyPopUp()
                        }
                      }}
                    >
                      {profile.mobile_verified && (
                        <img src={verifiedMark} alt="+" />
                      )}
                      {formActive !== 'mob' && userModified.canChange ? 'Edit' : 'Get OTP'}
                    </div>
                  )}
                </IonInput>
                {formErrors.mob && (
                  <span className="profile_container_formItem-errorMsg ">
                    Please enter a valid mobile number
                  </span>
                )}
              </div>
            </div>
          </div>

          <div className="profile_container_formItem">
            <div className="profile_container_formItem-title required">
              Email
              {profile?.email_verified && (
                <div className="profile_container_formItem-title-verified">
                  <img src={verifiedMark} alt="verified" /> Verified
                </div>
              )}
            </div>
            <div className="profileInputWrapper">
              <IonInput
                placeholder="Email"
                name="email"
                type="email"
                className={`profile_container_formItem-input ${
                  formErrors.email ? 'errored' : ''
                }`}
                value={formValue.email}
                onIonChange={onFormChange}
                disabled={formActive !== 'email'}
              ></IonInput>
              {formErrors.email && (
                <span className="profile_container_formItem-errorMsg ">
                  Please enter a valid email address
                </span>
              )}
              <img
                alt="edit"
                src={editImg}
                onClick={() => activateInput('email')}
                className={`profile_container_formItem-input-editImg ${
                  formActive === 'email' ? 'editable' : ''
                }`}
              />
            </div>
            <div className="profile_container_formItem-verifyBtnsRow">
              <div
                className={`profile_container_formItem-verifyBtnsRow-item`}
                onClick={() =>
                  !profile.email_verified
                    ? startMailVerify(formValue.email)
                    : toastr.success('', 'Email already verified')
                }
              >
                Verify with Gmail
              </div>
              <div
                className={`profile_container_formItem-verifyBtnsRow-item ${
                  emailLoading ? 'loading' : ''
                }`}
                onClick={() =>
                  !profile.email_verified
                    ? startMailVerify(formValue.email)
                    : toastr.success('', 'Email already verified')
                }
              >
                Verify with Link
              </div>
            </div>
          </div>

          <div className="profile_container_formItem">
            <div className="profile_container_formItem-title">
              Gender
              <span style={{ marginLeft: '5px' }}>(Optional)</span>
            </div>
            <div className="profileInputWrapper">
              <IonInput
                placeholder="Gender"
                name="gender"
                type="text"
                className="profile_container_formItem-input"
                value={formValue.gender}
                onIonChange={onFormChange}
                disabled={formActive !== 'gender'}
              ></IonInput>
              <img
                alt="edit"
                src={editImg}
                onClick={() => activateInput('gender')}
                className={`profile_container_formItem-input-editImg ${
                  formActive === 'gender' ? 'editable' : ''
                }`}
              />
            </div>
          </div>

          <div className="profile_container_formItem">
            <div className="profile_container_formItem-title">
              Date Of Birth
              <span style={{ marginLeft: '5px' }}>(Optional)</span>
            </div>
            <div className="profileInputWrapper">
              <DatePicker
                className={`profile_container_formItem-input ${
                  formErrors.birth ? 'errored' : ''
                }
                ${formActive !== 'birth' ? 'disabledDate' : ''}
                `}
                maxDate={new Date()}
                onChange={setDate}
                value={date || null}
                locale={'en-US'}
                showLeadingZeros={true}
                clearIcon={null}
                calendarIcon={null}
                format={formValue.birth || date ? 'dd/MM/yyyy' : 'dd MM yyyy'}
                dayPlaceholder={
                  formValue.birth ? getDate(new Date(formValue.birth)) : 'Date'
                }
                monthPlaceholder={
                  formValue.birth ? getMonth(new Date(formValue.birth)) : 'of'
                }
                yearPlaceholder={
                  formValue.birth
                    ? new Date(formValue.birth).getFullYear()
                    : 'Birth'
                }
                disabled={formActive !== 'birth'}
              />
              <img
                alt="edit"
                src={editImg}
                onClick={() => activateInput('birth')}
                className={`profile_container_formItem-input-editImg ${
                  formActive === 'birth' ? 'editable' : ''
                }`}
              />
            </div>
          </div>
        </div>
        <IonButton onClick={updateProfile} mode="md" class="profile_submit">
          Save
        </IonButton>
      </IonContent>
      {verifyPopUp && (
        <VerifyPopUp
          setVerifyPopUp={setVerifyPopUp}
          countryCode={countryCodeDial}
          mobile={formValue.mob || phone}
        />
      )}
    </IonPage>
  )
}

export default connectProfile()(Profile)
