import http from '../../services/httpAuth'
import getErrorMessage from '../../helpers/getErrorMessage.helper'
import { getMessageError, setMessageSuccess } from './message.slice'
import { setNotificationSettings } from '../user/user.slice'

export const requestMessage = (memberCode) => async (dispatch, getState) => {
  // if(memberCode) {
  const checkAuth = getState().auth
  //News msg here
  if (checkAuth) {
    try {
      const response = await http.post(`news/getNews`)
      if (response.data.status) {
        dispatch(setMessageSuccess(response.data.data))
        dispatch(setNotificationSettings(true));
      }
      return response
    } catch (e) {
      dispatch(getMessageError())
      // const errorMsg = getErrorMessage(e)
      // throw errorMsg
    }
  }
}
