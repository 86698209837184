import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { requestCancelOrder, requestCancelAllBets } from '../../store/betPlacing/betPlacing.thunks';

export const connectOpenBetsCasino = (wrappedProps) => (component) => {
  function mapStateToProps(state) {
    return {
      timeSettings: state.timeSettings,
    }
  }

  function mapDispatchToProps(dispatch) {
    const actions = {
      requestCancelOrder,
      requestCancelAllBets,
    }

    return { actions: bindActionCreators(actions, dispatch) }
  }

  return connect(mapStateToProps, mapDispatchToProps)(component)
}
