import './promotion.scss'
import React from 'react'
import { IonItemGroup } from '@ionic/react';
import TopBaner from '../TopBaner/TopBaner';
import QuickButtons from './QuickButtons/QuickButtons';
import { connectPromotions } from './Promotion.connect';
import { useEffect } from 'react';
import SportsButtons from './SportButtons/SportsButtons'
import { HeaderCasino } from '../../constants/Casino/HeaderCasino';

const Promotion = ({ inplayPopular, promotions, menuTop, actions, auth }) => {

  useEffect(() => {
      actions.requestPromotions()
      actions.requestSocials()
  }, [])

  return (
    <>
        {promotions ? <IonItemGroup className="promotion-content">
        {promotions?.slider && promotions?.slider.length >= 1 ? <TopBaner inPlaySlidingBanners={promotions?.slider} /> : ''}
        {menuTop && menuTop.length >=1 ? <SportsButtons sportsButtons={menuTop}/>  : "" }
        {HeaderCasino && HeaderCasino.length >= 1 ? <QuickButtons quickButtons={HeaderCasino} auth={auth} /> : ''}
      </IonItemGroup> : ''}
    </>
  );
}

export default connectPromotions()(Promotion)
