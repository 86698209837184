import React, { useState, useEffect } from 'react'
import {
  IonButton,
  IonContent,
  IonInput,
  IonItem,
  IonList,
  IonPage,
  IonHeader,
  IonLabel,
  IonImg,
  IonFooter,
} from '@ionic/react'
import './FooterDesktop.scss'
import { connectFooterDesktop } from './FooterDesktop.connect'
import { useHistory } from 'react-router'
import { goBackPages } from '../../config/app.config'
import {
  CONFIG as social,
  CONFIG,
  getVideoUrl,
  getUrlDomain,
} from '../../config/api.config'
import gaming from '../../assets/images/footer/gaming-1.png'
import gamblingCom from '../../assets/images/footer/gamblingCom.svg'
import BeGamble from '../../assets/images/footer/BeGamble.svg'
import mga from '../../assets/images/footer/mga.svg'
import corga2 from '../../assets/images/footer/corga2.svg'
import bankTransfer from '../../assets/images/footer/bankTransfer.svg'
import upi from '../../assets/images/footer/upi.svg'

const FooterDesktop = ({
  menu,
  version,
  actions,
  fromApp = false,
  B2C,
  socials,
}) => {
  const history = useHistory()
  const [pathname, setPathname] = useState([])

  // console.log("socials", socials)

  useEffect(() => {
    setPathname([])
  }, [history.location.pathname])

  if (
    fromApp &&
    (history.location.pathname === '/sportsbook/1444005' ||
      history.location.pathname === '/exchange-games' ||
      history.location.pathname === '/livecasino-lobby' ||
      history.location.pathname === '/slotsgames-lobby' ||
      history.location.pathname === '/transactions')
  ) {
    return <></>
  }

  return (
    // <div className='footerBlock'>
    //   <div className='footerBlock-content'>
    //     <span className='rulesAndRegulations' onClick = {() => history.push('/rules_regs')}>
    //         <span>Rules</span>
    //         <span className='andSymbol'>  </span>
    //         <span>Regulations</span>
    //       </span>
    //     <span className='copyright'> </span>
    //     <span> 2016-2022 </span>
    //     {version && <span style={{marginLeft: '7px'}}>v. {version}</span>}
    //   </div>
    // </div>

    <div className="landing-footer-desktop">
      {
        <a href={CONFIG.downloadAppLink} target={'_blank'}>
          <div className={'landing-footer-desktop__number'}>
            <div className={'android-button'} />
          </div>
        </a>
      }

      {B2C && getUrlDomain() && (
        <div className={'tutorials'}>
          {getVideoUrl('introduction') && (
            <div className={'tutorial-btn'}>
              <a href={getVideoUrl('introduction')} target={'_blank'}>
                Introduction
              </a>
            </div>
          )}
          {getVideoUrl('register') && (
            <div className={'tutorial-btn'}>
              <a href={getVideoUrl('register')} target={'_blank'}>
                How to register
              </a>
            </div>
          )}
          {getVideoUrl('deposit') && (
            <div className={'tutorial-btn'}>
              <a href={getVideoUrl('deposit')} target={'_blank'}>
                How to deposit
              </a>
            </div>
          )}
          {getVideoUrl('bonus') && (
            <div className={'tutorial-btn'}>
              <a href={getVideoUrl('bonus')} target={'_blank'}>
                How to use bonus
              </a>
            </div>
          )}
        </div>
      )}

      {B2C && socials && socials?.Whatsapp && (
        <a href={socials?.Whatsapp?.url} target="_blank">
          <div className={'landing-footer-desktop__number'}>
            <div className={'landing-footer-social-button whatsapp'} />
            <div>WHATSAPP NOW!</div>
          </div>
        </a>
      )}
      <div className="landing-footer-desktop__companies-wrapper">
        <div className="landing-footer-desktop__companies">
          <div className="landing-footer-desktop__companies-img">
            <a>
              <img loading={'lazy'} src={gaming} alt="gaming" />
            </a>
          </div>
          <p className="landing-footer-desktop__companies-info">
            It's a limited liability company incorporated under the laws of
            Curacao Players are requested not to contact any untrusted sources
            for it's accounts as this is an online site and they can only
            register independently without any agents. Only deposit through the
            account details generated by the system or provided by our official
            support team.
          </p>
        </div>
        <div className="landing-footer-desktop__companies-logos-rows-wrapper">
          <div className="landing-footer-desktop__companies-logos-row">
            <img loading={'lazy'} src={upi} alt="gaming" />
            <img loading={'lazy'} src={bankTransfer} alt="gaming" />
            <img loading={'lazy'} src={BeGamble} alt="gaming" />
            <img loading={'lazy'} src={gamblingCom} alt="gaming" />
            <img loading={'lazy'} src={corga2} alt="gaming" />

            <img loading={'lazy'} src={mga} alt="gaming" />
            {/*<img src={cogra} alt="gaming"/>*/}
            {/*<img src={ibas} alt="gaming"/>*/}
            {/*<img src={ibia} alt="gaming"/>*/}
            {/*<img src={plus18} alt="gaming"/>*/}
            {/*<img src={gameStop} alt="gaming"/>*/}
          </div>
        </div>
      </div>
      <div className="landing-footer-desktop__social-wrapper">
        {B2C && (
          <div className="landing-footer-social">
            {/*<a href={`https://wa.me/${CONFIG.mobiNumber}`}*/}
            {/*   target="_blank"*/}
            {/*   className="landing-footer-social-button whatsapp">*/}
            {/*  <span>{CONFIG.mobiNumber}</span>*/}
            {/*</a>*/}

            {/*{CONFIG.youtube ? (*/}
            {/*  <a href={`${CONFIG.youtube}`}*/}
            {/*  target="_blank"*/}
            {/*  className="landing-footer-social-button youtube">*/}
            {/*    <span>{CONFIG.youtube}</span>*/}
            {/*  </a>*/}
            {/*) : null}*/}
            {socials && socials?.Instagram && (
              <a
                href={socials?.Instagram?.url}
                target="_blank"
                className="landing-footer-social-button instagram"
              >
                {/* <span>{CONFIG.instagram}</span> */}
              </a>
            )}
            {socials && socials?.Telegram && (
              <a
                href={socials?.Telegram?.url}
                target="_blank"
                className="landing-footer-social-button telegram"
              >
                {/* <span>{CONFIG.telegram}</span> */}
              </a>
            )}
            {/*<a href={`https://www.messenger.com/t/${CONFIG.facebook}`}*/}
            {/*   target="_blank"*/}
            {/*   className="landing-footer-social-button fb">*/}
            {/*  <span>{CONFIG.facebook}</span>*/}
            {/*</a>*/}
            {/*<a href={`mailto:${CONFIG.email}`}*/}
            {/*   target="_blank"*/}
            {/*   className="landing-footer-social-button mail">*/}
            {/*  <span>{CONFIG.email}</span>*/}
            {/*</a>*/}
          </div>
        )}
        {/*<div className="landing-footer-desktop__social-faq">*/}
        {/*    <span onClick={() => { setIsRulesVisible(true) }}>FAQ</span>*/}
        {/*</div>*/}
        <div className="landing-footer-desktop__social-copyright">
          <span
            className="rulesAndRegulations"
            onClick={() => history.push('/rules_regs')}
          >
            <span>Rules</span>
            <span className="andSymbol"> </span>
            <span>Regulations</span>
          </span>
          <span className="copyright"> </span>
          <span> 2016-2024 </span>
          {version && <span style={{ marginLeft: '7px' }}>v. {version}</span>}
        </div>
      </div>
    </div>
  )
}

export default connectFooterDesktop()(FooterDesktop)
