import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { resetSearchResults, setSearchValue } from '../../store/search/search.slice';
import { requestSearchResults } from '../../store/search/search.thunks';
import { setNotifications, requestSaveOneClickSettings } from '../../store/user/user.thunks';
import { requestLogout } from '../../store/auth/auth.thunks'
import { setIsNavigationOpen, setIsNavigationID, setIsNavigationName, setIsNavigationReset, setIsAnimatedMenu, setIsMoreDetailed, setIsAnimatedText, setSearchValues, setIsDetailedGroup, setRenderIonContent } from '../../store/navigationHeaderMenu/navigationHeaderMenu.slice';
import { setLoginNew, setTab } from '../../store/LoginNew/loginNew.slice'
import { requestSportPageEvent } from '../../store/sportPageEvent/sportPageEvent.thunks';
import { requestMessage } from "../../store/message/message.thunks";
import { requestMenuTop } from '../../store/menuTop/menuTop.thunks';
import { requestMenu } from '../../store/menu/menu.thunks';
import { getMenuSuccess } from '../../store/menu/menu.slice'
import { requestCasinoPoint } from '../../store/liveCasino/liveCasino.thunks';
import { requestLogo } from '../../store/logo/logo.thunks';

export const connectToolbarDesktop = (wrappedProps) => (component) => {
  function mapStateToProps(state) {
    return {
      balance: state.balance,
      messages: state.message,
      notifications: state.user.settings.notifications,
      oneClick: state.user.settings.oneClickSettings,
      user: state.user,
      menuTop: state.menuTop,
      auth: state.auth,
      B2C: state.B2C,
      animatedText: state.navigationHeaderMenu.animatedText,
      searchValue: state.navigationHeaderMenu.searchValue,
      isNavigationName: state.navigationHeaderMenu.navigationName,
      logo: state.logo,
    }
  }

  function mapDispatchToProps(dispatch) {
    const actions = {
      requestSaveOneClickSettings,
      requestSearchResults, 
      setSearchValue,
      resetSearchResults,
      setNotifications,
      requestLogout,
      setIsNavigationOpen,
      setIsNavigationID,
      setIsNavigationName,
      setIsNavigationReset,
      setIsAnimatedMenu,
      setLoginNew,
      setTab,
      setIsMoreDetailed,
      setIsAnimatedText,
      setSearchValues,
      requestSportPageEvent,
      setIsDetailedGroup,
      setRenderIonContent,
      requestMessage,
      requestMenuTop,
      requestMenu,
      getMenuSuccess,
      requestCasinoPoint,
      requestLogo,
    }

    return { actions: bindActionCreators(actions, dispatch) }
  }

  return connect(mapStateToProps, mapDispatchToProps)(component)
}
