import React, { useState, useEffect } from 'react'
import {
  IonButton,
  IonContent,
  IonInput,
  IonItem,
  IonList,
  IonPage,
  IonHeader,
} from '@ionic/react'
import './ThanksWithdrawal.scss'
import Toolbar from '../../components/Toolbar/Toolbar'
import ThanksImg from '../../assets/images/thanksImg.svg'
import ThanksImgBig from '../../assets/images/thanksImgBig.svg'

import { connectThanksWithdrawal } from './ThanksWithdrawal.connect'
import { useHistory } from 'react-router'
import FooterDesktop from '../../components/FooterDesktop/FooterDesktop'

const ThanksWithdrawal = ({ user, elementsParams, actions, ...props }) => {
  const history = useHistory()
  const width = elementsParams && elementsParams?.clientWidth ? elementsParams?.clientWidth : window.innerWidth

  return (
    <IonPage>
      {width < 1024 ? (
        <IonHeader>
          <Toolbar />
        </IonHeader>
      ) : null}
      <IonContent className="ChangePassword" fullscreen>
        <div className="thankswithdraw-container">
          <div className="thankswithdraw-container__content">
            <div className="thanks-content__image">
              <img
                loading={"lazy"}
                className="thanks-content__image-small"
                src={ThanksImg}
                alt=""
              />
              <img
                loading={"lazy"}
                className="thanks-content__image-big"
                src={ThanksImgBig}
                alt=""
              />
            </div>
            <div className="thanks-content__thankyou">Thank you!</div>
            <div className="thanks-content__description">
              Kindly allow us 10-20 minutes to process your payout
            </div>
            <div className="thanks-content__buttons">
              {width >= 1024 ? (
                <>
                  <button
                    onClick={() => history.push('/default-page')}
                    className="pushable"
                  >
                    <span className="shadow"></span>
                    <span className="edge"></span>
                    <span className="front">Back</span>
                  </button>

                  <button
                    onClick={() => history.push('/transactions')}
                    className="pushable"
                  >
                    <span className="shadow"></span>
                    <span className="edge"></span>
                    <span className="front">Transaction</span>
                  </button>
                </>
              ) : (
                <>
                  <div
                    className="thanks-content__buttons-back"
                    onClick={() => history.push('/default-page')}
                  >
                    Back
                  </div>

                  <div
                    className="thanks-content__buttons-transaction"
                    onClick={() => history.push('/transactions')}
                  >
                    Transaction
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        {/*{width < 1024 && <FooterDesktop />}*/}
      </IonContent>
    </IonPage>
  )
}

export default connectThanksWithdrawal()(ThanksWithdrawal)
