import httpAuth from '../../services/httpAuth'
import httpNotAuth from '../../services/httpNotAuth'
import getErrorMessage from '../../helpers/getErrorMessage.helper'
import {
  getSportPageEventError,
  getSportPageEventSuccess,
  getSportPageEventDetailedSuccess,
} from './sportPageEvent.slice'

export const requestSportPageEvent = (eventId) => async (dispatch, getState) => {
  const checkAuth = getState().auth
  let data = {
    sport_id: eventId
  }
  if(checkAuth) {
    try {

        const response = await httpAuth.post(`match/homeMatches`, {...data, "combine": true})

        // const response = await httpNotAuth.post(`match/homeMatchesOpen`, data)

        if (response.data.status) {
            dispatch(getSportPageEventSuccess(response.data.data))
        }
        return response
    } catch (e) {
        dispatch(getSportPageEventError())
        // const errorMsg = getErrorMessage(e) 
        // throw errorMsg                       
    }
} else {
    try {
        const response = await httpNotAuth.post(`match/homeMatchesOpen`, data)
        if (response.data.status) {
            dispatch(getSportPageEventSuccess(response.data.data))
        }
        return response
    } catch (e) {
        dispatch(getSportPageEventError())
        // const errorMsg = getErrorMessage(e)
        // throw errorMsg
    }
  }
}

export const requestSportPageEventDetailed = (eventId, id) => async (dispatch, getState) => {
  const checkAuth = getState().auth
  let data = {
    series_id: id
  }
if(!checkAuth) {
  try {
      const response = await httpNotAuth.post(`match/homeMatchesOpen`, data)
      //const response = await axios.get(`https://89383249-2f56-4a28-b7cf-2bc8402ce7ed.mock.pstmn.io/api/exchange/odds/inPlayAndPopularEvents`)
      if (response.data.status) {
          dispatch(getSportPageEventSuccess(response.data.data))
      }
      return response
  } catch (e) {
      dispatch(getSportPageEventError())
      // const errorMsg = getErrorMessage(e) 
      // throw errorMsg                       
  }
} else {
  try {
            // const response = await httpAuth.post(`match/homeMatches`, data)

          const response = await httpAuth.post(`match/homeMatches`, {...data, "combine": true})
          if (response.data.status) {
          dispatch(getSportPageEventSuccess(response.data.data))
      }
      return response
  } catch (e) {
      dispatch(getSportPageEventError())
      // const errorMsg = getErrorMessage(e)
      // throw errorMsg
  }
}
}
