export const IndianCasino = [
    {
        "sport_id": "-100",
        "sport_name": "Casino",
        "series_id": "67660",
        "series_name": "2 Cards Teenpatti",
        "match_id": "67660",
        "match_name": "2 Cards Teenpatti",
        "market_id": "-100.67660.main",
        "marketId": "-100.67660.main",
        "status": "SUSPENDED",
        "inplay": true,
        "match_date": "2022-01-21T18:30:00.000Z",
        "marketIds": [
            "-100.67660.color_plus",
            "-100.67660.main",
            "-100.67660.mini_baccarat"
        ],
        "is_active": 1,
        "enable_fancy": 0,
        "fancy_count": 0,
        "market_count": 0,
        "bookmaker_count": 0,
        "match_tv_url": null,
        "has_tv_url": false,
        "has_sc_url": false,
        "match_scoreboard_url": null,
        "is_lock": false,
        "my_favorites": [],
        "is_created": 1,
        "from_db": 1
    },
    {
        "sport_id": "-100",
        "sport_name": "Casino",
        "series_id": "98789",
        "series_name": "7 up & Down",
        "match_id": "98789",
        "match_name": "7 up & Down",
        "market_id": "",
        "marketId": "",
        "status": "SUSPENDED",
        "inplay": true,
        "match_date": "2022-01-21T18:30:00.000Z",
        "marketIds": [
            "-100.98789.color",
            "-100.98789.main",
            "-100.98789.oddeven",
            "-100.98789.SUIT"
        ],
        "is_active": 1,
        "enable_fancy": 0,
        "fancy_count": 0,
        "market_count": 0,
        "bookmaker_count": 0,
        "match_tv_url": null,
        "has_tv_url": false,
        "has_sc_url": false,
        "match_scoreboard_url": null,
        "is_lock": false,
        "my_favorites": [],
        "is_created": 1,
        "from_db": 1
    },
    {
        "sport_id": "-100",
        "sport_name": "Casino",
        "series_id": "98793",
        "series_name": "7 up & Down (Virtual)",
        "match_id": "98793",
        "match_name": "7 up & Down (Virtual)",
        "market_id": "",
        "marketId": "",
        "status": "SUSPENDED",
        "inplay": true,
        "match_date": "2022-01-21T18:30:00.000Z",
        "marketIds": [
            "-100.98793.color",
            "-100.98793.main",
            "-100.98793.oddeven",
            "-100.98793.SUIT"
        ],
        "is_active": 1,
        "enable_fancy": 0,
        "fancy_count": 0,
        "market_count": 0,
        "bookmaker_count": 0,
        "match_tv_url": null,
        "has_tv_url": false,
        "has_sc_url": false,
        "match_scoreboard_url": null,
        "is_lock": false,
        "my_favorites": [],
        "is_created": 1,
        "from_db": 1
    },
    {
        "sport_id": "-100",
        "sport_name": "Casino",
        "series_id": "67690",
        "series_name": "29 Card Baccarat",
        "match_id": "67690",
        "match_name": "29 Card Baccarat",
        "market_id": "-100.67690.main",
        "marketId": "-100.67690.main",
        "status": "SUSPENDED",
        "inplay": true,
        "match_date": "2022-01-21T18:30:00.000Z",
        "marketIds": [
            "-100.67690.color_plusA",
            "-100.67690.color_plusB",
            "-100.67690.high_card",
            "-100.67690.lucky9",
            "-100.67690.main",
            "-100.67690.pairA",
            "-100.67690.pairB"
        ],
        "is_active": 1,
        "enable_fancy": 0,
        "fancy_count": 0,
        "market_count": 0,
        "bookmaker_count": 0,
        "match_tv_url": null,
        "has_tv_url": false,
        "has_sc_url": false,
        "match_scoreboard_url": null,
        "is_lock": false,
        "my_favorites": [],
        "is_created": 1,
        "from_db": 1
    },
    {
        "sport_id": "-100",
        "sport_name": "Casino",
        "series_id": "56967",
        "series_name": "32 cards casino",
        "match_id": "56967",
        "match_name": "32 cards casino",
        "market_id": "",
        "marketId": "",
        "status": "SUSPENDED",
        "inplay": true,
        "match_date": "2022-01-21T18:30:00.000Z",
        "marketIds": [
            "-100.56967.plain"
        ],
        "is_active": 1,
        "enable_fancy": 0,
        "fancy_count": 0,
        "market_count": 0,
        "bookmaker_count": 0,
        "match_tv_url": null,
        "has_tv_url": false,
        "has_sc_url": false,
        "match_scoreboard_url": null,
        "is_lock": false,
        "my_favorites": [],
        "is_created": 1,
        "from_db": 1
    },
    {
        "sport_id": "-100",
        "sport_name": "Casino",
        "series_id": "56966",
        "series_name": "32 cards casino (Virtual)",
        "match_id": "56966",
        "match_name": "32 cards casino (Virtual)",
        "market_id": "-100.56966.plain",
        "marketId": "-100.56966.plain",
        "status": "SUSPENDED",
        "inplay": true,
        "match_date": "2022-01-21T18:30:00.000Z",
        "marketIds": [
            "-100.56966.plain"
        ],
        "is_active": 1,
        "enable_fancy": 0,
        "fancy_count": 0,
        "market_count": 0,
        "bookmaker_count": 0,
        "match_tv_url": null,
        "has_tv_url": false,
        "has_sc_url": false,
        "match_scoreboard_url": null,
        "is_lock": false,
        "my_favorites": [],
        "is_created": 1,
        "from_db": 1
    },
    {
        "sport_id": "-100",
        "sport_name": "Casino",
        "series_id": "98791",
        "series_name": "Amar Akbar Anthony",
        "match_id": "98791",
        "match_name": "Amar Akbar Anthony",
        "market_id": "",
        "marketId": "",
        "status": "SUSPENDED",
        "inplay": true,
        "match_date": "2022-01-21T18:30:00.000Z",
        "marketIds": [
            "-100.98791.color",
            "-100.98791.main",
            "-100.98791.oddeven",
            "-100.98791.overunder7"
        ],
        "is_active": 1,
        "enable_fancy": 0,
        "fancy_count": 0,
        "market_count": 0,
        "bookmaker_count": 0,
        "match_tv_url": null,
        "has_tv_url": false,
        "has_sc_url": false,
        "match_scoreboard_url": null,
        "is_lock": false,
        "my_favorites": [],
        "is_created": 1,
        "from_db": 1
    },
    {
        "sport_id": "-100",
        "sport_name": "Casino",
        "series_id": "98795",
        "series_name": "Amar Akbar Anthony (Virtual)",
        "match_id": "98795",
        "match_name": "Amar Akbar Anthony (Virtual)",
        "market_id": "-100.98795.main",
        "marketId": "-100.98795.main",
        "status": "SUSPENDED",
        "inplay": true,
        "match_date": "2022-01-21T18:30:00.000Z",
        "marketIds": [
            "-100.98795.main"
        ],
        "is_active": 1,
        "enable_fancy": 0,
        "fancy_count": 0,
        "market_count": 0,
        "bookmaker_count": 0,
        "match_tv_url": null,
        "has_tv_url": false,
        "has_sc_url": false,
        "match_scoreboard_url": null,
        "is_lock": false,
        "my_favorites": [],
        "is_created": 1,
        "from_db": 1
    },
    {
        "sport_id": "-100",
        "sport_name": "Casino",
        "series_id": "87565",
        "series_name": "Andar Bahar (Virtual)",
        "match_id": "87565",
        "match_name": "Andar Bahar (Virtual)",
        "market_id": "",
        "marketId": "",
        "status": "SUSPENDED",
        "inplay": true,
        "match_date": "2022-01-21T18:30:00.000Z",
        "marketIds": [
            "-100.87565.main"
        ],
        "is_active": 1,
        "enable_fancy": 0,
        "fancy_count": 0,
        "market_count": 0,
        "bookmaker_count": 0,
        "match_tv_url": null,
        "has_tv_url": false,
        "has_sc_url": false,
        "match_scoreboard_url": null,
        "is_lock": false,
        "my_favorites": [],
        "is_created": 1,
        "from_db": 1
    },
    {
        "sport_id": "-100",
        "sport_name": "Casino",
        "series_id": "67570",
        "series_name": "Bollywood Casino",
        "match_id": "67570",
        "match_name": "Bollywood Casino",
        "market_id": "",
        "marketId": "",
        "status": "SUSPENDED",
        "inplay": true,
        "match_date": "2022-01-21T18:30:00.000Z",
        "marketIds": [
            "-100.67570.color",
            "-100.67570.pair",
            "-100.67570.plain",
            "-100.67570.single"
        ],
        "is_active": 1,
        "enable_fancy": 0,
        "fancy_count": 0,
        "market_count": 0,
        "bookmaker_count": 0,
        "match_tv_url": null,
        "has_tv_url": false,
        "has_sc_url": false,
        "match_scoreboard_url": null,
        "is_lock": false,
        "my_favorites": [],
        "is_created": 1,
        "from_db": 1
    },
    {
        "sport_id": "-100",
        "sport_name": "Casino",
        "series_id": "92038",
        "series_name": "Baccarat",
        "match_id": "92038",
        "match_name": "Baccarat",
        "market_id": "-100.92038.plain",
        "marketId": "-100.92038.plain",
        "status": "SUSPENDED",
        "inplay": true,
        "match_date": "2022-01-21T18:30:00.000Z",
        "marketIds": [
            "-100.92038.BANKER_PAIR",
            "-100.92038.BIG",
            "-100.92038.PERFECT_PAIR",
            "-100.92038.plain",
            "-100.92038.PLAYER_PAIR",
            "-100.92038.SMALL",
            "-100.92308.EITHER_PAIR",
            "-100.92038.EITHER_PAIR"
        ],
        "is_active": 1,
        "enable_fancy": 0,
        "fancy_count": 0,
        "market_count": 0,
        "bookmaker_count": 0,
        "match_tv_url": null,
        "has_tv_url": false,
        "has_sc_url": false,
        "match_scoreboard_url": null,
        "is_lock": false,
        "my_favorites": [],
        "is_created": 1,
        "from_db": 1
    },
    {
        "sport_id": "-100",
        "sport_name": "Casino",
        "series_id": "67575",
        "series_name": "Casino Meter",
        "match_id": "67575",
        "match_name": "Casino Meter",
        "market_id": "",
        "marketId": "",
        "status": "SUSPENDED",
        "inplay": true,
        "match_date": "2022-01-21T18:30:00.000Z",
        "marketIds": [
            "-100.67575.plain"
        ],
        "is_active": 1,
        "enable_fancy": 0,
        "fancy_count": 0,
        "market_count": 0,
        "bookmaker_count": 0,
        "match_tv_url": null,
        "has_tv_url": false,
        "has_sc_url": false,
        "match_scoreboard_url": null,
        "is_lock": false,
        "my_favorites": [],
        "is_created": 1,
        "from_db": 1
    },
    {
        "sport_id": "-100",
        "sport_name": "Casino",
        "series_id": "98790",
        "series_name": "Dragon Tiger",
        "match_id": "98790",
        "match_name": "Dragon Tiger",
        "market_id": "",
        "marketId": "",
        "status": "SUSPENDED",
        "inplay": true,
        "match_date": "2022-01-21T18:30:00.000Z",
        "marketIds": [
            "-100.98790.colord",
            "-100.98790.colort",
            "-100.98790.main",
            "-100.98790.oddevend",
            "-100.98790.oddevent",
            "-100.98790.suitd",
            "-100.98790.suitt"
        ],
        "is_active": 1,
        "enable_fancy": 0,
        "fancy_count": 0,
        "market_count": 0,
        "bookmaker_count": 0,
        "match_tv_url": null,
        "has_tv_url": false,
        "has_sc_url": false,
        "match_scoreboard_url": null,
        "is_lock": false,
        "my_favorites": [],
        "is_created": 1,
        "from_db": 1
    },
    {
        "sport_id": "-100",
        "sport_name": "Casino",
        "series_id": "98794",
        "series_name": "Dragon Tiger (Virtual)",
        "match_id": "98794",
        "match_name": "Dragon Tiger (Virtual)",
        "market_id": "-100.98794.main",
        "marketId": "-100.98794.main",
        "status": "SUSPENDED",
        "inplay": true,
        "match_date": "2022-01-21T18:30:00.000Z",
        "marketIds": [
            "-100.98794.main"
        ],
        "is_active": 1,
        "enable_fancy": 0,
        "fancy_count": 0,
        "market_count": 0,
        "bookmaker_count": 0,
        "match_tv_url": null,
        "has_tv_url": false,
        "has_sc_url": false,
        "match_scoreboard_url": null,
        "is_lock": false,
        "my_favorites": [],
        "is_created": 1,
        "from_db": 1
    },
    {
        "sport_id": "-100",
        "sport_name": "Casino",
        "series_id": "98567",
        "series_name": "Dream Catcher",
        "match_id": "98567",
        "match_name": "Dream Catcher",
        "market_id": "",
        "marketId": "",
        "status": "SUSPENDED",
        "inplay": true,
        "match_date": "2022-01-21T18:30:00.000Z",
        "marketIds": [
            "-100.98567.main"
        ],
        "is_active": 1,
        "enable_fancy": 0,
        "fancy_count": 0,
        "market_count": 0,
        "bookmaker_count": 0,
        "match_tv_url": null,
        "has_tv_url": false,
        "has_sc_url": false,
        "match_scoreboard_url": null,
        "is_lock": false,
        "my_favorites": [],
        "is_created": 1,
        "from_db": 1
    },
    {
        "sport_id": "-100",
        "sport_name": "Casino",
        "series_id": "56969",
        "series_name": "Hi Low (Virtual)",
        "match_id": "56969",
        "match_name": "Hi Low (Virtual)",
        "market_id": "",
        "marketId": "",
        "status": "SUSPENDED",
        "inplay": true,
        "match_date": "2022-01-21T18:30:00.000Z",
        "marketIds": [
            "-100.56969.color",
            "-100.56969.plain",
            "-100.56969.range"
        ],
        "is_active": 1,
        "enable_fancy": 0,
        "fancy_count": 0,
        "market_count": 0,
        "bookmaker_count": 0,
        "match_tv_url": null,
        "has_tv_url": false,
        "has_sc_url": false,
        "match_scoreboard_url": null,
        "is_lock": false,
        "my_favorites": [],
        "is_created": 1,
        "from_db": 1
    },
    {
        "sport_id": "-100",
        "sport_name": "Casino",
        "series_id": "56767",
        "series_name": "Live Teenpatti",
        "match_id": "56767",
        "match_name": "Live Teenpatti",
        "market_id": "",
        "marketId": "",
        "status": "SUSPENDED",
        "inplay": true,
        "match_date": "2022-01-21T18:30:00.000Z",
        "marketIds": [
            "-100.56767.plain",
            "-100.56767.plus"
        ],
        "is_active": 1,
        "enable_fancy": 0,
        "fancy_count": 0,
        "market_count": 0,
        "bookmaker_count": 0,
        "match_tv_url": null,
        "has_tv_url": false,
        "has_sc_url": false,
        "match_scoreboard_url": null,
        "is_lock": false,
        "my_favorites": [],
        "is_created": 1,
        "from_db": 1
    },
    {
        "sport_id": "-100",
        "sport_name": "Casino",
        "series_id": "92036",
        "series_name": "Matka (Virtual)",
        "match_id": "92036",
        "match_name": "Matka (Virtual)",
        "market_id": "",
        "marketId": "",
        "status": "SUSPENDED",
        "inplay": true,
        "match_date": "2022-01-21T18:30:00.000Z",
        "marketIds": [
            "-100.92036.COMMONDP",
            "-100.92036.COMMONSP",
            "-100.92036.DP",
            "-100.92036.DPALL",
            "-100.92036.multi",
            "-100.92036.oddeven",
            "-100.92036.SINGLE",
            "-100.92036.SP",
            "-100.92036.SPALL",
            "-100.92036.TRIO"
        ],
        "is_active": 1,
        "enable_fancy": 0,
        "fancy_count": 0,
        "market_count": 0,
        "bookmaker_count": 0,
        "match_tv_url": null,
        "has_tv_url": false,
        "has_sc_url": false,
        "match_scoreboard_url": null,
        "is_lock": false,
        "my_favorites": [],
        "is_created": 1,
        "from_db": 1
    },
    {
        "sport_id": "-100",
        "sport_name": "Casino",
        "series_id": "67600",
        "series_name": "Muflis Teenpatti",
        "match_id": "67600",
        "match_name": "Muflis Teenpatti",
        "market_id": "-100.67600.main",
        "marketId": "-100.67600.main",
        "status": "SUSPENDED",
        "inplay": true,
        "match_date": "2022-01-21T18:30:00.000Z",
        "marketIds": [
            "-100.67600.main",
            "-100.67600.m_baccarat",
            "-100.67600.top9"
        ],
        "is_active": 1,
        "enable_fancy": 0,
        "fancy_count": 0,
        "market_count": 0,
        "bookmaker_count": 0,
        "match_tv_url": null,
        "has_tv_url": false,
        "has_sc_url": false,
        "match_scoreboard_url": null,
        "is_lock": false,
        "my_favorites": [],
        "is_created": 1,
        "from_db": 1
    },
    {
        "sport_id": "-100",
        "sport_name": "Casino",
        "series_id": "67564",
        "series_name": "Poker",
        "match_id": "67564",
        "match_name": "Poker",
        "market_id": "",
        "marketId": "",
        "status": "SUSPENDED",
        "inplay": true,
        "match_date": "2022-01-21T18:30:00.000Z",
        "marketIds": [
            "-100.67564.plain"
        ],
        "is_active": 1,
        "enable_fancy": 0,
        "fancy_count": 0,
        "market_count": 0,
        "bookmaker_count": 0,
        "match_tv_url": null,
        "has_tv_url": false,
        "has_sc_url": false,
        "match_scoreboard_url": null,
        "is_lock": false,
        "my_favorites": [],
        "is_created": 1,
        "from_db": 1
    },
    {
        "sport_id": "-100",
        "sport_name": "Casino",
        "series_id": "67563",
        "series_name": "Poker (Virtual)",
        "match_id": "67563",
        "match_name": "Poker (Virtual)",
        "market_id": "",
        "marketId": "",
        "status": "SUSPENDED",
        "inplay": true,
        "match_date": "2022-01-21T18:30:00.000Z",
        "marketIds": [
            "-100.67563.plain"
        ],
        "is_active": 1,
        "enable_fancy": 0,
        "fancy_count": 0,
        "market_count": 0,
        "bookmaker_count": 0,
        "match_tv_url": null,
        "has_tv_url": false,
        "has_sc_url": false,
        "match_scoreboard_url": null,
        "is_lock": false,
        "my_favorites": [],
        "is_created": 1,
        "from_db": 1
    },
    {
        "sport_id": "-100",
        "sport_name": "Casino",
        "series_id": "67567",
        "series_name": "Poker 20-20",
        "match_id": "67567",
        "match_name": "Poker 20-20",
        "market_id": "-100.67567.PLAIN",
        "marketId": "-100.67567.PLAIN",
        "status": "SUSPENDED",
        "inplay": true,
        "match_date": "2022-01-21T18:30:00.000Z",
        "marketIds": [
            "-100.67567.PLAIN",
            "-100.67567.PLAYER_A_PLUS",
            "-100.67567.PLAYER_B_PLUS"
        ],
        "is_active": 1,
        "enable_fancy": 0,
        "fancy_count": 0,
        "market_count": 0,
        "bookmaker_count": 0,
        "match_tv_url": null,
        "has_tv_url": false,
        "has_sc_url": false,
        "match_scoreboard_url": null,
        "is_lock": false,
        "my_favorites": [],
        "is_created": 1,
        "from_db": 1
    },
    {
        "sport_id": "-100",
        "sport_name": "Casino",
        "series_id": "67620",
        "series_name": "Queen Race",
        "match_id": "67620",
        "match_name": "Queen Race",
        "market_id": "-100.67620.main",
        "marketId": "-100.67620.main",
        "status": "SUSPENDED",
        "inplay": true,
        "match_date": "2022-01-21T18:30:00.000Z",
        "marketIds": [
            "-100.67620.main"
        ],
        "is_active": 1,
        "enable_fancy": 0,
        "fancy_count": 0,
        "market_count": 0,
        "bookmaker_count": 0,
        "match_tv_url": null,
        "has_tv_url": false,
        "has_sc_url": false,
        "match_scoreboard_url": null,
        "is_lock": false,
        "my_favorites": [],
        "is_created": 1,
        "from_db": 1
    },
    {
        "sport_id": "-100",
        "sport_name": "Casino",
        "series_id": "56968",
        "series_name": "Hi Low",
        "match_id": "56968",
        "match_name": "Hi Low",
        "market_id": "",
        "marketId": "",
        "status": "SUSPENDED",
        "inplay": true,
        "match_date": "2022-01-21T18:30:00.000Z",
        "marketIds": [
            "-100.56968.color",
            "-100.56968.plain",
            "-100.56968.range"
        ],
        "is_active": 1,
        "enable_fancy": 0,
        "fancy_count": 0,
        "market_count": 0,
        "bookmaker_count": 0,
        "match_tv_url": null,
        "has_tv_url": false,
        "has_sc_url": false,
        "match_scoreboard_url": null,
        "is_lock": false,
        "my_favorites": [],
        "is_created": 1,
        "from_db": 1
    },
    {
        "sport_id": "-100",
        "sport_name": "Casino",
        "series_id": "90100",
        "series_name": "Race 20-20",
        "match_id": "90100",
        "match_name": "Race 20-20",
        "market_id": "",
        "marketId": "",
        "status": "SUSPENDED",
        "inplay": true,
        "match_date": "2022-01-21T18:30:00.000Z",
        "marketIds": [
            "-100.90100.main"
        ],
        "is_active": 1,
        "enable_fancy": 0,
        "fancy_count": 0,
        "market_count": 0,
        "bookmaker_count": 0,
        "match_tv_url": null,
        "has_tv_url": false,
        "has_sc_url": false,
        "match_scoreboard_url": null,
        "is_lock": false,
        "my_favorites": [],
        "is_created": 1,
        "from_db": 1
    },
    {
        "sport_id": "-100",
        "sport_name": "Casino",
        "series_id": "67710",
        "series_name": "RACE TO 17",
        "match_id": "67710",
        "match_name": "RACE TO 17",
        "market_id": "-100.67710.main",
        "marketId": "-100.67710.main",
        "status": "SUSPENDED",
        "inplay": true,
        "match_date": "2022-01-21T18:30:00.000Z",
        "marketIds": [
            "-100.67710.any_zero",
            "-100.67710.main"
        ],
        "is_active": 1,
        "enable_fancy": 0,
        "fancy_count": 0,
        "market_count": 0,
        "bookmaker_count": 0,
        "match_tv_url": null,
        "has_tv_url": false,
        "has_sc_url": false,
        "match_scoreboard_url": null,
        "is_lock": false,
        "my_favorites": [],
        "is_created": 1,
        "from_db": 1
    },
    {
        "sport_id": "-100",
        "sport_name": "Casino",
        "series_id": "98788",
        "series_name": "Roulette",
        "match_id": "98788",
        "match_name": "Roulette",
        "market_id": "-100.98788.main",
        "marketId": "-100.98788.main",
        "status": "SUSPENDED",
        "inplay": true,
        "match_date": "2022-01-21T18:30:00.000Z",
        "marketIds": [
            "-100.98788.color",
            "-100.98788.column",
            "-100.98788.dozens",
            "-100.98788.hi_low",
            "-100.98788.main",
            "-100.98788.oddeven"
        ],
        "is_active": 1,
        "enable_fancy": 0,
        "fancy_count": 0,
        "market_count": 0,
        "bookmaker_count": 0,
        "match_tv_url": null,
        "has_tv_url": false,
        "has_sc_url": false,
        "match_scoreboard_url": null,
        "is_lock": false,
        "my_favorites": [],
        "is_created": 1,
        "from_db": 1
    },
    {
        "sport_id": "-100",
        "sport_name": "Casino",
        "series_id": "98792",
        "series_name": "Roulette (Virtual)",
        "match_id": "98792",
        "match_name": "Roulette (Virtual)",
        "market_id": "-100.98792.main",
        "marketId": "-100.98792.main",
        "status": "SUSPENDED",
        "inplay": true,
        "match_date": "2022-01-21T18:30:00.000Z",
        "marketIds": [
            "-100.98792.color",
            "-100.98792.column",
            "-100.98792.hignlow",
            "-100.98792.main",
            "-100.98792.oddeven",
            "-100.98792.dozens"
        ],
        "is_active": 1,
        "enable_fancy": 0,
        "fancy_count": 0,
        "market_count": 0,
        "bookmaker_count": 0,
        "match_tv_url": null,
        "has_tv_url": false,
        "has_sc_url": false,
        "match_scoreboard_url": null,
        "is_lock": false,
        "my_favorites": [],
        "is_created": 1,
        "from_db": 1
    },
    {
        "sport_id": "-100",
        "sport_name": "Casino",
        "series_id": "90101",
        "series_name": "Race 20-20 (Virtual)",
        "match_id": "90101",
        "match_name": "Race 20-20 (Virtual)",
        "market_id": "-100.90101.main",
        "marketId": "-100.90101.main",
        "status": "SUSPENDED",
        "inplay": true,
        "match_date": "2022-01-21T18:30:00.000Z",
        "marketIds": [
            "-100.90101.main"
        ],
        "is_active": 1,
        "enable_fancy": 0,
        "fancy_count": 0,
        "market_count": 0,
        "bookmaker_count": 0,
        "match_tv_url": null,
        "has_tv_url": false,
        "has_sc_url": false,
        "match_scoreboard_url": null,
        "is_lock": false,
        "my_favorites": [],
        "is_created": 1,
        "from_db": 1
    },
    {
        "sport_id": "-100",
        "sport_name": "Casino",
        "series_id": "67565",
        "series_name": "Six player poker",
        "match_id": "67565",
        "match_name": "Six player poker",
        "market_id": "",
        "marketId": "",
        "status": "SUSPENDED",
        "inplay": true,
        "match_date": "2022-01-21T18:30:00.000Z",
        "marketIds": [
            "-100.67565.hands",
            "-100.67565.Patterns"
        ],
        "is_active": 1,
        "enable_fancy": 0,
        "fancy_count": 0,
        "market_count": 0,
        "bookmaker_count": 0,
        "match_tv_url": null,
        "has_tv_url": false,
        "has_sc_url": false,
        "match_scoreboard_url": null,
        "is_lock": false,
        "my_favorites": [],
        "is_created": 1,
        "from_db": 1
    },
    {
        "sport_id": "-100",
        "sport_name": "Casino",
        "series_id": "67566",
        "series_name": "Six player poker (Virtual)",
        "match_id": "67566",
        "match_name": "Six player poker (Virtual)",
        "market_id": "",
        "marketId": "",
        "status": "SUSPENDED",
        "inplay": true,
        "match_date": "2022-01-21T18:30:00.000Z",
        "marketIds": [
            "-100.67566.hands",
            "-100.67566.Patterns"
        ],
        "is_active": 1,
        "enable_fancy": 0,
        "fancy_count": 0,
        "market_count": 0,
        "bookmaker_count": 0,
        "match_tv_url": null,
        "has_tv_url": false,
        "has_sc_url": false,
        "match_scoreboard_url": null,
        "is_lock": false,
        "my_favorites": [],
        "is_created": 1,
        "from_db": 1
    },
    {
        "sport_id": "-100",
        "sport_name": "Casino",
        "series_id": "67720",
        "series_name": "Super Over",
        "match_id": "67720",
        "match_name": "Super Over",
        "market_id": "",
        "marketId": "",
        "status": "SUSPENDED",
        "inplay": true,
        "match_date": "2022-01-21T18:30:00.000Z",
        "marketIds": [
            "-100.67720.commonBoundary",
            "-100.67720.commonWicket",
            "-100.67720.main"
        ],
        "is_active": 1,
        "enable_fancy": 0,
        "fancy_count": 0,
        "market_count": 0,
        "bookmaker_count": 0,
        "match_tv_url": null,
        "has_tv_url": false,
        "has_sc_url": false,
        "match_scoreboard_url": null,
        "is_lock": false,
        "my_favorites": [],
        "is_created": 1,
        "from_db": 1
    },
    {
        "sport_id": "-100",
        "sport_name": "Casino",
        "series_id": "56766",
        "series_name": "Teenpatti One-Day (Virtual)",
        "match_id": "56766",
        "match_name": "Teenpatti One-Day (Virtual)",
        "market_id": "",
        "marketId": "",
        "status": "SUSPENDED",
        "inplay": true,
        "match_date": "2022-01-21T18:30:00.000Z",
        "marketIds": [
            "-100.56766.plain",
            "-100.56766.plus"
        ],
        "is_active": 1,
        "enable_fancy": 0,
        "fancy_count": 0,
        "market_count": 0,
        "bookmaker_count": 0,
        "match_tv_url": null,
        "has_tv_url": false,
        "has_sc_url": false,
        "match_scoreboard_url": null,
        "is_lock": false,
        "my_favorites": [],
        "is_created": 1,
        "from_db": 1
    },
    {
        "sport_id": "-100",
        "sport_name": "Casino",
        "series_id": "67640",
        "series_name": "Teenpatti Open",
        "match_id": "67640",
        "match_name": "Teenpatti Open",
        "market_id": "-100.67640.tp_open_main8",
        "marketId": "-100.67640.tp_open_main8",
        "status": "SUSPENDED",
        "inplay": true,
        "match_date": "2022-01-21T18:30:00.000Z",
        "marketIds": [
            "-100.67640.total1",
            "-100.67640.total2",
            "-100.67640.total3",
            "-100.67640.total4",
            "-100.67640.total5",
            "-100.67640.total6",
            "-100.67640.total7",
            "-100.67640.total8",
            "-100.67640.tp_open_main1",
            "-100.67640.tp_open_main2",
            "-100.67640.tp_open_main3",
            "-100.67640.tp_open_main4",
            "-100.67640.tp_open_main5",
            "-100.67640.tp_open_main6",
            "-100.67640.tp_open_main7",
            "-100.67640.tp_open_main8"
        ],
        "is_active": 1,
        "enable_fancy": 0,
        "fancy_count": 0,
        "market_count": 0,
        "bookmaker_count": 0,
        "match_tv_url": null,
        "has_tv_url": false,
        "has_sc_url": false,
        "match_scoreboard_url": null,
        "is_lock": false,
        "my_favorites": [],
        "is_created": 1,
        "from_db": 1
    },
    {
        "sport_id": "-100",
        "sport_name": "Casino",
        "series_id": "56768",
        "series_name": "Teenpatti T20",
        "match_id": "56768",
        "match_name": "Teenpatti T20",
        "market_id": "",
        "marketId": "",
        "status": "SUSPENDED",
        "inplay": true,
        "match_date": "2022-01-21T18:30:00.000Z",
        "marketIds": [
            "-100.56768.plain",
            "-100.56768.plus"
        ],
        "is_active": 1,
        "enable_fancy": 0,
        "fancy_count": 0,
        "market_count": 0,
        "bookmaker_count": 0,
        "match_tv_url": null,
        "has_tv_url": false,
        "has_sc_url": false,
        "match_scoreboard_url": null,
        "is_lock": false,
        "my_favorites": [],
        "is_created": 1,
        "from_db": 1
    },
    {
        "sport_id": "-100",
        "sport_name": "Casino",
        "series_id": "98566",
        "series_name": "Sicbo",
        "match_id": "98566",
        "match_name": "Sicbo",
        "market_id": "",
        "marketId": "",
        "status": "SUSPENDED",
        "inplay": true,
        "match_date": "2022-01-21T18:30:00.000Z",
        "marketIds": [
            "-100.98566.anytriple",
            "-100.98566.hi_low",
            "-100.98566.oddeven",
            "-100.98566.total",
            "-100.98566.triple"
        ],
        "is_active": 1,
        "enable_fancy": 0,
        "fancy_count": 0,
        "market_count": 0,
        "bookmaker_count": 0,
        "match_tv_url": null,
        "has_tv_url": false,
        "has_sc_url": false,
        "match_scoreboard_url": null,
        "is_lock": false,
        "my_favorites": [],
        "is_created": 1,
        "from_db": 1
    },
    {
        "sport_id": "-100",
        "sport_name": "Casino",
        "series_id": "56769",
        "series_name": "Teenpatti T20 (Virtual)",
        "match_id": "56769",
        "match_name": "Teenpatti T20 (Virtual)",
        "market_id": "",
        "marketId": "",
        "status": "SUSPENDED",
        "inplay": true,
        "match_date": "2022-01-21T18:30:00.000Z",
        "marketIds": [
            "-100.56769.plain",
            "-100.56769.plus"
        ],
        "is_active": 1,
        "enable_fancy": 0,
        "fancy_count": 0,
        "market_count": 0,
        "bookmaker_count": 0,
        "match_tv_url": null,
        "has_tv_url": false,
        "has_sc_url": false,
        "match_scoreboard_url": null,
        "is_lock": false,
        "my_favorites": [],
        "is_created": 1,
        "from_db": 1
    },
    {
        "sport_id": "-100",
        "sport_name": "Casino",
        "series_id": "67630",
        "series_name": "Teenpatti Test",
        "match_id": "67630",
        "match_name": "Teenpatti Test",
        "market_id": "-100.67630.main",
        "marketId": "-100.67630.main",
        "status": "SUSPENDED",
        "inplay": true,
        "match_date": "2022-01-21T18:30:00.000Z",
        "marketIds": [
            "-100.67630.flush",
            "-100.67630.main",
            "-100.67630.pair",
            "-100.67630.straight",
            "-100.67630.straight_flush",
            "-100.67630.trio"
        ],
        "is_active": 1,
        "enable_fancy": 0,
        "fancy_count": 0,
        "market_count": 0,
        "bookmaker_count": 0,
        "match_tv_url": null,
        "has_tv_url": false,
        "has_sc_url": false,
        "match_scoreboard_url": null,
        "is_lock": false,
        "my_favorites": [],
        "is_created": 1,
        "from_db": 1
    },
    {
        "sport_id": "-100",
        "sport_name": "Casino",
        "series_id": "67680",
        "series_name": "The Trap",
        "match_id": "67680",
        "match_name": "The Trap",
        "market_id": "-100.67680.main",
        "marketId": "-100.67680.main",
        "status": "SUSPENDED",
        "inplay": true,
        "match_date": "2022-01-21T18:30:00.000Z",
        "marketIds": [
            "-100.67680.main",
            "-100.67680.picture_card",
            "-100.67680.seven"
        ],
        "is_active": 1,
        "enable_fancy": 0,
        "fancy_count": 0,
        "market_count": 0,
        "bookmaker_count": 0,
        "match_tv_url": null,
        "has_tv_url": false,
        "has_sc_url": false,
        "match_scoreboard_url": null,
        "is_lock": false,
        "my_favorites": [],
        "is_created": 1,
        "from_db": 1
    },
    {
        "sport_id": "-100",
        "sport_name": "Casino",
        "series_id": "92037",
        "series_name": "Worli Matka",
        "match_id": "92037",
        "match_name": "Worli Matka",
        "market_id": "",
        "marketId": "",
        "status": "SUSPENDED",
        "inplay": true,
        "match_date": "2022-01-21T18:30:00.000Z",
        "marketIds": [
            "-100.92037.COMMONDP",
            "-100.92037.COMMONSP",
            "-100.92037.DP",
            "-100.92037.DPALL",
            "-100.92037.SINGLE",
            "-100.92037.SP",
            "-100.92037.SPALL",
            "-100.92037.TRIO"
        ],
        "is_active": 1,
        "enable_fancy": 0,
        "fancy_count": 0,
        "market_count": 0,
        "bookmaker_count": 0,
        "match_tv_url": null,
        "has_tv_url": false,
        "has_sc_url": false,
        "match_scoreboard_url": null,
        "is_lock": false,
        "my_favorites": [],
        "is_created": 1,
        "from_db": 1
    },
    {
        "sport_id": "-100",
        "sport_name": "Casino",
        "series_id": "67610",
        "series_name": "Trio",
        "match_id": "67610",
        "match_name": "Trio",
        "market_id": "",
        "marketId": "",
        "status": "SUSPENDED",
        "inplay": true,
        "match_date": "2022-01-21T18:30:00.000Z",
        "marketIds": [
            "-100.67610.CJ1",
            "-100.67610.CJ2",
            "-100.67610.color",
            "-100.67610.oddeven",
            "-100.67610.total",
            "-100.67610.winType"
        ],
        "is_active": 1,
        "enable_fancy": 0,
        "fancy_count": 0,
        "market_count": 0,
        "bookmaker_count": 0,
        "match_tv_url": null,
        "has_tv_url": false,
        "has_sc_url": false,
        "match_scoreboard_url": null,
        "is_lock": false,
        "my_favorites": [],
        "is_created": 1,
        "from_db": 1
    },
    {
        "sport_id": "-100",
        "sport_name": "Casino",
        "series_id": "67580",
        "series_name": "Casino War",
        "match_id": "67580",
        "match_name": "Casino War",
        "market_id": "",
        "marketId": "",
        "status": "SUSPENDED",
        "inplay": true,
        "match_date": "2022-01-21T18:30:00.000Z",
        "marketIds": [
            "-100.67580.main1",
            "-100.67580.main2",
            "-100.67580.main3",
            "-100.67580.main4",
            "-100.67580.main5",
            "-100.67580.main6"
        ],
        "is_active": 1,
        "enable_fancy": 0,
        "fancy_count": 0,
        "market_count": 0,
        "bookmaker_count": 0,
        "match_tv_url": null,
        "has_tv_url": false,
        "has_sc_url": false,
        "match_scoreboard_url": null,
        "is_lock": false,
        "my_favorites": [],
        "is_created": 1,
        "from_db": 1
    },
    {
        "sport_id": "-100",
        "sport_name": "Casino",
        "series_id": "87564",
        "series_name": "Andar Bahar",
        "match_id": "87564",
        "match_name": "Andar Bahar",
        "market_id": "",
        "marketId": "",
        "status": "SUSPENDED",
        "inplay": true,
        "match_date": "2022-01-21T18:30:00.000Z",
        "marketIds": [
            "-100.87564.main"
        ],
        "is_active": 1,
        "enable_fancy": 0,
        "fancy_count": 0,
        "market_count": 0,
        "bookmaker_count": 0,
        "match_tv_url": null,
        "has_tv_url": false,
        "has_sc_url": false,
        "match_scoreboard_url": null,
        "is_lock": false,
        "my_favorites": [],
        "is_created": 1,
        "from_db": 1
    },
    {
        "sport_id": "-100",
        "sport_name": "Casino",
        "series_id": "70008",
        "series_name": "Cricket",
        "match_id": "70008",
        "match_name": "Cricket",
        "market_id": "",
        "marketId": "",
        "status": "SUSPENDED",
        "inplay": false,
        "match_date": null,
        "marketIds": [],
        "is_active": 1,
        "enable_fancy": 0,
        "fancy_count": 0,
        "market_count": 0,
        "bookmaker_count": 0,
        "match_tv_url": null,
        "has_tv_url": false,
        "has_sc_url": false,
        "match_scoreboard_url": null,
        "is_lock": false,
        "my_favorites": [],
        "is_created": 1,
        "from_db": 1
    }
]