import React from 'react';

const HeaderTabs = ({ tabs, selectedIndex, setSelectedIndex }) => {

    return (
        <ul role="tablist" classNameName="nav nav-pills" aria-label="Tabs">
            {tabs && tabs.length && tabs.map((item, index) => {
                return (
                    <li className="nav-item" key={item.tab}>
                        <a
                            href="javascript:void(0);"
                            role="tab"
                            className={`nav-link ${selectedIndex === item.providerCode ? 'selected' : ''}`}
                            aria-controls=""
                            aria-selected="false"
                            id=""
                            onClick={() => { setSelectedIndex(item.providerCode) }}
                        >
                            <span></span><span id={`parentTab-0`}>{item.name}</span>
                        </a>
                    </li>
                );
            })}
        </ul>
    )
}

export default HeaderTabs;