export const HeaderCasino = [
    {
       "index":1,
       "id":"6",
       "icon":"./assets/images/headerCasino/diamond_icon.png",
       "redirect":"/casino_live/70011",
       "type":"",
       "backgroundImage":"./assets/images/headerCasino/Mines.webp",
       "name":"Mines",
       "backgroundColour":"#6D0870"
    },
    {
       "index":2,
       "id":"Slot_54",
       "icon":"./assets/images/headerCasino/aurai.svg",
       "redirect":"/casino_live/98789/FAWK",
       "type":"",
       "backgroundImage":"./assets/images/headerCasino/aurabg.png",
       "name":"Aura Casino",
       "backgroundColour":"#6D0870"
    },
    {
       "index":4,
       "icon":"",
       "redirect":"/casino_live/70001",
       "type":"",
       "backgroundImage":"./assets/images/headerCasino/fortunebg.jpg",
       "name":"Fortune Spin",
       "backgroundColour":"#02694D"
    },
    {
       "index":3,
       "icon":"./assets/images/headerCasino/blastoff-icon.svg",
       "redirect":"SPB-aviator",
       "type":"",
       "backgroundImage":"./assets/images/headerCasino/aviator.png",
       "name":"Aviator",
       "backgroundColour":"#02694D"
    },
    {
       "index":5,
       "icon":"./assets/images/headerCasino/evoicon.svg",
       "redirect":"/sports/EVO",
       "type":"",
       "backgroundImage":"./assets/images/headerCasino/evolution-quick-bg.png",
       "name":"Evolution",
       "backgroundColour":"#02694D"
    },
    {
       "index":6,
       "icon":"./assets/images/headerCasino/sportsicon.svg",
       "redirect":"/sports/QS",
       "type":"",
       "backgroundImage":"./assets/images/headerCasino/sportsbook-quick-bg.png",
       "name":"QuickSpin",
       "backgroundColour":"#034C6F"
    },
    {
       "index":7,
       "icon":"./assets/images/headerCasino/casinoicon.svg",
       "redirect":"/livecasino-lobby",
       "type":"",
       "backgroundImage":"./assets/images/headerCasino/livecasino-quick-bg.png",
       "name":"Live Casinos",
       "backgroundColour":"#fe6d04"
    },
    {
       "index":5,
       "icon":"./assets/images/headerCasino/ecricketicon.svg",
       "redirect":"/casino_live/70008",
       "type":"",
       "backgroundImage":"./assets/images/headerCasino/e-cricket-quick-bg.png",
       "name":"e-Cricket",
       "backgroundColour":"#034C6F"
    },
    {
       "index":6,
       "icon":"./assets/images/headerCasino/cardicon.svg",
       "redirect":"/casino_live/98789/FAWK",
       "type":"",
       "backgroundImage":"./assets/images/headerCasino/cardgames-quick-bg.png",
       "name":"Card Games",
       "backgroundColour":"#02694D"
    },
    {
       "index":7,
       "icon":"./assets/images/headerCasino/sloticon.svg",
       "redirect":"/slotsgames-lobby/52",
       "type":"",
       "backgroundImage":"./assets/images/headerCasino/slot-quick-bg.png",
       "name":"Slot Games",
       "backgroundColour":"#02694D"
    },
    {
       "index":8,
       "id":"Slot_54",
       "icon":"./assets/images/headerCasino/multiicon.png",
       "redirect":"/sports/EZU",
       "type":"",
       "backgroundImage":"./assets/images/headerCasino/multiplayer-quick-bg.png",
       "name":"Ezugi",
       "backgroundColour":"#02694D"
    },
    {
       "index":9,
       "id":"Slot_52",
       "icon":"./assets/images/headerCasino/fishingicon.png",
       "redirect":"/sports/SPB",
       "type":"",
       "backgroundImage":"./assets/images/headerCasino/fishinggames-quick-bg.png",
       "name":"Spribe",
       "backgroundColour":"#02694D"
    }
 ]