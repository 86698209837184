import './index.scss'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setIsPopUpOpen } from '../../store/slotsPopUp/slotsPopUp.slice'
import { createPortal } from "react-dom";
import slotsCasinoImg from './slotsCasinoPopUp.svg';
import closeButton from './closeButton.svg';
import { setSlotCasinoPopUp } from '../../store/slotCasinoPopUp/slotCasinoPopUp.slice'

const modalRoot = document.querySelector("#modal-root");

const SlotsCasinoPopUp = ({
  scrollPosition,
}) => {
  const dispatch = useDispatch();
  const liveCasino = useSelector((state) => state.cashPoint);

  return createPortal(
    <div className="slotsCasino-pop-up"
      style={{ top: scrollPosition + "px" }}
      onClick={(e) => {
        if (e.currentTarget === e.target) {
          dispatch(setIsPopUpOpen(false));
          console.log('e', e);
        }
      }}>
      <div className="slotsCasino-pop-up__content">
        <img className="slotsCasino-pop-up__content-img" src={slotsCasinoImg} alt='' />
        <img className="slotsCasino-pop-up__content-closeButton" onClick={() => dispatch(setSlotCasinoPopUp(false))} src={closeButton} alt='' />
        <div className="slotsCasino-pop-up__content-title">PLEASE NOTE</div>
        <div className="slotsCasino-pop-up__content-descr">(1 Point = ₹ {liveCasino})</div>
        <div className="slotsCasino-pop-up__content-welcome">Welcome to your new casino lobby</div>
        <div className="slotsCasino-pop-up__content-checkout">Checkout the exciting Live Casino tables, Slots and much more, upto 200+ game and counting...</div>
        <div className="slotsCasino-pop-up__content-button" onClick={() => dispatch(setSlotCasinoPopUp(false))}>OK, I AGREE !</div>
      </div>
    </div>
    , modalRoot
  )
}

export default SlotsCasinoPopUp;
