import http from '../../services/httpAuth'
import getErrorMessage from '../../helpers/getErrorMessage.helper'
import { getSportbookLiveError, getSportbookLiveSuccess } from './sportbookLive.slice'

export const requestSportbookStart = (id = '1444005', type = "SPORTSBOOK") => async (dispatch) => {
  // try {
  //   const response = await http.post(`/casino/live/session/open`, { id, type })
  //   if (response.data.success) {
  //     dispatch(getSportbookLiveSuccess(response.data.result))
  //   }
  //   return response
  // } catch (e) {
  //   dispatch(getSportbookLiveError())
  //   const errorMsg = getErrorMessage(e)
  //   throw errorMsg
  // }
}

export const requestSportbookEnd = () => async (dispatch) => {
  try {
    const response = await http.post(`/casino/live/session/close`)
    if (response.data.success) {
      dispatch(getSportbookLiveSuccess(response.data.result))
    }
    return response
  } catch (e) {
    dispatch(getSportbookLiveError())
    // const errorMsg = getErrorMessage(e)
    // throw errorMsg
  }
}
