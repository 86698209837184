import { createSlice } from '@reduxjs/toolkit'

export const qTechSlice = createSlice({
  name: 'qTech',
  initialState: null,
  reducers: {
    getQTechSuccess: (state, action) => action.payload,
    getQTechError: (state) => null,
  },
})

export const { getQTechSuccess, getQTechError } = qTechSlice.actions

export default qTechSlice.reducer
