import { createSlice } from '@reduxjs/toolkit'

export const qTechGamesSlice = createSlice({
  name: 'qTechGames',
  initialState: null,
  reducers: {
    getQTechGamesSuccess: (state, action) => action.payload,
    getQTechGamesError: (state) => null,
  },
})

export const { getQTechGamesSuccess, getQTechGamesError } = qTechGamesSlice.actions

export default qTechGamesSlice.reducer
