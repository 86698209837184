import { createSlice } from '@reduxjs/toolkit'

export const sportEventSlice = createSlice({
    name: 'sportEvent',
    initialState: {
        sportData: null,
        fancyData: null,
        sportStats: null,
        sportLadder: {},
        sportVideo: null,
        sportLimits: null,
        sportVisualisation: null,
        ladderTitle: null,
        ladderId: null,
        ladderShow: null,
        sportDataMarkets: null,
    },
    reducers: {
        getSportEventSuccess: (state, action) => ({ ...state, sportData: action.payload }),
        getFancySuccess: (state, action) => ({ ...state, fancyData: action.payload }),
        getSportEventError: (state) => ({ ...state, sportData: null }),
        getSportEventMarketsSuccess: (state, action) => ({ ...state, sportDataMarkets: action.payload }),
        getSportEventMarketsError: (state) => ({ ...state, sportDataMarkets: null }),
        getSportStatsSuccess: (state, action) => ({ ...state, sportStats: action.payload }),
        getSportStatsError: (state) => ({ ...state, sportStats: null }),
        getSportLadderSuccess: (state, action) => ({ ...state, sportLadder: action.payload }),
        getSportLadderError: (state) => ({ ...state, sportLadder: {} }),
        setSportLadderTitle: (state, action) => ({ ...state, ladderTitle: action.payload }),
        setSportLadderId: (state, action) => ({ ...state, ladderId: action.payload }),
        setSportLadderShow: (state, action) => ({ ...state, ladderShow: action.payload }),
        getSportVideoSuccess: (state, action) => ({ ...state, sportVideo: action.payload }),
        getSportVideoError: (state, action) => ({ ...state, sportVideo: null }),
        getSportLimitsSuccess: (state, action) => ({ ...state, sportLimits: action.payload }),
        getSportLimitsError: (state, action) => ({ ...state, sportLimits: null }),
        getSportVisualisationSuccess: (state, action) => ({ ...state, sportVisualisation: action.payload }),
        getSportVisualisationError: (state, action) => ({ ...state, sportVisualisation: null }),
    },
})

export const {
    getSportEventSuccess,
    getFancySuccess,
    getSportEventError,
    getSportStatsSuccess,
    getSportStatsError,
    getSportLadderSuccess,
    getSportLadderError,
    setSportLadderTitle,
    setSportLadderId,
    setSportLadderShow,
    getSportVideoError,
    getSportVideoSuccess,
    getSportVisualisationError,
    getSportVisualisationSuccess,
    getSportEventMarketsError,
    getSportEventMarketsSuccess,
    getSportLimitsSuccess,
    getSportLimitsError
} = sportEventSlice.actions

export default sportEventSlice.reducer
