import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { requestMobileVerification, requestProfile } from "../../store/profile/profile.thunks";
import { setLoginNew } from "../../store/LoginNew/loginNew.slice";


export const connectVerification = (wrappedProps) => (component) => {
  function mapStateToProps(state) {
    return {
      auth: state.auth,
      profile: state.profile
    }
  }

  function mapDispatchToProps(dispatch) {
    const actions = {
      requestMobileVerification,
      setLoginNew,
      requestProfile

    }

    return { actions: bindActionCreators(actions, dispatch) }
  }

  return connect(mapStateToProps, mapDispatchToProps)(component)
}
