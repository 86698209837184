import React, { useRef, useState } from 'react'
import '../promotion.scss'
import QuickButton from './QuickButton/QuickButton'
import { useDispatch } from 'react-redux';


const QuickButtons = ({ quickButtons }) => {
    const quickButtonsSortForIndex = [...quickButtons].sort((a, b) => a.index > b.index ? 1 : -1);
    const slider = useRef(null);
    const [isDown, setIsDown] = useState(false);
    const [isSlide, setIsSlide] = useState(false);
    const [startX, setStartX] = useState("");
    const [scrollLeft, setScrollLeft] = useState("");

    const quickButtonsList = quickButtonsSortForIndex.map((itemButton, index) => {
        return (<QuickButton
          isSlide={isSlide}
          key={index}
          name={itemButton.name}
          icon={itemButton.icon}
          redirect={ itemButton.redirect}
          backgroundImage={itemButton.backgroundImage}
          backgroundColour={itemButton.backgroundColour}
          id={itemButton.id}>
        </QuickButton>)
    })

    const dispatch = useDispatch()

    let quickButtonsStyle
    if (quickButtons.length == 1) {
        quickButtonsStyle = {gridTemplateColumns: '1fr'}
    }
    return (
        <div className={'promotion-buttons'}
             ref={slider}
             style={quickButtonsStyle}
             onMouseDown={(e) => {
               setIsDown(true)
               setStartX(e.pageX - slider.current.offsetLeft)
               setScrollLeft(slider.current.scrollLeft)
             }}
             onMouseUp={() => {
               if (isSlide) {
                setTimeout(() =>    setIsSlide(false), 500)
               }
                 setIsDown(false)
             }}
             onMouseMove={(e) => {
                 if(!isDown) return;
                 e.preventDefault();
                 setIsSlide(true)
                 const x = e.pageX - slider.current.offsetLeft;
                 const walk = (x - startX) * 3; //scroll-fast
                 slider.current.scrollLeft = scrollLeft - walk;
             }}
             onMouseLeave={() => {
                setIsDown(false)
                setIsSlide(false)
             }}
        >
            {quickButtonsList}
        </div>
    )
}

export default QuickButtons
