import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { requestProfile, requestMailVerification, requestOTPVerification, requestMobileVerification, updateProfile } from '../../store/profile/profile.thunks'

export const connectProfile = (wrappedProps) => (component) => {
  function mapStateToProps(state) {
    return {
      user: state.user,
      elementsParams: state.elementsParams,
      profile: state.profile
    }
  }

  function mapDispatchToProps(dispatch) {
    const actions = {
      requestProfile,
      requestMailVerification,
      requestMobileVerification,
      updateProfile,
      requestOTPVerification
    }

    return { actions: bindActionCreators(actions, dispatch) }
  }

  return connect(mapStateToProps, mapDispatchToProps)(component)
}
