import './pop-upHelp.scss'
// import { IonButton, IonImg, IonPopover } from '@ionic/react'
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import { setDetailedDepositTransaction } from '../../../store/detailedDepositTransaction/detailedDepositTransaction.slice'
import { useHistory } from 'react-router'

import Step1 from '../../../assets/images/depositHelp/Step1.svg'
import Step2 from '../../../assets/images/depositHelp/Step2.svg'
import Step3 from '../../../assets/images/depositHelp/Step3.svg'
import Step4 from '../../../assets/images/depositHelp/Step4.svg'
import Step5 from '../../../assets/images/depositHelp/Step5.svg'

import { createPortal } from "react-dom";

const modalRoot = document.querySelector("#modal-root");


const PopUPHelp = ({ content, open, onDidDismiss, data }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const token = localStorage.getItem('id_token')
  const [helpStep, setHelpStep] = useState(1)

  // const [remark, setRemark] = useState('')
  // const requestId = data.requestId
  // const [utr, setUTR] = useState(data.utr)
  // const [amount, setAmount] = useState(data.amount)

  // console.log('data', data)
  // console.log('requestId', data.requestId)
  // console.log('utr', data.utr)
  // console.log('amount', data.amount)

  // console.log(parseInt('222', 10))

  // const canceledData = {
  //   remark,
  //   requestId,
  // }

  // const editedData = {
  //   requestId,
  //   UTR: utr,
  //   amount,
  // }

  // const cancelOneTransactionRequest = async () => {
  //   return await axios
  //     .post(
  //       `https://dapi.gemexch.bet/api/payments/userid/cancel-request?requestId=${data.requestId}`,
  //       canceledData,
  //       {
  //         headers: {
  //           Authorization: `${token}`,
  //         },
  //       }
  //     )
  //     .then((response) => console.log(response.data.result))
  //     .then(history.push('/transactions'))
  // }

  // const editOneTransactionRequest = async () => {
  //   return await axios
  //     .post(
  //       `https://dapi.gemexch.bet/api/payments/userid/update-deposit-request?requestId=${data.requestId}`,
  //       editedData,
  //       {
  //         headers: {
  //           Authorization: `${token}`,
  //         },
  //       }
  //     )
  //     .then((response) => console.log(response.data.result))
  //     .then(onDidDismiss(false))
  // }

  return createPortal(
    <>

        { open && <div className="editPopUpHelp">
          <div
            className="pop-upHelp_wrapper"
            onClick={(e) => {
              if (e.currentTarget === e.target) {
                onDidDismiss(false)
                setHelpStep(1)
              }
            }}
          >
            <div className={ helpStep === 3  ? 'pop-upHelp-third' : "pop-upHelp"}>
              <div className="pop-upHelp-content">
                <div className="pop-upHelp-content_title">
                  Help
                </div>

                {helpStep === 1 &&
                <>
                <div className='pop-upHelp-content_title-big'>Step 1</div>
                <div className='pop-upHelp-content_title-desc'>Choose your payment method and fill in all the fields.</div>
                <div>
                  <img loading={"lazy"} src={Step1} alt=" Step 1" />
                </div>
                <div
                  className="pop-upHelp-content__buttons-send"
                  onClick={() => {
                    setHelpStep(2)
                  }
              }

                >
                  Next
                </div>
                </>
                }
                {helpStep === 2 &&
                <>
                <div className='pop-upHelp-content_title-big'>Step 2</div>
                <div className='pop-upHelp-content_title-desc'>Make Payment to below account via PhonePe, GooglePay, UPI App or IMPS netbanking. Use copy button to copy details.</div>
                <div>
                  <img loading={"lazy"} src={Step2} alt="Step 2" />
                </div>
                <div
                  className="pop-upHelp-content__buttons-send"
                  onClick={() => {
                    setHelpStep(3)
                  }
              }

                >
                 Next
                </div>
                </>
                }
                {helpStep === 3 &&
                <>
                <div className='pop-upHelp-content_title-big'>Step 3</div>
                <div className='pop-upHelp-content_title-desc'>Once transfer is successful on given bank account. Enter the UTR or Reference No. or RR Number of transfer.</div>
                <div>
                  <img loading={"lazy"} src={Step3} alt="Step 3" />
                </div>
                <div
                  className="pop-upHelp-content__buttons-send"
                  onClick={() => {
                    setHelpStep(4)
                  }
              }

                >
                  Next
                </div>
                <div className='thirdHelper'></div>
                </>
                }
                {helpStep === 4 &&
                <>
                <div className='pop-upHelp-content_title-big'>Step 4</div>
                <div className='pop-upHelp-content_title-desc'>Wait for the success message.</div>
                <div>
                  <img loading={"lazy"} src={Step4} alt="Step 4" style={{maxWidth: '85%', display: 'block', marginLeft: 'auto', marginRight: 'auto'}} />
                </div>
                 <div
                  className="pop-upHelp-content__buttons-send"
                  onClick={() => {
                    setHelpStep(5)
                  }
              }

                >
                  Next
                </div>
                </>
                }
                {helpStep === 5 &&
                <>
                <div className='pop-upHelp-content_title-big'>Step 5</div>
                <div>
                  <img loading={"lazy"} src={Step5} alt="Step 3" />
                </div>
                <div
                  className="pop-upHelp-content__buttons-got"
                  onClick={() => {
                    onDidDismiss(false)
                    setHelpStep(1)
                  }}
                >
                  Got It
                </div>
                </>
                }
              </div>

              {/* <div className="pop-upHelp-content__buttons">
                { helpStep === 5 ?
                  <div
                  className="pop-upHelp-content__buttons-send"
                  onClick={() => {
                    onDidDismiss(false)
                    setHelpStep(1)
                  }
              }

                >
                  Got It
                </div>
                  :
                  <div
                  className="pop-upHelp-content__buttons-send"
                  onClick={() => setHelpStep(helpStep + 1)}
                >
                  Next
                </div>}
              </div> */}
            </div>
          </div>
        </div>}
    </>,
    modalRoot
  )
}

export default PopUPHelp
