import axios from 'axios'
import { BASE_DEV_URL } from '../config/api.config'

const BASE_URL =  BASE_DEV_URL
const httpNotAuth = axios.create({
  baseURL: BASE_URL,
  headers: {
    Accept: 'application/json',
  },
})

export default httpNotAuth
