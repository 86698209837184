import React, { useState, useEffect } from 'react'
import './SportMarketTabs.scss'
import SportMarketStandardFancy from '../../components/SportMarketStandardFancy/SportMarketStandard'
import { useDispatch, useSelector } from 'react-redux'
import { requestBetPlacingStartAll } from '../../store/betPlacing/betPlacing.thunks'

const SportMarketTabs = ({ data, betPlacing, elementsParams, categories }) => {
  const [activeTab, setActiveTab] = useState('All')

  // const dispatch = useDispatch();
  //
  // useEffect(() => {
  //     if (data?.tabs[activeTab]?.name === "Fancy") {
  //         dispatch(requestBetPlacingStartAll(data?.tabs[activeTab].items))
  //     }
  //     }, [activeTab]);

  // useEffect(() => {
  //     if (!categories[activeTab]) setActiveTab(Object.keys(categories)[0])
  // });
  const checkStateWidth = useSelector(
    (state) => state.elementsParams.clientWidth
  )
  const width = checkStateWidth ? checkStateWidth : window.innerWidth
  let preparedCategories = (name) => {
    let res = name.replace('Market', '')
    if (res.length > 8) {
      return res.replace('Session', '')
    } else {
      return res
    }
  }
  return (
    <section className="SportMarketTabs">
      <div className="SportMarketTabs__header">
        <div
          key={'all'}
          className={`SportMarketTabs__header__item ${
            'All' == activeTab ? 'active' : ''
          }`}
          onClick={() => setActiveTab('All')}
        >
          All
        </div>
        {categories &&
          Object.keys(categories).map((tabGroupIndex) => (
            <div
              key={tabGroupIndex}
              className={`SportMarketTabs__header__item ${
                tabGroupIndex == activeTab ? 'active' : ''
              }`}
              onClick={() => setActiveTab(tabGroupIndex)}
            >
              {preparedCategories(categories[tabGroupIndex])}
            </div>
          ))}
      </div>
      <div className="SportMarketTabs__markets">
        {width > 1024 && (
          <div className="SportMarketTabs__markets_fancy-title">
            <div>Session Runs</div>
            <div className="SportMarketTabs__markets_fancy-title-block">
              <span>No</span>
              <span>Yes</span>
            </div>
          </div>
        )}
        {activeTab &&
          data && data?.length > 0 &&
          data
            .filter((item) => {
              return activeTab === 'All' || item.category === activeTab - 1
            })
            .map((item) => {
              return (
                <div key={item.id} className={`SportMarketTabs__markets__item`}>
                  <SportMarketStandardFancy
                    data={item}
                    betPlacing={betPlacing}
                    fancy={true}
                  />
                </div>
              )
            })}
      </div>
    </section>
  )
}

export default SportMarketTabs
