import React, { useEffect, useState } from 'react'
import './AccountStatementDetailsPage.scss'
import { IonContent, IonHeader, IonPage } from '@ionic/react'
import Toolbar from '../../components/Toolbar/Toolbar'
import { useLocation } from 'react-router'
import { connectAccountStatementDetailsPage } from './AccountStatementDetailsPage.connect'
import BettingProfitAndLossItemDetails from '../../components/BettingProfitAndLossItemDetails/bettingProfitAndLossItemDetails'
import { useHistory } from 'react-router-dom'
import { getCurrencyFormat } from '../../helpers/getCurrencyFormat.helper'

const AccountStatementDetailsPage = ({
  user,
  orderList,
  betHistory,
  lastActivityDate,
  actions,
  elementsParams,
}) => {
  const location = useLocation()
  const history = useHistory()

  const [itemData, setItemData] = useState(null)

  const [totals, setTotals] = useState({
    backSubtotal: 0,
    laySubtotal: 0,
    marketSubtotal: 0,
    commission: 0,
    netMarketTotal: 0,
    netSessionTotal: 0,
  })

  const width =
    elementsParams && elementsParams?.clientWidth
      ? elementsParams?.clientWidth
      : window.innerWidth

  const getBackSubtotal = () => {
    if (!itemData || !itemData?.length || itemData?.length <= 0) {
      return 0
    } else {
      let sum = 0
      itemData.forEach((item) => {
        if (item?.is_back === 1) {
          sum += item?.p_l || 0
        }
      })
      return sum
    }
  }
  const getLaySubtotal = () => {
    if (!itemData || !itemData?.length || itemData?.length <= 0) {
      return 0
    } else {
      let sum = 0
      itemData.forEach((item) => {
        if (item?.is_back === 0) {
          sum += item?.p_l || 0
        }
      })
      return sum
    }
  }

  const getCommission = () => {
    if (!itemData) {
      return 0
    }

    return itemData.memberComm || 0
  }

  const getNetMarketTotal = () => {
    if (itemData?.length <= 0) return 0

    return getBackSubtotal() + getLaySubtotal() - (itemData.memberComm || 0)
  }

  const getNetSessionTotal = () => {
    const itemDetails = getItemsDetail()
    if (!itemDetails) {
      return 0
    }

    let sum = 0
    itemDetails.forEach((item) => {
      sum += (item.payout || 0) - (item.stake || 0)
    })

    return sum
  }

  const getItemsDetail = () => {
    if (!itemData) {
      return
    }

    const itemDetails = itemData.providerId === 1 ? orderList : betHistory

    return itemDetails
  }

  const displayItemDetails = () => {
    const itemsDetail = getItemsDetail()
    if (!itemsDetail) {
      return null
    }

    return (
      width < 1024 &&
      itemsDetail.map((itemDetail, index) => (
        <BettingProfitAndLossItemDetails key={index} itemDetails={itemDetail} />
      ))
    )
  }

  useEffect(() => {
    if (history.location.pathname == '/account_statement_details') {
      if (location?.state?.itemData) setItemData(location.state.itemData)
      else {
        setTimeout(() => {
          if (location?.state?.itemData) setItemData(location.state.itemData)
          // else history.push("/betting_profit_and_loss")
        }, 500)
      }
    }
  }, [history.location.pathname, location.state])

  useEffect(() => {
    if (history.location.pathname == '/account_statement_details') {
      if (itemData?.providerId === 1 && user && !orderList) {
        actions
          .requestOrderListSuccess(
            user.memberCode,
            'settled',
            itemData?.marketId
          )
          .catch((e) => console.log('requestOrderListError = ', e))
      } else if (itemData?.providerId === 7 && !betHistory) {
        actions
          .requestBetHistorySuccess(itemData?.marketId)
          .catch((e) => console.log('requestBetHistoryError = ', e))
      }
    }
  }, [
    itemData,
    lastActivityDate,
    actions,
    betHistory,
    orderList,
    user,
    history.location.pathname,
  ])

  const updateData = () => {
    const backSubtotal = getBackSubtotal()
    const laySubtotal = getLaySubtotal()
    const marketSubtotal = backSubtotal + laySubtotal
    const commission = getCommission()

    setTotals({
      backSubtotal: backSubtotal,
      laySubtotal: laySubtotal,
      marketSubtotal: marketSubtotal,
      commission: commission,
      netMarketTotal: marketSubtotal - commission,
      netSessionTotal: getNetSessionTotal(),
    })
  }

  useEffect(() => {
    if (itemData?.providerId === 1) updateData()
  }, [orderList, itemData])

  useEffect(() => {
    if (itemData?.providerId === 7) updateData()
  }, [betHistory, itemData, updateData])

  return (
    <IonPage>
      <IonHeader>
        <Toolbar />
      </IonHeader>
      <IonContent className="betting-profit-and-loss-page" fullscreen>
        <div className="page-title-wrapper">
          <h2 className="page-title">Account Statement Details</h2>
        </div>

        <div className="betting-profit-and-loss-page__item-list">
          <div className="betting-profit-and-loss-page-item__header">
            {itemData?.length > 0 &&
              `${itemData[0]?.match_name} - ${itemData[0]?.market_name}`}
          </div>
          {displayItemDetails()}
        </div>

        {itemData ? (
          <div className="betting-profit-and-loss-page__item-subtotal">
            <div className="back-subtotal betting-profit-and-loss-page__item-subtotal__row">
              <span className="back-subtotal__title">Back subtotal:</span>
              <span className="back-subtotal__value betting-profit-and-loss-details-item__value">
                {getCurrencyFormat(getBackSubtotal())}
              </span>
            </div>
            <div className="lay-subtotal betting-profit-and-loss-page__item-subtotal__row">
              <span className="lay-subtotal__title">Lay subtotal:</span>
              <span className="lay-subtotal__value betting-profit-and-loss-details-item__value">
                {getCurrencyFormat(getLaySubtotal())}
              </span>
            </div>
            <div className="market-subtotal betting-profit-and-loss-page__item-subtotal__row">
              <span className="market-subtotal__title">Market subtotal:</span>
              <span className="market-subtotal__value betting-profit-and-loss-details-item__value">
                {getCurrencyFormat(getBackSubtotal() + getLaySubtotal())}
              </span>
            </div>
            <div className="commission betting-profit-and-loss-page__item-subtotal__row">
              <span className="commission__title">Commission:</span>
              <span className="commission__value betting-profit-and-loss-details-item__value">
                {getCurrencyFormat(totals.commission)}
              </span>
            </div>
            <div className="net-market-total betting-profit-and-loss-page__item-subtotal__row">
              <span className="net-market-total__title">Net Market Total:</span>
              <span className="net-market-total__value betting-profit-and-loss-details-item__value">
                {getCurrencyFormat(getNetMarketTotal())}
              </span>
            </div>
          </div>
        ) : (
          <div className="betting-profit-and-loss-page__item-subtotal">
            <div className="net-session-total betting-profit-and-loss-page__item-subtotal__row">
              <span className="net-session-total__title">
                Net Session Total:
              </span>
              <span className="net-session-total__value betting-profit-and-loss-details-item__value">
                {getCurrencyFormat(totals.netSessionTotal)}
              </span>
            </div>
          </div>
        )}
        {/*{width < 1024 && <FooterDesktop />}*/}
      </IonContent>
    </IonPage>
  )
}

export default connectAccountStatementDetailsPage()(AccountStatementDetailsPage)
