import { createSlice } from '@reduxjs/toolkit'

export const cashPointSlice = createSlice({
    name: 'cashPoint',
    initialState: 0,
    reducers: {
        getCasinoPointSuccess: (state, action) => action.payload,
        getCasinoPointError: (state) => state,
    },
})

export const {
    getCasinoPointSuccess,
    getCasinoPointError,
} = cashPointSlice.actions

export default cashPointSlice.reducer
