import React, { useState, useEffect } from 'react';
import { connectSearchDesktop } from './SearchDesktop.connect';
import './SearchDesktop.scss';
import { useHistory } from "react-router"
import Poligon from '../../assets/images/poligonIcon.svg'
import CricketIcon from '../../assets/images/sideBarCricket.svg'
import FootballIcon from '../../assets/images/sideBarFooltball.svg'
import TennisIcon from '../../assets/images/sideBarTennis.svg'
import HorseIcon from '../../assets/images/sideBarHorse.svg'
import GreyhoundIcon from '../../assets/images/sideBarGreyhound.svg'
import ExchangeIcon from '../../assets/images/sideBarExchange.svg'
import { useDispatch } from 'react-redux';
import { setIsAnimatedText } from '../../store/navigationHeaderMenu/navigationHeaderMenu.slice';

const SearchDesktop = ({
    search,
    searchList,
    menu,
    actions,
    animatedText,
    searchValue,
    elementsParams,
    // searchValues,
}) => {
    const history = useHistory();
    const [menus, setMenus] = useState([]);
    const [result, setResult] = useState([]);

    useEffect(() => {
        actions.requestSearchList()
    }, [])

    useEffect(() => {
        setMenus(searchList)
    }, [searchList])

    // console.log('animatedText', animatedText);
    // console.log('searchValues', searchValues);
    const dispatch = useDispatch()

    const [width, setWidth] = useState(window.innerWidth)
    useEffect(() => {
    if(width !== elementsParams?.clientWidth) actions.setClientWidthThunk(width)

    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth
      setWidth(newWidth)
      actions.setClientWidthThunk(newWidth)
    }
    window.addEventListener('resize', updateWindowDimensions)

    return () => window.removeEventListener('resize', updateWindowDimensions)
  }, [])

  const competionNames = (playerNames, eventTypeId) => {
    let eventsArray = menu.find((item) => item.id == eventTypeId)
    let competitionName = eventsArray?.children.find((el) => el.children.find(item => item.id === playerNames))
    return competitionName?.name
  }

    // useEffect(() => {
    //     if (history.location.pathname === '/searchDesktop') {
    //         setMenus(menu)
    //     }
    // }, [history.location.pathname])

    const gotoEvent = (item) => {
        if (item.eventTypeId == '77777') {
            history.push(`/casino_live/${item.id}`)
        } else {
            localStorage.setItem("Event", JSON.stringify(item));
            history.push(`/event-page/${item.eventTypeId}/${item.match_id}`)
        }
        dispatch(setIsAnimatedText(false))
    }

    const defaultText = () => {
        if(searchValue == ''){
            dispatch(setIsAnimatedText(false))
        }
    }

    // console.log('searchValue', searchValue);

    useEffect(() => {
        const result = [];
        if (menus)
            menus.filter(event => 
                event.match_name.toLowerCase().indexOf(search.value.toLowerCase()) !== -1 
                    && result.push({ ...event, eventTypeId: event.sport_id }))
        setResult(result)
    }, [menus, search])

    const renderResults = () => {
        if (search.value.length > 2) {
            // console.log('resres', result);
            return result && result.length ?
                <>
                    <div className="poligonSearch">
                        <img src={Poligon} alt="" />
                    </div>
                    <div className="searchList">
                        {
                            result.map((eventElement, id) =>
                                <div className="searchList-text" key={id} onClick={() => {
                                    gotoEvent(eventElement)
                                    actions.setSearchValues('')
                                    actions.setIsAnimatedText(false)
                                    }}>  
                                    <div style={{display: 'flex'}}>
                                        <img 
                                        style={{height: '19px'}}
                                        src={
                                        eventElement.eventTypeId == 4 
                                        ? CricketIcon
                                        : eventElement.eventTypeId == 1
                                        ? FootballIcon
                                        : eventElement.eventTypeId == 2 
                                        ? TennisIcon
                                        : eventElement.eventTypeId == 7
                                        ? HorseIcon
                                        : eventElement.eventTypeId == 4339
                                        ? GreyhoundIcon  
                                        : eventElement.eventTypeId == 1444001
                                        ? ExchangeIcon
                                        : ''} alt="" />
                                        <div className='searchList-text-info'>
                                            <span>{eventElement.match_name.length > 25 ? eventElement.match_name.slice(0, 25) + '...' : eventElement.match_name}</span>
                                            <div style={{marginTop: '5px', fontSize: '12px'}}>{competionNames(eventElement.id, eventElement.eventTypeId)}</div>
                                        </div>
                                    </div>
                                    <span>
                                        {/* {new Date(eventElement.startTime).toLocalDateString({
                                        day:'numeric',
                                        month: 'numeric',
                                        year: 'numeric',
                                        })} */}
                                        {/* 28.08.2022 */}
                                    </span>
                                    {/* <IonNote className="Search__results__item__type">event</IonNote> */}
                                </div>)
                        }
                    </div>
                </>
                : 
                <>
                <div className="poligon">
                    <img src={Poligon} alt="" />
                </div>
                <div className="searchList">
                    <span 
                        style={{color: '#000'}}
                        className="Search__empty">{result && search.value && search.value.length > 2 && `No events found matching for '${search.value}'`}</span>
                </div>
                </>
        }
        else return ''
    }



    return (
        <div>
            {renderResults()}
        </div>
    )
}

export default connectSearchDesktop()(SearchDesktop)
