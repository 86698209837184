import "./PastBetsTable.scss";
import React from "react";
import { tsToDate } from "../../helpers/date.helper";
import { getCurrencyFormatWithZeroAfterDot } from "../../helpers/getCurrencyFormat.helper";

const PastBetsTable = ({ timeSettings, bets, title }) => {
  return (
    <section className="PastBetsTable">
      <div className="PastBetsTable__table">
        <ul className="PastBetsTable__table-header">
          <li>Settled</li>
          <li>Description</li>
          <li>Type</li>
          <li>Odds</li>
          <li>Stake</li>
          <li className="text-right">Profit/Loss</li>
          <li className="text-right">Status</li>
        </ul>
        <div className="PastBetsTable__table-body">
          {bets && bets.length > 0 ? bets.map((item, index) =>
            <ul className="PastBetsTable__table-body-row" key={item?.createdAt + index}>
              <li className="PastBetsTable__table-body-row-date">
                <span>
                  {/*{*/}
                  {/*  tsToDate(item?.marketSettledDate, timeSettings?.timeZoneName).split(" ")[0]*/}
                  {/*    .split("/")*/}
                  {/*    .map((dateItem) => dateItem.length === 1 ? "0" + dateItem : dateItem)*/}
                  {/*    .join("/")*/}
                  {/*}*/}
                  {new Date(item?.createdAt).toLocaleDateString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit', })}
                </span>
                <span className="text-color">
                  {tsToDate(item?.createdAt, timeSettings?.timeZoneName).split(" ")[1]}
                </span>
              </li>
              <li className="PastBetsTable__table-body-row-description">
                <p className="PastBetsTable__table-body-row-description-name">{item?.match_name}</p>
                <p><span className="text-bold">{item?.selection_name} - </span> <span className="text-color">{item?.market_name}</span></p>
                <p>
                  <span>Bet ID {item?.bet_id} | </span>
                  {/*<span>Matched {tsToDate(item?.betPlacedDate, timeSettings?.timeZoneName)}</span>         */}
                  <span>
                    Matched
                    {new Date(item?.createdAt).toLocaleDateString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).replace(',', '')}
                  </span>
                </p>
              </li>
              <li className="PastBetsTable__table-body-row-info">{item?.is_back === 0 ? "Lay" : "Back"}</li>
              <li className="PastBetsTable__table-body-row-info">{item?.odds.toFixed(2)}</li>
              <li className="PastBetsTable__table-body-row-info">{getCurrencyFormatWithZeroAfterDot(item?.stack)}</li>
              <li className={`PastBetsTable__table-body-row-profit ${item?.p_l > 0 ? "green" : "red"}`}>
                {getCurrencyFormatWithZeroAfterDot(item?.p_l)}
              </li>
              <li className={`PastBetsTable__table-body-row-profit ${item?.p_l > 0 ? "green" : "red"}`}>
                {item?.p_l > 0 ? "WON" : "LOSS"}
              </li>
            </ul>,
          ) : (
            <ul className="PastBetsTable__table-body-no-bets">
              <li>
                You have no <span className="lowercase">{title} Bets</span>
              </li>
            </ul>
          )}

        </div>
      </div>
    </section>
  );
};

export default PastBetsTable;
