import React, { useState, useEffect } from "react";
import "./LoaderContent.scss";
import { IonSpinner } from "@ionic/react";
import ErrorIcon from "../../assets/images/errorIcon.svg";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import { connectLoaderContent } from "./LoaderContent.connect";


const LoaderContent = ({
                         messages,
                         notifications,
                         user,
                         contentScrolled = false,
                         setStyle = () => null,
                         actions,
                         search = false,
                         noSearch = true,
                         menu,
                         elementsParams
                       }) => {

  const width = elementsParams && elementsParams?.clientWidth ? elementsParams?.clientWidth : window.innerWidth;

  const [openError, setOpenError] = useState(false);
  const history = useHistory();

  const isAnimatedMenu = useSelector(
    (state) => state.navigationHeaderMenu.animatedMenu
  );

  const isNavigationOpen = useSelector(
    (state) => state.navigationHeaderMenu.isOpen
  );

  useEffect(() => {
    // if (width > 1024) {
      let timeout = setTimeout(() => {
        setOpenError(true);
      }, 10000);
      return () => clearTimeout(timeout);
    // } else {
    //   let timeout = setTimeout(() => {
    //     history.push("/default-page");
    //   }, 3000);
    //   return () => clearTimeout(timeout);
    // }
  }, []);

  return (
    width > 1024 ?
      openError
        ? <div className="LoaderContent">
          <div className="notEventPopUp">
            <div className="notEventPopUp-wrapper">
              <div className="notEventPopUp-wrapper__image">
                <img loading={"lazy"} className="notEventPopUp-wrapper__image-icon" src={ErrorIcon} alt="" />
              </div>
              <div className="notEventPopUp-wrapper__info">
                <div className="notEventPopUp-wrapper__info-title">SORRY</div>
                <div className="notEventPopUp-wrapper__info-description">This event is currently unavailable
                  Try again later
                </div>
                <div className="notEventPopUp-wrapper__info-button"
                     onClick={() => {
                       actions.setIsNavigationOpen(true);
                       // actions.setIsAnimatedMenu(true)
                       setTimeout(() => {
                         actions.setIsNavigationOpen(false);
                       }, 400);
                       setTimeout(() => {
                         actions.setIsAnimatedMenu(false);
                       }, 400);
                       // actions.setIsNavigationID(item.id)
                       // actions.setIsNavigationName(item.name)

                       setOpenError(false);
                       history.push(`/sports-page/`);
                     }}
                >OK
                </div>
              </div>
            </div>
          </div>
        </div>
        : <section className="LoaderContent">
          {/* <IonSpinner name="lines" /> */}
        </section>
      :
      <section className="LoaderContentMob">
        <IonSpinner name="lines" />
      </section>
  );


};

export default connectLoaderContent()(LoaderContent);
