import httpAuth from '../../services/httpAuth'
import getErrorMessage from '../../helpers/getErrorMessage.helper'
import { getQTechSuccess, getQTechError } from './qTech.slice'


export const requestQtechFilter = () => async (dispatch) => {
  try {
    const response = await httpAuth.get(`/qtech/all/sports`)
    if (response.data.status) {
      dispatch(getQTechSuccess(response.data.data))
    }
    return response
  } catch (e) {
    dispatch(getQTechError())
    // const errorMsg = getErrorMessage(e)
    // throw errorMsg
  }
}
