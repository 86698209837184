import httpAuth from '../../services/httpAuth'
import httpNotAuth from '../../services/httpNotAuth'
import getErrorMessage from '../../helpers/getErrorMessage.helper'
import { getExchangeGamesAllSuccess, getExchangeGamesAllError } from './exchangeGamesAll.slice'
import { IndianCasino } from '../../constants/Casino/IndainCasino'


export const requestExchangeGamesAll = () => async (dispatch, getState) => {
  const checkAuth = getState().auth
    const data = {sport_id: "-100"}
    if(checkAuth) {
        try {
            const response = await httpAuth.post(`/match/homeMatches`, data)
           if (response.data.status) {
            dispatch(getExchangeGamesAllSuccess({liveGames: response.data.data}))
        }
        return response
        } catch (e) {
            dispatch(getExchangeGamesAllError())
        }
    } else {
        dispatch(getExchangeGamesAllSuccess({liveGames: IndianCasino}))
    }
}
