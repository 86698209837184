import httpAuth from '../../services/httpAuth'
import httpNotAuth from '../../services/httpNotAuth'
import getErrorMessage from '../../helpers/getErrorMessage.helper'
import { getEventError, getEventSuccess } from './menuEvent.slice'

export const requestMenuEvent = (id) => async (dispatch, getState) => {
  const checkAuth = getState().auth

  if(checkAuth) {
    try {
      const response = await httpAuth.get(`/exchange/odds/inplayEvents/${id}`)
      if (response.data.success) {
        dispatch(getEventSuccess(response.data.result))
      }
      return response
    } catch (e) {
      dispatch(getEventError())
      // const errorMsg = getErrorMessage(e)
      // throw errorMsg
    }
  } else {
    try {
      const response = await httpNotAuth.get(`/exchange/odds/inplayEvents/${id}`)
      if (response.data.success) {
        dispatch(getEventSuccess(response.data.result))
      }
      return response
    } catch (e) {
      dispatch(getEventError())
      // const errorMsg = getErrorMessage(e)
      // throw errorMsg
    }
  }
  
}

export const requestMenuEventRace = (id) => async (dispatch, getState) => {
  const checkAuth = getState().auth  
  console.log("id", id)
  if(checkAuth) {
    try {
      const response = await httpAuth.post(`match/matchDetailsRunners`)
      if (response.data.success) {
        response.data.result[0].eventTypeId = id;
        dispatch(getEventSuccess(response.data.result))
      }
      return response
    } catch (e) {
      dispatch(getEventError())
      // const errorMsg = getErrorMessage(e)
      // throw errorMsg
    }
  } else {
    try {
      const response = await httpNotAuth.get(`/exchange/odds/races/${id}`)
      if (response.data.success) {
        response.data.result[0].eventTypeId = id;
        dispatch(getEventSuccess(response.data.result))
      }
      return response
    } catch (e) {
      dispatch(getEventError())
      // const errorMsg = getErrorMessage(e)
      // throw errorMsg
    }
  }
}
