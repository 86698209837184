import httpAuth from '../../services/httpAuth'
import getErrorMessage from '../../helpers/getErrorMessage.helper'
import {getAccountStatementError, getAccountStatementSuccess} from './accountStatement.slice'

export const requestAccountStatementSuccess = (dateFrom, dateTo) => async (dispatch) => {
// console.log('lastEightDayTime', lastEightDayTime)
// console.log('currentTime', currentTime)
    try {
        const data = {
            from_date: dateFrom,
            to_date: dateTo,
            limit: 100,
        }
        const response = await httpAuth.post(`/account/statements`, data)
        if (response.data.status) {
            dispatch(getAccountStatementSuccess(response?.data?.data[0]))
        }
        return response
    } catch (e) {
        dispatch(getAccountStatementError())
        // const errorMsg = getErrorMessage(e)
        // throw errorMsg
    }
}
