import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {requestAccountReport} from "../../store/accountReport/account-report.thunks";
import {requestBetHistorySuccess} from "../../store/betHistory/bet-history.thunks";
import {requestOrderListSuccess} from "../../store/orderList/order-list.thunks";

export const connectAccountStatementCasinoDetailsPage = (wrappedProps) => (component) => {
    function mapStateToProps (state) {
        return {
            user: state.user,
            orderList: state.orderList,
            betHistory: state.betHistory,
            lastActivityDate: state.balance.lastActivityDate,
            elementsParams: state.elementsParams
        };
    }

    function mapDispatchToProps (dispatch) {
        const actions = {
            requestAccountReport,
            requestOrderListSuccess,
            requestBetHistorySuccess,
        };

        return {actions: bindActionCreators(actions, dispatch)};
    }

    return connect(mapStateToProps, mapDispatchToProps)(component);
};
