import './inplayitemlist.scss'
import React from 'react'
import { useSelector } from 'react-redux';
import { IonList, IonListHeader } from "@ionic/react";
import InPlayItem from "../InPlayItem/inplayitem";
import { headerType } from "../../helpers/InPlayPopular.helper";
import { useHistory } from "react-router"
import EventTypeBanners from '../EventTypeBanners/EventTypeBanners'
import SportPageItem from '../SportPageItem/SportPageItem';

const InPlayPopularItemList = ({ type, itemHeaderData, balanceSetting, timeSettings, itemsData, displayedBack, setDisplayedBack, betPlacing }) => {

    const checkStateWidth = useSelector((state) => state.elementsParams.clientWidth)
    const width = checkStateWidth ? checkStateWidth : window.innerWidth

    const getHeaderIconColor = () => {
        switch (type) {
            case headerType['INPLAY']: {
                return 'inplay-color-icon';
            }

            case headerType['POPULAR']: {
                return 'popular-color-icon';
            }

            case headerType['FUTURE']: {
                return ;
            }

            default: return 'popular-color-icon';
        }
    }

    const getHeaderIcon = () => {
        switch (itemHeaderData.id) {
            case 1: {
                return `inplay-content__logo-icon--football`
            }

            case 2: {
                return 'inplay-content__logo-icon--tennis'
            }

            case 4: {
                return 'inplay-content__logo-icon--cricket'
            }

            case 7: {
                return 'inplay-content__logo-icon--horse'
            }

            case 4339: {
                return 'inplay-content__logo-icon--greyhound'
            }

            case 77777: {
                return 'inplay-content__logo-icon--casino'
            }

            case -100: {
                return 'inplay-content__logo-icon--exchange-games'
            }

            case 2378962: {
                return 'inplay-content__logo-icon--election'
            }

            default: return 'inplay-content__logo-icon--exchange-games'
        }
    }

  const getBanner = () => {
        if (headerType['INPLAY'] == type) {
             return <EventTypeBanners eventType={itemHeaderData.id} />
        } else return null
    }

    const renderHeader = () => {
        if (itemsData.length == 0 || (itemsData.length == 1 && itemsData[0].eventType == 'SPORTSBOOK')) return null
        return <IonListHeader className="inplay-item-list__header">
            <div className="inplay-item-list__header-content">
                <div className="inplay-item-list__header-logo">
                    <i className={`inplay-content__logo-icon ${getHeaderIcon()} ${getHeaderIconColor()}`} />
                    {itemHeaderData.name}
                </div>
                { (itemHeaderData.id === 1 || itemHeaderData.id === 4 || (width < 1024 && itemHeaderData.id === 2) ) &&
                  <div className="inplay-item-list__header-title">
                    <span>1</span>
                    <span>X</span>
                    <span>2</span>
                   </div>
                }
                {width > 1024 && itemHeaderData.id === 2 &&
                  <div className="inplay-item-list__header-title">
                      <span>1</span>
                      <span>2</span>
                  </div>
                }
            </div>
        </IonListHeader>
    }


    return (
        <IonList className="inplay-item-list">
            {getBanner()}
            {renderHeader()}
            {itemsData && itemsData.sort((a, b) => {
      // Sorting logic goes here
      // For example, to sort alphabetically by a property called 'name':
      if (a.match_date < b.match_date) return -1;
      if (a.match_date > b.match_date) return 1;
      return 0;
    }).map((itemData, index) => {
                if (itemData.eventType == 'SPORTSBOOK') return null
                // if (!itemData?.players) return null
                return ( !itemData.id ?
                    <InPlayItem
                      type={type}
                      betPlacing={betPlacing}
                      timeSettings={timeSettings}
                      balanceSetting={balanceSetting}
                      displayedBack={displayedBack}
                      setDisplayedBack={setDisplayedBack}
                      key={index}
                      itemData={itemData}
                    />
                    :
                    <SportPageItem
                      type={type}
                      betPlacing={betPlacing}
                      timeSettings={timeSettings}
                      balanceSetting={balanceSetting}
                      displayedBack={displayedBack}
                      setDisplayedBack={setDisplayedBack}
                      key={index}
                      sportItemData={itemData}
                    />
                )
            })}
        </IonList>
    )
}

export default InPlayPopularItemList
