import { createSlice } from '@reduxjs/toolkit'

export const casinoBetsSlice = createSlice({
    name: 'casinoBets',
    initialState: null,
    reducers: {
        getCasinoBetsSuccess: (state, action) => action.payload,
        getCasinoBetsError: (state) => null,
        
    },
})

export const {
    getCasinoBetsError,
    getCasinoBetsSuccess,
    setInfoDetailed,
} = casinoBetsSlice.actions

export default casinoBetsSlice.reducer
