import http from '../../services/httpAuth'
import getErrorMessage from '../../helpers/getErrorMessage.helper'
import {
  setUserSettingsOneClickStakes,
  setUserSettingsMobileStakesExchangeGame,
  setUserSettingsMobileStakes,
  setNotificationSettings,
  setUserSettingsStakes
} from './user.slice';
import { USER_DATA } from '../../config/app.config';

export const requestChangePassword = (data) => async (dispatch) => {
  try {

    const response = await http.post(`user/updatePassword`, data)
    if (response.data.success) {
      // pass changed
    }
    return response
  } catch (e) {
    const errorMsg = getErrorMessage(e)
    throw errorMsg
  }
}

export const requestSaveOneClickSettings = (memberCode, settings, withApi = false) => async (dispatch, getState) => {
  try {
    if (withApi) {
      const response = await http.post(`account/${memberCode}/settings/one-click`, settings)
      if (response.data.success) {
        dispatch(setUserSettingsOneClickStakes(settings));
        localStorage.setItem(USER_DATA, JSON.stringify(getState().user));
      }
      return response
    } else {
      dispatch(setUserSettingsOneClickStakes(settings));
      localStorage.setItem(USER_DATA, JSON.stringify(getState().user));
    }
  } catch (e) {
    const errorMsg = getErrorMessage(e)
    throw errorMsg
  }
}

export const requestSaveMobileStakesExchangeGameSettings = (memberCode, settings) => async (dispatch, getState) => {
  try {
    const response = await http.post(`account/${memberCode}/settings/mobile-stakes-exchange-game`, { settings })
    if (response.data.success) {
      dispatch(setUserSettingsMobileStakesExchangeGame(settings));
      localStorage.setItem(USER_DATA, JSON.stringify(getState().user));
    }
    return response
  } catch (e) {
    const errorMsg = getErrorMessage(e)
    throw errorMsg
  }
}
//TODO: add settings

export const requestSaveMobileStakesSettings = (memberCode, settings) => async (dispatch, getState) => {
  try {
    const response = await http.post(`account/${memberCode}/settings/mobile-stakes`, { settings })
    if (response.data.success) {
      dispatch(setUserSettingsMobileStakes(settings));
      localStorage.setItem(USER_DATA, JSON.stringify(getState().user));
    }
    return response
  } catch (e) {
    const errorMsg = getErrorMessage(e)
    throw errorMsg
  }
}

export const requestSaveStakesSettings = (settings) => async (dispatch, getState) => {
  try {
    const payload = {
      match_stack: settings
    }
    const response = await http.post(`user/updateMatchStack`, payload)
    if (response.data.status) {
      dispatch(setUserSettingsStakes(settings));
    }
    return response
  } catch (e) {
    const errorMsg = getErrorMessage(e)
    throw errorMsg
  }
}

export const setNotifications = (settings) => async (dispatch, getState) => {
  try {
    dispatch(setNotificationSettings(settings));
    localStorage.setItem(USER_DATA, JSON.stringify(getState().user));
    return true
  } catch (e) {
    // const errorMsg = getErrorMessage(e)
    // throw errorMsg
  }
}
