import React, { useEffect, useState, useRef } from 'react'
import { IonContent, IonPage, IonHeader } from '@ionic/react'
import { connectSlotsGamesLobby } from './SlotsGamesLobby.connect'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'

import './SlotsGamesLobby.scss'
import Toolbar from '../../components/Toolbar/Toolbar'
import WhiteArrow from '../../assets/images/slotsGames/Arrow.svg'
import JiliPopUp from '../../components/SlotsJiliPopUp'
import chip from './chip.png'
import slotsBackgroundEl from './slotsBackgroundEl.png'
import slotsRouletteIn from './slotsRouletteIn.png'
import slotsRouletteOut from './slotsRouletteOut.png'
import slotMachine from './slotMachine.png'
import FooterDesktop from '../../components/FooterDesktop/FooterDesktop'
import SlotsCasinoPopUp from '../../components/SlotsCasinoPopup'

const SlotsGamesLobby = ({
  actions,
  slotsGames,
  slotsGamesSearch,
  auth,
  elementsParams,
  quickButtonSlots,
  slotCasinoPopUp,
  qTechGames,
}) => {
  const [seeAllSlots, setSeeAllSlots] = useState({})
  const [jiliGame, setJiliGame] = useState()
  const [searchOn, setSearchOn] = useState(false)
  const width =
    elementsParams && elementsParams?.clientWidth
      ? elementsParams?.clientWidth
      : window.innerWidth.clientWidth
  const history = useHistory()

  const isPopUpOpen = useSelector((state) => state.slotsPopUp.isPopUpOpen)

  const handleClick = (id) => {
    let ref
    if (id === '51') ref = allSlots
    if (id === '52') ref = fishing
    if (id === '53') ref = bingo
    if (id === '54') ref = multiplayer
    if (id === '55') ref = virtual
    if (id === '56') ref = scratch

    if (ref) {
      ref &&
        ref.current &&
        ref.current.scrollIntoView({
          block: 'start',
          behavior: 'smooth',
        })
    }
  }

  const seeAll = (id) => {
    if (id === '51') return allSlots
    if (id === '52') return fishing
    if (id === '53') return bingo
    if (id === '54') return multiplayer
    if (id === '55') return virtual
    if (id === '56') return scratch
  }

  const stateNames = (id) => {
    if (id === '51') return slotsGames?.allSlots
    if (id === '52') return slotsGames?.fishingGames
    if (id === '53') return slotsGames?.bingoGames
    if (id === '54') return slotsGames?.multiplayerGames
    if (id === '55') return slotsGames?.virtualGames
    if (id === '56') return slotsGames?.scratchGames
  }

  useEffect(() => {
    // console.log('quickButtonSlots', quickButtonSlots)
    if (quickButtonSlots && quickButtonSlots !== 'slotsgames-lobby') {
      setTimeout(() => {
        handleClick(`${quickButtonSlots}`)
        actions.setQuickButtonSlots('')
      }, 1000)
    }
  }, [quickButtonSlots])

  // console.log('bumbum', slotsGames, slotsGames[stateNames('55')]);

  const allSlots = useRef(null)
  const fishing = useRef(null)
  const bingo = useRef(null)
  const multiplayer = useRef(null)
  const virtual = useRef(null)
  const scratch = useRef(null)

  useEffect(() => {
    // actions.requestSlotsGames()
    actions.requestQtechGames('RTG')
  }, [])

  const onOpenGame = (id, item) => {
    if (auth) {
      if (id.indexOf('jili') !== -1) {
        console.log('open')
        setJiliGame(item)
        actions.setIsPopUpOpen(true)
      } else {
        actions.requestSlotsGamesID(id)
        history.push(`/live_casino_slot/${id}`)
      }
    } else {
      actions.setLoginNew(true)
    }
  }

  const onSearchGames = (e) => {
    console.log('e', e)
    if (e.length >= 4) {
      setSearchOn(true)
      actions.requestSlotsGamesSearch(e)
    } else {
      setSearchOn(false)
    }
  }

  const onSelectGame = (data) => {
    if (!data?.id) return;
    const id = data?.id;
    const tableId = data?.provider?.id || '';

    if (auth) {
        let data = { gameId: id, providerCode: tableId}
        actions.requestQTechCasinoID(data)
        history.push(`/live_casino_slot/${id}`)
        // dispatch(openSlotCasinoPopUp());
    } else {
      actions.setLoginNew(true);
        // dispatch(setLoginNavigationData({ page: 'casino_live', ids: [id, tableId] }));
    }
}

  function getPreferredImage(images) {
    // Initialize variables for banner and logo-square URLs
    let bannerImage = null
    let logoSquareImage = null

    // Loop through the array of images
    images.forEach((image) => {
      if (image.type === 'banner') {
        bannerImage = image.url
      } else if (image.type === 'logo-square') {
        logoSquareImage = image.url
      }
    })

    // Return the banner image if available, otherwise return the logo-square image
    return bannerImage || logoSquareImage
  }

  return (
    <IonPage>
      {width < 1024 ? (
        <IonHeader>
          <Toolbar />
        </IonHeader>
      ) : (
        <></>
      )}
      {isPopUpOpen ? <JiliPopUp item={jiliGame} /> : null}
      <IonContent>
        {slotCasinoPopUp && <SlotsCasinoPopUp />}
        <div
          className="bgSlots"
          style={width < 1024 ? { marginTop: '0px' } : {}}
        >
          <div
            className="Slots-header"
            style={width < 1024 ? { position: 'relative' } : {}}
          >
            {width < 1024 ? (
              <>
                <img
                  loading={'lazy'}
                  className="Slots-header__img"
                  // src={SlotsLobbyMobile}
                  src={slotsBackgroundEl}
                  alt=""
                />
                <div className="Slots-header__filterMobile">
                  {/* <div className="Slots-header__filterMobile-search">
                    <input
                      className="Slots-header__filterMobile-search-input"
                      type="text"
                      name="search"
                      autoComplete="off"
                      placeholder="SEARCH GAMES"
                      onChange={(e) => onSearchGames(e.target.value)}
                    />
                    <img
                      loading={'lazy'}
                      className="Slots-header__filterMobile-search-icon"
                      src={SearchIcon}
                      alt=""
                    />
                  </div> */}
                  <div className="Slots-header__filterMobile-items">
                    {slotsGames?.slotsGamesButtons?.length &&
                      slotsGames?.slotsGamesButtons?.map((item) => {
                        return (
                          <div
                            className="Slots-header__filterMobile-items-item"
                            onClick={() => handleClick(item?.id)}
                          >
                            <span>{item?.name}</span>
                          </div>
                        )
                      })}
                  </div>
                </div>
              </>
            ) : (
              <div className="Slots-header__img">
                <img
                  loading={'lazy'}
                  className="Slots-header__img-img"
                  src={slotsBackgroundEl}
                  alt=""
                />
                {/* <img
                  className="Slots-header__img-arrow"
                  src={WhiteArrow}
                  alt=""
                /> */}
              </div>
            )}
            {/* {width >= 1024 ? */}
            <>
              {width > 1024 && (
                <div className="roulettContainer">
                  <img
                    loading={'lazy'}
                    className="insideRouletteS"
                    src={slotsRouletteIn}
                    alt="f"
                  ></img>
                  <img
                    loading={'lazy'}
                    className="outsideRouletteS"
                    src={slotsRouletteOut}
                    alt="f"
                  ></img>
                </div>
              )}
              <div className="slotMachine">
                <img loading={'lazy'} src={slotMachine}></img>
                <div className="slotMachine__topTextContainer">
                  <div className="slotMachine__starsTop">
                    <span className="slotMachine__starsTop-star">★</span>
                    <span className="slotMachine__starsTop-star">★</span>
                    <span className="slotMachine__starsTop-star">★</span>
                    <span className="slotMachine__starsTop-star">★</span>
                    <span className="slotMachine__starsTop-star">★</span>
                    <span className="slotMachine__starsTop-star">★</span>
                    <span className="slotMachine__starsTop-star">★</span>
                    <span className="slotMachine__starsTop-star">★</span>
                    <span className="slotMachine__starsTop-star">★</span>
                    <span className="slotMachine__starsTop-star">★</span>
                    <span className="slotMachine__starsTop-star">★</span>
                    <span className="slotMachine__starsTop-star">★</span>
                    <span className="slotMachine__starsTop-star">★</span>
                  </div>
                  <div class="slotMachine__mainText">
                    <b>
                      SLO<span>T&nbsp;</span>MACH<span>I</span>NE
                    </b>
                  </div>
                  {/* <span className='slotMachine__mainText'>SLOT MACHINE</span> */}
                  <div className="slotMachine__starsBottom">
                    <span className="slotMachine__starsTop-star">★</span>
                    <span className="slotMachine__starsTop-star">★</span>
                    <span className="slotMachine__starsTop-star">★</span>
                    <span className="slotMachine__starsTop-star">★</span>
                    <span className="slotMachine__starsTop-star">★</span>
                    <span className="slotMachine__starsTop-star">★</span>
                    <span className="slotMachine__starsTop-star">★</span>
                    <span className="slotMachine__starsTop-star">★</span>
                    <span className="slotMachine__starsTop-star">★</span>
                    <span className="slotMachine__starsTop-star">★</span>
                    <span className="slotMachine__starsTop-star">★</span>
                    <span className="slotMachine__starsTop-star">★</span>
                    <span className="slotMachine__starsTop-star">★</span>
                  </div>
                </div>
                <div className="slotMachine__textContainer">
                  <span className="slotMachine__freeSpins">Free Spins</span>
                </div>
              </div>
              <div id="formContainer">
                <div className="circles">
                  <span>
                    <img loading={'lazy'} src={chip} alt="chip" />
                  </span>
                  <span>
                    <img loading={'lazy'} src={chip} alt="chip" />
                  </span>
                  <span>
                    <img loading={'lazy'} src={chip} alt="chip" />
                  </span>
                  <span>
                    <img loading={'lazy'} src={chip} alt="chip" />
                  </span>
                  <span>
                    <img loading={'lazy'} src={chip} alt="chip" />
                  </span>
                  <span>
                    <img loading={'lazy'} src={chip} alt="chip" />
                  </span>
                </div>
              </div>
            </>
            {/* :
            null
          } */}

            {width >= 1024 ? (
              <div className="Slots-header__filter">
                <div className="Slots-header__filter-row">
                  {slotsGames?.slotsGamesButtons?.length &&
                    slotsGames?.slotsGamesButtons?.map((item) => {
                      return (
                        <div
                          className="Slots-header__filter-row-item"
                          onClick={() => handleClick(item?.id)}
                        >
                          <span>{item?.name}</span>
                        </div>
                      )
                    })}
                  {/* <div
                    style={{
                      position: 'absolute',
                      right: '-5px',
                    }}
                  >
                    <input
                      className="Slots-header__filter-row-search"
                      type="text"
                      placeholder="SEARCH GAMES"
                      name="search"
                      autoComplete="off"
                      onChange={(e) => onSearchGames(e.target.value)}
                    />
                    <img
                      loading={'lazy'}
                      className="Slots-header__filter-row-icon"
                      src={SearchIcon}
                      alt=""
                    />
                  </div> */}
                </div>
              </div>
            ) : null}
            <div className="Slots-header__text">
              <div className="Slots-header__text-name">SLOTS GAMES</div>
            </div>
          </div>

          <div className={`Slots-content ${width < 1024 ? 'mobile' : ''}`}>
            <img
              loading={'lazy'}
              className="Slots-content__arrow"
              src={WhiteArrow}
              alt=""
            />
            <div className="Slots-content__menu">
              <div className="Slots-content__menu-games">
                <div className="Slots-content__menu-games__allgames">
                  {!searchOn ? (
                    <>
                      {slotsGames?.mainGames &&
                        slotsGames?.mainGames?.map((item, index) => {
                          return (
                            <>
                              <div className="Slots-content__menu-games__allgames-title">
                                <span
                                  className="Slots-content__menu-games__allgames-title-ref"
                                  ref={seeAll(item?.id)}
                                  id={item?.id}
                                ></span>
                                <div className="Slots-content__menu-games__allgames-title-name">
                                  {item?.name}
                                </div>
                                <div
                                  className="Slots-content__menu-games__allgames-title-seeAll"
                                  onClick={() => {
                                    setSeeAllSlots({
                                      ...seeAllSlots,
                                      [index]: !seeAllSlots[index],
                                    })
                                    if (item?.id === '56') {
                                      return
                                    } else {
                                      actions.requestSlotsGamesLobby(item?.id)
                                    }

                                    console.log('iii', item)
                                  }}
                                >
                                  {seeAllSlots[index] ? 'See less' : 'See all'}
                                </div>
                              </div>
                              <div style={{ width: '100%', overflowX: 'auto' }}>
                                <div
                                  className="Slots-content__menu-games__allgames-items"
                                  style={
                                    seeAllSlots[index] && item?.id !== '56'
                                      ? {
                                          height: '100%',
                                          flexDirection: 'row',
                                          justifyContent: 'space-around',
                                        }
                                      : seeAllSlots[index] && item?.id === '56'
                                      ? {
                                          height: '440px',
                                          flexDirection: 'row',
                                          justifyContent: 'space-around',
                                        }
                                      : {}
                                  }
                                >
                                  {!seeAllSlots[index]
                                    ? slotsGames?.mainGames &&
                                      slotsGames?.mainGames[index]?.games?.map(
                                        (item) => {
                                          return (
                                            <div
                                              className="Slots-content__menu-games__allgames-items-item"
                                              onClick={() =>
                                                onOpenGame(item?.ID, item)
                                              }
                                            >
                                              <div className="altBackground">
                                                <span>{item?.Trans?.en}</span>
                                                <img
                                                  loading={'lazy'}
                                                  src={item?.ImageURL}
                                                  alt=" "
                                                  // onClick = {() => onOpenGame(item?.ID)}
                                                />
                                                <div className="img-gamename-slots">
                                                  {item?.Trans?.en}
                                                </div>
                                              </div>
                                            </div>
                                          )
                                        }
                                      )
                                    : slotsGames && stateNames(item?.id)
                                    ? stateNames(item?.id)?.map((item) => {
                                        return (
                                          <div className="Slots-content__menu-games__allgames-items-item">
                                            <div className="altBackground">
                                              <span>{item?.name}</span>
                                              <img
                                                loading={'lazy'}
                                                src={item?.image}
                                                alt=" "
                                                onClick={() =>
                                                  onOpenGame(item?.id)
                                                }
                                              />
                                              <div className="img-gamename-slots">
                                                {item?.name}
                                              </div>
                                            </div>
                                          </div>
                                        )
                                      })
                                    : slotsGames?.mainGames &&
                                      slotsGames?.mainGames[index]?.games?.map(
                                        (item) => {
                                          return (
                                            <div className="Slots-content__menu-games__allgames-items-item">
                                              <div className="altBackground">
                                                <span>{item?.Trans?.en}</span>
                                                <img
                                                  loading={'lazy'}
                                                  src={item?.ImageURL}
                                                  alt=" "
                                                  onClick={() =>
                                                    onOpenGame(item?.ID)
                                                  }
                                                />
                                                <div className="img-gamename-slots">
                                                  {item?.Trans?.en}
                                                </div>
                                              </div>
                                            </div>
                                          )
                                        }
                                      )}
                                </div>
                              </div>
                            </>
                          )
                        })}

                      <div className="Slots-content__menu-games__allgames-title">
                        <div
                          ref={allSlots}
                          className="Slots-content__menu-games__allgames-title-name"
                        >
                          All Slot
                        </div>
                        <div
                          className="Slots-content__menu-games__allgames-title-seeAll"
                          onClick={() => {
                            setSeeAllSlots(!seeAllSlots)
                            actions.requestSlotsGamesLobby('51')
                          }}
                        >
                          {seeAllSlots ? 'See less' : 'See all'}
                        </div>
                      </div>
                      <div
                       style={
                        seeAllSlots
                          ? {
                              height: '100%',
                              flexDirection: 'row',
                              justifyContent: 'space-around',
                            }
                          : {}
                      }
                        className="Slots-content__menu-games__allgames-items"
                      >
                        {qTechGames &&
                          qTechGames?.map((item) => {
                            return (
                              <div
                                className="Slots-content__menu-games__allgames-items-item"
                                onClick={() => onSelectGame(item)}
                              >
                                <div className="altBackground">
                                  <span>{item?.name}</span>
                                  <img
                                    loading={'lazy'}
                                    src={getPreferredImage(item?.images)}
                                    alt=" "
                                    // onClick = {() => onOpenGame(item?.ID)}
                                  />
                                  <div className="img-gamename-slots">
                                    {item?.name}
                                  </div>
                                </div>
                              </div>
                            )
                          })}
                      </div>

                      {/* hard code */}
                      {/* <div className="Slots-content__menu-games__allgames-title">
                        <div
                          ref={allSlots}
                          className="Slots-content__menu-games__allgames-title-name"
                        >
                          All Slot
                        </div>
                        <div
                          className="Slots-content__menu-games__allgames-title-seeAll"
                          onClick={() => {
                            setSeeAllSlots(!seeAllSlots)
                            actions.requestSlotsGamesLobby('51')
                          }}
                        >
                          {seeAllSlots ? 'See less' : 'See all'}
                        </div>
                      </div>
                      <div
                        style={
                          seeAllSlots
                            ? { height: '100%' }
                            : slotsGames && slotsGames[0]?.games?.length < 8
                            ? { height: '100%' }
                            : slotsGames && slotsGames[0]?.games?.length < 1
                            ? { display: 'none' }
                            : {}
                        }
                        className="Slots-content__menu-games__allgames-items"
                      >
                        {!seeAllSlots
                          ? slotsGames &&
                            slotsGames[0]?.games?.map((item) => {
                              return (
                                <div
                                  className="Slots-content__menu-games__allgames-items-item"
                                  onClick={() => onOpenGame(item?.ID, item)}
                                >
                                  <img
                                    src={item?.ImageURL}
                                    alt="asdasdasdasd"
                                    onClick={() => onOpenGame(item?.ID, item)}
                                  />
                                </div>
                              )
                            })
                          : slotsGames && slotsGames?.allSlots
                          ? slotsGames &&
                            slotsGames?.allSlots?.map((item) => {
                              return (
                                <div className="Slots-content__menu-games__allgames-items-item">
                                  {item?.image ?
                                  <img
                                    src={item?.image}
                                    alt=""
                                    onClick={() => onOpenGame(item?.id, item)}
                                  />
                                  :
                                  <div className='additionalItem'>{item?.name}</div>}
                                </div>
                              )
                            })
                          : slotsGames &&
                            slotsGames[0]?.games?.map((item) => {
                              return (
                                <div className="Slots-content__menu-games__allgames-items-item">
                                  <img
                                    src={item?.ImageURL}
                                    alt=""
                                    onClick={() => onOpenGame(item?.ID, item)}
                                  />
                                </div>
                              )
                            })}
                      </div>

                      <div className="Slots-content__menu-games__allgames-title">
                        <div
                          ref={fishing}
                          className="Slots-content__menu-games__allgames-title-name"
                        >
                          Fishing Games
                        </div>
                        <div
                          className="Slots-content__menu-games__allgames-title-seeAll"
                          onClick={() => {
                            setSeeFishingGames(!seeFishingGames)
                            actions.requestSlotsGamesLobby('52')
                          }}
                        >
                          {seeFishingGames ? 'See less' : 'See all'}
                        </div>
                      </div>
                      <div
                        style={
                          seeFishingGames
                            ? { height: '100%' }
                            : slotsGames && slotsGames[1]?.games?.length < 8
                            ? { height: '100%' }
                            : slotsGames && slotsGames[1]?.games?.length < 1
                            ? { display: 'none' }
                            : {}
                        }
                        className="Slots-content__menu-games__allgames-items"
                      >
                        {!seeFishingGames
                          ? slotsGames &&
                            slotsGames[1]?.games?.map((item) => {
                              return (
                                <div className="Slots-content__menu-games__allgames-items-item">
                                  <img
                                    src={item?.ImageURL}
                                    alt=""
                                    onClick={() => onOpenGame(item?.ID, item)}
                                  />
                                </div>
                              )
                            })
                          : slotsGames && slotsGames?.fishingGames
                          ? slotsGames &&
                            slotsGames?.fishingGames?.map((item) => {
                              return (
                                <div className="Slots-content__menu-games__allgames-items-item">
                                  <img
                                    src={item?.image}
                                    alt=""
                                    onClick={() => onOpenGame(item?.id, item)}
                                  />
                                </div>
                              )
                            })
                          : slotsGames &&
                            slotsGames[1]?.games?.map((item) => {
                              return (
                                <div className="Slots-content__menu-games__allgames-items-item">
                                  <img
                                    src={item?.ImageURL}
                                    alt=""
                                    onClick={() => onOpenGame(item?.ID, item)}
                                  />
                                </div>
                              )
                            })}
                      </div>

                      <div className="Slots-content__menu-games__allgames-title">
                        <div
                          ref={bingo}
                          className="Slots-content__menu-games__allgames-title-name"
                        >
                          Bingo
                        </div>
                        <div
                          className="Slots-content__menu-games__allgames-title-seeAll"
                          onClick={() => {
                            setSeeBingoGames(!seeBingoGames)
                            actions.requestSlotsGamesLobby('53')
                          }}
                        >
                          {seeBingoGames ? 'See less' : 'See all'}
                        </div>
                      </div>
                      <div
                        style={
                          seeBingoGames
                            ? { height: '100%' }
                            : width < 1024 && slotsGames && slotsGames[2]?.games?.length > 4
                            ? {height: '440px'}
                            : slotsGames && slotsGames[2]?.games?.length < 8
                            ? { height: '100%' }
                            : slotsGames && slotsGames[2]?.games?.length < 1
                            ? { display: 'none' }
                            : {}
                        }
                        className="Slots-content__menu-games__allgames-items"
                      >
                        {!seeBingoGames
                          ? slotsGames &&
                            slotsGames[2]?.games?.map((item) => {
                              return (
                                <div className="Slots-content__menu-games__allgames-items-item">
                                  <img
                                    src={item?.ImageURL}
                                    alt=""
                                    onClick={() => onOpenGame(item?.ID, item)}
                                  />
                                </div>
                              )
                            })
                          : slotsGames && slotsGames?.bingoGames
                          ? slotsGames &&
                            slotsGames?.bingoGames?.map((item) => {
                              return (
                                <div className="Slots-content__menu-games__allgames-items-item">
                                  <img
                                    src={item?.image}
                                    alt=""
                                    onClick={() => onOpenGame(item?.id, item)}
                                  />
                                </div>
                              )
                            })
                          : slotsGames &&
                            slotsGames[2]?.games?.map((item) => {
                              return (
                                <div className="Slots-content__menu-games__allgames-items-item">
                                  <img
                                    src={item?.ImageURL}
                                    alt=""
                                    onClick={() => onOpenGame(item?.ID, item)}
                                  />
                                </div>
                              )
                            })}
                      </div>

                      <div
                        ref={multiplayer}
                        className="Slots-content__menu-games__allgames-title">
                        <div className="Slots-content__menu-games__allgames-title-name">
                          Multiplayer
                        </div>
                        <div className="Slots-content__menu-games__allgames-title-seeAll"
                        onClick = {() => {
                          setSeeMultiplayerGames(!seeMultiplayerGames)
                          actions.requestSlotsGamesLobby('54')
                        }}>
                          {seeMultiplayerGames ? 'See less' : 'See all'}
                        </div>
                      </div>
                      <div className="Slots-content__menu-games__allgames-items">
                        {!seeMultiplayerGames ?
                          slotsGames && slotsGames[5]?.games?.map((item) => {
                            return (
                            <div className="Slots-content__menu-games__allgames-items-item">
                              <img src={item?.ImageURL} alt="" onClick = {() => onOpenGame(item?.ID, item)}/>
                            </div>
                            )
                          })
                          :
                          slotsGames && slotsGames?.multiplayerGames?.map((item) => {
                              return (
                              <div className="Slots-content__menu-games__allgames-items-item">
                                <img src={item?.image} alt="" onClick = {() => onOpenGame(item?.id, item)}/>
                              </div>
                              )
                            })}
                      </div>

                      <div className="Slots-content__menu-games__allgames-title">
                        <div
                          ref={virtual}
                          className="Slots-content__menu-games__allgames-title-name"
                        >
                          {' '}
                          Virtual Sports
                        </div>
                        <div
                          className="Slots-content__menu-games__allgames-title-seeAll"
                          onClick={() => {
                            setSeeVirtualGames(!seeVirtualGames)
                            actions.requestSlotsGamesLobby('55')
                          }}
                        >
                          {seeVirtualGames ? 'See less' : 'See all'}
                        </div>
                      </div>
                      <div
                        style={
                          seeVirtualGames
                            ? { height: '100%' }
                            : slotsGames && slotsGames[3]?.games?.length < 8
                            ? { height: '100%' }
                            : {}
                        }
                        className="Slots-content__menu-games__allgames-items"
                      >
                        {!seeVirtualGames
                          ? slotsGames &&
                            slotsGames[3]?.games?.map((item) => {
                              return (
                                <div
                                  className="Slots-content__menu-games__allgames-items-item"
                                  onClick={() => onOpenGame(item?.ID, item)}
                                >
                                  <img src={item?.ImageURL} alt="" />
                                </div>
                              )
                            })
                          : slotsGames && slotsGames?.virtaulGames
                          ? slotsGames &&
                            slotsGames?.virtualGames?.map((item) => {
                              return (
                                <div
                                  className="Slots-content__menu-games__allgames-items-item"
                                  onClick={() => onOpenGame(item?.id, item)}
                                >
                                  <img src={item?.image} alt="" />
                                </div>
                              )
                            })
                          : slotsGames &&
                            slotsGames[3]?.games?.map((item) => {
                              return (
                                <div
                                  className="Slots-content__menu-games__allgames-items-item"
                                  onClick={() => onOpenGame(item?.ID, item)}
                                >
                                  <img src={item?.ImageURL} alt="" />
                                </div>
                              )
                            })}
                      </div>

                      <div className="Slots-content__menu-games__allgames-title">
                        <div
                          ref={scratch}
                          className="Slots-content__menu-games__allgames-title-name"
                        >
                          Scratch Cards
                        </div>
                        <div
                          className="Slots-content__menu-games__allgames-title-seeAll"
                          onClick={() => {
                            setSeeScratchGames(!seeScratchGames)
                            actions.requestSlotsGamesLobby('56')
                          }}
                        >
                          {seeScratchGames ? 'See less' : 'See all'}
                        </div>
                      </div>
                      <div
                        // style={seeScratchGames
                        //   ? {height:'100%'}
                        //   : slotsGames && slotsGames[4]?.games?.length < 8
                        //   ? {height: '100%'}
                        //   : {}}
                        className="Slots-content__menu-games__allgames-items"
                      >
                        {!seeScratchGames
                          ? slotsGames &&
                            slotsGames[4]?.games?.map((item) => {
                              return (
                                <div
                                  className="Slots-content__menu-games__allgames-items-item"
                                  onClick={() => onOpenGame(item?.ID, item)}
                                >
                                  <img src={item?.ImageURL} alt="" />
                                </div>
                              )
                            })
                          : slotsGames && slotsGames?.scratchGames
                          ? slotsGames &&
                            slotsGames?.scratchGames?.map((item) => {
                              return (
                                <div
                                  className="Slots-content__menu-games__allgames-items-item"
                                  onClick={() => onOpenGame(item?.id, item)}
                                >
                                  <img src={item?.image} alt="" />
                                </div>
                              )
                            })
                          : slotsGames &&
                            slotsGames[4]?.games?.map((item) => {
                              return (
                                <div
                                  className="Slots-content__menu-games__allgames-items-item"
                                  onClick={() => onOpenGame(item?.ID, item)}
                                >
                                  <img src={item?.ImageURL} alt="" />
                                </div>
                              )
                            })}
                      </div> */}
                    </>
                  ) : (
                    <>
                      <div className="Slots-content__menu-games__allgames-title">
                        <div className="Slots-content__menu-games__allgames-title-name">
                          Games
                        </div>
                      </div>
                      <div
                        style={{
                          height: '100%',
                          marginBottom: '150px',
                          flexDirection: 'row',
                          justifyContent: 'space-around',
                        }}
                        className="Slots-content__menu-games__allgames-items"
                      >
                        {slotsGamesSearch &&
                          slotsGamesSearch?.map((item) => {
                            return (
                              <div
                                className="Slots-content__menu-games__allgames-items-item"
                                onClick={() => onOpenGame(item?.id, item)}
                                style={{ position: 'relative' }}
                              >
                                <img src={item?.image} alt="" />
                                <div className="img-gamename-slotsWithSearch">
                                  {item?.name}
                                </div>
                              </div>
                            )
                          })}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            {/* <div style={{marginTop: '100px'}}> */}
            {width > 1024 && <FooterDesktop />}
            {/* </div> */}
          </div>
        </div>
        {/*{width < 1024 && <FooterDesktop />}*/}
      </IonContent>
    </IonPage>
  )
}

export default connectSlotsGamesLobby()(SlotsGamesLobby)
