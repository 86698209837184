import React from 'react'
import { useEffect, useState } from 'react'
import './OpenBetsItem.scss'
import { tsToDate } from '../../helpers/date.helper'
import { useHistory } from 'react-router-dom'
import { getFormattedDate } from '../../helpers/date.helper'
import { useDispatch, useSelector } from 'react-redux'
import { setInfo } from '../../store/openBets/openBets.slice'

const OpenBetsItem = ({
  timeSettings,
  bet,
  isEvent,
  isUnmatched = false,
  cancel,
  redirect = false,
}) => {
  const history = useHistory()

  const setInfoDetailed = useSelector((state) => state.openBetsList.detailed)
  const checkStateWidth = useSelector(
    (state) => state.elementsParams.clientWidth
  )
  const width = checkStateWidth ? checkStateWidth : window.innerWidth

  const getPriseFor_SB = (marketId, price) => {
    return marketId && marketId.slice(marketId.length - 3) === '_SB'
      ? ((price - 1) * 100).toFixed(2).replace('.00', '')
      : price
  }

  const getTitle = () => {
    const isBack = bet.is_back
    const isFancy =
      bet.marketType == 'INNINGS_RUNS' && bet.marketBettingType == 'LINE'
    const text = isFancy
      ? `${bet.line} runs - ${isBack ? 'Yes' : 'No'}`
      : isBack
      ? 'Back'
      : 'Lay'
    const profit = bet.p_l.toFixed(2)
    //TODO: maybe change averagePrice to price ?
    const averagePrice = getPriseFor_SB(bet.marketId, bet.averagePrice)
    // return <div className={`OpenBetsItem__title ${isBack ? '' : 'lay'}`}>{text} {!isFancy && bet.selectionName} for {bet.orderStatus === 'matched' ? bet.sizeMatched : bet.sizeRemaining} @ {averagePrice} {isBack ? 'to win' : 'liability'} {profit}.</div>
    return (
      <div className={`OpenBetsItem__title ${isBack ? '' : 'lay'}`}>
        {text} {!isFancy && bet.selection_name} for {bet.stack.toFixed(2)} @{' '}
        {bet.odds} {isBack ? 'to win' : 'liability'} {profit}.
      </div>
    )
  }

  const getName = () => {
    if (bet.match_name.indexOf('${HH:mm}') != -1) {
      const formattedOpenDate = getFormattedDate(
        bet.createdAt,
        timeSettings.timeZoneName
      )
      return bet.match_name.replace(/\${HH:mm}/, formattedOpenDate.time)
    } else {
      return bet.match_name
    }
  }
  return width > 1024 ? (
    <div
      className="OpenBetsItem"
      onClick={() => {
        if (redirect) {
          localStorage.setItem('Event', JSON.stringify(bet))
          history.push(`/event-page/${bet.sport_id}/${bet.match_id}`)
        }
      }}
    >
      {getTitle()}
      <div className="OpenBetsItem__matchName">{bet.match_name}</div>
      <div className="OpenBetsItem__info">
        {!isEvent && (
          <div>
            <strong>{getName()}</strong>
          </div>
        )}
        <div>
          <strong>{bet.match_name}</strong>
        </div>
        {setInfoDetailed && (
          <div>
            <strong>Placed</strong>:{' '}
            {tsToDate(bet.createdAt, timeSettings.timeZoneName)}
          </div>
        )}
      </div>
      {isUnmatched && (
        <button
          className="OpenBetsItem__button"
          onClick={() => cancel(bet.bet_id)}
        >
          Cancel
        </button>
      )}
    </div>
  ) : (
    <div
      className="OpenBetsItem"
      onClick={() => {
        if (redirect) {
          localStorage.setItem('Event', JSON.stringify(bet))
          let matchId = bet.match_id
          if (bet.sport_id == 7 || bet.sport_id == 4339) matchId = bet.market_id
          history.push(`/event-page/${bet.sport_id}/${matchId}`)
        }
      }}
    >
      {getTitle()}
      <div className="OpenBetsItem__info">
        {!isEvent && (
          <div>
            <strong>{getName()}</strong>
          </div>
        )}
        <div>
          <strong>{bet.market_name}</strong>
        </div>
        <div>
          <strong>Placed</strong>:{' '}
          {tsToDate(bet.createdAt, timeSettings.timeZoneName)}
        </div>
      </div>
      {isUnmatched && (
        <button
          className="OpenBetsItem__button"
          onClick={() => cancel(bet.bet_id)}
        >
          Cancel
        </button>
      )}
    </div>
  )
}

export default OpenBetsItem
