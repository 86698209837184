import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { 
        requestSaveOneClickSettings,
        requestSaveMobileStakesExchangeGameSettings,
        requestSaveMobileStakesSettings,
        requestSaveStakesSettings
      } from '../../../store/user/user.thunks';
import { setIsOpen } from '../../../store/editStakesPopUp/editStakesPopUp.slice';
import { requestStack } from '../../../store/auth/auth.thunks';

export const connectEditStakesPopUp = (wrappedProps) => (component) => {
  function mapStateToProps(state) {
    return {
      user: state.user,
      balanceInformationHeight: state.balanceInformationHeight,
      isDetailedBalanceOpen: state.isDetailedBalanceOpen
    }
  }

  function mapDispatchToProps(dispatch) {
    const actions = {
      requestSaveOneClickSettings,
      requestSaveMobileStakesExchangeGameSettings,
      requestSaveMobileStakesSettings,
      requestSaveStakesSettings,
      requestStack,
      setIsOpen,
    }

    return { actions: bindActionCreators(actions, dispatch) }
  }

  return connect(mapStateToProps, mapDispatchToProps)(component)
}
