import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { requestChangePassword } from '../../store/user/user.thunks'
import { setIsNavigationOpen, setIsNavigationID, setIsNavigationName, setIsAnimatedMenu, setIsMoreDetailed, setIsDetailedGroup, setIsDetailedGroupName, setIsOpenSport, setIsEventDetailed, setRenderIonContent, setSportsName} from '../../store/navigationHeaderMenu/navigationHeaderMenu.slice';
import { setLoginNew } from '../../store/LoginNew/loginNew.slice'
import { requestSportData } from '../../store/sportEvent/sportEvent.thunks';
import { setSlotCasinoPopUp } from '../../store/slotCasinoPopUp/slotCasinoPopUp.slice';
import { requestMenu } from '../../store/menu/menu.thunks';
import { getMenuSuccess } from '../../store/menu/menu.slice'
import { requestCompetition } from '../../store/competitions/competitions.thunks';
import { requestCasinoPoint } from '../../store/liveCasino/liveCasino.thunks';

export const connectLeftSideMenuDesktop = (wrappedProps) => (component) => {
  function mapStateToProps(state) {
    return {
      menu: state.menu,
      timeSettings: state.timeSettings,
      auth: state.auth,
      sportPageEvent: state.sportPageEvent,
      sportItemData: state.sportItemData,
      moreDetailed: state.navigationHeaderMenu.moreDetailed,
      eventDetailed: state.navigationHeaderMenu.eventDetailed,
      sportsName: state.navigationHeaderMenu.sportsName,
      slotCasinoPopUp: state.slotCasinoPopUp,
      competition: state.competition,
    }
  }

  function mapDispatchToProps(dispatch) {
    const actions = {
      requestSportData,
      setIsNavigationOpen,
      setIsNavigationID,
      setIsNavigationName,
      setIsAnimatedMenu,
      setIsMoreDetailed,
      setIsDetailedGroup,
      setIsDetailedGroupName,
      setLoginNew,
      setIsOpenSport,
      setIsEventDetailed,
      setRenderIonContent,
      setSportsName,
      setSlotCasinoPopUp,
      requestMenu,
      getMenuSuccess,
      requestCompetition,
      requestCasinoPoint
    }

    return { actions: bindActionCreators(actions, dispatch) }
  }

  return connect(mapStateToProps, mapDispatchToProps)(component)
}
