 import httpAuth from '../../services/httpAuth'
import {
    getProfileSuccess,
    getProfileError
} from './profile.slice'
import { toastr } from "react-redux-toastr";

export const requestProfile = () => async (dispatch, getState) => {

    try {
        // twitter-config - in another reqest if news tab opened
        const userId = getState().user.memberCode;

        if(userId) {
          return httpAuth.get(`https://dev.gemexch.co/api/account/${userId}/profile/get`)
                .then(res => {
                    console.log("🚀 ~ file: profile.thunks.js:16 ~ requestProfile ~ res:", res)
                    if (res.data) {
                        dispatch(getProfileSuccess(res.data))
                    }
                    return res.data
                })
        }
    } catch (e) {
        dispatch(getProfileError())
    }
}

export const requestMailVerification = (email) => async (dispatch, getState) => {

    try {
        const userId = getState().user.memberCode;

        if(userId) {
          await httpAuth.post(`/account/${userId}/profile/StartMailVerification`,  { email: email })
                .then(res => {
                  if (res.data) {
                      console.log('res#@#@', res.data);
                      return res.data
                        // console.log(res.data);
                    }
                })
        }
    } catch (e) {
        console.log('E', e);
        // dispatch(getProfileError())
    }
}

export const requestMobileVerification = (countryCode, mobile) => async (dispatch, getState) => {
    try {
        const userId = getState().user.memberCode;

        if(userId) {
         return httpAuth.post(`https://dev.gemexch.co/api/account/${userId}/profile/StartMobileVerification`,  { mobile: mobile, countryCode: countryCode })
                .then(res => {
                    console.log('res', res.data);
                    if (res?.data.success) {
                      toastr.success('', `${res?.data?.result}`)
                      return true
                    } else {
                      toastr.error('', `${res?.data.status.statusDesc}`)
                      return false
                    }
                }).catch(e => {
                  toastr.error('', `${e?.status?.statusDesc}`)
                }
            )
        }
    } catch (e) {
        console.log('!!!!!!!!!!! E', e);
    }
}

export const requestOTPVerification = (countryCode, mobile, otp) => async (dispatch, getState) => {
  try {
    const userId = getState().user.memberCode;
    const data = {
      "mobile": mobile,
      "countryCode": countryCode,
      "OTP": otp
    }

    if(userId) {
      return httpAuth.post(`https://dev.gemexch.co/api/account/${userId}/profile/verify/mobile`,  data)
        .then(res => {
          if (res?.data.success) {
            const msg = res?.data?.status?.statusDesc
            toastr.success('', msg?.includes("Otp") ? "OTP Verified": msg)
            return true
          } else {
            toastr.error('', `${res?.data?.error}`)
            return false
          }
        }).catch(e => {
            console.log("eeee", e);
            toastr.error('', `${e?.status?.statusDesc}`)
          }
        )
    }
  } catch (e) {
    console.log('E', e);
  }
}

export const updateProfile = (name, gender, birth) => async (dispatch, getState) => {
  try {
    const userId = getState().user.memberCode

    if (userId) {
      return httpAuth
        .post(`/account/${userId}/profile/update`, {
            "full_name": name,
            "gender": gender,
            "date_of_birth": birth
        })
        .then((res) => {
          console.log('res', res.data)
          if (res.data) {
            console.log(res.data)
          }
        })
    }
  } catch (e) {
    console.log('E', e)
  }
}

export const confirmMailVerification =
  (userId, verifyToken) => async (dispatch) => {
    try {
        return httpAuth
          .get(`/profile/verify/email/${userId}/${verifyToken}`)
          .then(res => {
            console.log('res', res.data, res.data.status, res.data.success);
            if (res?.data.success) {
              toastr.success('', `${res?.data?.status?.statusDesc}`)
              return true
            } else {
              toastr.error('', `${res?.data.status.statusDesc}`)
              return false
            }
        }).catch(e => {
          console.log("eeee", e);
          toastr.error('', `${e?.status?.statusDesc}`)
        })
    } catch (e) {
      console.log('E', e)
    }
  }