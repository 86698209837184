import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { resetSearchResults, setSearchValue } from '../../store/search/search.slice';
import { requestSearchResults, requestSearchList } from '../../store/search/search.thunks';
import { setNotifications, requestSaveOneClickSettings } from '../../store/user/user.thunks';
import { setLoginNew, setTab } from '../../store/LoginNew/loginNew.slice'
import { setOpenSearch } from '../../store/search/search.slice';
import { requestMessage } from "../../store/message/message.thunks";
import { requestLogo } from '../../store/logo/logo.thunks';

export const connectToolbar = (wrappedProps) => (component) => {
  function mapStateToProps(state) {
    return {
      balance: state.balance,
      messages: state.message,
      notifications: state.user.settings.notifications,
      oneClick: state.user.settings.oneClickSettings,
      user: state.user,
      B2C: state.B2C,
      auth: state.auth,
      openSearch: state.search.openSearch,
      menu: state.menu,
      searchInput: state.search,
      searchList: state.search.list,
      logo: state.logo,
    }
  }

  function mapDispatchToProps(dispatch) {
    const actions = {
      requestSaveOneClickSettings,
      requestSearchResults,
      setSearchValue,
      resetSearchResults,
      setNotifications,
      setLoginNew,
      setTab,
      setOpenSearch,
      requestMessage,
      requestSearchList,
      requestLogo,
    }

    return { actions: bindActionCreators(actions, dispatch) }
  }

  return connect(mapStateToProps, mapDispatchToProps)(component)
}
