import React, { useEffect, useState } from 'react'
// import { IonItem } from "@ionic/react";
import './SportEvent.scss'
import { connectSportEvent } from './SportEvent.connect'
import SportMarketStandard from '../../components/SportMarketStandard/SportMarketStandard'
import SportMarketTabs from '../../components/SportMarketTabs/SportMarketTabs'
import LadderPopover from '../../components/LadderPopover/LadderPopover'

import RulesPopUpSportEvents from '../ExchangeGame/helpers/Rules/RulesPopUpSportEvents'

const SportEvent = ({
  sportData,
  sportDataFancy,
  ladderShow,
  betPlacing,
  rulesPopUp,
  actions,
  elementsParams
}) => {
  
  const renderMarketGroupsFancy = () => {
    let groups = sportDataFancy?.data
    if (Array.isArray(sportDataFancy?.data))
      // sportData.forEach((item) => {
      //   if (!item.hidden) {
      //     if (!item.tabGroupId) groups.push(item)
      //     else {
      //       const tabGroupIdIndex = groups.findIndex(
      //         (el) => el.tabGroupId === item.tabGroupId
      //       )
      //       if (tabGroupIdIndex === -1) {
      //         const newTabGroup = {
      //           tabGroupId: item.tabGroupId,
      //           tabs: {
      //             [`${item.tabGroupIndex}`]: {
      //               name: item.tabGroupName,
      //               items: [item],
      //             },
      //           },
      //         }
      //         groups.push(newTabGroup)
      //       } else {
      //         if (groups[tabGroupIdIndex].tabs[`${item.tabGroupIndex}`])
      //           groups[tabGroupIdIndex].tabs[
      //             `${item.tabGroupIndex}`
      //           ].items.push(item)
      //         else
      //           groups[tabGroupIdIndex].tabs[`${item.tabGroupIndex}`] = {
      //             name: item.tabGroupName,
      //             items: [item],
      //           }
      //       }
      //     }
      //   }
      // })
      // if(groups?.length && groups.filter(item => item.tabGroupId !== 0).length){
      //   groups = [...groups.filter(item => item.tabGroupId === 0), groups.filter(item => item.tabGroupId !== 0)[0]]
      // }
    if (groups.length === 0)
      return <div className="SportEvent__message">No market data available</div>
    return ( groups?.length &&
      <SportMarketTabs
          data={groups}
          key={groups}
          betPlacing={betPlacing}
          categories={sportDataFancy?.fancy_category}
      />
    )
  }
  const renderMarketGroups = () => {
    let groups = sportData
    if (Array.isArray(sportData))
      // sportData.forEach((item) => {
      //   if (!item.hidden) {
      //     if (!item.tabGroupId) groups.push(item)
      //     else {
      //       const tabGroupIdIndex = groups.findIndex(
      //         (el) => el.tabGroupId === item.tabGroupId
      //       )
      //       if (tabGroupIdIndex === -1) {
      //         const newTabGroup = {
      //           tabGroupId: item.tabGroupId,
      //           tabs: {
      //             [`${item.tabGroupIndex}`]: {
      //               name: item.tabGroupName,
      //               items: [item],
      //             },
      //           },
      //         }
      //         groups.push(newTabGroup)
      //       } else {
      //         if (groups[tabGroupIdIndex].tabs[`${item.tabGroupIndex}`])
      //           groups[tabGroupIdIndex].tabs[
      //             `${item.tabGroupIndex}`
      //           ].items.push(item)
      //         else
      //           groups[tabGroupIdIndex].tabs[`${item.tabGroupIndex}`] = {
      //             name: item.tabGroupName,
      //             items: [item],
      //           }
      //       }
      //     }
      //   }
      // })
      // if(groups?.length && groups.filter(item => item.tabGroupId !== 0).length){
      //   groups = [...groups.filter(item => item.tabGroupId === 0), groups.filter(item => item.tabGroupId !== 0)[0]]
      // }
    if (groups.length === 0)
      return <div className="SportEvent__message">No market data available</div>
    return groups?.map((item) =>{
      return item.tabGroupId ? (
        <SportMarketTabs
          data={item}
          key={`TGID${item.tabGroupId}`}
          betPlacing={betPlacing}
        />
      ) : (
        <SportMarketStandard
          data={item}
          key={item.id}
          betPlacing={betPlacing}
        />
      )
  })
  }

  const width = elementsParams && elementsParams?.clientWidth ? elementsParams?.clientWidth : window.innerWidth

  return (
    <div className="SportEvent">
      {width > 1024 ? (
        <div className={`favourites-header__rules-button-wrapper`}>
          <div
            className={`favourites-header__rules-button`}
            onClick={() => {
              actions.setRulesPopUp(true)
            }}
          >
            <div className={`favourites-header__rules-button-icon`}>
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7 0C3.1402 0 0 3.14024 0 7.00004C0 10.8598 3.1402 14 7 14C10.8598 14 14 10.8598 14 7.00004C14 3.14024 10.8598 0 7 0ZM7 12.7273C3.84194 12.7273 1.27273 10.1581 1.27273 7.00004C1.27273 3.84202 3.84194 1.27273 7 1.27273C10.1581 1.27273 12.7273 3.84202 12.7273 7.00004C12.7273 10.1581 10.158 12.7273 7 12.7273Z"
                  fill="black"
                />
                <path
                  d="M7.00017 2.96875C6.5324 2.96875 6.15186 3.34955 6.15186 3.81762C6.15186 4.28526 6.5324 4.66572 7.00017 4.66572C7.46794 4.66572 7.84849 4.28526 7.84849 3.81762C7.84849 3.34955 7.46794 2.96875 7.00017 2.96875Z"
                  fill="black"
                />
                <path
                  d="M7.00013 5.93945C6.64869 5.93945 6.36377 6.22437 6.36377 6.57582V10.394C6.36377 10.7454 6.64869 11.0304 7.00013 11.0304C7.35158 11.0304 7.6365 10.7454 7.6365 10.394V6.57582C7.6365 6.22437 7.35158 5.93945 7.00013 5.93945Z"
                  fill="black"
                />
              </svg>
            </div>
            {width > 1024 ? (
              <span className={`favourites-header__rules-button-text`}>
                Rules
              </span>
            ) : null}
          </div>
          {rulesPopUp && <RulesPopUpSportEvents />}
        </div>
      ) : null}

      {renderMarketGroups()}
      {renderMarketGroupsFancy()}
      <LadderPopover open={ladderShow} />
    </div>
  )
}
export default connectSportEvent()(SportEvent)
