export const CasinoProviderDATA = [
    {
        "eventTypeId": "77777",
        "eventType": "LIVE_CASINO",
        "isInPlay": true,
        "event": {
            "id": "72",
            "name": "Mega Ball",
            "openDate": "2019-11-16T17:00:00Z",
            "matchStatsEnabled": true,
            "videoEnabled": true
        },
        "market": {
            "id": "1.rngmegaball",
            "groupById": "77777",
            "name": "Match odds",
            "aussieExchange": false,
            "exchangeId": "1",
            "start": 1584444174239,
            "btype": "CASINO",
            "mtype": "MATCH_ODDS",
            "stype": null,
            "event": {
                "id": "77777",
                "name": "RNG Mega Ball",
                "openDate": "2020-03-17T11:22:54.239Z",
                "venue": "England",
                "countryCode": "GB",
                "countryCodeISO2": "",
                "altName": "RNG Mega Ball"
            },
            "eventTypeId": "77777",
            "inPlay": true,
            "competition": {
                "id": "99999999",
                "name": "LIVE_CASINO"
            },
            "matched": 1882376,
            "numWinners": 1,
            "numRunners": 2,
            "numActiveRunners": 2,
            "status": "OPEN",
            "statusLabel": "plain",
            "lastUpdateTime": 1584444338937,
            "oddsType": null,
            "providerId": 2,
            "maxLiabilityPerBet": null,
            "maxLiabilityPerMarket": null,
            "betDelay": 2,
            "hidden": false,
            "isBettable": false,
            "bettableTime": null,
            "consolidatedRunner": {
                "back": [
                    null,
                    null,
                    null
                ],
                "lay": [
                    null,
                    null,
                    null
                ]
            }
        },
        "scores": null,
        "players": [
            {
                "name": "💎 Real Teenpatti 💎"
            }
        ],
        "matchStatsEnabled": true,
        "videoEnabled": true,
        "hasVideo": false,
        "id": 'EVO',
        "image": "./assets/images/dashCasino/jilib.png"
    },
    {
        "eventTypeId": "77777",
        "eventType": "LIVE_CASINO",
        "isInPlay": true,
        "event": {
            "id": "1444000",
            "name": "Mega Ball",
            "openDate": "2019-11-16T17:00:00Z",
            "matchStatsEnabled": true,
            "videoEnabled": true
        },
        "market": {
            "id": "1.rngmegaball",
            "groupById": "77777",
            "name": "Match odds",
            "aussieExchange": false,
            "exchangeId": "1",
            "start": 1584444174239,
            "btype": "CASINO",
            "mtype": "MATCH_ODDS",
            "stype": null,
            "event": {
                "id": "77777",
                "name": "RNG Mega Ball",
                "openDate": "2020-03-17T11:22:54.239Z",
                "venue": "England",
                "countryCode": "GB",
                "countryCodeISO2": "",
                "altName": "RNG Mega Ball"
            },
            "eventTypeId": "77777",
            "inPlay": true,
            "competition": {
                "id": "99999999",
                "name": "LIVE_CASINO"
            },
            "matched": 1882376,
            "numWinners": 1,
            "numRunners": 2,
            "numActiveRunners": 2,
            "status": "OPEN",
            "statusLabel": "plain",
            "lastUpdateTime": 1584444338937,
            "oddsType": null,
            "providerId": 2,
            "maxLiabilityPerBet": null,
            "maxLiabilityPerMarket": null,
            "betDelay": 2,
            "hidden": false,
            "isBettable": false,
            "bettableTime": null,
            "consolidatedRunner": {
                "back": [
                    null,
                    null,
                    null
                ],
                "lay": [
                    null,
                    null,
                    null
                ]
            }
        },
        "scores": null,
        "players": [
            {
                "name": "💎 CASINO LOBBY 💎"
            }
        ],
        "matchStatsEnabled": true,
        "videoEnabled": true,
        "hasVideo": false,
        "id": 'CQC',
        "image": "./assets/images/dashCasino/casinob.png"
    },
    {
        "eventTypeId": "77777",
        "eventType": "LIVE_CASINO",
        "isInPlay": true,
        "event": {
            "id": "EZUGI-lobby",
            "name": "Mega Ball",
            "openDate": "2019-11-16T17:00:00Z",
            "matchStatsEnabled": true,
            "videoEnabled": true
        },
        "market": {
            "id": "1.rngmegaball",
            "groupById": "77777",
            "name": "Match odds",
            "aussieExchange": false,
            "exchangeId": "1",
            "start": 1584444174239,
            "btype": "CASINO",
            "mtype": "MATCH_ODDS",
            "stype": null,
            "event": {
                "id": "77777",
                "name": "RNG Mega Ball",
                "openDate": "2020-03-17T11:22:54.239Z",
                "venue": "England",
                "countryCode": "GB",
                "countryCodeISO2": "",
                "altName": "RNG Mega Ball"
            },
            "eventTypeId": "77777",
            "inPlay": true,
            "competition": {
                "id": "99999999",
                "name": "LIVE_CASINO"
            },
            "matched": 1882376,
            "numWinners": 1,
            "numRunners": 2,
            "numActiveRunners": 2,
            "status": "OPEN",
            "statusLabel": "plain",
            "lastUpdateTime": 1584444338937,
            "oddsType": null,
            "providerId": 2,
            "maxLiabilityPerBet": null,
            "maxLiabilityPerMarket": null,
            "betDelay": 2,
            "hidden": false,
            "isBettable": false,
            "bettableTime": null,
            "consolidatedRunner": {
                "back": [
                    null,
                    null,
                    null
                ],
                "lay": [
                    null,
                    null,
                    null
                ]
            }
        },
        "scores": null,
        "players": [
            {
                "name": "💎 Ezugi 💎"
            }
        ],
        "matchStatsEnabled": true,
        "videoEnabled": true,
        "hasVideo": false,
        "id": 'EZU',
        "image": "./assets/images/dashCasino/ezugib.png"
    },
    {
        "eventTypeId": "77777",
        "eventType": "LIVE_CASINO",
        "isInPlay": true,
        "event": {
            "id": "betgames",
            "name": "Mega Ball",
            "openDate": "2019-11-16T17:00:00Z",
            "matchStatsEnabled": true,
            "videoEnabled": true
        },
        "market": {
            "id": "1.rngmegaball",
            "groupById": "77777",
            "name": "Match odds",
            "aussieExchange": false,
            "exchangeId": "1",
            "start": 1584444174239,
            "btype": "CASINO",
            "mtype": "MATCH_ODDS",
            "stype": null,
            "event": {
                "id": "77777",
                "name": "RNG Mega Ball",
                "openDate": "2020-03-17T11:22:54.239Z",
                "venue": "England",
                "countryCode": "GB",
                "countryCodeISO2": "",
                "altName": "RNG Mega Ball"
            },
            "eventTypeId": "77777",
            "inPlay": true,
            "competition": {
                "id": "99999999",
                "name": "LIVE_CASINO"
            },
            "matched": 1882376,
            "numWinners": 1,
            "numRunners": 2,
            "numActiveRunners": 2,
            "status": "OPEN",
            "statusLabel": "plain",
            "lastUpdateTime": 1584444338937,
            "oddsType": null,
            "providerId": 2,
            "maxLiabilityPerBet": null,
            "maxLiabilityPerMarket": null,
            "betDelay": 2,
            "hidden": false,
            "isBettable": false,
            "bettableTime": null,
            "consolidatedRunner": {
                "back": [
                    null,
                    null,
                    null
                ],
                "lay": [
                    null,
                    null,
                    null
                ]
            }
        },
        "scores": null,
        "players": [
            {
                "name": "💎 Betgames.TV 💎"
            }
        ],
        "matchStatsEnabled": true,
        "videoEnabled": true,
        "hasVideo": false,
        "id": 'BTV',
        "image": "./assets/images/dashCasino/betgamesb.png"
    },
    {
        "eventTypeId": "77777",
        "eventType": "LIVE_CASINO",
        "isInPlay": true,
        "event": {
            "id": "livegames_lobby",
            "name": "Mega Ball",
            "openDate": "2019-11-16T17:00:00Z",
            "matchStatsEnabled": true,
            "videoEnabled": true
        },
        "market": {
            "id": "1.rngmegaball",
            "groupById": "77777",
            "name": "Match odds",
            "aussieExchange": false,
            "exchangeId": "1",
            "start": 1584444174239,
            "btype": "CASINO",
            "mtype": "MATCH_ODDS",
            "stype": null,
            "event": {
                "id": "77777",
                "name": "RNG Mega Ball",
                "openDate": "2020-03-17T11:22:54.239Z",
                "venue": "England",
                "countryCode": "GB",
                "countryCodeISO2": "",
                "altName": "RNG Mega Ball"
            },
            "eventTypeId": "77777",
            "inPlay": true,
            "competition": {
                "id": "99999999",
                "name": "LIVE_CASINO"
            },
            "matched": 1882376,
            "numWinners": 1,
            "numRunners": 2,
            "numActiveRunners": 2,
            "status": "OPEN",
            "statusLabel": "plain",
            "lastUpdateTime": 1584444338937,
            "oddsType": null,
            "providerId": 2,
            "maxLiabilityPerBet": null,
            "maxLiabilityPerMarket": null,
            "betDelay": 2,
            "hidden": false,
            "isBettable": false,
            "bettableTime": null,
            "consolidatedRunner": {
                "back": [
                    null,
                    null,
                    null
                ],
                "lay": [
                    null,
                    null,
                    null
                ]
            }
        },
        "scores": null,
        "players": [
            {
                "name": "💎 Livegames.IO 💎"
            }
        ],
        "matchStatsEnabled": true,
        "videoEnabled": true,
        "hasVideo": false,
        "id": 'SWL',
        "image": "./assets/images/dashCasino/liveb.png"
    },
    {
        "eventTypeId": "77777",
        "eventType": "LOBBY",
        "isInPlay": true,
        "event": {
            "id": "39",
            "name": "Mega Ball",
            "openDate": "2019-11-16T17:00:00Z",
            "matchStatsEnabled": true,
            "videoEnabled": true
        },
        "market": {
            "id": "1.rngmegaball",
            "groupById": "77777",
            "name": "Match odds",
            "aussieExchange": false,
            "exchangeId": "1",
            "start": 1584444174239,
            "btype": "CASINO",
            "mtype": "MATCH_ODDS",
            "stype": null,
            "event": {
                "id": "77777",
                "name": "RNG Mega Ball",
                "openDate": "2020-03-17T11:22:54.239Z",
                "venue": "England",
                "countryCode": "GB",
                "countryCodeISO2": "",
                "altName": "RNG Mega Ball"
            },
            "eventTypeId": "77777",
            "inPlay": true,
            "competition": {
                "id": "99999999",
                "name": "LIVE_CASINO"
            },
            "matched": 1882376,
            "numWinners": 1,
            "numRunners": 2,
            "numActiveRunners": 2,
            "status": "OPEN",
            "statusLabel": "plain",
            "lastUpdateTime": 1584444338937,
            "oddsType": null,
            "providerId": 2,
            "maxLiabilityPerBet": null,
            "maxLiabilityPerMarket": null,
            "betDelay": 2,
            "hidden": false,
            "isBettable": false,
            "bettableTime": null,
            "consolidatedRunner": {
                "back": [
                    null,
                    null,
                    null
                ],
                "lay": [
                    null,
                    null,
                    null
                ]
            }
        },
        "scores": null,
        "players": [
            {
                "name": "💎 Virtual Sports 💎"
            }
        ],
        "matchStatsEnabled": true,
        "videoEnabled": true,
        "hasVideo": false,
        "id": 'PPV',
        "image": "./assets/images/dashCasino/virtualb.png"
    },
    {
        "eventTypeId": "77777",
        "eventType": "LOBBY",
        "isInPlay": true,
        "event": {
            "id": "5",
            "name": "Mega Ball",
            "openDate": "2019-11-16T17:00:00Z",
            "matchStatsEnabled": true,
            "videoEnabled": true
        },
        "market": {
            "id": "1.rngmegaball",
            "groupById": "77777",
            "name": "Match odds",
            "aussieExchange": false,
            "exchangeId": "1",
            "start": 1584444174239,
            "btype": "CASINO",
            "mtype": "MATCH_ODDS",
            "stype": null,
            "event": {
                "id": "77777",
                "name": "RNG Mega Ball",
                "openDate": "2020-03-17T11:22:54.239Z",
                "venue": "England",
                "countryCode": "GB",
                "countryCodeISO2": "",
                "altName": "RNG Mega Ball"
            },
            "eventTypeId": "77777",
            "inPlay": true,
            "competition": {
                "id": "99999999",
                "name": "LIVE_CASINO"
            },
            "matched": 1882376,
            "numWinners": 1,
            "numRunners": 2,
            "numActiveRunners": 2,
            "status": "OPEN",
            "statusLabel": "plain",
            "lastUpdateTime": 1584444338937,
            "oddsType": null,
            "providerId": 2,
            "maxLiabilityPerBet": null,
            "maxLiabilityPerMarket": null,
            "betDelay": 2,
            "hidden": false,
            "isBettable": false,
            "bettableTime": null,
            "consolidatedRunner": {
                "back": [
                    null,
                    null,
                    null
                ],
                "lay": [
                    null,
                    null,
                    null
                ]
            }
        },
        "scores": null,
        "players": [
            {
                "name": "💎 Fishing Games 💎"
            }
        ],
        "matchStatsEnabled": true,
        "videoEnabled": true,
        "hasVideo": false,
        "id": 'GFG',
        "image": "./assets/images/dashCasino/fishingb.png"
    },
    {
        "eventTypeId": "77777",
        "eventType": "LOBBY",
        "isInPlay": true,
        "event": {
            "id": "7",
            "name": "Mega Ball",
            "openDate": "2019-11-16T17:00:00Z",
            "matchStatsEnabled": true,
            "videoEnabled": true
        },
        "market": {
            "id": "1.rngmegaball",
            "groupById": "77777",
            "name": "Match odds",
            "aussieExchange": false,
            "exchangeId": "1",
            "start": 1584444174239,
            "btype": "CASINO",
            "mtype": "MATCH_ODDS",
            "stype": null,
            "event": {
                "id": "77777",
                "name": "RNG Mega Ball",
                "openDate": "2020-03-17T11:22:54.239Z",
                "venue": "England",
                "countryCode": "GB",
                "countryCodeISO2": "",
                "altName": "RNG Mega Ball"
            },
            "eventTypeId": "77777",
            "inPlay": true,
            "competition": {
                "id": "99999999",
                "name": "LIVE_CASINO"
            },
            "matched": 1882376,
            "numWinners": 1,
            "numRunners": 2,
            "numActiveRunners": 2,
            "status": "OPEN",
            "statusLabel": "plain",
            "lastUpdateTime": 1584444338937,
            "oddsType": null,
            "providerId": 2,
            "maxLiabilityPerBet": null,
            "maxLiabilityPerMarket": null,
            "betDelay": 2,
            "hidden": false,
            "isBettable": false,
            "bettableTime": null,
            "consolidatedRunner": {
                "back": [
                    null,
                    null,
                    null
                ],
                "lay": [
                    null,
                    null,
                    null
                ]
            }
        },
        "scores": null,
        "players": [
            {
                "name": "💎 Table Games 💎"
            }
        ],
        "matchStatsEnabled": true,
        "videoEnabled": true,
        "hasVideo": false,
        "id": 'SPB',
        "image": "./assets/images/dashCasino/tablegamesb.png"
    },
    {
        "eventTypeId": "77777",
        "eventType": "LOBBY",
        "isInPlay": true,
        "event": {
            "id": "40",
            "name": "Mega Ball",
            "openDate": "2019-11-16T17:00:00Z",
            "matchStatsEnabled": true,
            "videoEnabled": true
        },
        "market": {
            "id": "1.rngmegaball",
            "groupById": "77777",
            "name": "Match odds",
            "aussieExchange": false,
            "exchangeId": "1",
            "start": 1584444174239,
            "btype": "CASINO",
            "mtype": "MATCH_ODDS",
            "stype": null,
            "event": {
                "id": "77777",
                "name": "RNG Mega Ball",
                "openDate": "2020-03-17T11:22:54.239Z",
                "venue": "England",
                "countryCode": "GB",
                "countryCodeISO2": "",
                "altName": "RNG Mega Ball"
            },
            "eventTypeId": "77777",
            "inPlay": true,
            "competition": {
                "id": "99999999",
                "name": "LIVE_CASINO"
            },
            "matched": 1882376,
            "numWinners": 1,
            "numRunners": 2,
            "numActiveRunners": 2,
            "status": "OPEN",
            "statusLabel": "plain",
            "lastUpdateTime": 1584444338937,
            "oddsType": null,
            "providerId": 2,
            "maxLiabilityPerBet": null,
            "maxLiabilityPerMarket": null,
            "betDelay": 2,
            "hidden": false,
            "isBettable": false,
            "bettableTime": null,
            "consolidatedRunner": {
                "back": [
                    null,
                    null,
                    null
                ],
                "lay": [
                    null,
                    null,
                    null
                ]
            }
        },
        "scores": null,
        "players": [
            {
                "name": "💎 Bingo 💎"
            }
        ],
        "matchStatsEnabled": true,
        "videoEnabled": true,
        "hasVideo": false,
        "id": 'BTG',
        "image": "./assets/images/dashCasino/bingob.png"
    }
];