import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  setIsAnimatedMenu,
  setIsAnimatedText,
  setIsDetailedGroup,
  setIsMoreDetailed,
  setIsNavigationID,
  setIsNavigationName,
  setIsNavigationOpen, setIsNavigationReset,
  setRenderIonContent,
} from '../../../../store/navigationHeaderMenu/navigationHeaderMenu.slice'
import { setLoginNew } from '../../../../store/LoginNew/loginNew.slice'
import { requestMenuTop } from '../../../../store/menuTop/menuTop.thunks'
import { requestMenu } from '../../../../store/menu/menu.thunks'
import { getMenuSuccess } from '../../../../store/menu/menu.slice'
import { setSlotCasinoPopUp } from '../../../../store/slotCasinoPopUp/slotCasinoPopUp.slice'
import { requestCasinoPoint } from '../../../../store/liveCasino/liveCasino.thunks'

export const connectSportButton = (wrappedProps) => (component) => {
  function mapStateToProps(state) {
    return {
      auth: state.auth,
      elementsParams: state.elementsParams,
      menu: state.menuTop,
      searchValue: state.navigationHeaderMenu.searchValue,
      isNavigationName: state.navigationHeaderMenu.navigationName,
    }
  }

  function mapDispatchToProps(dispatch) {
    const actions = {
      setIsNavigationOpen,
      setRenderIonContent,
      setIsNavigationID,
      setIsNavigationName,
      setIsNavigationReset,
      setIsAnimatedMenu,
      setLoginNew,
      setIsMoreDetailed,
      setIsDetailedGroup,
      setIsAnimatedText,
      requestMenuTop,
      requestMenu,
      getMenuSuccess,
      setSlotCasinoPopUp,
      requestCasinoPoint
    }

    return { actions: bindActionCreators(actions, dispatch) }
  }

  return connect(mapStateToProps, mapDispatchToProps)(component)
}
