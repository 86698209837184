import http from '../../services/httpAuth'
import getErrorMessage from '../../helpers/getErrorMessage.helper'
import { getRecaptchaSuccess, getRecaptchaError } from './recaptcha.slice'
import { setVersion } from '../version/version.slice'
import { setAndroidVersion } from '../version/androidVersion.slice'
import { setB2C } from '../B2C/b2c.slice'
import { BASE_CONFIG_URL_PUBLIC } from "../../config/api.config";

export const requestRecaptcha = (data) => async (dispatch) => {
  try {
    // const response = await http.get(BASE_CONFIG_URL_PUBLIC, data)
    // console.log("response ", response)
    // if (response.data.success) {
    //   if(response.data.result?.appConfig[3]?.moduleName === 'redirect') {
    //     let data = response.data.result?.appConfig[3]?.model
    //     let host = window.location.host
    //     if(Object.keys(data).includes(host)) {
    //       if(host !== data[`${host}`]) {
    //         console.log("host changed", data[`${host}`])
    //         window.location.replace(data[`${host}`])
    //       } else {
    //         console.log("no need to change host")
    //       }
    //     }
    //   }
    // }
    // if (response.data.success) {
    //   window.gogleeIdTracker = response.data.result.gaConfig.trackingId
    //   dispatch(
    //     getRecaptchaSuccess(
    //       response.data.result?.recaptchaConfig?.recaptchaSiteKey
    //     )
    //   )
    //   dispatch(
    //     setVersion(response.data.result.appConfig[1].model['lot-version'])
    //     // todo need check
    //     // setVersion(response.data.result.appConfig[1].model['lot-version-test'])
    //   )
    //   dispatch(setAndroidVersion(response.data.result.appConfig[1].model['lot-android-version']))
    //   // dispatch(
    //   //   setB2C(response?.data?.result?.isB2C || false)
    //   // )
    //   // localStorage.setItem("B2C", response.data.result.isB2C)
    //   // console.log(response)
    // }
    // return response
  } catch (e) {
    dispatch(getRecaptchaError())
    // const errorMsg = getErrorMessage(e)
    // throw errorMsg
  }
}
