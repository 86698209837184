import React from "react";
import { useHistory } from "react-router";
import './TopBannerSlider.scss'


const TopBannerOne = ({ inPlaySlidingBanners, }) => {
  const history = useHistory();

  return (
    <div className='banner-one__item' >
      <div className="banner-one__item_img"
        style={{ backgroundImage: `url(${inPlaySlidingBanners[0].image})`, backgroundColor: inPlaySlidingBanners[0].backgroundColour }}
        onClick={() => {
          history.push(inPlaySlidingBanners[0].redirect)
          // if(auth) {
          //   history.push(inPlaySlidingBanners[0].redirect)
          // } else {
          //   dispatch(setLoginNew(true))
          // }
        }}/>
    </div>
  );
}


export default TopBannerOne
