import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { requestSportbookStart, requestSportbookEnd } from '../../store/sportbookLive/sportbookLive.thunks';
import { setUrlForSportbook } from '../../store/betbyWidget/urlForSportbook.slice';
import { requestSportPageEvent } from '../../store/sportPageEvent/sportPageEvent.thunks';
import { requestInplayPopular } from '../../store/inplayPopular/inplayPopular.thunks';
import { requestSlotsGames } from '../../store/slotsGames/slotsGames.thunks';
import { requestSlotsGamesLobby } from '../../store/slotsGames/slotsGames.thunks'
import { requestSlotsGamesSearch } from '../../store/slotsGamesSearch/slotsGamesSearch.thunks';
import { requestSlotsGamesID } from '../../store/slotsGames/slotsGames.thunks';
import { setIsPopUpOpen } from '../../store/slotsPopUp/slotsPopUp.slice';
import { setLoginNew } from '../../store/LoginNew/loginNew.slice';
import { setQuickButtonSlots } from '../../store/slotsGames/slotsGames.slice';
import { requestQtechGames } from '../../store/qTechGames/qTechGames.thunks';
import { requestQTechCasinoID } from '../../store/liveCasino/liveCasino.thunks';


export const connectSlotsGamesLobby = (wrappedProps) => (component) => {
  function mapStateToProps(state) {
    return {
      sportPageEvent: state.sportPageEvent,
      inplayPopular: state.inplayPopular,
      slotsGames: state.slotsGames,
      slotsGamesSearch: state.slotsGamesSearch,
      auth: state.auth,
      elementsParams: state.elementsParams,
      quickButtonSlots: state.slotsGames.quickButtonSlots,
      slotCasinoPopUp: state.slotCasinoPopUp,
      qTechGames: state.qTechGames,
    }
  }

  function mapDispatchToProps(dispatch) {
    const actions = {
      requestSportPageEvent,
      requestInplayPopular,
      requestSlotsGames,
      requestSlotsGamesLobby,
      requestSlotsGamesSearch,
      requestSlotsGamesID,
      setIsPopUpOpen,
      setLoginNew,
      setQuickButtonSlots,
      requestQtechGames,
      requestQTechCasinoID,
    }

    return { actions: bindActionCreators(actions, dispatch) }
  }

  return connect(mapStateToProps, mapDispatchToProps)(component)
}
