import { connect } from 'react-redux'
import { requestRaceOdds } from '../../store/raceOdds/raceOdds.thunks'
import { bindActionCreators } from '@reduxjs/toolkit'
// import { bindActionCreators } from 'redux'
// import { requestRacingPageData } from '../../store/racingPage/racingPage.thunks'

export const connectUpcomingRacesAccordion = (wrappedProps) => (component) => {
  function mapStateToProps(state) {
    return {
      timeSettings: state.timeSettings,
      betPlacing: state.betPlacing,
      raceOdds: state.raceOdds,
    }
  }

  function mapDispatchToProps(dispatch) {
    const actions = {
      requestRaceOdds
    }
  
    return { actions: bindActionCreators(actions, dispatch) }
  }


  return connect(mapStateToProps, mapDispatchToProps)(component)
  // return connect(mapStateToProps)(component)
}
