import React, { useState, useEffect } from 'react'
import {
  IonButton,
  IonContent,
  IonInput,
  IonItem,
  IonList,
  IonPage,
  IonHeader,
} from '@ionic/react'
import './DetailedDepositTransaction.scss'
import Toolbar from '../../components/Toolbar/Toolbar'
import PayTM from '../../assets/images/payTM.svg'
import UPI from '../../assets/images/UPI.svg'
import IMPS from '../../assets/images/IMPS.svg'
import Refresh from '../../assets/images/refresh.svg'
import { useIonViewWillEnter } from '@ionic/react'
import { connectDetailedDepositTransaction } from './DetailedDepositTransaction.connect'
import { useHistory } from 'react-router'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import { setDetailedDepositTransaction } from '../../store/detailedDepositTransaction/detailedDepositTransaction.slice'

import { useSelector } from 'react-redux'
import PopUp from './PopUp'
import { getCurrencyFormat } from '../../helpers/getCurrencyFormat.helper'
import editIcon from '../../assets/images/transactions/editIcon.svg'
import refreshIcon from '../../assets/images/transactions/refreshIcon.svg'
import cancelIcon from '../../assets/images/transactions/cancelIcon.svg'
import deleteButton from "../../assets/images/deleteButton.svg";
import { BASE_DEV_URL } from "../../config/api.config";
import { getCustomStatus } from "../../config/app.config";
import moment  from "moment";

const DetailedDepositTransaction = ({ user, actions, balance,elementsParams, ...props }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  console.log('DetailedDepositTransactionPage')

  const data = useSelector((state) => state.detailedDepositTransaction)
  // console.log('detailedDepositTransactionData', data)

  // const [detailes, setDetailes] = useState()
  const token = localStorage.getItem('id_token')
  // const cancelOneTransactionRequest = async () => {
  //   return await axios
  //     .get(`https://dapi.gemexch.bet/api/payments/userid/cancel-request`, {
  //       headers: {
  //         Authorization: `${token}`,
  //       },
  //     })
  //     .then((response) =>
  //       dispatch(setDetailedDepositTransaction(response.data.result[0]))
  //     )
  // }


  const width = elementsParams && elementsParams?.clientWidth ? elementsParams?.clientWidth : window.innerWidth


  const updateOneTransactionRequest = async () => {
    setLoadingRefresh(true)
    //TODO: check this API
    return await axios
      .get(
        `https://dapi.gemexch.bet/api/payments/userid/txns?requestId=${data.requestId}`,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      )
      .then((response) => {
        dispatch(setDetailedDepositTransaction(response.data.result[0]))
        setLoadingRefresh(false)
      }
      )
      .catch((e) => {
        setLoadingRefresh(false)
        // toastr.error("Error", getErrorMessage(e));
        // console.log(e);
      })
  }

  // useIonViewWillEnter(() => {
  //   updateOneTransactionRequest()
  // })

  // console.log('Saved detailes in component', detailes)

  // useEffect(() => {
  //   updateOneTransactionRequest()
  // }, [])

  const getAmOrPmTime = () => {
    const currentTime = data?.createdAt?.slice(11, 16)
    let hours = currentTime?.slice(0, 2)
    const AmOrPm = hours >= 12 ? 'pm' : 'am'
    let minutes = currentTime?.slice(3, 5)
    hours = hours % 12 || 12
    const finalTime = +hours + ':' + minutes + ' ' + AmOrPm
    return finalTime
  }

  const [openPopUp, setOpenPopUp] = useState(false)
  const [editPoUP, seteditPoUP] = useState(false);
  const [withdrawAccount, setWithdrawAccount] = useState('')
  const [prevAccounts, setPrevAccounts] = useState();
  const [loadingRefresh, setLoadingRefresh] = useState(false);

  const [message, setMessage] = useState('')
  const [digits, setDigits] = useState('')
  const [accountNumber, setAccountNumber] = useState('')
  const [infoIFSC, setInfoIFSC] = useState({})
  const [withdrawalAmount, setwithdrowalAmount] = useState(0)
  const [withdrawalIFSC, setWithdrawalIFSC] = useState()
  const [withdrawalAccNumber, setWithdrawalAccNumber] = useState()
  const [withdrawalAccName, setWithdrawalAccName] = useState()
  const [isRulesAccept, setIsRulesAccept] = useState(true);
  const [isNamesMatch, setisNamesMatch] = useState(false)
  const [isEnoughMoney, setIsEnoughMoney] = useState(false)
  const [itemIFSC, setItemIFSC] = useState()
  const [nameMessage, setNameMessage] = useState('')
  const [openPopUpDelete, setOpenPopUpDelete] = useState(false)
  const [openPopUpHelp, setOpenPopUpHelp] = useState(false)
  const [inputesFromHistory, setInputesFromHistory] = useState(false)
  const [inputesCustom, setInputesCustom] = useState(false)
  const [confirmAccNumber, setConfirmAccNumber] = useState('');
  const [submitBtnDisabled, setSubmitBtnDisabled ] = useState(false);

  const data1 = {
    amount: withdrawalAmount,
    acNo: withdrawalAccNumber,
    IFSC: withdrawalIFSC,
    bankName: infoIFSC?.BANK,
    branchName: infoIFSC?.BRANCH,
    accountName: withdrawalAccName,
  }




  // Fields check
  let [withdrawalAmountCheck, setWithdrawalAmountCheck] = useState('');
  let [withdrawalIFSCCheck, setWithdrawalIFSCCheck] = useState('');
  let [withdrawalAccNumberCheck, setWithdrawalAccNumberCheck] = useState('');
  let [withdrawalAccNameCheck, setWithdrawalAccNameCheck] = useState('');
  let [confirmAccNumberCheck, setConfirmAccNumberCheck] = useState('');
  let [isRulesAcceptCheck, setIsRulesAcceptCheck] = useState('');

  const fieldCheck = () => {
    !withdrawalAmount ? setWithdrawalAmountCheck(false) : setWithdrawalAmountCheck('')
    !withdrawalIFSC ? setWithdrawalIFSCCheck(false) : setWithdrawalIFSCCheck('')
    !withdrawalAccNumber ? setWithdrawalAccNumberCheck(false) : setWithdrawalAccNumberCheck('')
    !withdrawalAccName ? setWithdrawalAccNameCheck(false) : setWithdrawalAccNameCheck('')
    confirmAccNumber.length < 1 ? setConfirmAccNumberCheck(false) : setConfirmAccNumberCheck('')
    !isRulesAccept ? setIsRulesAcceptCheck(false) : setIsRulesAcceptCheck('')
  }
  // End Fields check
  useEffect(() => {
    setTimeout(() => {
      if (itemIFSC?.IFSCCode?.length === 11) {
        codeIFSC(itemIFSC?.IFSCCode)
      }
    })
  }, [itemIFSC?.IFSCCode])


  useEffect(() => {
    if (prevAccounts) {
      setWithdrawalIFSC()
      setWithdrawalAccName()
      setisNamesMatch(false)
      setWithdrawalAccNumber()
      setWithdrawalIFSC(itemIFSC?.IFSCCode)
      setWithdrawalAccName(itemIFSC?.accountName)
      setisNamesMatch(true)
      setWithdrawalAccNumber(itemIFSC?.accountNo)
    }
  }, [itemIFSC])

  const onValidationAmount = (e) => {
    if (balance?.availBalance < e) {
      setMessage('You don`t have enough money on your balance')
      // setTimeout(() => {
      //   setMessage('')
      // }, 3000)
      // setIsEnoughMoney(false)
    } else if (e < 0) {
      setwithdrowalAmount(e)
      setMessage("Amount can't be negative")
      setIsEnoughMoney(false)
    } else if (e < 1) {
      setwithdrowalAmount(e)
      setMessage("Amount can't be empty")
      setIsEnoughMoney(false)
    } else if (e < 100) {
      setwithdrowalAmount(e)
      setMessage("Amount can't be less than 100")
      setIsEnoughMoney(false)
    } else {
      setwithdrowalAmount(e)
      setMessage('')
      setIsEnoughMoney(true)
    }
  }

  const onValidationIFSC = (e) => {
    setWithdrawalIFSC(e)
    if (e.length === 11) {
      codeIFSC(e)
      setDigits()
    } else {
      setInfoIFSC()
      setDigits('IFSC should be 11 digits')
    }
  }

  const onNewValidationIFSC = (e) => {
    setWithdrawalIFSC(e)
    if (e.length === 11) {
      codeIFSC(e)
      setDigits()
    } else {
      setInfoIFSC()
      setDigits('IFSC should be 11 digits')
    }
    // if(e.length >= 1 && e.length !== 11){
    //   setDigits('IFSC should be 11 digits')
    // } else{
    //   setDigits()
    // }
  }

  const onAccNumber = (e) => {
    setWithdrawalAccNumber(e)
  }

  const onAccName = (e) => {
    if (e.length < 32) {
      setWithdrawalAccName(e)
      setNameMessage('')
    } else {
      setNameMessage('Account name should be less than 32 digits')
    }
  }

  const onNewAccNumber = (e) => {
    setWithdrawalAccNumber(e)
  }

  const onNewAccName = (e) => {
    if (e.length < 32) {
      setWithdrawalAccName(e)
      setNameMessage('')
    } else {
      setNameMessage('Account name should be less than 32 digits')
    }
  }

  const onValidationAccnumber = (e) => {
    setConfirmAccNumber(e)
    // console.log('onValidationAccnumber', e)
    if (e.length > 0 && e !== withdrawalAccNumber) {
      setAccountNumber('Account number should match')
      setisNamesMatch(false)
      // console.log('isNamesMatch', isNamesMatch)
    } else {
      setAccountNumber('')
      setisNamesMatch(true)
      // console.log(isNamesMatch)
    }
  }

  useEffect(()=>{
    codeAcc();
  },[]);

  const withrawRequest = async  () =>{
    if (
      withdrawalIFSC?.length === 11 &&
      withdrawalAccNumber &&
      isNamesMatch &&
      withdrawalAccName

    ) {
      console.log(
        'rules accepted',
        isRulesAccept,
        'amount',
        withdrawalAmount,
        'IFSC',
        withdrawalIFSC,
        'accNumber',
        withdrawalAccNumber,
        'Accname',
        withdrawalAccName
      )
      setSubmitBtnDisabled(true)

      data1.IFSC = data1?.IFSC?.toString()?.toUpperCase();
    // TODO:change api
      // return await axios
      //   .post(BASE_DEV_URL + '/payments/userid/withdraw-request', data1, {
      //     headers: {
      //       Authorization: `${token}`,
      //     },
      //   })
      //   .then((response) =>
      //     setTimeout(() => {
      //       setSubmitBtnDisabled(false)
      //       history.push('/thanksWithdrawal')
      //     }, 500)
      //   )
      //   // .catch((error) =>   toastr.error('Error', error?.response?.data?.result))
      //   .catch((error) => {
      //     setSubmitBtnDisabled(false)
      //     toastr.error('Error', error?.response?.data?.error)
      //     const errorMsg = getErrorMessage(error)
      //     throw errorMsg
      //   })
    }
  }

  const codeAcc = async () => {
    return await axios
      .get(BASE_DEV_URL + '/payments/userid/account-list', {
        headers: {
          Authorization: `${token}`,
        },
      })
      .then((response) =>
        response.data.result
          ? setPrevAccounts(response.data.result)
          : (setPrevAccounts())
      )
      .catch((e) => {
        // const errorMsg = getErrorMessage(e)
        // throw errorMsg
      })
  }
  const timeDiff = (createdAt) => {
    const end = moment(createdAt);
    const duration = moment.duration(moment().diff(end));
    const minutes = duration.asMinutes();
    console.log(moment.duration(moment().diff(end)).asMinutes(),'createdAt')
    return minutes;
  }

  const codeIFSC = async (e) => {
    return await axios
      .get(BASE_DEV_URL + `/payments/userid/ifsc?ifsc=${e}`, {
        headers: {
          Authorization: `${token}`,
        },
      })
      .then((response) => setInfoIFSC(response.data.result))
      .catch((e) => {
        setInfoIFSC()
        // toastr.error("Error", getErrorMessage(e));
        // console.log(e);
      })
  }


  console.log('data', data);

  return (
    <IonPage>
      <IonHeader>
        <Toolbar />
      </IonHeader>
      {/* <div className="detailed-transaction-menu">
        <div className="transactions-menu">
          <div
            className={`deposite ${
              data.type === 'Deposit' ? 'active' : 'hideTab'
            }`}
          >
            DEPOSIT
          </div>
          <div
            className={`withdrawal ${
              data.type === 'Withdrawal' ? 'active' : 'hideTab'
            }`}
          >
            WITHDRAWAL
          </div>
        </div>
      </div> */}

      <IonContent className="ChangePassword" fullscreen>
        <>
          {data.type === 'Deposit' ? (
            <>
              {data && (
                <div className="detailedTransaction-container">
                  {/*{data?.status === "Pending" && <div className="signUpNew_whattsUp_button" onClick={() => window.open(`${CONFIG.whatsappSupportLink}`)}>*/}
                  {/*  <div className="signUpNew_whattsUp_button-icon"/>*/}
                  {/*  <div className="signUpNew_whattsUp_button-number">Customer Care</div>*/}
                  {/*</div>}*/}
                  {/*{*/}
                  {/*  <div className="detailedTransaction-container-info">*/}
                  {/*    <div className="detailedTransaction-container-info-title">*/}
                  {/*      If your deposit has not credited since 15 minutes, Please contact customer care.*/}
                  {/*    </div>*/}
                  {/*  </div>*/}
                  {/*}*/}
                  {/*{ data?.createdAt && timeDiff(data?.createdAt) && timeDiff(data?.createdAt) > 15 && (*/}
                  {/*    <a href={CONFIG.whatsappSupportLink} target={"_blank"}>*/}
                  {/*      <div  className="whatsapp-care-button" />*/}
                  {/*    </a>)*/}
                  {/* }*/}
                  <div className="detailedTransaction-container-info">
                    <div className="detailedTransaction-container-info-title">
                      Request Id
                    </div>
                    <div className="detailedTransaction-container-info-descr">
                      {data?.requestId}
                    </div>
                  </div>
                  <div className="detailedTransaction-container-info">
                    <div className="detailedTransaction-container-info-title">
                      Date & Time
                    </div>
                    <div className="detailedTransaction-container-info-descr">
                      <span>{new Date(data?.createdAt).toLocaleString('en-GB', {
                        day: "numeric",
                        month: "long",
                        year: 'numeric',
                        }).replace('at', ' ')};
                        </span>
                        <span style={{display: 'inline-block', marginLeft: '7px'}}>
                        {new Date(data?.createdAt).toLocaleString('en-GB', {
                          hour: "numeric",
                          minute: "numeric",
                          second: '2-digit'
                          }).replace('at', ' ')
                        }
                        </span>
                    </div>
                  </div>
                  <div className="detailedTransaction-container-info">
                    <div className="detailedTransaction-container-info-title">
                      Amount
                    </div>
                    <div className="detailedTransaction-container-info-descr">
                      ₹ {getCurrencyFormat(data?.amount)}
                    </div>
                  </div>
                  <div className="detailedTransaction-container-info">
                    <div className="detailedTransaction-container-info-title">
                      Type
                    </div>
                    <div className="detailedTransaction-container-info-descr">
                      {data?.type}
                    </div>
                  </div>
                  <div className="detailedTransaction-container-info">
                    <div className="detailedTransaction-container-info-title">
                      Status
                    </div>
                    <div className="detailedTransaction-container-info-descr">
                      {data?.status ? getCustomStatus(data?.status) : '-'}
                    </div>
                  </div>
                  <div className="detailedTransaction-container-info">
                    <div className="detailedTransaction-container-info-title">
                      Payment Type
                    </div>
                    <div className="detailedTransaction-container-info-descr">
                      {data?.gateway?.name}
                    </div>
                  </div>
                  <div className="detailedTransaction-container-info">
                    <div className="detailedTransaction-container-info-title">
                      Wallet
                    </div>
                    <div className="detailedTransaction-container-info-descr">
                      {data?.gateway?.acNo}
                    </div>
                  </div>
                  <div className="detailedTransaction-container-info">
                    <div className="detailedTransaction-container-info-title">
                      UTR No.
                    </div>
                    <div className="detailedTransaction-container-info-descr">
                      {data?.utr}
                    </div>
                  </div>
                </div>
              )}

              {data && data.status === 'Cancelled' && (
                <div className="reason-container">
                  <div className="reason-container_title">Reason:</div>
                  <div className="reason-container_text">
                    {data.remarks
                      ? data.remarks
                      : 'No reason submitted by user.'}
                  </div>
                </div>
              )}

              {data &&
                (data.status === 'Approved' ||
                  data.status === 'Rejected' ||
                  data.status === 'Complete' ||
                  data.status === 'Cancelled') && (
                  <div className="detailed-content__buttons">
                    <div
                      className="detailed-content__buttons-back"
                      onClick={() => history.push('/transactions')}
                    >
                      Back
                    </div>
                  </div>
                )}

              {data && data.status === 'Pending' && (
                <div className="detailed-content__buttons">
                  <div
                    className="detailed-content__buttons-edit"
                    onClick={() => {
                      setOpenPopUp(true)
                      seteditPoUP(true)
                      console.log('editPoUP in component', editPoUP)
                    }}
                  >
                    <img loading={"lazy"} src={editIcon} alt="" />
                    <span> </span>
                    <span className='detailed-content__buttons-edit__text'>
                      Edit
                    </span>
                  </div>

                  <div
                    className="detailed-content__buttons-back pushable"
                    onClick={() => updateOneTransactionRequest()}
                  >
                    <span className="shadow"></span>
                    <span className="edge"></span>
                    <img loading={"lazy"} className={`${loadingRefresh && 'loadingRefresh'}`} src={refreshIcon} alt="" />
                    <span> </span>
                    <span className='detailed-content__buttons-cancel__text'>
                       Refresh
                    </span>
                  </div>

                  <div
                    className="detailed-content__buttons-cancel"
                    onClick={() => {
                      setOpenPopUp(true)
                      seteditPoUP(false)
                    }}
                  >
                    <img loading={"lazy"} src={cancelIcon} alt="" />
                    <span className='detailed-content__buttons-cancel__text'>
                    Cancel
                    </span>
                  </div>
                </div>
              )}

              {/*{data && data.filename && data.filename !== '' && (*/}
              {/*    <div className="detailedTransaction-container">*/}
              {/*      <img className="" src={} alt="" />*/}
              {/*    </div>*/}
              {/*  )}*/}

            </>
          ) : (
            // WITHDRAWAL DEATAILS *************************************
            <>
              {data && (
                <div className="detailedTransaction-container">
                  <div className="detailedTransaction-container-info">
                    <div className="detailedTransaction-container-info-title">
                      Request Id
                    </div>
                    <div className="detailedTransaction-container-info-descr">
                      {data?.requestId}
                    </div>
                  </div>
                  <div className="detailedTransaction-container-info">
                    <div className="detailedTransaction-container-info-title">
                      Date & Time
                    </div>
                    <div className="detailedTransaction-container-info-descr">
                      <span>{new Date(data?.createdAt).toLocaleString('en-GB', {
                        day: "numeric",
                        month: "long",
                        year: 'numeric',
                        }).replace('at', ' ')};
                        </span>
                        <span style={{display: 'inline-block', marginLeft: '7px'}}>
                        {new Date(data?.createdAt).toLocaleString('en-GB', {
                          hour: "numeric",
                          minute: "numeric",
                          second: '2-digit'
                          }).replace('at', ' ')
                        }
                        </span>
                    </div>
                  </div>
                  <div className="detailedTransaction-container-info">
                    <div className="detailedTransaction-container-info-title">
                      Amount
                    </div>
                    <div className="detailedTransaction-container-info-descr">
                      ₹ {getCurrencyFormat(data?.amount)}
                    </div>
                  </div>
                  <div className="detailedTransaction-container-info">
                    <div className="detailedTransaction-container-info-title">
                      Type
                    </div>
                    <div className="detailedTransaction-container-info-descr">
                      {data?.type}
                    </div>
                  </div>
                  <div className="detailedTransaction-container-info">
                    <div className="detailedTransaction-container-info-title">
                      Status
                    </div>
                    <div className="detailedTransaction-container-info-descr">
                      {data?.status ? getCustomStatus(data?.status) : '-'}
                    </div>
                  </div>
                  <div className="detailedTransaction-container-info">
                    <div className="detailedTransaction-container-info-title">
                      Payment Type
                    </div>
                    <div className="detailedTransaction-container-info-descr">
                      {data?.gateway?.name ? data?.gateway?.name : "-"}
                    </div>
                  </div>
                  <div className="detailedTransaction-container-info">
                    <div className="detailedTransaction-container-info-title">
                      IFSC Code
                    </div>
                    <div className="detailedTransaction-container-info-descr">
                      {data?.account?.IFSC}
                    </div>
                  </div>
                  <div className="detailedTransaction-container-info">
                    <div className="detailedTransaction-container-info-title">
                      Account Number
                    </div>
                    <div className="detailedTransaction-container-info-descr">
                      {data?.account?.accountNo}
                    </div>
                  </div>
                  <div className="detailedTransaction-container-info">
                    <div className="detailedTransaction-container-info-title">
                      Account Holder Name
                    </div>
                    <div className="detailedTransaction-container-info-descr">
                    {data?.account?.accountHolderName}
                    </div>
                  </div>
                </div>
              )}
              {data && data.status === 'Cancelled' && (
                <div className="reason-container">
                  <div className="reason-container_title">Reason:</div>
                  <div className="reason-container_text">
                    {data.remarks
                      ? data.remarks
                      : 'No reason submitted by user.'}
                  </div>
                </div>
              )}
               {/*TODO:correct status*/}
              {data &&
                (data.status === 'Approved' ||
                  data.status === 'Rejected' ||
                  data.status == "Complete" ||
                  data.status === 'Cancelled') && (
                  <div className="detailed-content__buttons">
                    <div
                      className="detailed-content__buttons-back"
                      onClick={() => history.push('/transactions')}
                    >
                      Back
                    </div>
                  </div>
                )}

              {data && data.status === 'Pending' && (
                <div className="detailed-content__buttons">
                  <div
                    className="detailed-content__buttons-edit"
                    onClick={() => {
                      setOpenPopUp(true)
                      seteditPoUP(true)
                      console.log('editPoUP in component', editPoUP)
                    }}
                  >
                    <img loading={"lazy"} src={editIcon} alt="" />
                    <span> </span>
                    <span className='detailed-content__buttons-edit__text'>
                      Edit
                    </span>
                  </div>

                  <div
                    className="detailed-content__buttons-back"
                    onClick={() => updateOneTransactionRequest()}
                  >
                    <img loading={"lazy"} className={`${loadingRefresh && 'loadingRefresh'}`} src={refreshIcon} alt="" />
                    <span> </span>
                    <span className='detailed-content__buttons-back__text'>
                      Refresh
                    </span>
                  </div>

                  <div
                    className="detailed-content__buttons-cancel"
                    onClick={() => {
                      setOpenPopUp(true)
                      seteditPoUP(false)
                    }}
                  >
                    <img loading={"lazy"} src={cancelIcon} alt="" />
                    <span className='detailed-content__buttons-cancel__text'>
                    Cancel
                    </span>
                  </div>
                </div>
              )}
            </>
          )}
        </>
        {
           false &&
          <div className={"account-form detailedTransaction-container"}>
            <h6>Add New Account</h6>
          <div className="withdraw-account__flex transaction-page">

            <label onClick={()=>{
              setInputesFromHistory(false);
              setWithdrawAccount('');
              setItemIFSC(null);
              setInfoIFSC(null);
            }
            } htmlFor="15091" className="withdraw-account__item add-account__item">
              <div className="withdraw-account__item-content">
                <div className={"add-icon"}>
                  <div className={"vertical"}></div>
                  <div className={"horizontal"}></div>
                </div>
                <div className={"withdraw-account__item-content__IFSC"}>
                  Add
                </div>
              </div>
            </label>
            {prevAccounts?.length > 0
              ? prevAccounts.map((item) => {
                return (
                  <label
                    htmlFor={item.accountId}
                    className={
                      withdrawAccount === item.accountId
                        ? 'withdraw-account__item-checked'
                        : 'withdraw-account__item'
                    }
                    onClick={() => {
                      setWithdrawAccount(item.accountId)
                      setInputesFromHistory(true)
                      // setInputesCustom(false)
                    }}
                  >
                    <div
                      className="withdraw-account__item-content"
                      onClick={() => setItemIFSC(item)}
                    >
                      <div className="withdraw-account__item-content__account">
                        {item.accountName}
                      </div>
                      <div className="withdraw-account__item-content__account">
                        ********
                        {item.accountNo
                          ? item.accountNo.slice(-4)
                          : ''}
                      </div>
                      <div className="withdraw-account__item-content__IFSC">
                        {item.bankName}
                        <input
                          className="check-account"
                          type="radio"
                          name="choosenaccount"
                          checked={withdrawAccount === item.accountId}
                          id={item.accountId}
                        />
                        <img
                          className="delete-account"
                          src={deleteButton}
                          alt=""
                          loading={"lazy"}
                          // onClick={() => setOpenPopUpDelete(true)}
                        />
                      </div>
                    </div>
                  </label>
                )
              })
              : null}
          </div>
            {
              !inputesFromHistory &&
              <div className="withdraw-desktop__rightpage-inputcontent">
                <div className="withdraw-input-content">
                  <div
                    className={`page-subtitle ${!withdrawalIFSC && withdrawalIFSCCheck === false ? "withdraw__empty-input" : ""}`}>
                    <span>IFSC Code*</span>
                    {!withdrawalIFSC && withdrawalIFSCCheck === false ?
                      <span className={`input-error-message`}>Please fill required fields</span> : ''}
                  </div>
                  <input
                    className="withdraw-textarea"
                    type="text"
                    // onReset={() => setInputedIFSC()}
                    // onChange={(e) => setWithdrawalIFSC(e.target.value)}
                    onChange={(e) => onNewValidationIFSC(e.target.value)}
                    // defaultValue={itemIFSC?.IFSCCode}
                    // value={itemIFSC?.IFSCCode ? itemIFSC?.IFSCCode : inputedIFSC}
                  />
                  <div className="validation-message">{digits}</div>
                  {infoIFSC ? (
                    <div className="withdraw-textarea__description">
                      {infoIFSC?.BANK}, {infoIFSC?.BRANCH}, {infoIFSC?.CITY}
                    </div>
                  ) : null}
                </div>
                <div className="withdraw-input-content account-number-input-wrapper">
                  <div
                    className={`page-subtitle ${!withdrawalAccNumber && withdrawalAccNumberCheck === false ? "withdraw__empty-input" : ""}`}>
                    <span>Account number*</span>
                    {!withdrawalAccNumber && withdrawalAccNumberCheck === false ?
                      <span className={`input-error-message`}>Please fill required fields</span> : ''}
                  </div>
                  <input
                    id="account"
                    className="withdraw-textarea hidden-accnumber"
                    type="text"
                    placeholder="*************"
                    onChange={(e) => onAccNumber(e.target.value)}
                    // defaultValue={itemIFSC?.accountNo}
                  />
                </div>
                <div className="withdraw-input-content ">
                  <div
                    className={`page-subtitle ${confirmAccNumber.length < 1 && confirmAccNumberCheck === false ? "withdraw__empty-input" : ""}`}>
                    <span>Confirm account number*</span>
                    {confirmAccNumber.length < 1 && confirmAccNumberCheck === false ?
                      <span className={`input-error-message`}>Please fill required fields</span> : ''}
                  </div>
                  <input
                    className="withdraw-textarea"
                    type="text"
                    placeholder="Enter your account: E.g.123456789"
                    disabled={!withdrawalAccNumber}
                    onChange={(e) => onValidationAccnumber(e.target.value)}
                    // defaultValue={itemIFSC?.accountNo}
                  />
                  <div className="validation-message">{accountNumber}</div>
                </div>
                <div className="withdraw-input-content">
                  <div
                    className={`page-subtitle ${!withdrawalAccName && withdrawalAccNameCheck === false ? "withdraw__empty-input" : ""}`}>
                    <span>Account name*</span>
                    {!withdrawalAccName && withdrawalAccNameCheck === false ?
                      <span className={`input-error-message`}>Please fill required fields</span> : ''}
                  </div>
                  <input
                    className="withdraw-textarea"
                    type="text"
                    placeholder="Account Name"
                    onChange={(e) => onAccName(e.target.value)}
                    // defaultValue={itemIFSC?.accountName}
                    maxLength={32}
                  />
                  <div className="validation-message">{nameMessage}</div>
                </div>
                {width >= 1024 ? (
                  <div className="submitBtnW">
                    <button
                      type="button"
                      onClick={
                        !isRulesAccept &&
                        isEnoughMoney &&
                        withdrawalIFSC?.length === 11 &&
                        withdrawalAccNumber &&
                        isNamesMatch &&
                        withdrawalAccName &&
                        withdrawalAmount ? withrawRequest : fieldCheck}
                      className={` 
                        ${
                        !isRulesAccept &&
                        isEnoughMoney &&
                        withdrawalIFSC?.length === 11 &&
                        withdrawalAccNumber &&
                        isNamesMatch &&
                        withdrawalAccName &&
                        withdrawalAmount
                          ? 'submitValidBtn'
                          : 'withdrawalNotValidBtn'
                      }`}
                      disabled={submitBtnDisabled}
                    >
                      {!isRulesAccept &&
                      isEnoughMoney &&
                      withdrawalIFSC?.length === 11 &&
                      withdrawalAccNumber &&
                      isNamesMatch &&
                      withdrawalAccName &&
                      withdrawalAmount ? (
                        <>
                          <span className="shadow"></span>
                          <span className="edge"></span>
                          <span className="front">Submit</span>
                        </>
                      ) : (
                        <div className="withdraw-submitNew__text">
                          Submit
                        </div>
                      )}
                    </button>
                  </div>
                ) : (
                  <button
                    className={
                      isRulesAccept &&
                      isEnoughMoney &&
                      withdrawalIFSC?.length === 11 &&
                      isNamesMatch &&
                      withdrawalAccName &&
                      withdrawalAmount
                        ? 'withdraw-submit'
                        : 'withdrawalNotValidBtn'
                    }
                    onClick={withrawRequest}
                    type="button"
                    disabled={submitBtnDisabled}
                  >
                    <div className="withdraw-submit__text">Submit</div>
                  </button>
                )}
              </div>
            }

            {inputesFromHistory && itemIFSC && prevAccounts && (
              <div className="withdraw-desktop__rightpage-inputcontent">
                <div className="withdraw-input-content">
                  <div className="page-subtitle">IFSC Code*</div>
                  <input
                    className="withdraw-textarea"
                    type="text"
                    // onChange={(e) => setWithdrawalIFSC(e.target.value)}
                    onChange={(e) => onValidationIFSC(e.target.value)}
                    // defaultValue={itemIFSC?.IFSCCode}
                    disabled={true}
                    value={itemIFSC?.IFSCCode}
                  />
                  <div className="validation-message">{digits}</div>
                  {infoIFSC ? (
                    <div className="withdraw-textarea__description">
                      {infoIFSC?.BANK}, {infoIFSC?.BRANCH}, {infoIFSC?.CITY}
                    </div>
                  ) : null}
                </div>
                <div className="withdraw-input-content ">
                  <div className="page-subtitle">Account number*</div>
                  <input
                    id="account"
                    className="withdraw-textarea hidden-accnumber"
                    type="text"
                    placeholder="*************"
                    disabled={true}
                    onChange={(e) => onAccNumber(e.target.value)}
                    value={itemIFSC?.accountNo}
                  />
                </div>
                <div className="withdraw-input-content">
                  <div className="page-subtitle">
                    Confirm account number*
                  </div>
                  <input
                    className="withdraw-textarea"
                    type="text"
                    placeholder="Enter your account: E.g.123456789"
                    disabled={true}
                    onChange={(e) => onValidationAccnumber(e.target.value)}
                    value={itemIFSC?.accountNo}
                  />
                  <div className="validation-message">{accountNumber}</div>
                </div>
                <div className="withdraw-input-content">
                  <div className="page-subtitle">Account name*</div>
                  <input
                    className="withdraw-textarea"
                    type="text"
                    placeholder="Account Name"
                    onChange={(e) => onAccName(e.target.value)}
                    disabled={true}
                    value={itemIFSC?.accountName}
                    maxLength={32}
                  />
                  <div className="validation-message">{nameMessage}</div>
                </div>
                {width >= 1024 ? (
                  <div className="submitBtnW">
                    <button
                      type="button"
                      onClick={withrawRequest}
                      className={` 
                          ${
                        isRulesAccept &&
                        isEnoughMoney &&
                        withdrawalIFSC?.length === 11 &&
                        isNamesMatch &&
                        withdrawalAccName &&
                        withdrawalAmount
                          ? 'submitValidBtn'
                          : 'withdrawalNotValidBtn'
                      }`}
                      disabled={submitBtnDisabled}
                    >
                      {isRulesAccept &&
                      isEnoughMoney &&
                      withdrawalIFSC?.length === 11 &&
                      isNamesMatch &&
                      withdrawalAccName &&
                      withdrawalAmount ? (
                        <>
                          <span className="shadow"></span>
                          <span className="edge"></span>
                          <span className="front">Submit</span>
                        </>
                      ) : (
                        <div className="withdraw-submitNew__text">
                          Submit
                        </div>
                      )}
                    </button>
                  </div>
                ) : (
                  <button
                    className={
                      !isRulesAccept &&
                      isEnoughMoney &&
                      withdrawalIFSC?.length === 11 &&
                      isNamesMatch &&
                      withdrawalAccName &&
                      withdrawalAmount
                        ? 'withdraw-submit'
                        : 'withdrawalNotValidBtn'
                    }
                    onClick={withrawRequest}
                    type="button"
                    disabled={submitBtnDisabled}
                  >
                    <div className="withdraw-submit__text">Submit</div>
                  </button>
                )}
              </div>
            )}

          </div>
        }
        {/*{width < 1024 && <FooterDesktop />}*/}
        {/*  TODO:correct status
           data.status == "Complete" && data.type === 'Withdrawal'*/}
      </IonContent>
      <PopUp
        data={data}
        content={editPoUP}
        open={openPopUp}
        onDidDismiss={() => setOpenPopUp(false)}
        refresh={() => updateOneTransactionRequest()}
      />
    </IonPage>
  )
}

export default connectDetailedDepositTransaction()(DetailedDepositTransaction)
