import React, { useEffect, useState } from "react";
import CloseCross from "../../assets/images/closeCross.svg";
import "./NotificationPopup.scss";
import httpAuth from "../../services/httpAuth";

const NotificationPopup = ({actions,messages=[]}) =>{
  const [msg,setMsg] = useState(null);
  useEffect(()=>{
    if(messages && messages.length>0){
      let temp = messages.find((e)=>e.Type == "P");
      if(temp && temp.text) setMsg(temp);
      console.log(temp,messages);
    }
    // console.log(actions);
  },[messages]);

  const agree = async () =>{
    try {
      const user_data = localStorage.getItem("user_data");
      let member_code;
      if (user_data) {
        let user = JSON.parse(user_data);
        member_code = user.memberCode;
      }
      if (member_code) {
        let msgId = msg.id;
        setMsg(null);
      let res = await httpAuth.delete(`/account/${member_code}/notification/message?id=${msgId}`);
      if(res && res.data && res.data.success){
        actions.requestMessage(member_code)
          .catch((e)=>console.log(e));
      }
      console.log(res);
      }
    }
    catch (e) {
      console.log(e);
    }
  }
  return(
    msg ?
    <>
      <div className={"NotificationPopUp"}>
        <div className={"Notification_wrapper"}>
          <div className={"pop-up"}>
              <div className={"pop-up-content"}>
                <div className={"pop-up-content__header"}>
                  <div className={"pop-up-content__header__title"}>Important !!!</div>
                </div>
                <div className={"pop-up-content__header__msg"}>{msg?.text}</div>
                <div onClick={agree} className={"pop-up-content__btn"}>I Understand</div>
              </div>
          </div>
        </div>
      </div>
    </>
      :""
  )
}

export default  NotificationPopup;
