import './index.scss'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import CloseIcon from '../../assets/images/slotsGames/crossPopUp.svg'
import { setIsPopUpOpen } from '../../store/slotsPopUp/slotsPopUp.slice'
import { requestJiliSlot } from '../../store/slotsPopUp/slotsPopUp.thunks'
import { requestJILIGamesID } from '../../store/slotsGames/slotsGames.thunks'
import { getCurrencyFormatWithZeroAfterDot } from '../../helpers/getCurrencyFormat.helper'

const JiliPopUp = ({
  scrollPosition,
  item,
 }) => {
  const history = useHistory()
  const dispatch = useDispatch();
  const { balance } = useSelector((state) => state);

  const [inputValue, setInputValue] = useState('')

  const sendDeposit = () => {
    dispatch(requestJiliSlot(inputValue))
    let id = item?.ID ?? item?.id;
    dispatch(requestJILIGamesID(id))
    history.push(`/live_casino_slot/${id}`)
    setTimeout(
      () => {
        dispatch(setIsPopUpOpen(false))
      }, 1000)
  }

  return (
    <div className="jili-pop-up"
      style={{top: scrollPosition + "px"}}
      onClick={(e) => {
        if (e.currentTarget === e.target) {
            dispatch(setIsPopUpOpen(false));
            console.log('e', e);
        }
      }}>
      <div className="jili-pop-up__content">
        <div className='jili-pop-up__content-header'>
          <span>Transfer funds for {item?.Trans?.en}</span>
          <img className='jili-pop-up__content-header-close' src={CloseIcon} alt="" onClick = {() => dispatch(setIsPopUpOpen(false))}/>
        </div>
        <div className='jili-pop-up__content-body'>
          <div className='jili-pop-up__content-body-toContinue'>To continue the game, top up your balance</div>
          <div className='jili-pop-up__content-body-info'>Deposit = 15 sec, Withdrawal = 2 min after exit</div>
            <div className='jili-pop-up__content-body-value'>
              <div className='jili-pop-up__content-body-value-balance'>Balance {getCurrencyFormatWithZeroAfterDot(balance?.availBalance)}</div>
              <input className='jili-pop-up__content-body-value-input'
                type="number"
                placeholder='0'
                onChange={(e) => setInputValue(e.target.value)}
                />
            </div>
            <button className='jili-pop-up__content-body-button' onClick={() => {
              sendDeposit()
            }}>
              Accept & launch
            </button>
        </div>
      </div>
    </div>
  )
}

export default JiliPopUp;
