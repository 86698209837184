import { createSlice } from '@reduxjs/toolkit'

export const menuTopSlice = createSlice({
  name: 'menuTop',
  initialState: [],
  reducers: {
    getMenuTopSuccess: (state, action) => action.payload,
    getMenuTopError: (state) => state,
  },
})

export const { getMenuTopSuccess, getMenuTopError } = menuTopSlice.actions

export default menuTopSlice.reducer
