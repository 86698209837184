import React from 'react';
import { createPortal } from "react-dom";

const modalRoot = document.querySelector("#modal-root");

const ErrorPopUp = () => {
  return createPortal(
    <div className="ErrorPopUp">
      <div className="ErrorPopUp_wrapper">
        <div className="ErrorPopUp">
          <div className="ErrorPopUp-content">
            <div className="ErrorPopUp-content_title">
              It looks like we’re having some internal issues.
              Our team has been notified. Kindly restart the App
            </div>
            <div className="ErrorPopUp-content_title">
              ऐसा लगता है कि हमें कुछ आंतरिक समस्याएं हो रही हैं।
              हमारी टीम को सूचित कर दिया गया है। कृपया ऐप को पुनरारंभ करें
            </div>
            <button className="ErrorPopUp-content_button"
              onClick={() => {
                window.location.reload();
                localStorage.removeItem("pageStack")
                localStorage.removeItem("page")
              }}>
              Reload page
            </button>
          </div>
        </div>
      </div>
    </div>,
    modalRoot
  )
}

export default ErrorPopUp
