import React from 'react'
import './OpenBetsCasino.scss'
import { connectOpenBetsCasino } from './OpenBetsCasino.connect'
import OpenBetsListCasino from '../OpenBetsListCasino/OpenBetsListCasino'

const OpenBetsCasino = ({
  timeSettings,
  openBets,
  title,
  redirect,
  isEvent,
}) => {
  return (
    <section className="OpenBets">
      <>
        <OpenBetsListCasino
          bets={openBets}
          timeSettings={timeSettings}
          isEvent={isEvent}
          redirect={redirect}
          title={title}
        />
      </>
    </section>
  )
}

export default connectOpenBetsCasino()(OpenBetsCasino)
