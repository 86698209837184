import { createSlice } from '@reduxjs/toolkit'

export const logoSlice = createSlice({
  name: 'logo',
  initialState: [],
  reducers: {
    getLogoSuccess: (state, action) => action.payload,
    getLogoError: (state) => state,
  },
})

export const { getLogoSuccess, getLogoError } = logoSlice.actions

export default logoSlice.reducer
