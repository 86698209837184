import httpAuth from '../../services/httpAuth'
import getErrorMessage from '../../helpers/getErrorMessage.helper'
import { getFortuneWheelError, getFortuneWheelSuccess } from './fortuneWheel.slice'

export const requestFortuneWheel = (id = '70001', type = "FAWK") => async (dispatch) => {
  // try {
  //     const response = await httpAuth.post(`/casino/live/session/open`, { id, type })
  //     if (response.data.success) {
  //       dispatch(getFortuneWheelSuccess(response.data.result))
  //     }
  //     return response
  // } catch (e) {
  //   dispatch(getFortuneWheelError())
  // }
}



export const requestFortuneWheelEnd = () => async (dispatch) => {
  try {
    const response = await httpAuth.post(`/casino/live/session/close`)
    if (response.data.success) {
      dispatch(getFortuneWheelSuccess(response.data.result))
    }
    return response
  } catch (e) {
    dispatch(getFortuneWheelError())
    // const errorMsg = getErrorMessage(e)
    // throw errorMsg
  }
}
