import React, { useEffect } from 'react'
import { IonContent, IonPage, IonHeader } from '@ionic/react'
import { connectExchangeGamesMobile } from './ExchangeGamesMobile.connect'
import ExchangeGamesDesktopList from '../../components/ExchangeGamesDesktopList/ExchangeGamesDesktopList'
import FooterDesktop from '../../components/FooterDesktop/FooterDesktop'
import Toolbar from '../../components/Toolbar/Toolbar'
import mobileBgImg from '../../assets/images/exchange_games_bg_mobile.jpg'
import wheelIn from '../ExchangeGames/wheelIn.png'
import wheelOut from '../ExchangeGames/wheelOut.png'
import girlMobile from './girlMobile.png'
import './ExchangeGamesMobile.scss'
import SlotsCasinoPopUp from '../../components/SlotsCasinoPopup'

const ExchangeGamesMobile = ({ actions, exchangeGamesAll, auth, slotCasinoPopUp }) => {
  useEffect(() => {
    actions.requestExchangeGamesAll()
  }, [auth])

  const balls = () => {
    return (
      <div className="commonForC">
        {Array(30)
          .fill(1)
          .map((el, i) => (
            <div key={i} className="c"></div>
          ))}
      </div>
    )
  }

  return (
    <>
    {slotCasinoPopUp && <SlotsCasinoPopUp/>}
    <IonPage>
      <IonHeader>
        <Toolbar />
      </IonHeader>
      <IonContent>
        <div
          className={`exchangeGamesMobile ${
            auth && exchangeGamesAll ? ' ' : 'unauthorized'
          }`}
        >
          <div className="exchangeGamesMobile-header">
            <div className="exchangeGamesMobile-header-anim">
              {balls()}
              <div>
                <img loading={"lazy"} className="insideRouletteM" src={wheelIn} alt="f"></img>
                <img loading={"lazy"} className="outsideRouletteM" src={wheelOut} alt="f"></img>
              </div>
              <img loading={"lazy"} src={girlMobile} alt="girl" className="girl" />
            </div>
            <div className="exchangeGamesMobile-header-text">
              <div className="exchangeGamesMobile-header__credo">
                Play. Win. Enjoy.
              </div>
              <div className="exchangeGamesMobile-header__description">
                We have something for everyone. With over 30 games to choose
                from, play the best slots, jackpots and live casino games.
              </div>
            </div>
          </div>
          <div className="exchangeGamesMobile-content">
            {exchangeGamesAll && (
              <div className="exchangeGamesMobile-content__allgames">
                <div className="exchangeGamesMobile-content__mobile-background">
                  <img
                    loading={"lazy"}
                    className={`exchangeGamesMobile-content__mobile-background-img`}
                    src={mobileBgImg}
                    alt=""
                  />
                </div>
                <div className="exchangeGamesMobile-content__livegames">
                  <h2 className="exchangeGamesMobile-content__title">
                    Live games
                  </h2>

                  <ExchangeGamesDesktopList
                    type={'inpl'}
                    itemsData={exchangeGamesAll?.liveGames}
                    actions={actions}
                  />
                </div>
                <div className="exchangeGamesMobile-content__livegames">
                  <h2 className="exchangeGamesMobile-content__title">
                    Virtual games
                  </h2>
                  <ExchangeGamesDesktopList
                    type={'inpl'}
                    itemsData={exchangeGamesAll?.virtual}
                    actions={actions}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
          {/*<FooterDesktop />*/}
      </IonContent>
    </IonPage>
    </>
  )
}

export default connectExchangeGamesMobile()(ExchangeGamesMobile)
