import { useSelector, useDispatch } from 'react-redux'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import Slider from 'react-slick'
import './TopBannerSlider.scss'
import { setLoginNew } from '../../../store/LoginNew/loginNew.slice'
import { setUrlForSportbook } from '../../../store/betbyWidget/urlForSportbook.slice'
import ReactGA from 'react-ga4'
// import BASE_DEV_URL from '../../../services/httpAuth'
import { BASE_DEV_URL, BASE_DEV_URL_PUBLIC } from '../../../config/api.config'

const TopBannerSlider = ({ inPlaySlidingBanners }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const auth = useSelector((state) => state.auth)
  const [isSwipe, setIsSwipe] = useState(false)

  const swipeHandler = () => {
    setIsSwipe(true)
    setTimeout(() => setIsSwipe(false), 500)
  }
  const settings = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: inPlaySlidingBanners.length,
    slidesToScroll: 1,
    pauseOnHover: false,
    className: 'slider variable-width',
    variableWidth: true,
    responsive: [
      {
        breakpoint: 9999,
        settings: {
          autoplaySpeed: 3000,
          autoplay: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  }

  const inPlaySlidingBannersSortForIndex = [...inPlaySlidingBanners].sort(
    (a, b) => (a.index > b.index ? 1 : -1)
  )

  const gotoEvent = (redirect) => {
    if (redirect.startsWith('http')) {
      window.location.href = redirect
    }

    if (redirect.split('=')[1]) {
      dispatch(setUrlForSportbook(redirect.split('=')[1]))
    } else {
      dispatch(setUrlForSportbook(null))
    }
    history.push(redirect)
  }

  //TODO: top baner here

  let bannerList = inPlaySlidingBannersSortForIndex.map((item) => {
    let image_url = `${BASE_DEV_URL}${item.description}`

    if (item.hasOwnProperty('self_host')) {
      if (item.self_host == true) {
        image_url = `${BASE_DEV_URL}${item.description}`
      } else {
        image_url = item.description;
      }
    } else {
      image_url = `${BASE_DEV_URL}${item.description}`
    }

    return (
      // item?.name !== '' && (
      <div className="slider_banner__item" key={item.index}>
        <img
          className="slider_banner__item_img"
          alt={item.title}
          // style={{ backgroundImage: `url(${image_url})`, backgroundColor: item.backgroundColour}}
          src={image_url}
          // onClick={() => {
          //   if (!isSwipe && item?.redirect && item.redirect !== ""){
          //     ReactGA.event({
          //       label: "Top-Banner",
          //       category: "User-Click",
          //       action: item?.name + "_Top_Banner",
          //     });
          //     item.redirect && gotoEvent(item.redirect);

          //   }

          // if(auth) {
          //   if(!isSwipe)  gotoEvent(item.redirect)
          // } else {
          //   dispatch(setLoginNew(true))
          // }
          // }
          // }
        />
      </div>
    )
  })

  return (
    <Slider onSwipe={swipeHandler} {...settings}>
      {bannerList}
    </Slider>
  )
}

export default TopBannerSlider
