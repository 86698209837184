import React, { useEffect, useState } from 'react'
import {
  IonContent,
  IonPage,
  IonHeader,
  IonIcon,
  IonButton,
} from '@ionic/react'
import './RulesPage.scss'
import { connectRulesPage } from './RulesPage.connect'
import { useHistory, useParams } from 'react-router'
import goBackIcon from '../../assets/images/goback.svg'
import { useSelector, useDispatch } from 'react-redux'
import { GeneralRules } from '../../constants/Rules'

const RulesPage = ({ openBets, actions }) => {
  const history = useHistory()
  const { eventTypeId, competitionId } = useParams()

  const rulesPage = useSelector((state) => state.rulesPage)
  console.log(rulesPage)
  const { isOpen } = useSelector((state) => state.rulesPopUp)

  function createMarkup(info) {
    try{
      return { __html: info?.replaceAll('\\n', '') }
    } catch (e) {
      console.log(e);
    }
  }

  const [show, setShow] = useState(false)
  const [showFancy, setShowFancy] = useState(false)

  const handleToggle = () => {
    setShow(!show)
  }
  const handleToggleFancy = () => {
    setShowFancy(!showFancy)
  }

  useEffect(() => {
    if (
      history.location.pathname.indexOf(
        `/rules_page/${eventTypeId}/${competitionId}`
      ) !== -1
    )
      actions.requestRulesPage(eventTypeId, competitionId)
  }, [competitionId, eventTypeId, history.location.pathname])

  return (
    <IonPage>
      <IonHeader className="RulesPage__header">
        <IonButton
          className="RulesPage__header__btn"
          mode="md"
          fill="clear"
          onClick={() => history.goBack()}
        >
          {/* <IonIcon className="menu-icon" src={goBackIcon} /> */}
          <IonIcon className="menu-icon go-back-icon" />
        </IonButton>
        <div className="RulesPage__header__title">Rules & Results</div>
      </IonHeader>
      <IonContent className="RulesPage" fullscreen>
        <div className={`rules-popupSportEvents__content`}>
          {eventTypeId == 2 ||
            eventTypeId == 3 ||
            (eventTypeId == 4 && (
              <>
                {
                  <div class="dropdown">
                    <div className="rulesTitle" onClick={() => handleToggle()}>
                      <span
                        className={`${
                          !show
                            ? 'rules-accordion__icon'
                            : 'rules-accordion__iconRot'
                        }`}
                      ></span>
                      General Rules
                    </div>
                    <div className={`${!show ? 'notShow' : 'show'}`}>
                      {show ? (
                        <div
                          className="rulesContainer generalFont"
                          dangerouslySetInnerHTML={createMarkup(
                            GeneralRules[0].rules
                          )}
                        ></div>
                      ) : null}
                    </div>
                  </div>
                }
              </>
            ))}
          {eventTypeId == 2 ||
            eventTypeId == 3 ||
            (eventTypeId == 4 && (
              <>
                {
                  <div class="dropdownFancy">
                    <div
                      className="rulesTitle"
                      onClick={() => handleToggleFancy()}
                    >
                      <span
                        className={`${
                          !showFancy
                            ? 'rules-accordion__icon'
                            : 'rules-accordion__iconRot'
                        }`}
                      ></span>
                      Fancy Rules
                    </div>
                    <div className={`${!showFancy ? 'notShow' : 'showFancy'}`}>
                      {showFancy ? (
                        <div
                          className="rulesContainer"
                          dangerouslySetInnerHTML={createMarkup(
                            GeneralRules[1].rules
                          )}
                        ></div>
                      ) : null}
                    </div>
                  </div>
                }
              </>
            ))}
        </div>
      </IonContent>
    </IonPage>
  )
}

export default connectRulesPage()(RulesPage)
