export // Full static list of countries with codes and flags
const countryOptions = [
  { value: '+93', label: 'AF (+93)', flag: 'af', length: 9, country: 'Afghanistan' },
  { value: '+355', label: 'AL (+355)', flag: 'al', length: 9, country: 'Albania' },
  { value: '+213', label: 'DZ (+213)', flag: 'dz', length: 9, country: 'Algeria' },
  { value: '+376', label: 'AD (+376)', flag: 'ad', length: 6, country: 'Andorra' },
  { value: '+244', label: 'AO (+244)', flag: 'ao', length: 9, country: 'Angola' },
  { value: '+1-268', label: 'AG (+1-268)', flag: 'ag', length: 7, country: 'Antigua and Barbuda' },
  { value: '+54', label: 'AR (+54)', flag: 'ar', length: 10, country: 'Argentina' },
  { value: '+374', label: 'AM (+374)', flag: 'am', length: 8, country: 'Armenia' },
  { value: '+61', label: 'AU (+61)', flag: 'au', length: 9, country: 'Australia' },
  { value: '+43', label: 'AT (+43)', flag: 'at', length: 10, country: 'Austria' },
  { value: '+994', label: 'AZ (+994)', flag: 'az', length: 9, country: 'Azerbaijan' },
  { value: '+1-242', label: 'BS (+1-242)', flag: 'bs', length: 7, country: 'Bahamas' },
  { value: '+973', label: 'BH (+973)', flag: 'bh', length: 8, country: 'Bahrain' },
  { value: '+880', label: 'BD (+880)', flag: 'bd', length: 10, country: 'Bangladesh' },
  { value: '+1-246', label: 'BB (+1-246)', flag: 'bb', length: 7, country: 'Barbados' },
  { value: '+375', label: 'BY (+375)', flag: 'by', length: 9, country: 'Belarus' },
  { value: '+32', label: 'BE (+32)', flag: 'be', length: 9, country: 'Belgium' },
  { value: '+501', label: 'BZ (+501)', flag: 'bz', length: 7, country: 'Belize' },
  { value: '+229', label: 'BJ (+229)', flag: 'bj', length: 8, country: 'Benin' },
  { value: '+975', label: 'BT (+975)', flag: 'bt', length: 8, country: 'Bhutan' },
  { value: '+591', label: 'BO (+591)', flag: 'bo', length: 8, country: 'Bolivia' },
  { value: '+387', label: 'BA (+387)', flag: 'ba', length: 8, country: 'Bosnia and Herzegovina' },
  { value: '+267', label: 'BW (+267)', flag: 'bw', length: 7, country: 'Botswana' },
  { value: '+55', label: 'BR (+55)', flag: 'br', length: 11, country: 'Brazil' },
  { value: '+673', label: 'BN (+673)', flag: 'bn', length: 7, country: 'Brunei' },
  { value: '+359', label: 'BG (+359)', flag: 'bg', length: 9, country: 'Bulgaria' },
  { value: '+226', label: 'BF (+226)', flag: 'bf', length: 8, country: 'Burkina Faso' },
  { value: '+257', label: 'BI (+257)', flag: 'bi', length: 8, country: 'Burundi' },
  { value: '+855', label: 'KH (+855)', flag: 'kh', length: 8, country: 'Cambodia' },
  { value: '+237', label: 'CM (+237)', flag: 'cm', length: 9, country: 'Cameroon' },
  { value: '+1', label: 'CA (+1)', flag: 'ca', length: 10, country: 'Canada' },
  { value: '+238', label: 'CV (+238)', flag: 'cv', length: 7, country: 'Cape Verde' },
  { value: '+236', label: 'CF (+236)', flag: 'cf', length: 8, country: 'Central African Republic' },
  { value: '+235', label: 'TD (+235)', flag: 'td', length: 8, country: 'Chad' },
  { value: '+56', label: 'CL (+56)', flag: 'cl', length: 9, country: 'Chile' },
  { value: '+86', label: 'CN (+86)', flag: 'cn', length: 11, country: 'China' },
  { value: '+57', label: 'CO (+57)', flag: 'co', length: 10, country: 'Colombia' },
  { value: '+269', label: 'KM (+269)', flag: 'km', length: 7, country: 'Comoros' },
  { value: '+242', label: 'CG (+242)', flag: 'cg', length: 9, country: 'Congo' },
  { value: '+243', label: 'CD (+243)', flag: 'cd', length: 9, country: 'Congo, Democratic Republic' },
  { value: '+506', label: 'CR (+506)', flag: 'cr', length: 8, country: 'Costa Rica' },
  { value: '+385', label: 'HR (+385)', flag: 'hr', length: 9, country: 'Croatia' },
  { value: '+53', label: 'CU (+53)', flag: 'cu', length: 8, country: 'Cuba' },
  { value: '+357', label: 'CY (+357)', flag: 'cy', length: 8, country: 'Cyprus' },
  { value: '+420', label: 'CZ (+420)', flag: 'cz', length: 9, country: 'Czech Republic' },
  { value: '+45', label: 'DK (+45)', flag: 'dk', length: 8, country: 'Denmark' },
  { value: '+253', label: 'DJ (+253)', flag: 'dj', length: 8, country: 'Djibouti' },
  { value: '+1-767', label: 'DM (+1-767)', flag: 'dm', length: 7, country: 'Dominica' },
  { value: '+1-809', label: 'DO (+1-809)', flag: 'do', length: 7, country: 'Dominican Republic' },
  { value: '+593', label: 'EC (+593)', flag: 'ec', length: 9, country: 'Ecuador' },
  { value: '+20', label: 'EG (+20)', flag: 'eg', length: 10, country: 'Egypt' },
  { value: '+503', label: 'SV (+503)', flag: 'sv', length: 8, country: 'El Salvador' },
  { value: '+240', label: 'GQ (+240)', flag: 'gq', length: 9, country: 'Equatorial Guinea' },
  { value: '+291', label: 'ER (+291)', flag: 'er', length: 7, country: 'Eritrea' },
  { value: '+372', label: 'EE (+372)', flag: 'ee', length: 8, country: 'Estonia' },
  { value: '+251', label: 'ET (+251)', flag: 'et', length: 9, country: 'Ethiopia' },
  { value: '+679', label: 'FJ (+679)', flag: 'fj', length: 7, country: 'Fiji' },
  { value: '+358', label: 'FI (+358)', flag: 'fi', length: 10, country: 'Finland' },
  { value: '+33', label: 'FR (+33)', flag: 'fr', length: 9, country: 'France' },
  { value: '+241', label: 'GA (+241)', flag: 'ga', length: 7, country: 'Gabon' },
  { value: '+220', label: 'GM (+220)', flag: 'gm', length: 7, country: 'Gambia' },
  { value: '+995', label: 'GE (+995)', flag: 'ge', length: 9, country: 'Georgia' },
  { value: '+49', label: 'DE (+49)', flag: 'de', length: 10, country: 'Germany' },
  { value: '+233', label: 'GH (+233)', flag: 'gh', length: 9, country: 'Ghana' },
  { value: '+30', label: 'GR (+30)', flag: 'gr', length: 10, country: 'Greece' },
  { value: '+1-473', label: 'GD (+1-473)', flag: 'gd', length: 7, country: 'Grenada' },
  { value: '+299', label: 'GL (+299)', flag: 'gl', length: 6, country: 'Greenland' },
{ value: '+502', label: 'GT (+502)', flag: 'gt', length: 8, country: 'Guatemala' },
{ value: '+224', label: 'GN (+224)', flag: 'gn', length: 9, country: 'Guinea' },
{ value: '+245', label: 'GW (+245)', flag: 'gw', length: 7, country: 'Guinea-Bissau' },
{ value: '+592', label: 'GY (+592)', flag: 'gy', length: 7, country: 'Guyana' },
{ value: '+509', label: 'HT (+509)', flag: 'ht', length: 8, country: 'Haiti' },
{ value: '+504', label: 'HN (+504)', flag: 'hn', length: 8, country: 'Honduras' },
{ value: '+36', label: 'HU (+36)', flag: 'hu', length: 9, country: 'Hungary' },
{ value: '+354', label: 'IS (+354)', flag: 'is', length: 7, country: 'Iceland' },
{ value: '+91', label: 'IN (+91)', flag: 'in', length: 10, country: 'India' },
{ value: '+62', label: 'ID (+62)', flag: 'id', length: 10, country: 'Indonesia' },
{ value: '+98', label: 'IR (+98)', flag: 'ir', length: 10, country: 'Iran' },
{ value: '+964', label: 'IQ (+964)', flag: 'iq', length: 10, country: 'Iraq' },
{ value: '+353', label: 'IE (+353)', flag: 'ie', length: 9, country: 'Ireland' },
{ value: '+972', label: 'IL (+972)', flag: 'il', length: 9, country: 'Israel' },
{ value: '+39', label: 'IT (+39)', flag: 'it', length: 10, country: 'Italy' },
{ value: '+1-876', label: 'JM (+1-876)', flag: 'jm', length: 7, country: 'Jamaica' },
{ value: '+81', label: 'JP (+81)', flag: 'jp', length: 10, country: 'Japan' },
{ value: '+962', label: 'JO (+962)', flag: 'jo', length: 9, country: 'Jordan' },
{ value: '+7', label: 'KZ (+7)', flag: 'kz', length: 10, country: 'Kazakhstan' },
{ value: '+254', label: 'KE (+254)', flag: 'ke', length: 9, country: 'Kenya' },
{ value: '+686', label: 'KI (+686)', flag: 'ki', length: 8, country: 'Kiribati' },
{ value: '+383', label: 'XK (+383)', flag: 'xk', length: 9, country: 'Kosovo' },
{ value: '+965', label: 'KW (+965)', flag: 'kw', length: 8, country: 'Kuwait' },
{ value: '+996', label: 'KG (+996)', flag: 'kg', length: 9, country: 'Kyrgyzstan' },
{ value: '+856', label: 'LA (+856)', flag: 'la', length: 9, country: 'Laos' },
{ value: '+371', label: 'LV (+371)', flag: 'lv', length: 8, country: 'Latvia' },
{ value: '+961', label: 'LB (+961)', flag: 'lb', length: 8, country: 'Lebanon' },
{ value: '+266', label: 'LS (+266)', flag: 'ls', length: 9, country: 'Lesotho' },
{ value: '+231', label: 'LR (+231)', flag: 'lr', length: 7, country: 'Liberia' },
{ value: '+218', label: 'LY (+218)', flag: 'ly', length: 9, country: 'Libya' },
{ value: '+423', label: 'LI (+423)', flag: 'li', length: 7, country: 'Liechtenstein' },
{ value: '+370', label: 'LT (+370)', flag: 'lt', length: 8, country: 'Lithuania' },
{ value: '+352', label: 'LU (+352)', flag: 'lu', length: 9, country: 'Luxembourg' },
{ value: '+261', label: 'MG (+261)', flag: 'mg', length: 10, country: 'Madagascar' },
{ value: '+265', label: 'MW (+265)', flag: 'mw', length: 10, country: 'Malawi' },
{ value: '+60', label: 'MY (+60)', flag: 'my', length: 9, country: 'Malaysia' },
{ value: '+960', label: 'MV (+960)', flag: 'mv', length: 9, country: 'Maldives' },
{ value: '+223', label: 'ML (+223)', flag: 'ml', length: 8, country: 'Mali' },
{ value: '+356', label: 'MT (+356)', flag: 'mt', length: 8, country: 'Malta' },
{ value: '+692', label: 'MH (+692)', flag: 'mh', length: 7, country: 'Marshall Islands' },
{ value: '+222', label: 'MR (+222)', flag: 'mr', length: 8, country: 'Mauritania' },
{ value: '+230', label: 'MU (+230)', flag: 'mu', length: 10, country: 'Mauritius' },
{ value: '+52', label: 'MX (+52)', flag: 'mx', length: 10, country: 'Mexico' },
{ value: '+691', label: 'FM (+691)', flag: 'fm', length: 7, country: 'Micronesia' },
{ value: '+373', label: 'MD (+373)', flag: 'md', length: 8, country: 'Moldova' },
{ value: '+377', label: 'MC (+377)', flag: 'mc', length: 8, country: 'Monaco' },
{ value: '+976', label: 'MN (+976)', flag: 'mn', length: 8, country: 'Mongolia' },
{ value: '+382', label: 'ME (+382)', flag: 'me', length: 8, country: 'Montenegro' },
{ value: '+212', label: 'MA (+212)', flag: 'ma', length: 9, country: 'Morocco' },
{ value: '+258', label: 'MZ (+258)', flag: 'mz', length: 9, country: 'Mozambique' },
{ value: '+95', label: 'MM (+95)', flag: 'mm', length: 9, country: 'Myanmar' },
{ value: '+264', label: 'NA (+264)', flag: 'na', length: 9, country: 'Namibia' },
{ value: '+674', label: 'NR (+674)', flag: 'nr', length: 8, country: 'Nauru' },
{ value: '+977', label: 'NP (+977)', flag: 'np', length: 10, country: 'Nepal' },
{ value: '+31', label: 'NL (+31)', flag: 'nl', length: 9, country: 'Netherlands' },
{ value: '+64', label: 'NZ (+64)', flag: 'nz', length: 9, country: 'New Zealand' },
{ value: '+505', label: 'NI (+505)', flag: 'ni', length: 8, country: 'Nicaragua' },
{ value: '+227', label: 'NE (+227)', flag: 'ne', length: 8, country: 'Niger' },
{ value: '+234', label: 'NG (+234)', flag: 'ng', length: 10, country: 'Nigeria' },
{ value: '+389', label: 'MK (+389)', flag: 'mk', length: 8, country: 'North Macedonia' },
{ value: '+47', label: 'NO (+47)', flag: 'no', length: 8, country: 'Norway' },
{ value: '+968', label: 'OM (+968)', flag: 'om', length: 8, country: 'Oman' },
{ value: '+92', label: 'PK (+92)', flag: 'pk', length: 10, country: 'Pakistan' },
{ value: '+680', label: 'PW (+680)', flag: 'pw', length: 7, country: 'Palau' },
{ value: '+507', label: 'PA (+507)', flag: 'pa', length: 7, country: 'Panama' },
{ value: '+675', label: 'PG (+675)', flag: 'pg', length: 10, country: 'Papua New Guinea' },
{ value: '+595', label: 'PY (+595)', flag: 'py', length: 9, country: 'Paraguay' },
{ value: '+51', label: 'PE (+51)', flag: 'pe', length: 9, country: 'Peru' },
{ value: '+63', label: 'PH (+63)', flag: 'ph', length: 10, country: 'Philippines' },
{ value: '+48', label: 'PL (+48)', flag: 'pl', length: 9, country: 'Poland' },
{ value: '+351', label: 'PT (+351)', flag: 'pt', length: 9, country: 'Portugal' },
{ value: '+974', label: 'QA (+974)', flag: 'qa', length: 8, country: 'Qatar' },
{ value: '+40', label: 'RO (+40)', flag: 'ro', length: 10, country: 'Romania' },
{ value: '+7', label: 'RU (+7)', flag: 'ru', length: 10, country: 'Russia' },
{ value: '+250', label: 'RW (+250)', flag: 'rw', length: 9, country: 'Rwanda' },
{ value: '+1-869', label: 'KN (+1-869)', flag: 'kn', length: 7, country: 'Saint Kitts and Nevis' },
{ value: '+1-758', label: 'LC (+1-758)', flag: 'lc', length: 7, country: 'Saint Lucia' },
{ value: '+1-784', label: 'VC (+1-784)', flag: 'vc', length: 7, country: 'Saint Vincent and the Grenadines' },
{ value: '+685', label: 'WS (+685)', flag: 'ws', length: 7, country: 'Samoa' },
{ value: '+378', label: 'SM (+378)', flag: 'sm', length: 7, country: 'San Marino' },
{ value: '+239', label: 'ST (+239)', flag: 'st', length: 7, country: 'Sao Tome and Principe' },
{ value: '+966', label: 'SA (+966)', flag: 'sa', length: 9, country: 'Saudi Arabia' },
{ value: '+221', label: 'SN (+221)', flag: 'sn', length: 9, country: 'Senegal' },
{ value: '+381', label: 'RS (+381)', flag: 'rs', length: 9, country: 'Serbia' },
{ value: '+248', label: 'SC (+248)', flag: 'sc', length: 8, country: 'Seychelles' },
{ value: '+232', label: 'SL (+232)', flag: 'sl', length: 8, country: 'Sierra Leone' },
{ value: '+65', label: 'SG (+65)', flag: 'sg', length: 8, country: 'Singapore' },
{ value: '+421', label: 'SK (+421)', flag: 'sk', length: 9, country: 'Slovakia' },
{ value: '+386', label: 'SI (+386)', flag: 'si', length: 8, country: 'Slovenia' },
{ value: '+677', label: 'SB (+677)', flag: 'sb', length: 7, country: 'Solomon Islands' },
{ value: '+252', label: 'SO (+252)', flag: 'so', length: 9, country: 'Somalia' },
{ value: '+27', label: 'ZA (+27)', flag: 'za', length: 10, country: 'South Africa' },
{ value: '+211', label: 'SS (+211)', flag: 'ss', length: 9, country: 'South Sudan' },
{ value: '+34', label: 'ES (+34)', flag: 'es', length: 9, country: 'Spain' },
{ value: '+94', label: 'LK (+94)', flag: 'lk', length: 10, country: 'Sri Lanka' },
{ value: '+249', label: 'SD (+249)', flag: 'sd', length: 9, country: 'Sudan' },
{ value: '+597', label: 'SR (+597)', flag: 'sr', length: 8, country: 'Suriname' },
{ value: '+46', label: 'SE (+46)', flag: 'se', length: 9, country: 'Sweden' },
{ value: '+41', label: 'CH (+41)', flag: 'ch', length: 9, country: 'Switzerland' },
{ value: '+963', label: 'SY (+963)', flag: 'sy', length: 9, country: 'Syria' },
{ value: '+886', label: 'TW (+886)', flag: 'tw', length: 10, country: 'Taiwan' },
{ value: '+992', label: 'TJ (+992)', flag: 'tj', length: 9, country: 'Tajikistan' },
{ value: '+255', label: 'TZ (+255)', flag: 'tz', length: 10, country: 'Tanzania' },
{ value: '+66', label: 'TH (+66)', flag: 'th', length: 9, country: 'Thailand' },
{ value: '+670', label: 'TL (+670)', flag: 'tl', length: 9, country: 'Timor-Leste' },
{ value: '+228', label: 'TG (+228)', flag: 'tg', length: 8, country: 'Togo' },
{ value: '+676', label: 'TO (+676)', flag: 'to', length: 8, country: 'Tonga' },
{ value: '+1-868', label: 'TT (+1-868)', flag: 'tt', length: 7, country: 'Trinidad and Tobago' },
{ value: '+216', label: 'TN (+216)', flag: 'tn', length: 8, country: 'Tunisia' },
{ value: '+90', label: 'TR (+90)', flag: 'tr', length: 10, country: 'Turkey' },
{ value: '+993', label: 'TM (+993)', flag: 'tm', length: 8, country: 'Turkmenistan' },
{ value: '+688', label: 'TV (+688)', flag: 'tv', length: 7, country: 'Tuvalu' },
{ value: '+256', label: 'UG (+256)', flag: 'ug', length: 10, country: 'Uganda' },
{ value: '+380', label: 'UA (+380)', flag: 'ua', length: 9, country: 'Ukraine' },
{ value: '+971', label: 'AE (+971)', flag: 'ae', length: 9, country: 'United Arab Emirates' },
{ value: '+44', label: 'GB (+44)', flag: 'gb', length: 10, country: 'United Kingdom' },
{ value: '+1', label: 'US (+1)', flag: 'us', length: 10, country: 'United States' },
{ value: '+598', label: 'UY (+598)', flag: 'uy', length: 8, country: 'Uruguay' },
{ value: '+998', label: 'UZ (+998)', flag: 'uz', length: 9, country: 'Uzbekistan' },
{ value: '+678', label: 'VU (+678)', flag: 'vu', length: 7, country: 'Vanuatu' },
{ value: '+379', label: 'VA (+379)', flag: 'va', length: 7, country: 'Vatican City' },
{ value: '+58', label: 'VE (+58)', flag: 've', length: 10, country: 'Venezuela' },
{ value: '+84', label: 'VN (+84)', flag: 'vn', length: 10, country: 'Vietnam' },
{ value: '+681', label: 'WF (+681)', flag: 'wf', length: 7, country: 'Wallis and Futuna' },
{ value: '+212', label: 'EH (+212)', flag: 'eh', length: 9, country: 'Western Sahara' },
{ value: '+967', label: 'YE (+967)', flag: 'ye', length: 9, country: 'Yemen' },
{ value: '+260', label: 'ZM (+260)', flag: 'zm', length: 10, country: 'Zambia' },
{ value: '+263', label: 'ZW (+263)', flag: 'zw', length: 9, country: 'Zimbabwe' },
];
