import React, { useEffect, useState } from 'react'
import { tsToDate } from '../../helpers/date.helper'
import SportMarketStandard from '../SportMarketStandard/SportMarketStandard'
import { connectUpcomingRacesAccordion } from './UpcomingRacesAccordion.connect'
import './UpcomingRacesAccordion.scss'
import { IonList } from '@ionic/react'
import { joidTigari } from '../../helpers/joditigari.helper'

const UpcomingRacesAccordion = ({
  item,
  index,
  timeSettings,
  betPlacing,
  raceOdds,
  showHeader = false,
  showJockeyName = false,
  isAccordionOpen,
  setIsAccordionOpen,
  actions,
}) => {
  const [allRunnerSuspend, setAllRunnerSuspend] = useState(false)
  const [eventID, setEventId] = useState(null)
  const [selectedRaces, setSelectedRaces] = useState([])
  const [combinedMarket, setCombinedMarket] = useState(null)

  useEffect(()=> {
    if (selectedRaces.length <= 1) {
      setCombinedMarket(null);
    } else {
      let horseData = joidTigari(selectedRaces)
      setCombinedMarket(horseData);
    }
  }, [selectedRaces])

  // get all suspend
  useEffect(() => {
    if (
      raceOdds &&
      raceOdds[`${eventID} ${isAccordionOpen}`]?.runners?.filter(
        (runner) => runner.status !== 'SUSPENDED'
      ).length >= 1
    ) {
      // console.log('item', raceOdds[`${eventID} ${isAccordionOpen}`])
      setAllRunnerSuspend(false)
    } else {
      setAllRunnerSuspend(true)
    }
  }, [raceOdds, eventID, isAccordionOpen])

  // end get all suspend
  // console.log('item', raceOdds)
  // slice first 3 runners
  const slicedItem = { ...item, runners: item?.runners?.slice(0, 3) }

  // country flag
  let flag = slicedItem?.country_code?.toLowerCase()
  // console.log('slicedItem?.event', slicedItem?.event)

  const setEvent = (item) => {
    setIsAccordionOpen(item?.market_id)
    setEventId(item?.match_id)

    actions.requestRaceOdds(item?.match_id, item?.market_id)
  }

  return (
    <div
      key={slicedItem?.market_id + index}
      className="race-page__content-upcoming-races-table-row"
    >
      <div
        className="race-page__content-upcoming-races-table-row-header f32"
        onClick={() =>
          isAccordionOpen === slicedItem?.market_id
            ? setIsAccordionOpen(null)
            : setEvent(slicedItem)
        }
      >
        <div className={`flag ${flag}`}></div>
        <div className="race-page__content-upcoming-races-table-row-header-text">
          <span>{slicedItem?.name?.replace('${HH:mm}', '')}</span>
          <span>
            {tsToDate(
              Date.parse(slicedItem?.market_start_time),
              timeSettings?.timeZoneName
            )
              .split(' ')[1]
              .split(':', 2)
              .join(':')}
          </span>
        </div>
        <div className="race-page__content-upcoming-races-table-row-header-arrowContainer">
          <span
            className={`race-page__content-upcoming-races-table-row-header-arrow ${
              isAccordionOpen === slicedItem?.market_id ? 'active' : ''
            }`}
          ></span>
        </div>
      </div>
      {combinedMarket &&
            <div
            className={`race-page__content-upcoming-races-table-row-inner ${
              isAccordionOpen === slicedItem?.market_id ? 'open' : ''
            }`}
          >
            <div className="race-page__content-rase-odds-table-header">
              <p>
              Combined Market
              </p>
              <div>
                <span>Back</span>
                <span>Lay</span>
              </div>
            </div>
            <div
              className={`race-page__content-upcoming-races-table-row-items-wrapper ${
                allRunnerSuspend === true ? 'all-suspended' : ''
              }`}
            >
              {allRunnerSuspend === true && (
                <div className="race-page__content-all-suspend-cover">
                  <span>SUSPENDED</span>
                </div>
              )}
                {combinedMarket && (
                  <SportMarketStandard
                    data={combinedMarket}
                    key={combinedMarket?.market_id}
                    betPlacing={betPlacing}
                    showHeader={false}
                    showJockeyName={true}
                    isRacing={true}
                  />
                )}
              </div>
            </div>
}

      <div
        className={`race-page__content-upcoming-races-table-row-inner ${
          isAccordionOpen === slicedItem?.market_id ? 'open' : ''
        }`}
      >
        <div className="race-page__content-rase-odds-table-header">
          <p>
            Top 3 of{' '}
            {raceOdds &&
              raceOdds[`${eventID} ${isAccordionOpen}`]?.runners?.length}{' '}
            runners
          </p>
          <div>
            <span>Back</span>
            <span>Lay</span>
          </div>
        </div>
        <div
          className={`race-page__content-upcoming-races-table-row-items-wrapper ${
            allRunnerSuspend === true ? 'all-suspended' : ''
          }`}
        >
          {allRunnerSuspend === true && (
            <div className="race-page__content-all-suspend-cover">
              <span>SUSPENDED</span>
            </div>
          )}

          <SportMarketStandard
            data={raceOdds ? raceOdds[`${eventID} ${isAccordionOpen}`] : {}}
            key={slicedItem?.market_id}
            betPlacing={betPlacing}
            showHeader={showHeader}
            showJockeyName={showJockeyName}
            isRacing={true}
            selectedRaces={selectedRaces}
            setSelectedRaces={setSelectedRaces}
          />
        </div>
      </div>
    </div>
  )
}

export default connectUpcomingRacesAccordion()(UpcomingRacesAccordion)
