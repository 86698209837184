import httpAuth from '../../services/httpAuth'
import httpNotAuth from '../../services/httpNotAuth'
import getErrorMessage from '../../helpers/getErrorMessage.helper'
import { getOddsError, getOddsSuccess } from './raceOdds.slice'

export const requestRaceOdds = (eventId, oddId) => async (dispatch, getState) => {
  const checkAuth = getState().auth
  let data = {
    market_id: oddId,
    match_id: eventId,
    combine: true
  }
  // console.log("requestRace in req", "eventId", eventId, "oddId" , oddId)

  if(checkAuth) {
    try {
      const response = await httpAuth.post(`match/matchDetails`, data)
      if (response.data.status) {
        let payload = {
          match_id: eventId,
          marketId: oddId, 
          data: response.data.data[0]
        }
        dispatch(getOddsSuccess(payload))
      }
      return response
    } catch (e) {
      dispatch(getOddsError())
      // const errorMsg = getErrorMessage(e)
      // throw errorMsg
    }
  } else {
    try {
      const response = await httpNotAuth.post(`match/matchDetailsOpen`, { market_id: oddId, match_id: eventId,})
      if (response.data.status) {
        let payload = {
          match_id: eventId,
          marketId: oddId, 
          data: response.data.data[0]
        }
        dispatch(getOddsSuccess(payload))
      }
      return response
    } catch (e) {
      dispatch(getOddsError())
      // const errorMsg = getErrorMessage(e)
      // throw errorMsg
    }
  }
  
}