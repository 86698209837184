import httpAuth from '../../services/httpAuth'
import axios from 'axios'

import getErrorMessage from '../../helpers/getErrorMessage.helper'
import { getPromotionsError, getPromotionsSuccess, getSocialsSuccess } from './promotions.slice'
import { BASE_DEV_URL, getHostnameFromURL } from '../../config/api.config'

export const requestPromotions = () => async (dispatch) => {
    try {
        const response = await httpAuth.get("content/sliders")
        // const response = await axios.get(`https://lotus.com/banners`)
        if (response.data.status) {
            dispatch(getPromotionsSuccess(response.data.data))
        }
        return response
    } catch (e) {
        // dispatch(getPromotionsError())
        // const errorMsg = getErrorMessage(e)
        // throw errorMsg
    }
}


export const requestSocials = () => async (dispatch) => {
    let data ={"slug":`${getHostnameFromURL(BASE_DEV_URL)}-social`}
    try {
        const response = await httpAuth.post("/content/get", data)
        // const response = await axios.get(`https://lotus.com/banners`)
        if (response.data.status) {
            // console.log("response.data.result", response.data.result)
            dispatch(getSocialsSuccess(JSON.parse(response.data.data.description)))
        }
        return response
    } catch (e) {
        // dispatch(getPromotionsError())
        // const errorMsg = getErrorMessage(e)
        // throw errorMsg
    }
}
