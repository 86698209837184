import { createSlice } from '@reduxjs/toolkit'

export const promotionsSlice = createSlice({
    name: 'promotions',
    initialState: {slider: [], socials: null},
    reducers: {
        getPromotionsSuccess: (state, action) => {
            return {
              ...state,
              slider: action.payload
            }
        },
        getSocialsSuccess: (state, action) => {
            return {
              ...state,
              socials: action.payload
            }
          },
        getPromotionsError: (state) => state,
    },
})

export const {
    getPromotionsSuccess,
    getPromotionsError,
    getSocialsSuccess
} = promotionsSlice.actions

export default promotionsSlice.reducer
