import './pop-upDelete.scss'
// import { IonButton, IonImg, IonPopover } from '@ionic/react'
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import { setDetailedDepositTransaction } from '../../../store/detailedDepositTransaction/detailedDepositTransaction.slice'
import { useHistory } from 'react-router'
import { BASE_DEV_URL } from '../../../config/api.config'
import getErrorMessage from '../../../helpers/getErrorMessage.helper'
import { toastr } from 'react-redux-toastr'
import { USER_DATA } from '../../../config/app.config'


const PopUPDelete = ({ content, open, onDidDismiss, data, update }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const token = localStorage.getItem('id_token')

  const deleteWithdrawalMethod = async () => {
    const userData = localStorage.getItem(USER_DATA)
    const userDetail = JSON.parse(userData)

    const payload = {
      user_id: userDetail?._id,
      _id: data,
    };

    return await axios
      .post(BASE_DEV_URL + `wallet/removePaymentDetails`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => response.data.status ?(update(), toastr.success('Account remove successfully')) : toastr.error(response.data?.msg) )
      .then(onDidDismiss(false))
      .catch((e) => {
        // const errorMsg = getErrorMessage(e)
        // throw errorMsg
      })
  }

  // const [remark, setRemark] = useState('')
  // const requestId = data.requestId
  // const [utr, setUTR] = useState(data.utr)
  // const [amount, setAmount] = useState(data.amount)

  // console.log('data', data)
  // console.log('requestId', data.requestId)
  // console.log('utr', data.utr)
  // console.log('amount', data.amount)

  // console.log(parseInt('222', 10))

  // const canceledData = {
  //   remark,
  //   requestId,
  // }

  // const editedData = {
  //   requestId,
  //   UTR: utr,
  //   amount,
  // }

  // const cancelOneTransactionRequest = async () => {
  //   return await axios
  //     .post(
  //       `https://dapi.gemexch.bet/api/payments/userid/cancel-request?requestId=${data.requestId}`,
  //       canceledData,
  //       {
  //         headers: {
  //           Authorization: `${token}`,
  //         },
  //       }
  //     )
  //     .then((response) => console.log(response.data.result))
  //     .then(history.push('/transactions'))
  // }

  // const editOneTransactionRequest = async () => {
  //   return await axios
  //     .post(
  //       `https://dapi.gemexch.bet/api/payments/userid/update-deposit-request?requestId=${data.requestId}`,
  //       editedData,
  //       {
  //         headers: {
  //           Authorization: `${token}`,
  //         },
  //       }
  //     )
  //     .then((response) => console.log(response.data.result))
  //     .then(onDidDismiss(false))
  // }

  return (
    <>
      {open && (
        <div className="editPopUpDelete">
          <div
            className="pop-upDelete_wrapper"
            onClick={(e) => {
              if (e.currentTarget === e.target) {
                onDidDismiss(false)
              }
            }}
          >
            <div className="pop-upDelete">
              <div className="pop-upDelete-content">
                <div className='titleBox'>
                <div className="pop-upDelete-content_title" style={{margin: '0px'}}>Remove Account</div>
                <svg
                  onClick={() => onDidDismiss(false)}
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <g clip-path="url(#clip0_571_5284)">
                    <path
                      d="M9.46585 8.01265L15.6959 1.78238C16.1014 1.37713 16.1014 0.721889 15.6959 0.316635C15.2907 -0.088618 14.6354 -0.088618 14.2302 0.316635L7.99991 6.54691L1.76983 0.316635C1.36438 -0.088618 0.709336 -0.088618 0.304082 0.316635C-0.101361 0.721889 -0.101361 1.37713 0.304082 1.78238L6.53416 8.01265L0.304082 14.2429C-0.101361 14.6482 -0.101361 15.3034 0.304082 15.7087C0.506045 15.9108 0.771595 16.0124 1.03695 16.0124C1.30232 16.0124 1.56768 15.9108 1.76983 15.7087L7.99991 9.4784L14.2302 15.7087C14.4323 15.9108 14.6977 16.0124 14.9631 16.0124C15.2284 16.0124 15.4938 15.9108 15.6959 15.7087C16.1014 15.3034 16.1014 14.6482 15.6959 14.2429L9.46585 8.01265Z"
                      fill="black"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_571_5284">
                      <rect width="16" height="16" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                </div>
                <div className="pop-upDelete-content_question">Are you sure you want to remove this account</div>
              </div>

              <div className="pop-upDelete-content__buttonss">
              <div
                  className="pop-upDelete-content__buttonss-cancel"
                  onClick={() => onDidDismiss(false)}
                >
                  Cancel
                </div>

                <div
                  className="pop-upDelete-content__buttonss-delete"
                  onClick={() => {
                    deleteWithdrawalMethod()
                  }}
                >
                  Yes, Remove
                </div>

              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default PopUPDelete
