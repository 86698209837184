import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { BASE_DEV_URL } from '../../config/api.config'
import { ID_TOKEN } from '../../config/app.config'
import axios from 'axios'
import "./index.css"

export default function Card({ expired, x, activeBonusAvailable }) {
  const [activeBonus, setActiveBonus] = useState(false)
  const [claimed, setClaimed] = useState(false)
  const [activated, setActivated] = useState(false)
  const [loading, setloading] = useState(false)

  const [info, setInfo] = useState(null)
  useEffect(() => {
    if (x?.ACTIVATION_DATE && x?.EXPIRY_DATE) {
      setActiveBonus(true)
    }
  }, [])
  function getInfo(promoCode) {
    axios
      .get(`${BASE_DEV_URL}/account/promo/details?promoCode=${promoCode}`, {
        headers: {
          Authorization: localStorage.getItem(`${ID_TOKEN}`),
        },
      })
      .then(function (response) {
        console.log(response)
        // setShowInfo(true)
        setInfo(response.data.result)
      })
      .catch(function (error) {
        console.log(error)
      })
  }
  function claimBonus(bonusId) {
    setloading(true)
    axios
      .get(`${BASE_DEV_URL}/account/bonus/claim?bonus_id=${bonusId}`, {
        headers: {
          Authorization: localStorage.getItem(`${ID_TOKEN}`),
        },
      })
      .then(function (response) {
        console.log(response)
        localStorage.setItem('bonusClaimedId', bonusId)
        setloading(false)
        setClaimed(true)
      })
      .catch(function (error) {
        console.log(error)
      })
  }
  function activateBonus(bonusId) {
    setloading(true)
    axios
      .get(`${BASE_DEV_URL}/account/bonus/activate?bonus_id=${bonusId}`, {
        headers: {
          Authorization: localStorage.getItem(`${ID_TOKEN}`),
        },
      })
      .then(function (response) {
        console.log(response)
        localStorage.setItem('bonusActivatedId', bonusId)
        setloading(false)
        setActivated(true)
      })
      .catch(function (error) {
        console.log(error)
      })
  }
  useEffect(() => {
    const activatedId = localStorage.getItem('bonusActivatedId') * 1
    console.log(activatedId, x?.BONUS_ID)
    if (activatedId && activatedId === x?.BONUS_ID) {
      setActivated(true)
    }
  }, [])
  function createMarkup(promo) {
    try {
      if (typeof promo === 'string') {
        return { __html: promo?.replaceAll('\\n', '') }
      } else return { __html: '<div></div>' }
    } catch (e) {
      console.log(e)
    }
  }
  useEffect(() => {
    const claimedId = localStorage.getItem('bonusClaimedId') * 1
    console.log(claimedId, x?.BONUS_ID)
    if (claimedId && claimedId === x?.BONUS_ID) {
      setClaimed(true)
    }
  }, [])

  function timeRemaining(time) {
    var date = new Date()
    var now = date.getTime()
    var deadline = new Date(time).getTime()
    var t = deadline - now
    var days = Math.floor(t / (1000 * 60 * 60 * 24))
    var hours = Math.floor((t / (1000 * 60 * 60)) % 24)
    var mins = Math.floor((t / 1000 / 60) % 60)
    return `${days}days ${hours}hrs ${mins}mins left`
  }
  
  return (
    <>
      <div
        className="deposit-wrrap"
      >
        {expired && (
          <div className="deposit-expired">
            <div className="deposit-expired-text">
              Expired
            </div>
          </div>
        )}

        <div
          className={`expired ${
            expired
              ? 'plus-bg-slate-700'
              : activeBonus
                ? 'plus-bg-green-500'
                : 'plus-bg-orange-500'
          }`}
        >
          {!expired && <div className="shimmer-animation"></div>}
          {expired
            ? 'Expired'
            : activeBonus
              ? 'Active Bonus'
              : 'Awaiting Bonus'}
        </div>
        <svg
          onClick={() => getInfo(x?.PROMO_CODE)}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          class="info-icon"
        >
          <path
            fill-rule="evenodd"
            d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm8.706-1.442c1.146-.573 2.437.463 2.126 1.706l-.709 2.836.042-.02a.75.75 0 01.67 1.34l-.04.022c-1.147.573-2.438-.463-2.127-1.706l.71-2.836-.042.02a.75.75 0 11-.671-1.34l.041-.022zM12 9a.75.75 0 100-1.5.75.75 0 000 1.5z"
            clip-rule="evenodd"
          />
        </svg>

        <div
          className="deposit-container"
        >
          <div className="deposit-title">
            <span className="deposit-title-text">
              {x?.promoDetails?.tagLine}
            </span>
          </div>

          {activeBonus ? (
            <span className="deposit-descr">
              Current wagered amount ₹
              {x.BONUS_ID == x.wagered?.bonusId ? x?.wagered?.totalWagered : 0}
            </span>
          ) : (
            <span className="deposit-descr">
              Bonus Amount ₹{x?.AMOUNT}
            </span>
          )}
          {activeBonus && (
            <div className="deposit-timer">
              <svg
                width="12"
                height="15"
                viewBox="0 0 12 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M4 0C3.44772 0 3 0.447715 3 1C3 1.55228 3.44772 2 4 2H8C8.55228 2 9 1.55228 9 1C9 0.447715 8.55228 0 8 0H4ZM6 15C9.31371 15 12 12.3137 12 9C12 5.68629 9.31371 3 6 3C2.68629 3 0 5.68629 0 9C0 12.3137 2.68629 15 6 15ZM9.07849 6.06775C9.32063 5.74159 9.28723 5.28725 8.99998 5L4.9685 8.60646C4.5823 8.95195 4.56564 9.55094 4.93205 9.91735C5.32169 10.307 5.96649 10.2596 6.29495 9.81715L9.07849 6.06775Z"
                  fill="white"
                />
              </svg>

              {timeRemaining(x.EXPIRY_DATE)}
            </div>
          )}
          <div className="line"></div>
          {activeBonus && !claimed && !x.wagered?.claimAvailable && (
            <span className="procent">
              {x.BONUS_ID == x.wagered?.bonusId
                ? (
                  (x?.wagered?.totalWagered * 100) /
                  x?.wagered?.wageredCap
                ).toFixed('0')
                : '0'}
              %
            </span>
          )}
          {activeBonus ? (
            claimed ? (
              <div className="deposit-text-white">
                Amount has been claimed successfully it will be credited to your
                account soon
              </div>
            ) : (
              <div className="deposit-bonus">
                {x.BONUS_ID == x.wagered?.bonusId &&
                x.wagered?.claimAvailable ? (
                  <div
                    onClick={() => claimBonus(x?.BONUS_ID)}
                    className="deposit-claim"
                  >
                    {loading && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="animate-spin -ml-1 mr-3 h-5 w-5 "
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="#ffffff"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M12 6l0 -3" />
                        <path d="M16.25 7.75l2.15 -2.15" />
                        <path d="M18 12l3 0" />
                        <path d="M16.25 16.25l2.15 2.15" />
                        <path d="M12 18l0 3" />
                        <path d="M7.75 16.25l-2.15 2.15" />
                        <path d="M6 12l-3 0" />
                        <path d="M7.75 7.75l-2.15 -2.15" />
                      </svg>
                    )}
                    <div className="shimmer-animation"></div>Claim ₹{x?.AMOUNT} Now
                  </div>
                ) : (
                  <>
                    <div className="deposit-bonus-conteiner">
                      <span className="deposit-bonus-text-start">0</span>
                      <div className="deposit-bonus-line-white">
                        <span
                          style={{
                            width: `${
                              x.BONUS_ID == x.wagered?.bonusId
                                ? (
                                  (x?.wagered?.totalWagered * 100) /
                                  x?.wagered?.wageredCap
                                ).toFixed('0')
                                : 0
                            }%`,
                          }}
                          className="deposit-bonus-line-green"
                        ></span>
                      </div>
                      <span className="deposit-bonus-text-end">
                        {x.BONUS_ID == x.wagered?.bonusId
                          ? x?.wagered?.wageredCap
                          : ' 100%'}
                      </span>
                    </div>
                  </>
                )}
              </div>
            )
          ) : activeBonusAvailable ? (
            <div className="deposit-claim-activ">
              <span className="deposit-claim-text-orange">
                Claim activated bonus to activate this bonus
              </span>
            </div>
          ) : activated ? (
            <div className="bonus-succes">
              <div className="bonus-succes-text">
                Bonus activated successfully changes will be reflected soon!
              </div>
            </div>
          ) : (
            <div className="bonuses-active-now">
              <span className="bonuses-active-now-text">
                Valid {x?.promoDetails?.validity} days after activation
              </span>
              <div
                onClick={() => activateBonus(x?.BONUS_ID)}
                className="bonuses-active-now-bonus"
              >
                <div className="shimmer-animation"></div> Activate Now
              </div>
            </div>
          )}
          </div>
        </div>
      {info && (
        <div className="info-wrrap fixed">
          <div className="info-close text-black">
            <svg
              onClick={() => setInfo(null)}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="info-close-icon"
            >
              <path
                fill-rule="evenodd"
                d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-1.72 6.97a.75.75 0 10-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 101.06 1.06L12 13.06l1.72 1.72a.75.75 0 101.06-1.06L13.06 12l1.72-1.72a.75.75 0 10-1.06-1.06L12 10.94l-1.72-1.72z"
                clip-rule="evenodd"
              />
            </svg>
          </div>

          <div
            className="info-descr"
            dangerouslySetInnerHTML={createMarkup(info?.info)}
          />
        </div>
      )}
    </>
  )
}
