import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  requestMenuEvent,
  requestMenuEventRace
} from '../../store/menuEvent/menuEvent.thunks';
import { getEventSuccess } from '../../store/menuEvent/menuEvent.slice';

export const connectRaceList = (wrappedProps) => (component) => {
  function mapStateToProps(state) {
    return {
      menu: state.menu,
      balanceSettings: state.balanceSettings,
      betPlacing: state.betPlacing,
      timeSettings: state.timeSettings
    }
  }

  function mapDispatchToProps(dispatch) {
    const actions = {
      requestMenuEvent,
      requestMenuEventRace,
      getEventSuccess
    }

    return { actions: bindActionCreators(actions, dispatch) }
  }

  return connect(mapStateToProps, mapDispatchToProps)(component)
}
