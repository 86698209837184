import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { requestChangePassword } from '../../store/user/user.thunks'
import { requestProfile } from "../../store/profile/profile.thunks";

export const connectWithdrawal = (wrappedProps) => (component) => {
  function mapStateToProps(state) {
    return {
      user: state.user,
      balance: state.balance,
      elementsParams: state.elementsParams
    }
  }

  function mapDispatchToProps(dispatch) {
    const actions = {
      // requestChangePassword,
      requestProfile
    }

    return { actions: bindActionCreators(actions, dispatch) }
  }

  return connect(mapStateToProps, mapDispatchToProps)(component)
}
