import React, { useState, useEffect } from 'react'
import {
  IonContent,
  IonRadioGroup,
  IonRadio,
  IonLabel,
  IonListHeader,
  IonList,
  IonItem,
  IonPage,
  IonHeader,
} from '@ionic/react'
import './TimeSettings.scss'
import Toolbar from '../../components/Toolbar/Toolbar'

import { connectTimeSettings } from './TimeSettings.connect'
import { formatTime } from '../../helpers/date.helper'

const monthName = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"];

const TimeSettings = ({ timeZoneName, serverTime, actions, ...props }) => {
  const [gmtDevice, setGmtDevice] = useState('GMT +00:00')
  const [timeCounter, setTimeCounter] = useState(0)

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeCounter(timeCounter => timeCounter + 1);
    }, 1000);
    return () => clearInterval(timer)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeCounter]);

  useEffect(() => {
    const now = new Date()
    let offset = `${now}`.replace(/^[\w]+ [\w]+ [0-9]{2} [0-9]{4} [0-9]{2}:[0-9]{2}:[0-9]{2} ([\w]+)([+-][0-9]{2})([0-9]{2})()/, '$1 $2:$3$4').replace(/^([\w]+ [+-][0-9]{2}:[0-9]{2}) (.*)/, '$1')
    setGmtDevice(offset)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // useEffect(() => {
  //   actions.requestServerTime().then((res) => console.log(res))
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  const getTime = () => {
    const now = new Date()
    if (timeZoneName === 'system') return <span className="date">{monthName[now.getUTCMonth()]} {now.getUTCDate()}th, {now.getUTCFullYear()} <span className="time">{formatTime(now.getUTCHours())}:{formatTime(now.getUTCMinutes())}:{formatTime(now.getUTCSeconds())}</span></span>
    else if (timeZoneName === 'device') return <span className="date">{monthName[now.getMonth()]} {now.getDate()}th, {now.getFullYear()} <span className="time">{formatTime(now.getHours())}:{formatTime(now.getMinutes())}:{formatTime(now.getSeconds())}</span></span>
    else {
      now.setMinutes(now.getMinutes() + now.getTimezoneOffset() + 330)
      return <span className="date">{monthName[now.getMonth()]} {now.getDate()}th, {now.getFullYear()} <span className="time">{formatTime(now.getHours())}:{formatTime(now.getMinutes())}:{formatTime(now.getSeconds())}</span></span>
    }
  }

  return (
    <IonPage>
      <IonHeader>
        <Toolbar />
      </IonHeader>
      <IonContent className="TimeSettings" fullscreen>
        <div className="page-title-wrapper">
          <h2 className="page-title">Time Settings</h2>
        </div>
        <IonList>
          <IonRadioGroup value={timeZoneName} onIonChange={e => actions.setTimeZoneName(e.detail.value)}>
            <IonListHeader>
              <IonLabel>{getTime()}</IonLabel>
            </IonListHeader>

          <div>

          </div>
            <IonItem className="TimeSettings__option TimeSettings__option--first">
              <IonLabel>System time - (GMT +00:00)</IonLabel>
              <IonRadio value="system" mode="md" />
            </IonItem>

            <IonItem className="TimeSettings__option">
              <IonLabel>Your device time - ({gmtDevice})</IonLabel>
              <IonRadio value="device" mode="md" />
            </IonItem>

            <IonItem className="TimeSettings__option TimeSettings__option--last">
              <IonLabel>India Standard time - (GMT +05:30)</IonLabel>
              <IonRadio value="india" mode="md" />
            </IonItem>
          </IonRadioGroup>
        </IonList>
      </IonContent>
    </IonPage>
  )
}

export default connectTimeSettings()(TimeSettings)
