import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {setInfoDetailed} from "../../store/openBets/openBets.slice";

export const connectAccountStatementCasinoDetailsTable = (wrappedProps) => (component) => {
    function mapStateToProps (state) {
        return {
            user: state.user,
            timeSettings: state.timeSettings,
            betHistory: state.betHistory,
            orderList: state.orderList,
        };
    }

    function mapDispatchToProps (dispatch) {
        const actions = {
            setInfoDetailed,
        };

        return {actions: bindActionCreators(actions, dispatch)};
    }

    return connect(mapStateToProps, mapDispatchToProps)(component);
};
