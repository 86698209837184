import React, { useState, useEffect } from 'react'
import './OpenBetsListCasino.scss'
import DesktopOpenBetsItem from '../DesktopOpenBetsItem/DesktopOpenBetsItem'
import { useSelector } from 'react-redux'
import { connectOpenBetsListCasino } from './OpenBetsListCasino.connect'
import OpenBetsItem from '../OpenBetsItem/OpenBetsItem'
import { tsToDate, getFormattedDate } from '../../helpers/date.helper'
import { formatTime } from '../../helpers/date.helper'
import OpenBetsItemCasino from '../OpenBetsItemCasino/OpenBetsItemCasino'



const OpenBetsListCasino = ({
  timeSettings,
  bets,
  title,
  isEvent,
  redirect,
  isUnmatched,
  cancel,
  sportEventStats,
  cancelAll,
  actions,
  elementsParams,
}) => {
  //TODO: open bets here
  const eventdata = JSON.parse(localStorage.getItem("Event"))
  const [collapsed, setCollapsed] = useState(false)
  const [isOrderedByDate, setIsOrderedByDate] = useState(false)
  const [isConsolidate, setIsConsolidate] = useState(false)
  const [isAverage, setIsAverage] = useState(false)
  const [removeAllBets, setRemoveAllBets] = useState();

  const setThisEventDispatch = useSelector((state) => state.openBetsList.event)
  const setUnmatchedChangedDispatch = useSelector((state) => state.openBetsList.unmatchedChanged)

  const width = elementsParams && elementsParams?.clientWidth ? elementsParams?.clientWidth : window.innerWidth

  const timeZoneName = useSelector((state) => state.timeSettings.timeZoneName)
  const getZonedTime = (timezone, time) => {
    let date = time ? new Date(time) : new Date()
    if (timezone === 'system')
      date.setMinutes(date.getMinutes() + date.getTimezoneOffset())
    if (timezone === 'india')
      date.setMinutes(date.getMinutes() + date.getTimezoneOffset() + 330)

    return date
  }
  const tsToDate = (ts, timezone) => {
    const date = getZonedTime(timezone, ts)

    return `${date.getDate().toString().length === 1
        ? '0' + date.getDate()
        : date.getDate()
      }/${(date.getMonth() + 1).toString().length === 1
        ? '0' + (date.getMonth() + 1)
        : date.getMonth() + 1
      }/${date.getFullYear()} ${date.getHours()}:${formatTime(
        date.getMinutes()
      )}:${formatTime(date.getSeconds())}`
  }
  const formattedTime = (time) => {
    // const startTime = time
    const startDate = new Date(time)

    const optimizedDate = tsToDate(startDate, timeSettings?.timeZoneName)
    return optimizedDate.slice(11, 20).split(':', 2).join(':')
  }


  const structuredData = bets && bets.reduce((acc, item) => {
    // Destructure match_id and market_id from the current item
    const { match_id, market_id } = item;

    // Initialize the object for match_id if it doesn't exist yet
    if (!acc[match_id]) {
      acc[match_id] = [];
    }

    // Push the item into the array corresponding to the match_id
    acc[match_id].push(item);

    return acc;
  }, {});

  const averageBet = (bets) => {
    if (bets[0]?.marketType !== 'INNINGS_RUNS') {
      let name
      let marketType
      let calculated = []
      let stake = 0
      let stakeLay = 0
      let odd = []
      let oddLay = []
      let profit = 0
      let profitLay = 0
      bets.map((oneBet, index) => {
        name = oneBet.runnerName
        marketType = oneBet.marketName

        if (!oneBet.isBack) {
          stakeLay = stakeLay + oneBet.stake
          oddLay.push(oneBet.odds)
        } else {
          stake = stake + oneBet.stake
          odd.push(oneBet.odds)
        }
      })
      // for (let i = 0; i < odd.length; i++){
      //   odd =
      // }
      let averageOdd =
        (odd.reduce((a, b) => a + b, 0) / odd.length).toFixed(2) * 1
      let averageOddLay =
        (oddLay.reduce((a, b) => a + b, 0) / oddLay.length).toFixed(2) * 1

      profit = (stake * averageOdd - stake).toFixed(2) * 1
      profitLay = (stakeLay * averageOddLay - stakeLay).toFixed(2) * 1
      return (calculated = [
        {
          stake: stake,
          odds: averageOdd,
          profit: profit,
          name: name,
          marketType: marketType,
        },
        {
          stake: stakeLay,
          odds: averageOddLay,
          profit: profitLay,
          name: name,
          marketType: marketType,
        },
      ])
      // console.log(stake);
    } else {
      let inningArr = []
      bets.map((item) => {
        inningArr.push(item)
      })
      return inningArr
    }
  }

// console.log("bets", bets)
  return (width < 1024 ? <section className="OpenBetsList">
    <div className="OpenBetsList__title" onClick={() => setCollapsed(!collapsed)}>
      <span className="OpenBetsList__title__text">{title}</span>
      <i className={`chevron ${collapsed ? 'down' : ''}`}></i>
    </div>
    {!collapsed && <div className="OpenBetsList__content">
      {bets && bets.length > 0
        ?
        bets.map((item, index) =>
          <OpenBetsItemCasino isEvent={isEvent} timeSettings={timeSettings} redirect={redirect} isUnmatched={isUnmatched} bet={item} key={index} cancel={cancel} />)
        : <div className="OpenBetsItem nomatch">You have no <span className="lowercase">{title}</span> in last 14 days</div>
      }
    </div>}
  </section> :
    <section className="OpenBetsList">
      {isUnmatched && bets && bets[0] && (
        <div className="OpenBetsList__unmatchedButtons">
          <button
            type="button"
            className="OpenBetsList__unmatchedButtons-cancel"
            onClick={() => {
              cancelAll()
              setRemoveAllBets(true)
              setTimeout(() => {
                setRemoveAllBets(false)
              }, 2500)
            }}
          >
            Cancel all
          </button>
          <button
            type="button"
            className="OpenBetsList__unmatchedButtons-reset"
            disabled={!setUnmatchedChangedDispatch}
            onClick={() => {
              actions.setResetUnmatched(true)
              actions.setUnmatchedChanged(false)
            }}
          >
            Reset
          </button>
        </div>
      )}

      <div
        className="OpenBetsList__title"
        onClick={() => setCollapsed(!collapsed)}
      >
        <span className="OpenBetsList__title__text">{title}</span>
        <div
          className={
            collapsed
              ? 'OpenBetsList__downButton downImg'
              : 'OpenBetsList__downButton upImg'
          }
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
            <path d="M224 416c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L224 338.8l169.4-169.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-192 192C240.4 412.9 232.2 416 224 416z" fill="#2C4F57" />
          </svg>
        </div>
      </div>
      {!isUnmatched && !collapsed && (
        <div className="OpenBetsList__topFilters">
          <div
            className="OpenBetsList__topFilters-item"
            onClick={() => {
              setIsConsolidate(!isConsolidate)
              setIsAverage(false)
              setIsOrderedByDate(false)
            }}
          >
            <div
              className={
                !isConsolidate
                  ? 'OpenBetsLit__topFilters-item-check'
                  : 'OpenBetsLit__topFilters-item-check checked'
              }
            ></div>
            <span className="OpenBetsLit__topFilters-item-title">
              Consolidate
            </span>
          </div>
          <div
            className="OpenBetsList__topFilters-item"
            onClick={() => {
              setIsAverage(!isAverage)
              setIsOrderedByDate(false)
              setIsConsolidate(false)
            }}
          >
            <div
              className={
                !isAverage
                  ? 'OpenBetsLit__topFilters-item-check'
                  : 'OpenBetsLit__topFilters-item-check checked'
              }
            ></div>
            <span className="OpenBetsLit__topFilters-item-title">
              Average Odds
            </span>
          </div>
          <div
            className="OpenBetsList__topFilters-item"
            onClick={() => {
              setIsOrderedByDate(!isOrderedByDate)
              setIsAverage(false)
              setIsConsolidate(false)
            }}
          >
            <div
              className={
                !isOrderedByDate
                  ? 'OpenBetsLit__topFilters-item-check'
                  : 'OpenBetsLit__topFilters-item-check checked'
              }
            ></div>
            <span className="OpenBetsLit__topFilters-item-title">
              Order By Date
            </span>
          </div>
        </div>
      )}
      {/*Sorted By Current Event*/}
      {setThisEventDispatch ? (
        <div>
          {!collapsed && (
            <div className="OpenBetsList__content">
              {structuredData && Object.keys(structuredData).find(
                (group) => {
                  return group === eventdata?.match_id
                }
              ) ? (
                Object.keys(structuredData).map(
                  (group) =>
                    group === eventdata?.match_id && (
                      <div className="OpenBetsList__content-competition">
                        <div className="OpenBetsList__content-competition-title">
                          {structuredData[group][0]?.match_name}
                        </div>
                        {!isAverage ? (
                          <>

                            {structuredData[group].map((market) => (
                              // START HERE 
                              <div className="OpenBetsList__content-itemsByMarket">
                                {!isConsolidate &&
                                  market.marketType == 'INNINGS_RUNS' &&
                                  !isAverage && (
                                    <div className="OpenBetsList__content-itemsByMarket-name">
                                      {market.name}
                                    </div>
                                  )}
                                {!isConsolidate && !isAverage && !isUnmatched && (
                                  <div className="OpenBetsList__content-itemsRow">
                                    <div
                                      className={`OpenBetsList__content-itemsRow-${market.marketType == 'INNINGS_RUNS'
                                          ? 'runs'
                                          : 'market'
                                        }`}
                                    >
                                      {market.marketType == 'INNINGS_RUNS'
                                        ? 'Runs'
                                        : market.name}
                                    </div>
                                    <div className="OpenBetsList__content-itemsRow-odds">
                                      Odds
                                    </div>
                                    <div className="OpenBetsList__content-itemsRow-stake">
                                      Stake
                                    </div>
                                    <div className="OpenBetsList__content-itemsRow-profit">
                                      Profit/Liability
                                    </div>
                                  </div>
                                )}
                                <div
                                  className={
                                    isOrderedByDate
                                      ? 'OpenBetsList__content-itemsByMarket-allBets'
                                      : ''
                                  }
                                >
                                  <>
                                    <div>
                                      {isConsolidate && market.index === 0 && (
                                        <>
                                          <div className="OpenBetsList__content-itemsByMarket-name">
                                            {market.runnerName}
                                          </div>
                                          <div
                                            className="OpenBetsList__content-itemsRow"
                                            style={{ marginTop: '0' }}
                                          >
                                            {market.marketType ==
                                              'INNINGS_RUNS' && (
                                                <div
                                                  className={`OpenBetsList__content-itemsRow-${market.marketType ==
                                                      'INNINGS_RUNS'
                                                      ? 'runs'
                                                      : 'market'
                                                    }`}
                                                >
                                                  {market.marketType ==
                                                    'INNINGS_RUNS'
                                                    ? 'Runs'
                                                    : market.name}
                                                </div>
                                              )}
                                            <div className="OpenBetsList__content-itemsRow-odds">
                                              Odds
                                            </div>
                                            <div className="OpenBetsList__content-itemsRow-stake">
                                              Stake
                                            </div>
                                            <div className="OpenBetsList__content-itemsRow-profit">
                                              Profit/Liability
                                            </div>
                                          </div>
                                        </>
                                      )}
                                      <DesktopOpenBetsItem
                                        isEvent={isEvent}
                                        timeSettings={timeSettings}
                                        redirect={redirect}
                                        isUnmatched={isUnmatched}
                                        bet={market}
                                        key={market.id}
                                        cancel={cancel}
                                        consolidate={isConsolidate}
                                      />
                                    </div>


                                  </>

                                </div>
                              </div>
                            ))}
                          </>
                        ) : (
                          <>
                            <div>
                              <DesktopOpenBetsItem
                                isEvent={isEvent}
                                timeSettings={timeSettings}
                                redirect={redirect}
                                isUnmatched={isUnmatched}
                                stake={averageBet(structuredData[group])}
                                key={structuredData && structuredData[group][0].id}
                                cancel={cancel}
                                consolidate={false}
                                isAverage={true}
                              />
                            </div>
                          </>
                        )}


                      </div>
                    )
                )
              ) : (
                <div className="OpenBetsItem noBetsItem">
                  You have no <span className="lowercase">{title}</span> for
                  this event
                </div>
              )}
            </div>
          )}
        </div>
      ) : (
        // Default
        <div>
          {!collapsed && (
            <div className="OpenBetsList__content">
              {isUnmatched && removeAllBets &&
                <div className="DesktopOpenBetsItem__removingHover"> Removing all bets....</div>
              }

              {structuredData && Object.keys(structuredData).length > 0 ? (
                Object.keys(structuredData).map((group) => {
                  return (
                    <div className="OpenBetsList__content-competition">
                      <div className="OpenBetsList__content-competition-title">
                        {/* ${tsToDate(new Date(bets[group]?.marketTime), timeZoneName)?.slice(11, 20)?.split(':', 2)?.join(':')}  */}
                        {`
                        ${structuredData[group][0]?.match_name?.replace('${HH:mm}', '')}`}
                      </div>
                      {!isAverage ? (
                        <>
                          {structuredData[group].map((market) => (
                            <div className="OpenBetsList__content-itemsByMarket">
                              {!isConsolidate &&
                                market.marketType == 'INNINGS_RUNS' &&
                                !isAverage && (
                                  <div className="OpenBetsList__content-itemsByMarket-name">
                                    {market.name}
                                  </div>
                                )}
                              {!isConsolidate && !isAverage && !isUnmatched && (
                                <div className="OpenBetsList__content-itemsRow">
                                  <div
                                    className={`OpenBetsList__content-itemsRow-${market.marketType == 'INNINGS_RUNS'
                                        ? 'runs'
                                        : 'market'
                                      }`}
                                  >
                                    {market.marketType == 'INNINGS_RUNS'
                                      ? 'Runs'
                                      : market.name}
                                  </div>
                                  <div className="OpenBetsList__content-itemsRow-odds">
                                    Odds
                                  </div>
                                  <div className="OpenBetsList__content-itemsRow-stake">
                                    Stake
                                  </div>
                                  <div className="OpenBetsList__content-itemsRow-profit">
                                    Profit/Liability
                                  </div>
                                </div>
                              )}
                              <div
                                className={
                                  isOrderedByDate
                                    ? 'OpenBetsList__content-itemsByMarket-allBets'
                                    : ''
                                }
                              >
                                <>

                                  <div key={market.index}>
                                    {isConsolidate && market.index === 0 && (
                                      <>
                                        <div className="OpenBetsList__content-itemsByMarket-name">
                                          {market.runnerName}
                                        </div>
                                        <div
                                          className="OpenBetsList__content-itemsRow"
                                          style={{ marginTop: '0' }}
                                        >
                                          {market.marketType ==
                                            'INNINGS_RUNS' && (
                                              <div
                                                className={`OpenBetsList__content-itemsRow-${market.marketType ==
                                                    'INNINGS_RUNS'
                                                    ? 'runs'
                                                    : 'market'
                                                  }`}
                                              >
                                                {market.marketType ==
                                                  'INNINGS_RUNS'
                                                  ? 'Runs'
                                                  : market.name}
                                              </div>
                                            )}
                                          <div className="OpenBetsList__content-itemsRow-odds">
                                            Odds
                                          </div>
                                          <div className="OpenBetsList__content-itemsRow-stake">
                                            Stake
                                          </div>
                                          <div className="OpenBetsList__content-itemsRow-profit">
                                            Profit/Liability
                                          </div>
                                        </div>
                                      </>
                                    )}
                                    <DesktopOpenBetsItem
                                      isEvent={isEvent}
                                      timeSettings={timeSettings}
                                      redirect={redirect}
                                      isUnmatched={isUnmatched}
                                      bet={market}
                                      key={market.id}
                                      cancel={cancel}
                                      consolidate={isConsolidate}
                                    />
                                  </div>


                                </>


                              </div>
                            </div>
                          ))}

                        </>
                      ) : (
                        <>
                          <div>
                            <DesktopOpenBetsItem
                              isEvent={isEvent}
                              timeSettings={timeSettings}
                              redirect={redirect}
                              isUnmatched={isUnmatched}
                              stake={averageBet(structuredData[group])}
                              key={structuredData && structuredData[group][0].id}
                              cancel={cancel}
                              consolidate={false}
                              isAverage={true}
                            />
                          </div>
                        </>
                      )}
                    </div>
                  )
                })
              ) : (
                <div className="OpenBetsItem noBetsItem">
                  You have no <span className="lowercase">{title}</span>
                </div>
              )}
            </div>
          )}
        </div>
      )}


    </section>
  )
}

export default connectOpenBetsListCasino()(OpenBetsListCasino)
