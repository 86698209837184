import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { setRulesPopUp } from '../../store/rulesPopUp/rulesPopUp.slice'

export const connectSportEvent = (wrappedProps) => (component) => {
  function mapStateToProps(state) {
    return {
      sportData: state.sportEvent.sportData,
      sportDataFancy: state.sportEvent.fancyData,
      ladderShow: state.sportEvent.ladderShow,
      betPlacing: state.betPlacing,
      rulesPopUp: state.rulesPopUp,
      elementsParams: state.elementsParams
    }
  }

  function mapDispatchToProps(dispatch) {
    const actions = { setRulesPopUp }

    return { actions: bindActionCreators(actions, dispatch) }
  }

  return connect(mapStateToProps, mapDispatchToProps)(component)
}
