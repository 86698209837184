import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { requestRaceOdds } from '../../store/raceOdds/raceOdds.thunks'
import { requestSportStart } from '../../store/eventPage/eventPage.thunks'

export const connectRacingPage = (wrappedProps) => (component) => {
  function mapStateToProps(state) {
    return {
      timeSettings: state.timeSettings,
      navigationHeaderMenu: state.navigationHeaderMenu,
      betPlacing: state.betPlacing,
      menu: state.menu,
      auth: state.auth,
      racingPage: state.racingPage
    }
  }

  function mapDispatchToProps(dispatch) {
    const actions = {
      requestRaceOdds,
      requestSportStart
    }

    return { actions: bindActionCreators(actions, dispatch) }
  }

  return connect(mapStateToProps, mapDispatchToProps)(component)
}
