import httpAuth from '../../services/httpAuth'
import getErrorMessage from '../../helpers/getErrorMessage.helper'
import { getAccountReportError, getAccountReportSuccess } from './account-report.slice'


export const requestAccountReport = (fromTo = { fromDate: '', toDate: '' }) => async (dispatch) => {
    let data = {
        limit: 50,
        page: 1,
        search: {},
        from_date: fromTo.fromDate,
        to_date: fromTo.toDate
    }
    try {
            const response = await httpAuth.post(`/report/eventsProfitLoss`, data)
            if (response.data.status) {
                dispatch(getAccountReportSuccess(response?.data?.data?.data))
            }
            return response
    } catch (e) {
        dispatch(getAccountReportError())
        // const errorMsg = getErrorMessage(e)
        // throw errorMsg
    }
}
