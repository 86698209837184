import httpAuth from '../../services/httpAuth'
import getErrorMessage from '../../helpers/getErrorMessage.helper'
import {
    getExposureError,
    getExposureSuccess,
} from './racePage.slice'

export const requestRaceExposure = (eventId) => async (dispatch, getState) => {
    const userId = getState().user.memberCode;
    if(userId) {
        try {
            let data = {
                match_id: eventId
            }
            // twitter-config - in another reqest if news tab opened
            httpAuth.post(`/bet/getTeamPosition`, data)
                .then(res => {
                    if (res.data.status) {
                        dispatch(getExposureSuccess(res.data.data))
                    }
                })
            return
        } catch (e) {
            dispatch(getExposureError())
            // const errorMsg = getErrorMessage(e)
            // throw errorMsg
        }
    }
}
