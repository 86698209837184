import React, { useEffect, useState } from 'react'
import DatePicker from 'react-date-picker'
import { IonContent, IonHeader, IonPage } from '@ionic/react'
import { connectMyPastBets } from './MyPastBets.connect'
import OpenBets from '../../components/OpenBets/OpenBets'
import OpenBetsTable from '../../components/OpenBetsTable/OpenBetsTable'
import PastBetsTable from '../../components/PastBetsTable/PastBetsTable'
import Toolbar from '../../components/Toolbar/Toolbar'
import './MyPastBets.scss'

const MyPastBets = ({
  openBets,
  timeSettings,
  actions,
  elementsParams,
  unMatchedBets,
}) => {
  const currentDate = new Date()
  const date7DaysAgo = new Date(currentDate.setDate(currentDate.getDate() - 14))

  const [activeButton, setActiveButton] = useState('matched')
  const [isCurrent, setIsCurrent] = useState(true)
  const [selectedDateFrom, setSelectedDateFrom] = useState(
    new Date(date7DaysAgo)
  )
  const [selectedDateTo, setSelectedDateTo] = useState(new Date())
  const [pastBets, setPastBets] = useState(null)
  const [openBetsTime, setPastBetsTime] = useState(null)
  const [validationError, setValidationError] = useState('')
  const [filterOpen, setfilterOpen] = useState(false)
  const [choosenFrom, setChoosenFrom] = useState(false)
  const [choosenTo, setChoosenTo] = useState(false)
  const width =
    elementsParams && elementsParams?.clientWidth
      ? elementsParams?.clientWidth
      : window.innerWidth

  function getLastTimeOfDay(dateInput) {
    const date = new Date(dateInput)
    date.setUTCHours(23, 59, 59, 999)

    return date.toISOString()
  }

  function getStartTimeOfDay(dateInput) {
    const date = new Date(dateInput)
    date.setUTCHours(0, 0, 0, 0)

    return date.toISOString()
  }

  const dateValidation = (from, to) => {
    if (from > to)
      return setValidationError("'To' date must be greater than 'From' date.")
    else if (setValidationError) setValidationError('')
    if (new Date().setHours(23, 59, 59, 999) < to)
      return setValidationError(
        "'To' date must not be greater than today's date."
      )
    else if (setValidationError) setValidationError('')
    if (!validationError) {
      actions
        .requestPastBets(getStartTimeOfDay(from), getLastTimeOfDay(to))
        .then((res) => setPastBets(res?.data?.data[0]?.data))
        .catch((e) => console.log('requestPastBets error = ', e))
    }
  }

  let todayDate = new Date()

  const getMonth = (date) => {
    return `${date.toLocaleDateString('en-GB', {
      month: '2-digit',
    })}`
  }

  const getDate = (date) => {
    return `${date.toLocaleDateString('en-GB', {
      day: '2-digit',
    })}`
  }

  const getWithTimeLimit = () => {
    let DateFrom =
      selectedDateFrom && new Date(selectedDateFrom).setHours(0, 0, 0, 0)
    let DateTo =
      selectedDateTo && new Date(selectedDateTo).setHours(23, 59, 59, 999)
    dateValidation(DateFrom, DateTo)
    // if (!validationError) {
    //     if (isCurrent) {
    //         actions.requestOpenBetsWithDate(DateFrom, DateTo)
    //           .then(res => setPastBetsTime(res?.data?.result))
    //           .catch((e) => console.log("requestOpenBetsWithDate error = ", e));
    //     } else {
    //         actions.requestPastBets(DateFrom, DateTo)
    //           .then(res => setPastBets(res?.data?.result))
    //           .catch((e) => console.log("requestPastBets error = ", e));
    //     }
    // }
  }
  useEffect(() => {
    getWithTimeLimit()
  }, [isCurrent])

  return (
    <>
      {width < 1024 ? (
        <IonPage>
          <IonHeader>
            <Toolbar />
          </IonHeader>
          <IonContent className="MyOpenBets" fullscreen>
            <>
              <div className="page-title-wrapper">
                <h2 className="page-title">Past Bets</h2>
              </div>
              <div className="account-statement-page__filter-content">
                <div>
                  <button
                    onClick={() => {
                      setfilterOpen((prevValue) => !prevValue)
                      // setChoosenFrom(false)
                      // setChoosenTo(false)
                    }}
                    className={`filter-button ${
                      filterOpen && 'filter-button--active'
                    }`}
                  >
                    Filter
                  </button>
                </div>

                {filterOpen && (
                  <div className="account-statement-page__filter-content">
                    <div
                      className="account-statement-page__filters-date-input"
                      // style={{marginRight: "15px"}}
                      onClick={() => {
                        // setChoosenFrom(true)
                      }}
                    >
                      <p
                        style={
                          choosenFrom
                            ? { fontSize: '12px', transition: 'linear 0.2s' }
                            : {}
                        }
                      >
                        Date from:
                      </p>
                      <div>
                        <DatePicker
                          onChange={setSelectedDateFrom}
                          value={selectedDateFrom}
                          locale={'en-US'}
                          showLeadingZeros={true}
                          clearIcon={null}
                          calendarIcon={null}
                          format={'dd/MM/yyyy'}
                          dayPlaceholder={getDate(todayDate)}
                          monthPlaceholder={getMonth(todayDate)}
                          yearPlaceholder={todayDate.getFullYear()}
                        />
                      </div>
                    </div>
                    <div
                      className="account-statement-page__filters-date-input"
                      // style={{marginRight: "15px"}}
                      onClick={() => {
                        // setChoosenTo(true)
                      }}
                    >
                      <p
                        style={
                          choosenTo
                            ? { fontSize: '12px', transition: 'linear 0.2s' }
                            : {}
                        }
                      >
                        Date to:
                      </p>
                      <div>
                        <DatePicker
                          onChange={setSelectedDateTo}
                          value={selectedDateTo}
                          locale={'en-US'}
                          showLeadingZeros={true}
                          clearIcon={null}
                          calendarIcon={null}
                          format={'dd/MM/yyyy'}
                          dayPlaceholder={getDate(todayDate)}
                          monthPlaceholder={getMonth(todayDate)}
                          yearPlaceholder={todayDate.getFullYear()}
                        />
                      </div>
                    </div>
                    <button
                      className="account-statement-page__date-search-button"
                      // disabled={!(selectedDateFrom && selectedDateTo)}
                      onClick={() => {
                        getWithTimeLimit()
                        setfilterOpen((prevValue) => !prevValue)
                      }}
                    >
                      <span>Apply</span>
                    </button>
                    {validationError && (
                      <div className="account-statement-page__filter-content-error">
                        {validationError}
                      </div>
                    )}
                  </div>
                )}
              </div>

              <OpenBets
                openBets={pastBets}
                unMatchedBets={unMatchedBets}
                redirect={false}
              />
            </>
            {/*<FooterDesktop />*/}
          </IonContent>
        </IonPage>
      ) : (
        <div className="MyOpenBets" fullscreen>
          <div className="MyOpenBets__page">
            <div className="page-title-wrapper  MyOpenBets__page-title-wrapper">
              <h2 className="page-title MyOpenBets__page-title">My Bets</h2>
            </div>
            <div className="MyOpenBets__page-menu">
              <div className="MyOpenBets__page-menu-buttons-wrapper">
                <div className="MyOpenBets__page-menu-buttons-row MyOpenBets__page-menu-buttons-row-time">
                  <div
                    onClick={() => setIsCurrent(true)}
                    className={`MyOpenBets__page-menu-button ${
                      isCurrent ? 'active' : ''
                    }`}
                  >
                    <span>Current</span>
                  </div>
                  <div
                    onClick={() => setIsCurrent(false)}
                    className={`MyOpenBets__page-menu-button ${
                      !isCurrent ? 'active' : ''
                    }`}
                  >
                    <span>Past</span>
                  </div>
                </div>
                {isCurrent && (
                  <div className="MyOpenBets__page-menu-buttons-row">
                    <div
                      onClick={() => setActiveButton('matched')}
                      className={`MyOpenBets__page-menu-button ${
                        activeButton === 'matched' ? 'active' : ''
                      }`}
                    >
                      <span>Matched</span>
                    </div>
                    <div
                      onClick={() => setActiveButton('unmatched')}
                      className={`MyOpenBets__page-menu-button ${
                        activeButton === 'unmatched' ? 'active' : ''
                      }`}
                    >
                      <span>Unmatched</span>
                    </div>
                  </div>
                )}
              </div>
              <div className="MyOpenBets__page-menu-filter-content">
                <div className="MyOpenBets__page-menu-filter-content-date-input">
                  <p>From</p>
                  <div>
                    <DatePicker
                      onChange={setSelectedDateFrom}
                      value={selectedDateFrom}
                      locale={'en-US'}
                      showLeadingZeros={true}
                      clearIcon={null}
                      calendarIcon={null}
                      format={'dd/MM/yyyy'}
                      dayPlaceholder={getDate(todayDate)}
                      monthPlaceholder={getMonth(todayDate)}
                      yearPlaceholder={todayDate.getFullYear()}
                    />
                  </div>
                </div>
                <div className="MyOpenBets__page-menu-filter-content-date-input">
                  <p>To</p>
                  <div>
                    <DatePicker
                      onChange={setSelectedDateTo}
                      value={selectedDateTo}
                      locale={'en-US'}
                      showLeadingZeros={true}
                      clearIcon={null}
                      calendarIcon={null}
                      format={'dd/MM/yyyy'}
                      dayPlaceholder={getDate(todayDate)}
                      monthPlaceholder={getMonth(todayDate)}
                      yearPlaceholder={todayDate.getFullYear()}
                    />
                  </div>
                </div>
                <button
                  className="MyOpenBets__page-menu-date-search-button"
                  // disabled={!(selectedDateFrom && selectedDateTo)}
                  onClick={getWithTimeLimit}
                >
                  <span>
                    <svg
                      width="13"
                      height="13"
                      viewBox="0 0 13 13"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12.8023 11.8409L9.59762 8.623C10.4216 7.67732 10.8731 6.48745 10.8731 5.24874C10.8731 2.35463 8.4342 0 5.43654 0C2.43888 0 0 2.35463 0 5.24874C0 8.14286 2.43888 10.4975 5.43654 10.4975C6.5619 10.4975 7.63432 10.1698 8.5512 9.5477L11.7803 12.7901C11.9152 12.9254 12.0968 13 12.2913 13C12.4754 13 12.6501 12.9322 12.7827 12.809C13.0645 12.5472 13.0735 12.1132 12.8023 11.8409ZM5.43654 1.36924C7.65229 1.36924 9.45485 3.10954 9.45485 5.24874C9.45485 7.38795 7.65229 9.12825 5.43654 9.12825C3.2208 9.12825 1.41823 7.38795 1.41823 5.24874C1.41823 3.10954 3.2208 1.36924 5.43654 1.36924Z"
                        fill="white"
                      />
                    </svg>
                  </span>
                  <span>Search</span>
                </button>
                {validationError && (
                  <div className="MyOpenBets__page-menu-filter-content-error">
                    {validationError}
                  </div>
                )}
              </div>
            </div>
            {isCurrent && (
              <OpenBetsTable
                bets={activeButton === 'matched' ? openBetsTime : unMatchedBets}
                timeSettings={timeSettings}
                title={`${activeButton} Bets`}
                redirect={true}
                activeButton={activeButton}
              />
            )}
            {!isCurrent && (
              <PastBetsTable
                bets={pastBets}
                timeSettings={timeSettings}
                title={'Past'}
              />
            )}
          </div>
        </div>
      )}
    </>
  )
}

export default connectMyPastBets()(MyPastBets)
