import httpAuth from '../../services/httpAuth'
import getErrorMessage from '../../helpers/getErrorMessage.helper'
import {
    getExposureError,
    getExposureSuccess,
    getExposureFancyError,
    getExposureFancySuccess,
    getOrdersError,
    getOrdersSuccess,
} from './eventPage.slice'

export const requestSportStart = (eventId, isRacing) => async (dispatch, getState) => {

    try {
        // twitter-config - in another reqest if news tab opened
        const userId = getState().auth;
        let data = {
            match_id: eventId
        }
        if (isRacing) {
            data = {
                market_id: eventId
            }
        }
        if(userId) {
            //exposure for regular markets
            httpAuth.post(`bet/getTeamPosition`, data)
                .then(res => {
                    if (res.data.status) {
                        dispatch(getExposureSuccess(res.data.data))
                    }
                })
            if (isRacing) return;
            //exposure for Fancy 
            httpAuth.post(`bet/getFancyLiability`, data)
            .then(res => {
                if (res.data.status) {
                    dispatch(getExposureFancySuccess(res.data.data))
                }
            })



            // httpAuth.get(`/account/${userId}/order-count?eventId=${eventId}`)
            //     .then(res => {
            //         if (res.data.success) {
            //             dispatch(getOrdersSuccess(res.data.result))
            //         }
            //     })
        }
        return
    } catch (e) {
        dispatch(getExposureError())
        // dispatch(getOrdersError())
        // const errorMsg = getErrorMessage(e)
        // throw errorMsg
    }
}
