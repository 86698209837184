import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { setLoginNew } from '../../store/LoginNew/loginNew.slice'
import { setSlotCasinoPopUp } from '../../store/slotCasinoPopUp/slotCasinoPopUp.slice'
import { requestMenu } from '../../store/menu/menu.thunks'
import { getMenuSuccess } from '../../store/menu/menu.slice'
import { requestMenuTop } from '../../store/menuTop/menuTop.thunks'
import { requestCasinoPoint } from '../../store/liveCasino/liveCasino.thunks'

export const connectLeftSideMenu = (wrappedProps) => (component) => {
  function mapStateToProps(state) {
    return {
      menu: state.menu,
      auth: state.auth,
      menuTop: state.menuTop,
      version: state.version,
      promotion: state.promotions.socials,
      B2C: state.B2C,
    }
  }

  function mapDispatchToProps(dispatch) {
    const actions = {
      setLoginNew,
      setSlotCasinoPopUp,
      requestMenu,
      getMenuSuccess,
      requestMenuTop,
      requestCasinoPoint,
    }

    return { actions: bindActionCreators(actions, dispatch) }
  }

  return connect(mapStateToProps, mapDispatchToProps)(component)
}
