import "./OpenBetsCasinoTable.scss";
import React from "react";
import { tsToDate } from "../../helpers/date.helper";
import { useHistory } from "react-router-dom";
import { getCurrencyFormatWithZeroAfterDot } from "../../helpers/getCurrencyFormat.helper";

const OpenBetsCasinoTable = ({ timeSettings, bets, title, redirect, activeButton }) => {

  const history = useHistory();

  const handleClickName = (item) => {
    if (redirect) history.push(`/event-page/${item?.sport_id}/${item?.match_id}`);
  };

  const getProfitLiability = (item) => {
    const isFancy = item?.marketType === "INNINGS_RUNS" && item?.marketBettingType === "LINE";
    const profit = isFancy ? (item.size * item?.averagePrice / 100).toFixed(2) : (item.size * item?.averagePrice - item?.size).toFixed(2);

    return item.side === "Back" ? { profit, liability: "" } : { profit: "", liability: profit };
  };

  return (
    <section className="OpenBetsTable">
      <div className="OpenBetsTable__table">
        <ul className="OpenBetsTable__table-header">
          <li>Placed</li>
          <li>Description</li>
          <li>Type</li>
          <li>Odds</li>
          <li>Stake</li>
          <li>Liability</li>
          <li>Potential Profit</li>
        </ul>
        <div className="OpenBetsTable__table-body">
          {bets && bets.length > 0 ? (
            bets.map((market, index) => {
              const marketName = market?.marketName;
              return <ul className="OpenBetsTable__table-body-row" key={market?._id + index}>
                <li className="OpenBetsTable__table-body-row-date">
                  <span>{tsToDate(market.createdAt, timeSettings.timeZoneName).split(" ")[0]}</span>
                  <span
                    className="text-color">{tsToDate(market.createdAt, timeSettings.timeZoneName).split(" ")[1]}</span>
                </li>
                <li className="OpenBetsTable__table-body-row-description">
                  <p className="OpenBetsTable__table-body-row-description-name">
                    {market.matchName}
                  </p>
                  <p><span className="text-bold">{market.runnerName}</span> <span className="text-color">{marketName}</span></p>
                  <p>
                    <span>Bet ID {market._id}</span>
                    {activeButton === "matched" &&
                      <span className="OpenBetsTable__table-body-row-description-matched-time" >
                        Matched {tsToDate(market.createdAt, timeSettings.timeZoneName)}
                      </span>
                    }
                  </p>
                </li>
                <li className="OpenBetsTable__table-body-row-info">{market.isBack ? "Back" : 'Lay'}</li>
                <li className="OpenBetsTable__table-body-row-info">{market.odds}</li>
                <li className="OpenBetsTable__table-body-row-info">{getCurrencyFormatWithZeroAfterDot(market.stake)}</li>
                <li className="OpenBetsTable__table-body-row-info">{getCurrencyFormatWithZeroAfterDot((market.liability))}</li>
                <li className="OpenBetsTable__table-body-row-profit">{getCurrencyFormatWithZeroAfterDot((market).pnl)}</li>
              </ul>
            }
            )
          ) : (
            <ul className="OpenBetsTable__table-body-no-bets">
              <li>
                You have no <span className="lowercase">{title}</span>
              </li>
            </ul>
          )}

        </div>
      </div>
    </section>
  );
};

export default OpenBetsCasinoTable;
