import httpAuth from '../../services/httpAuth'
import getErrorMessage from '../../helpers/getErrorMessage.helper'
import { getBetHistoryError, getBetHistorySuccess } from './bet-history.slice'

export const requestBetHistorySuccess = (data) => async (dispatch) => {
    try {
        const response = await httpAuth.post(`/bet/settledBets`, data)
        if (response.data.status) {
            dispatch(getBetHistorySuccess(response.data?.data[0]?.data))
        }
        return response
    } catch (e) {
        dispatch(getBetHistoryError())
        const errorMsg = getErrorMessage(e)
        throw errorMsg
    }
}

export const requestCasinoBetHistorySuccess = (data) => async (dispatch) => {
    try {
        const response = await httpAuth.post(`/lotus/bets`, data)
        if (response.data.status) {
            dispatch(getBetHistorySuccess(response?.data?.data?.data))
        }
        return response
    } catch (e) {
        dispatch(getBetHistoryError())
        const errorMsg = getErrorMessage(e)
        throw errorMsg
    }
}

